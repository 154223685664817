import classNames from "classnames";
import { Wrapper } from "./one-in-row.styles";

const OneInRow = ({ children, noMarginBottom, compact }) => {
  const classes = classNames("one-in-row", {
    "one-in-row--noMarginBottom": noMarginBottom,
    "one-in-row--compact": compact,
  });

  return <Wrapper className={classes}>{children}</Wrapper>;
};

export default OneInRow;
