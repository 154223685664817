import styled from "styled-components";
import { Textarea, Input } from "ui";

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const InputStyled = styled(Input)`
  line-height: 14px;
`;

export const TextareaStyled = styled(Textarea)`
  line-height: 14px;
  textarea {
    min-height: 90px;
  }
`;
