const endpoints = {
  AUTH_SIGNIN: "auth/as-customer/sign-in",
  AUTH_SIGNUP: "auth/as-customer/sign-up",
  AUTH_LOGOUT: "auth/as-customer/log-out",
  AUTH_REFRESH: "auth/refresh",
  AUTH_CHECK_AUTHORIZE: "auth/checkAuthorize",
  AUTH_RESET_PASSWORD: "auth/reset-password",
  USER_ME: "user/me",
  USER_DETAILS: "user/details",
  USER_ADDRESS: "user/address",
  SEARCH_ESTIMATE: "search/estimate",
  SEARCH_IS_EXISTS_SEARCH_RESULT: "search/isExistsSearchResult",
  CATALOG: "catalog",
  CATALOG_PRODUCTS: "catalog/products",
  CATALOG_PRICE: "catalog/price",
  CATALOG_RELATED: "catalog/relatedProducts",
  UTIL_COUNTRIES: "util/countries",
  UTIL_CATALOGS: "util/catalogs",
  UTIL_VAT_PATTERNS: "util/vat-patterns",
  UTIL_US_STATES: "util/us-states",
  UTIL_IMAGE: "util/image",
  UTIL_CATALOG_WEIGHTS: "util/catalog-weights-mg",
  UTIL_CONVERT_FROM_MRV: "util/convert-from-mrv",
  UTIL_CONVERT_TO_MRV: "util/convert-to-mrv",
  UTIL_CARRIERS: "util/carriers",
  CART_NEW: "cart/new",
  CART_CONTENT: "cart/content",
  CART_INFO: "cart/info",
  CART_CLEAR: "cart/clear",
  CART_GROUP_UPDATE: "cart/groupUpdate",
  CART_ADDITIONAL_COSTS: "cart/additional-costs",
  CART_PAYMENT_METHODS: "cart/payments",
  CART_ALL_TO_CART: "cart/allToCart",
  CART_ADD_BILLING_ADDRESS: "cart/addBillingAddress",
  ORDERS_QUOTE: "orders/quote",
  ORDERS_CREATE_REAL_QUOTE: "orders/createRealQuote",
  CUSTOMERS_ORDERS: "customers/current/orders",
  CUSTOMERS_INVOICES_PAYMENTS: "customers/current/invoices/payments",
  ORDERS_ORDER: "orders/order",
  ORDERS_SUBMIT: "orders/submit",
  ORDERS_ORDER_CONTENT: "orders/orderContent",
  APP_INFO: "app/info",
  CUSTOMERS_CURRENT: "customers/current",

  // APIv2
  CATALOG_FILTER_CLASSES: "/catalog/building-blocks/classes",
  CATALOG_FILTER_SUBCLASSES: "catalog/building-blocks/subclasses",
  CATALOG_FILTER_COLLECTIONS: "catalog/screening-compounds/collections",
  GROUP_UPDATE: 'customers/current/bucket/weight-and-count-in-catalogs',
  CATALOG_ANALYZE_COMPOUNDS: "catalog/search/analyze-compounds"
};

export default endpoints;
