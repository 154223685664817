import { useState } from "react";
import DiscardOrder from "./DiscardOrder";

const DiscardOrderContainer = ({ handleDiscard }) => {
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <DiscardOrder
      handleDiscard={handleDiscard}
      open={open}
      setOpen={setOpen}
      showTooltip={showTooltip}
      setShowTooltip={setShowTooltip}
    />
  );
};

export default DiscardOrderContainer;
