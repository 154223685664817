import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { hotjar } from "react-hotjar";
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MaterialThemeProvider } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";
import "react-toastify/dist/ReactToastify.css";
import "./eventsource-polyfill";

import App from "./App";
import theme from "./theme";
import common from "./constants/common";
import GlobalStyle from "./GlobalStyle";

import configureStore from "./redux/configureStore";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

localStorage.setItem("public-url", common.PUBLIC_URL);

hotjar.initialize(common.HOTJAR_ID, common.HOTJAR_SV);

const { store, persistor } = configureStore();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <StyledEngineProvider injectFirst>
      <MaterialThemeProvider theme={theme}>
        <GlobalStyle />
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </MaterialThemeProvider>
    </StyledEngineProvider>
  </QueryClientProvider>
);
