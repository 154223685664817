import { endpoints } from "../../constants";
import { customFetch } from "../../utils";
import api from "../../axios/api";

const initParams = {
  retry: 5,
  retryDelay: 300,
};

export async function fetchUser() {
  const res = await customFetch(
    {
      url: endpoints.USER_ME,
    },
    initParams
  );

  return res[0];
}

export async function fetchProfile() {
  const res = await api.get(endpoints.USER_DETAILS, initParams);

  return res?.data;
}

export async function fetchAddresses() {
  const res = await customFetch(
    {
      url: endpoints.USER_ADDRESS,
    },
    initParams
  );

  return res[0];
}

export const fetchLogout = async () => {
  return await customFetch({
    method: "post",
    url: endpoints.AUTH_LOGOUT,
  });
};

export const fetchPostAddress = async (data) => {
  return await customFetch(
    {
      method: "post",
      url: endpoints.USER_ADDRESS,
      data,
    },
    initParams
  );
};
