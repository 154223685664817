import React from "react";
import { ReactComponent as CartIcon } from "../../icons/cart.svg";
import PriceSelect from "../../parts/price-select/PriceSelect";
import { getButtonLabel } from "../../utils";
import { InfoList, Button, Counter } from "../index";
import {
  Wrapper,
  Name,
  StockAndCounterGroup,
  ListWrapper,
  PriceWrapper,
} from "./one-product-card.styles";
import {
  CatalogSearchResult,
  ProductPriceAndSamples,
  ProductSample,
} from "../../typings/DTO/data-contracts";
import { ProductTypesV2 } from "../../constants/productTypes";

const infoListConfig = {
  [ProductTypesV2.SCR]: {
    config: {
      catalog: { title: "Product class", order: 1 },
      molecularWeight: { title: "MW", order: 6 },
      purity: { title: "Purity", order: 11 },
      stocks: { title: "Stock Availability", order: 12 },
      leadTime: { title: "Lead time", order: 13 },
    },
    separators: [11],
    limit: null,
  },
  DEFAULT: {
    config: {
      catalog: { title: "Product class", order: 1 },
      CAS: { title: "CAS number", order: 4 },
      molecularWeight: { title: "MW", order: 6 },
      purity: { title: "Purity", order: 11 },
      stocks: { title: "Stock Availability", order: 12 },
      leadTime: { title: "Lead time", order: 13 },
    },
    separators: [11],
    limit: null,
  },
};

const getConfigByCatalog = (catalog: ProductTypesV2) => {
  return infoListConfig[catalog] || infoListConfig["DEFAULT"];
};

type OneProductCardProps = {
  item: CatalogSearchResult;
  counter: { count: number };
  handleAdd: () => void;
  isAddingToCart: boolean;
  price: {
    prices: ProductPriceAndSamples;
    setCurrentPrice: () => void;
    currentPrice: ProductSample;
    currency: { abbr: string; symbol: string };
  };
};

const OneProductCard = ({
  item,
  counter,
  handleAdd,
  isAddingToCart,
  price: { prices, setCurrentPrice, currentPrice, currency },
}: OneProductCardProps) => {
  const buttonLabel = getButtonLabel({
    catalog: item?.catalog,
    currentPrice,
  });

  return (
    <Wrapper className="one-product-card">
      <Name>{item?.code}</Name>
      <ListWrapper>
        <InfoList item={item} config={getConfigByCatalog(item.catalog)} />
      </ListWrapper>
      <PriceWrapper>
        {!currentPrice || prices?.currency !== currency?.abbr ? (
          <div>Load prices...</div>
        ) : (
          <PriceSelect
            prices={prices}
            setCurrentPrice={setCurrentPrice}
            currentPrice={currentPrice}
            currency={currency}
            variant="white"
            type="extended"
          />
        )}
      </PriceWrapper>
      <StockAndCounterGroup>
        <Counter counter={counter} variant="white" fullWidth />
      </StockAndCounterGroup>
      <Button
        iconLeft={<CartIcon />}
        onClick={handleAdd}
        fullWidth
        loading={isAddingToCart.toString()}
        disabled={!currentPrice}
      >
        {buttonLabel}
      </Button>
    </Wrapper>
  );
};

export default OneProductCard;
