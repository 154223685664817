import styled from "styled-components";
import { Title } from "ui";
import { borderColor } from "ui/colors";

export const Wrapper = styled.div``;

export const SearchSubSection = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${borderColor};

  &:last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const SearchTitle = styled(Title)`
  &.title h2 {
    font-weight: 500;
    line-height: 32px;
  }
`;
