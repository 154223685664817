import React, { Fragment } from "react";

const beautifyFormula = (formula) => {
  return [...formula].map((el, idx) => {
    const isNumber = !isNaN(parseInt(el));

    if (isNumber) {
      return <sub key={idx}>{el}</sub>;
    } else {
      return <Fragment key={idx}>{el}</Fragment>;
    }
  });
};

export default beautifyFormula;
