import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import {
  accentColor,
  darkBlue,
  darkBlueDisabled,
  lightBlue,
  lightBlueDisabled,
} from "ui/colors";

export const Wrapper = styled.div`
  border-radius: 8px;
  border: 1px solid transparent;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
  padding: 20px;
  background-color: ${lightBlueDisabled};
  color: ${darkBlueDisabled};

  ${(props) => {
    if (!props.isLoading && !props.disabled) {
      return `
        background-color: ${lightBlue};
        color: ${darkBlue};

        &:hover {
          cursor: pointer;
          border-color: ${accentColor};
          color: ${accentColor};
        }
      `;
    }
  }}
`;

export const Spinner = styled(CircularProgress)`
  width: 20px !important;
  height: 20px !important;
  color: ${accentColor};
`;

export const Title = styled.div`
  font-size: 18px;
`;

export const RightCell = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
`;
