import styled from "styled-components";

export const Wrapper = styled.div`
  &.flex {
    display: flex;

    &--marginBottom {
      margin-bottom: 28px;
    }
  }
`;
