// @ts-check

import React from "react";
import { ExpandButtonIcon, Wrapper } from "./ExpandButton.styles";

export const ExpandButton = ({ expanded }) => {
  return (
    <Wrapper>
      <ExpandButtonIcon className={expanded && `expanded`} />
    </Wrapper>
  );
};
