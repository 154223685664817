import styled from "styled-components";

export const Wrapper = styled.div`
  &.uikit {
    background-color: #fefefe;
    padding-bottom: 100px;

    h1 {
      margin: 0;
    }
  }

  .uikit__header {
    padding: 40px 20px;
    text-align: center;
    background-color: #2a3850;
    color: #fff;
  }

  .uikit__navigation {
    margin-bottom: 28px;
  }
`;
