import { SET_VERSION, SET_VERSION_READY } from "./types";

const initialState = {
  versionReady: false,
  prevVersion: "",
  version: "",
  versionChanged: null,
};

const versionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_VERSION:
      return {
        ...state,
        prevVersion: state.version,
        version: action.payload,
        versionChanged: action.payload !== state.version,
      };
    case SET_VERSION_READY:
      return {
        ...state,
        versionReady: action.payload,
      };
    default:
      return state;
  }
};

export default versionReducer;
