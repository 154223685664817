import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  events,
  useAmplitudeState,
} from "../../../../context/amplitude-context";
import { checkoutActions } from "../../../../redux/checkout";
import { Button } from "../../../../ui";
import {
  Wrapper,
  Content,
  CheckIcon,
  Title,
  Text,
  ButtonWrapper,
} from "./Thank.styles";

const Thank = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/profile/orders`);
  };

  return (
    <Wrapper>
      <Content>
        <CheckIcon />
        <Title>Thank you, the order is placed</Title>
        <Text>Wait for order confirmation in your personal account</Text>
        <ButtonWrapper>
          <Button onClick={handleClick} fullWidth>
            My Orders
          </Button>
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
};

export default Thank;
