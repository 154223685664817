// @ts-check

import React from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

import { Button, ErrorMessage } from "ui";
import Email from "parts/form-inputs/email/Email";
import { events, useAmplitudeState } from "context/amplitude-context";
import { handleSubmit, getValidationSchema } from "./utils";
import { FormLayout } from "ui/form-layout/form-layout";
import { ButtonWrapper } from "./SendEmailForm.styled";

export default function ResetPassword({ setShowCheckEmail }) {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      amplitude.logEvent(events.forgot_password, { sent_form: true });

      handleSubmit(values, { formik, setShowCheckEmail, history });
    },
  });

  return (
    <FormLayout title="Reset password">
      <form onSubmit={formik.handleSubmit}>
        <Email formik={formik} />
        {formik.errors.common ? (
          <ErrorMessage>{formik.errors.common}</ErrorMessage>
        ) : null}
        <ButtonWrapper>
          <Button
            size="small"
            type="submit"
            fullWidth
            disabled={formik.isSubmitting}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </form>
    </FormLayout>
  );
}
