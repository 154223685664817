import * as yup from "yup";
import regexPatterns from "./regexPatterns";

const validation = {
  INVOICE_NUMBERS: yup
    .string("Enter invoice numbers")
    .matches(
      regexPatterns.INVOICE_NUMBERS,
      "Invoice is not found or sender is not correct."
    )
    .required("This field cannot be blank"),
  INVOICE_TOTAL_SUM: yup
    .number()
    .positive("This field cannot be null")
    .test(
      "is-decimal",
      "The amount should be a decimal with maximum two digits after comma",
      (val) => {
        if (val != undefined) {
          return regexPatterns.TOTAL_SUM_INVOICES.test(val);
        }
        return true;
      }
    )
    .typeError((err) => {
      const originalValue = err?.originalValue;

      if (originalValue) {
        return "Total sum must be a number. For example 20.00";
      } else {
        return "This field cannot be blank";
      }
    })
    .required("This field cannot be blank"),
  FIRST_NAME: yup
    .string("Enter your First Name")
    .required("First Name is required"),
  FULL_NAME: yup.string("Enter your Full Name"),
  COMMENT: yup.string("Enter Comment"),
  COUNTRY: yup.string("Enter your Country").required("Country is required"),
  STATE: yup.string("Enter your State").required("State is required"),
  CITY: yup.string("Enter City").required("City is required"),
  ADDRESS: yup.string("Enter Address").required("Address is required"),
  ZIP: yup
    .string("Enter Postal Code")
    .max(40, "Postal code should be of maximum 40 characters length")
    .matches(regexPatterns.ZIP, "Invalid Postal Code.")
    .required("Postal Code is required"),
  LAST_NAME: yup
    .string("Enter your Last Name")
    .required("Last Name is required"),
  COMPANY: yup.string("Enter your Company").required("Company is required"),
  SEARCH: yup
    .string("Enter search string")
    .required("Search string is required"),
  PHONE: yup
    .string("Enter your phone")
    .matches(regexPatterns.PHONE, "Invalid phone number.")
    .required("Phone is required"),
  EMAIL: yup
    .string("Enter your email")
    .email("Invalid email address.")
    .matches(regexPatterns.EMAIL, "Invalid email address.")
    .required("Email is required"),
  CARRIER: yup.number("Choose carrier").required("Carrier is required"),
  CARRIER_ID: yup.string("Enter Carrier ID").when("carrier", {
    is: (carrier) => carrier !== 0,
    then: yup.string("Enter Carrier ID").required("Field is required"),
  }),
  PASSWORD: yup
    .string("Enter your password")
    .test(
      "empty-check",
      "Password must not contain empty spaces",
      (password) => {
        let isValid = true;

        if (
          password &&
          password?.length > password.replace(/\s/g, "")?.length
        ) {
          isValid = false;
        }

        return isValid;
      }
    )
    .max(40, "Password should be of maximum 40 characters length")
    .required("Password is required"),
  REPEAT_PASSWORD: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required("This field is required")
};

export default validation;
