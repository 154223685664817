import { put, call, select } from "redux-saga/effects";
import { utilActions } from "./index";
import {
  fetchCarriers,
  fetchCatalogs,
  fetchCatalogWeights,
  fetchCountries,
  fetchImages,
  fetchUsStates,
  fetchVatPatterns,
} from "./api";

export function* requestCatalogs() {
  try {
    const payload = yield call(fetchCatalogs);
    yield put(utilActions.setCatalogs(payload));
  } catch (e) {
    console.log("Something went wrong!");
  }
}

export function* requestCountries() {
  try {
    const payload = yield call(fetchCountries);
    yield put(utilActions.setCountries(payload));
  } catch (e) {
    console.log("Something went wrong!");
  }
}

export function* requestVatPatterns() {
  try {
    const payload = yield call(fetchVatPatterns);
    yield put(utilActions.setVatPatterns(payload));
  } catch (e) {
    console.log("Something went wrong!");
  }
}

export function* requestUsStates() {
  try {
    const payload = yield call(fetchUsStates);
    yield put(utilActions.setUsStates(payload));
  } catch (e) {
    console.log("Something went wrong!");
  }
}

export function* requestCatalogWeights() {
  try {
    const payload = yield call(fetchCatalogWeights);
    yield put(utilActions.setCatalogWeights(payload));
  } catch (e) {
    console.log("Something went wrong!");
  }
}

export function* requestImages({ payload }) {
  try {
    const state = yield select();
    const storeImages = state.util.images;
    const items = [];

    payload.forEach((el) => {
      const image = storeImages?.find((item) => item.code === el.code);

      if (!image) {
        items.push(el);
      }
    });

    if (items.length) {
      const result = yield call(() => fetchImages({ items }));

      yield put(utilActions.setImages(result));
    }
  } catch (e) {
    console.log(e);
    console.log("Something went wrong!");
  }
}

export function* requestCarriers() {
  try {
    const res = yield fetchCarriers();
    yield put(utilActions.setCarriers(res));
  } catch (e) {
    console.log("Something went wrong!");
  }
}
