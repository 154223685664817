// @ts-check

import React, { useMemo } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import animateScrollTo from "animated-scroll-to";
import { usePathname, usePrevLocation } from "hooks";

export const HomeLink = ({ children, to, className, ...rest }) => {
  const pathname = usePathname();
  const prevLocation = usePrevLocation();

  const isHomePage = useMemo(
    () => pathname === to && prevLocation === to,
    [pathname, prevLocation, to]
  );

  return isHomePage ? (
    <div
      className={classNames(className, `${className}--pseudo`)}
      data-test="footer-nav-item-search"
      onClick={() => animateScrollTo(0)}
    >
      {children}
    </div>
  ) : (
    <Link
      {...rest}
      to={to}
      className={className}
      data-test="footer-nav-item-search"
    >
      {children}
    </Link>
  );
};
