import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { checkoutActions, checkoutSelectors } from "../../../../redux/checkout";
import { getValidationSchema } from "./utils";
import Additional from "./Additional";

const AdditionalContainer = () => {
  const dispatch = useDispatch();
  const checkoutData = useSelector(checkoutSelectors.selectData);

  const formik = useFormik({
    initialValues: {
      poNumber: checkoutData?.additional?.poNumber,
      comment: checkoutData?.additional?.comment,
    },
    validationSchema: getValidationSchema(),
  });

  useEffect(() => {
    const newData = { ...checkoutData };
    newData.additional = formik.values;
    dispatch(checkoutActions.setData(newData));
  }, [formik.values.poNumber, formik.values.comment]);

  return <Additional formik={formik} />;
};

export default AdditionalContainer;
