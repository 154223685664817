import classNames from "classnames";
import { Wrapper } from "./title.styles";

const Title = ({ children, variant = "h2", className = "", ...rest }) => {
  const classes = classNames("title", className);

  const TagName = variant;

  return (
    <Wrapper className={classes} {...rest}>
      <TagName>{children}</TagName>
    </Wrapper>
  );
};

export default Title;
