import styled from "styled-components";
import { accentColor } from "ui/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  margin-right: -12px;
`;

export const TabItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  padding: 0 12px;
  position: relative;
  box-sizing: border-box;

  &:nth-child(1) .labled-tabs__image-wrapper svg {
    width: 80%;
  }
  &:nth-child(2) .labled-tabs__image-wrapper svg {
    width: 80%;
  }
  &:nth-child(3) .labled-tabs__image-wrapper svg {
    width: 80%;
  }
  &:nth-child(4) .labled-tabs__image-wrapper svg {
    height: 80%;
  }
`;

const hoveredBorderCss = `border: 1px solid ${accentColor};`;

export const ImageWrapper = styled.div`
  border: 1px solid rgb(227, 227, 227);
  width: 100%;
  padding-top: 50%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-size: 95%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  svg {
    position: absolute;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }

  ${({ isHovered }) => {
    if (isHovered) {
      return hoveredBorderCss;
    }
  }}

  ${({ isActive }) => {
    if (isActive) {
      return `
        background-color: #E8F6FF;
        border-color: ${accentColor};
      `;
    } else {
      return `
        &:hover {
          ${hoveredBorderCss}
        }
      `;
    }
  }}
`;

export const Image = styled.img`
  width: 140px;
`;

export const Title = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  margin-top: 16px;
  color: rgba(13, 13, 13, 0.8);

  ${({ isActive }) => {
    if (isActive) {
      return `
        color: ${accentColor};
        font-weight: 500;
      `;
    }
  }}
`;
