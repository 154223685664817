import React from "react";
import { Input } from "../../../ui";

const FullName = ({ formik, ...props }) => {
  return (
    <Input
      label="Full Name"
      name="fullName"
      formik={formik}
      data-test="full_name_input"
      placeholder="Enter full name"
      {...props}
    />
  );
};

export default FullName;
