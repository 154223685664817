import * as React from "react";
import { DeleteIcon, DownloadIcon, SettingsIcon, TableWeight } from "ui/icons";
import { Button, Dropdown } from "ui";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../redux/user";

export default function ModifyCartSelect({
  handleClearCart,
  handleDownloadSdf,
  handleGroupUpdate,
  isSdfLoading,
}) {
  const isAuth = useSelector(userSelectors.selectIsAuth);
  return (
    <div>
      <Dropdown
        control={
          <Button
            type="outlined-white"
            size="small"
            dataTestAttr=""
            iconLeft={<SettingsIcon />}
          >
            Modify cart
          </Button>
        }
        dataTestAttr="cart-settings-dropdown"
      >
        <Dropdown.MenuItem
          icon={<DeleteIcon />}
          style={{ minWidth: "240px" }}
          dataTestAttr=""
          onClick={handleClearCart}
        >
          Clear cart
        </Dropdown.MenuItem>
        <Dropdown.MenuItem
          icon={<DownloadIcon />}
          style={{ minWidth: "240px" }}
          dataTestAttr=""
          onClick={handleDownloadSdf}
          disabled={isSdfLoading}
        >
          Download SDF
        </Dropdown.MenuItem>
        {
          isAuth && <Dropdown.MenuItem
          icon={<TableWeight />}
          className="fillNone"
          style={{ minWidth: "240px" }}
          dataTestAttr=""
          onClick={handleGroupUpdate}
          disabled={isSdfLoading}
        >
          Group update
        </Dropdown.MenuItem> }
      </Dropdown>
    </div>
  );
}
