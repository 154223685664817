import classNames from "classnames";
import { Wrapper } from "./two-in-row.styles";

const TwoInRow = ({ children, compact }) => {
  const classes = classNames("two-in-row", {
    "two-in-row--compact": compact,
  });

  return <Wrapper className={classes}>{children}</Wrapper>;
};

export default TwoInRow;
