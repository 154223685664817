import { InfoCell } from "features/super-cart/Summary/Summary.styled";
import React from "react";
import { CartItemResponse } from "typings/DTO/data-contracts";
import { CustomizedTooltip } from "ui";
import { InfoIcon } from "ui/icons";
import { LikeLinkButton } from "../../../../components";
import { CheckoutCartItem, Loader } from "../../../../ui";
import DiscardOrderContainer from "../discard-order/DiscardOrder.container";
import PendingTimerContainer from "../pending-timer/PendingTimer.container";
import { OrderSummary } from "./Sidebar.container";
import {
  Button,
  Content,
  Divider,
  Footer,
  FooterInfoRow,
  Header,
  InfoTitle,
  InfoValue,
  List,
  ListItem,
  Scrollable,
  Title,
  Wrapper,
} from "./Sidebar.styled";

type SidebarProps = {
  cartItems: CartItemResponse[];
  handleEdit: VoidFunction;
  isLoading: boolean;
  handleConfirm: (items: CartItemResponse[]) => void;
  readyToConfirm: boolean;
  orderSummary: OrderSummary;
  confirmTitle: string;
  whenCanBePaid: string;
  isDisabledConfirmButton: boolean;
  pendingTimerCallback: () => void;
  handleDiscard: () => Promise<void>;
  showDiscard: boolean;
  inqueryItems: CartItemResponse[];
  inStockItems: CartItemResponse[];
};

const Sidebar = ({
  inqueryItems,
  inStockItems,
  handleEdit,
  isLoading,
  handleConfirm,
  readyToConfirm,
  orderSummary,
  confirmTitle,
  whenCanBePaid,
  isDisabledConfirmButton,
  pendingTimerCallback,
  handleDiscard,
  showDiscard,
}: SidebarProps) => {
  return (
    <Wrapper>
      {isLoading ? (
        <Loader fullWidth paddings>
          Loading total...
        </Loader>
      ) : (
        <>
          {Boolean(inStockItems.length) && (
            <>
              <Header>
                <Title>In Stock</Title>
                <LikeLinkButton onClick={handleEdit}>Edit cart</LikeLinkButton>
              </Header>
              <Content>
                <Scrollable>
                  <List>
                    {inStockItems.map((el, idx) => {
                      return (
                        <ListItem key={idx}>
                          <CheckoutCartItem item={el} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Scrollable>
              </Content>
            </>
          )}
          {Boolean(inqueryItems.length) && (
            <>
              <Header>
                <InfoCell>
                  <Title>Synthesis</Title>
                  <CustomizedTooltip
                    title="Our chemists will evaluate the synthesis and get 
back to you with price and lead time confirmation 
within 1-2 days. No worries, it’s free of charge."
                    placement="right"
                  >
                    <InfoIcon />
                  </CustomizedTooltip>
                </InfoCell>
              </Header>
              <Content>
                <Scrollable>
                  <List>
                    {inqueryItems.map((el, idx) => {
                      return (
                        <ListItem key={idx}>
                          <CheckoutCartItem item={el} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Scrollable>
              </Content>
            </>
          )}
          <Footer>
            <FooterInfoRow>
              <InfoCell>
                <InfoTitle>Subtotal</InfoTitle>
                {Boolean(inqueryItems.length) && (
                  <CustomizedTooltip title="Order includes feasible compounds which will be confirmed by our sales representative">
                    <InfoIcon />
                  </CustomizedTooltip>
                )}
              </InfoCell>

              <InfoValue>{orderSummary?.subtotal}</InfoValue>
            </FooterInfoRow>
            <Divider mt={10} mb={10} />
            <FooterInfoRow>
              <InfoTitle>Handling</InfoTitle>
              <InfoValue>{orderSummary?.handling}</InfoValue>
            </FooterInfoRow>
            <FooterInfoRow>
              <InfoTitle>Shipping</InfoTitle>
              <InfoValue>{orderSummary?.shipping}</InfoValue>
            </FooterInfoRow>
            <Divider mt={10} mb={10} />
            <FooterInfoRow>
              <InfoTitle>Tax</InfoTitle>
              <InfoValue>{orderSummary?.tax}</InfoValue>
            </FooterInfoRow>

            <FooterInfoRow>
              <InfoTitle>Discount</InfoTitle>
              <InfoValue>{orderSummary?.discount}</InfoValue>
            </FooterInfoRow>
            <Divider mt={12} mb={15} />
            <FooterInfoRow bold>
              <InfoTitle>Total price</InfoTitle>
              <InfoValue>{orderSummary?.total}</InfoValue>
            </FooterInfoRow>

            <PendingTimerContainer
              whenCanBePaid={whenCanBePaid}
              callback={pendingTimerCallback}
            />

            <Button
              onClick={handleConfirm}
              disabled={isDisabledConfirmButton}
              type="success"
              variant="success"
              style={{
                display: readyToConfirm ? "inline-flex" : "none",
              }}
            >
              {confirmTitle}
            </Button>

            {showDiscard ? (
              <div
                style={{
                  position: "relative",
                  top: "10px",
                  paddingBottom: "5px",
                }}
              >
                <DiscardOrderContainer handleDiscard={handleDiscard} />
              </div>
            ) : null}
          </Footer>
        </>
      )}
    </Wrapper>
  );
};

export default Sidebar;
