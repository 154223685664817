import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { customFetch } from "utils";
import { endpoints, rootPages } from "constants";
import { prepareData } from "./utils";
import { checkoutActions } from "redux/checkout";
import { events, useAmplitudeState } from "context/amplitude-context";
import { MODES } from "./constants";
import superCartActions from "features/super-cart/redux/actions";

const useHandleConfirmCheckout = ({
  setMode,
  checkoutData,
  cartItems,
  currency,
  cartInfo,
}) => {
  const history = useHistory();
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();

  const total = cartInfo.data?.total;

  const handleConfirm = async () => {
    setMode(MODES.SENDING_ORDER);

    const data = prepareData({
      ...checkoutData,
      currency: currency.abbr,
    });

    const res = await customFetch({
      url: `${endpoints.ORDERS_SUBMIT}`,
      method: "post",
      data,
    });

    if (res[1]) {
      setMode(MODES.ERROR);
    } else if (res[0]) {
      // REVENUE AND EVENTS
      cartItems.forEach((cartItem) => {
        const revenue = new amplitude.Revenue()
          .setProductId(cartItem.code)
          .setPrice(cartItem.price)
          .setQuantity(cartItem.quantity)
          .setRevenueType("order");

        amplitude.logRevenueV2(revenue);
      }); // END. REVENUE AND EVENTS

      amplitude.logEvent(events.checkout_order, {
        total: total,
        currency: currency.abbr,
      });

      const paymentType = checkoutData?.payment?.type;

      if (paymentType === "card") {
        const paymentToken = res[0]?.paymentToken;

        localStorage.setItem("payment-token", paymentToken);

        history.push(`/${rootPages.SUBMIT_PAYMENT_FORM}`);
      } else {
        setMode(MODES.THANK);
        dispatch(superCartActions.clear());
        dispatch(checkoutActions.setInitial());
      }
    }
  };

  return { handleConfirm };
};

export default useHandleConfirmCheckout;
