import api from "../axios/api";
import { endpoints } from "../constants";
import { Buffer } from "buffer";
import { toast } from "react-toastify";
import isBase64Valid from "./isBase64Valid";
import { common } from "constants/index";

const initParams = {
  retry: 5,
  retryDelay: 300,
};

type ResponseHeaders = Record<string, string | number | boolean>;
type ResponseStatus = number;
type ResponseMessage = string;

export type DataResponse<T> = [
  T,
  ResponseMessage?,
  ResponseStatus?,
  ResponseHeaders?
];

export enum RequestMethods {
  GET = "get",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
  DELETE = "delete",
  OPTIONS = "options",
}

const decryptResponseData = async (
  encryptedAndBase64: string,
  secretKey: string
) => {
  try {
    const encrypted = Buffer.from(encryptedAndBase64, "base64");
    const bufferKey = Buffer.from(secretKey);

    const initVector = encrypted.slice(0, 16);
    const ciphertext = encrypted.slice(16);
    const algorithm = { name: "AES-GCM", iv: initVector };
    const key = await crypto?.subtle?.importKey(
      "raw",
      bufferKey,
      algorithm,
      false,
      ["decrypt"]
    );
    const decryption = await crypto?.subtle?.decrypt(
      algorithm,
      key,
      ciphertext
    );

    const decodedString = new TextDecoder().decode(decryption);
    return JSON.parse(decodedString);
  } catch (error) {
    toast.error("Something went wrong");
    console.log(error);
    return null;
  }
};

const customFetch = async <T = any>(
  {
    method = RequestMethods.GET,
    url,
    data,
    config,
  }: {
    method?: RequestMethods;
    url: string;
    data?: Record<string, string | number | boolean> | FormData;
    config?: any;
  },
  params = initParams
): Promise<DataResponse<T>> => {
  try {
    let res;

    switch (method) {
      case RequestMethods.GET:
      case RequestMethods.DELETE:
      case RequestMethods.OPTIONS:
        res = await api[method](url, { ...config, ...params });
        break;
      case RequestMethods.POST:
      case RequestMethods.PUT:
      case RequestMethods.PATCH:
        res = await api[method](url, data, { ...config, ...params });
        break;
      default:
        throw new Error(`Method "${method}" not found'`);
    }

    let responseData = res.data;

    if (responseData && isBase64Valid(responseData)) {
      responseData = await decryptResponseData(res.data, common.DECRYPTION_KEY);
    }

    return [responseData, null, res.status, res.headers];
  } catch (err) {
    if (!err.response) {
      return [null, "Something went wrong. Please try again later.", null];
    }

    if (err.response) {
      const message =
        err.response.data?.message ||
        err.response.data?.details ||
        err.response;

      let newMessage = message;

      const isResetPasswordUrl = err.response.request.responseURL.includes(
        endpoints.AUTH_RESET_PASSWORD
      );

      if (err?.response.status === 403) {
        newMessage = "Invalid email / password combination.";
      } else if (isResetPasswordUrl) {
        newMessage = "Invalid email address.";
      }

      return [null, newMessage, err.response.status];
    } else {
      console.error('"Catch in customFetch util:"', err);
      return [null, "Some unhandled error!"];
    }
  }
};

export default customFetch;
