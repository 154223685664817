import { useEffect, useState } from "react";

const useCountdown = (targetDate) => {
  const [countDownDate, setCountDownDate] = useState(
    new Date(targetDate).getTime()
  );
  const [stop, setStop] = useState(false);

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    if (stop) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [countDownDate, stop]);

  return {
    ...getReturnValues(countDown, setStop),
    setCountDownDate,
    setStop,
  };
};

const getReturnValues = (countDown) => {
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { minutes, seconds };
};

export default useCountdown;
