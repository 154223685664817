import * as React from "react";
import { Box } from "@mui/system";
import Fade from "@mui/material/Fade";
import { Backdrop } from "ui";
import {
  Modal,
  Title,
  IconButtonStyled,
  ContentWrapper,
  TextStyled,
  ButtonsStyled,
} from "./modal.styles";
import { CloseIcon } from "ui/icons";
import { isFunc } from "utils";

const style = () => {
  return {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -40%)",
    minWidth: 450,
    backgroundColor: "white",
    borderRadius: "8px",
    outline: "none",
    maxHeight: "100vh",
    overflowY: "auto",
    zIndex: 2000,
  };
};

const TransitionsModal = ({
  children,
  title,
  open,
  setOpen,
  disabled,
  width,
  boxProps = {},
  styles = {},
  backdropProps = {},
  dataTestAttr,
  hideCloseIcon,
  onClose,
  ...rest
}) => {
  const handleClose = () => {
    setOpen(false);

    if (isFunc(onClose)) {
      onClose();
    }
  };

  const sx = {
    ...style(),
    ...styles,
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        date-test={dataTestAttr}
        {...rest}
      >
        <Fade in={open} timeout={300}>
          <div>
            {open && <Backdrop onClick={handleClose} {...backdropProps} />}
            <Box sx={sx} width={width} maxWidth="100%" {...boxProps}>
              {title ? <Title data-test="modal-title">{title}</Title> : ""}
              {!hideCloseIcon ? (
                <IconButtonStyled
                  onClick={handleClose}
                  size="medium"
                  disabled={disabled}
                  data-test="close-modal-button"
                >
                  <CloseIcon />
                </IconButtonStyled>
              ) : null}

              <ContentWrapper>{children}</ContentWrapper>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransitionsModal;

TransitionsModal.Text = ({ children }) => {
  return <TextStyled>{children}</TextStyled>;
};

TransitionsModal.Buttons = ({ children }) => {
  return <ButtonsStyled>{children}</ButtonsStyled>;
};
