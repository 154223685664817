import { useEffect } from "react";
import animateScrollTo from "animated-scroll-to";
import { usePathname, usePrevLocation } from "../../hooks";
import { endpoints } from "../../constants";

const ScrollToTop = () => {
  const pathname = usePathname();
  const prevLocation = usePrevLocation();

  useEffect(() => {
    if (prevLocation) {
      if (pathname !== `/${endpoints.CATALOG}`) {
        animateScrollTo(0);
      }
    }
  }, [pathname, prevLocation]);

  return null;
};

export default ScrollToTop;
