import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 28px;
  row-gap: 24px;

  &.two-in-row--compact {
    column-gap: 16px;
    row-gap: 18px;
  }
`;
