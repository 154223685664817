import React from "react";
import {
  Cell,
  Group,
  Image,
  Info,
  MessageRow,
  Row,
  SubInfo,
  Title,
  Wrapper,
} from "./CheckoutCartItem.styled";

const additionalMessage =
  "Feasible compound. Price will be confirmed by our sales representative";

type CheckoutCartItemProps = {
  title: string;
  image: string;
  count: number;
  amount: string;
  price: string;
  isInquery: boolean;
};

const CheckoutCartItem = ({
  title,
  image,
  count,
  amount,
  price,
  isInquery,
}: CheckoutCartItemProps) => {
  return (
    <Wrapper>
      <Group>
        <Cell>
          <Image style={{ backgroundImage: `url(${image})` }} />
        </Cell>
        <Info>
          <Row>
            <Title>{title}</Title>
            <SubInfo>
              {count} x {amount} x {price}
            </SubInfo>
          </Row>
          {isInquery && <MessageRow>{additionalMessage}</MessageRow>}
        </Info>
      </Group>
    </Wrapper>
  );
};

export default CheckoutCartItem;
