import React from "react";
import { useHistory } from "react-router-dom";
import { rootPages } from "constants";
import Sidebar from "./Sidebar";

export type OrderSummary = {
  subtotal: string;
  handling: string;
  shipping: string;
  tax: string;
  discount: string;
  total: string;
};

type SidebarContainerProps = {
  handleConfirm: () => void;
  readyToConfirm: boolean;
  isLoading: boolean;
  cartItems: any[];
  orderSummary: OrderSummary;
  confirmTitle: string;
  whenCanBePaid: string;
  isDisabledConfirmButton: boolean;
  pendingTimerCallback: () => void;
  handleDiscard: () => Promise<void>;
  showDiscard: boolean;
};

const SidebarContainer = ({
  handleConfirm = () => {},
  readyToConfirm = false,
  isLoading = false,
  cartItems = [],
  orderSummary = {},
  confirmTitle = "",
  whenCanBePaid,
  isDisabledConfirmButton,
  pendingTimerCallback,
  handleDiscard,
  showDiscard,
}: SidebarContainerProps) => {
  const history = useHistory();

  const handleEdit = () => {
    history.push(`/${rootPages.CART}`);
  };

  const inqueryItems = cartItems.filter((item) => !!item?.isInquery);
  const inStockItems = cartItems.filter((item) => !item?.isInquery);

  return (
    <Sidebar
      inqueryItems={inqueryItems}
      inStockItems={inStockItems}
      handleEdit={handleEdit}
      isLoading={isLoading}
      handleConfirm={() => handleConfirm(cartItems)}
      readyToConfirm={readyToConfirm}
      orderSummary={orderSummary}
      confirmTitle={confirmTitle}
      whenCanBePaid={whenCanBePaid}
      isDisabledConfirmButton={isDisabledConfirmButton}
      pendingTimerCallback={pendingTimerCallback}
      handleDiscard={handleDiscard}
      showDiscard={showDiscard}
    />
  );
};

export default SidebarContainer;
