import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { onboardingActions, onboardingSelectors } from "../redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { rootPages } from "constants/index";
import SorryToSeeYouGoPopup from "./markup";

const SorryToSeeYouGoPopupContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const open = useSelector(onboardingSelectors.selectShowSorryPopup);

  const setOpen = (isOpen) => {
    dispatch(onboardingActions.setShowSorryPopup(isOpen));
  };

  const handleGoToOldStore = () => {
    setOpen(false);
    history.push(`/${rootPages.REDIRECT_TO_OLD_STORE}`);
  };

  return (
    <SorryToSeeYouGoPopup
      open={open}
      setOpen={setOpen}
      handleGoToOldStore={handleGoToOldStore}
    />
  );
};

export default SorryToSeeYouGoPopupContainer;
