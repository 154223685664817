import { Button } from "ui/index";
import PriceSelect from "parts/price-select/PriceSelect";
import React from "react";
import { CartIcon } from "ui/icons";
import { convertDataByConfig } from "ui/info-list/info-list";
import {
  Wrapper,
  InfoWrapper,
  Code,
  Image,
  PriceList,
  DetailsListWrapper,
  DetailsListItem,
  ImageWrapper,
} from "./CatalogGridCard.styled";
import Spinner from "../../spinner/Spinner";

export const CatalogGridCard = ({
  item,
  infoListConfig,
  image,
  code,
  handleClick,
  href,
  buttonLabel,
  readyToCart,
  isAddingToCart,
  handleAdd,
  setCurrentPrice,
  currentPrice,
  currency,
}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        {image ? (
          <Image
            style={{ backgroundImage: `url(${image?.data || image?.url})` }}
            onClick={handleClick}
            to={href}
          />
        ) : (
          <Spinner size={36} isLoading />
        )}
      </ImageWrapper>

      <InfoWrapper>
        <Code to={href} onClick={handleClick}>
          {code}
        </Code>
        <PriceList>
          <PriceSelect
            prices={item?.prices}
            setCurrentPrice={setCurrentPrice}
            currentPrice={currentPrice}
            currency={currency}
            label={false}
            fullWidth
          />
        </PriceList>
        <Button
          iconLeft={<CartIcon />}
          fullWidth
          size="small"
          onClick={handleAdd}
          isLoading={isAddingToCart}
          disabled={!readyToCart}
          data-test="add_to_cart_button"
        >
          {buttonLabel}
        </Button>
        <DetailsList
          className="list-wrapper"
          item={item?.product}
          config={infoListConfig}
        />
      </InfoWrapper>
    </Wrapper>
  );
};

const DetailsList = ({ item, config, className }) => {
  const list = convertDataByConfig(item, config);
  return (
    <DetailsListWrapper className={className}>
      {list.map((item) => (
        <DetailsListItem key={item.key}>
          <span>{item.title}</span>
          <span title={item.raw}>{item.value || "-"}</span>
        </DetailsListItem>
      ))}
    </DetailsListWrapper>
  );
};
