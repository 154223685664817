import styled from "styled-components";
import { accentColor } from "ui/colors";

export const Wrapper = styled.div`
  .restriction__control {
    font-weight: 500;
    color: ${accentColor};
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    &:hover {
      opacity: 0.7;
    }
  }

  .restriction__text {
    margin-top: 1px;
  }

  .restriction__icon {
    margin-right: 8px;
    display: flex;

    svg {
      width: 24px;
      fill: ${accentColor};
    }
  }
`;
