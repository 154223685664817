import { PUSH_ERROR, CLEAR_ERRORS } from "./types";

const initialState = {
  errors: [],
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_ERROR:
      return {
        errors: [...state.errors, action.payload],
      };
    case CLEAR_ERRORS:
      return {
        errors: [],
      };
    default:
      return state;
  }
};

export default errorReducer;
