import { useDispatch } from "react-redux";
import { loadersActions } from "../redux";

const useLoaderFullPage = () => {
  const dispatch = useDispatch();

  return {
    showLoaderFillPage: () => {
      dispatch(loadersActions.setLoaderFullPage(true));
    },
    hideLoaderFillPage: () => {
      dispatch(loadersActions.setLoaderFullPage(false));
    },
  };
};

export default useLoaderFullPage;
