import styled from "styled-components";

export const Text = styled.p`
  margin: 0;
  line-height: 32px;
  font-size: 24px;
  text-align: center;
  margin-top: 60px;
`;

export const Page = styled.div`
  padding-top: 32px;
`;

export const PaperInner = styled.div`
  padding-top: 120px;
  padding-right: 30px;
  padding-bottom: 120px;
  padding-left: 30px;
`;
