import userTitles from "../constants/userTitles";

const getUserTitlesOptions = () => {
  return userTitles.map((el) => {
    let title = el;

    if (el === "Undefined") {
      title = "--";
    }

    return { value: el, title };
  });
};

export default getUserTitlesOptions;
