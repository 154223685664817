import React, { Fragment } from "react";
import { Link } from "../index";
import { Wrapper } from "./nav-link.styles";

const NavLink = ({ children, to, href, active, ...props }) => {
  const common = (
    <Wrapper className="nav-link">
      <span className="nav-link__text">{children}</span>
    </Wrapper>
  );

  if (href) {
    return (
      <a href={href} style={{ textDecoration: "none" }} {...props}>
        {common}
      </a>
    );
  } else if (to) {
    return (
      <Link to={to} disableDecoration {...props}>
        {common}
      </Link>
    );
  }

  return (
    <div style={{ cursor: "pointer" }} {...props}>
      {common}
    </div>
  );
};

export default NavLink;
