const generateCatalogsArray = (catalogs) => {
  if (catalogs) {
    return Object.keys(catalogs).map((key) => ({
      title: catalogs[key],
      key,
    }));
  }

  return null;
};

export default generateCatalogsArray;
