/* eslint-disable react/no-unescaped-entities */
import React from "react";

const Content = () => {
  return (
    <div className="content">
      <h2>1. Introduction</h2>
      <p>
        1.1. <a href="http://EnamineStore.com">EnamineStore.com</a> is an
        electronic service for online browsing and shopping for products
        supplied by Enamine (hereinafter referred to as "Enamine"). These Terms
        of Use and Sale (the "Terms of Use") apply to the EnamineStore web site
        located at <a href="http://www.enaminestore.com">EnamineStore</a>{" "}
        (hereinafter referred to as, the "Site"). The Site is the property of
        Enamine. By using the site, you agree to these Terms of Use and Sale, if
        you do not agree, do not use the Site.
      </p>
      <p>
        1.2. Enamine reserves the right, at its sole discretion, to change,
        modify, add or remove any provisions of these Terms of Use, at any time
        without notice. It is your responsibility to check these Terms of Use
        periodically for changes. If you continue to use the Site following the
        posting of changes, you accept and agree to the changes.
      </p>
      <p>
        1.3. The entity you are contracting with is SIA Enamine, a limited
        liability company established under the laws of Latvia and having its
        principal place of business at Vestienas iela 2 B, LV-1035 Riga, Latvia
        if you reside in the European Union and Enamine LLC, a limited liability
        company established under the law of state of New Jersey of the United
        States of America and having its principal place of business at
        Princeton Corporate Plaza, 7 Deer Park Drive, Ste. M-3, Monmouth Jct.,
        NJ 08852, if you reside in United States of America or other countries
        of the world.
      </p>
      <h2>2. Definitions</h2>
      <p>
        "Catalog" means whole Enamine, UkrOrgSyntez Ltd (UORSY) and FCH Group
        (FCH) catalogs or any selections made from these catalogs and requested
        by the client including but not limited to online{" "}
        <a href="http://EnamineStore.com">EnamineStore.com</a> searches,
        requests sent by electronic mail, fax, regular post, or in oral
        form."Confidential Information" means information of a proprietary
        nature including but not limited to prices, request(s) for quotation,
        purchase requisitions, purchase order information, user to user
        transactional information, the fee and this agreement. "Products" means
        reagents for synthesis (Building Blocks) and screening compounds
        intended primarily for laboratory research offered for sale and sold by
        EnamineStore to Purchasers via the Site."Purchaser" means a registered
        user who uses the Site to purchase Products from Enamine. "Site" means
        EnamineStore website to be found at&nbsp;
        <a href="https://www.enaminestore.com/">https://www.enaminestore.com</a>
        . "User" means a Purchaser registered via Enamine Site and may be
        referred to as "you", "your" in this Terms.
      </p>
      <h2>3. Content</h2>
      <p>
        3.1. All text, formulas, photographs, trademarks, logos (collectively,
        "Content"), including but not limited to the design, structure,
        selection, coordination, expression and arrangement of such Content,
        contained on the Site is owned, controlled or licensed by or to Enamine,
        and is protected by copyright, patent and trademark laws, and various
        other intellectual property rights and unfair competition laws.
      </p>
      <p>
        3.2. Except as expressly provided in these Terms of Use, no part of the
        Site and no Content may be copied, reproduced, republished, uploaded,
        posted, publicly displayed, encoded, translated, transmitted or
        distributed in any way to any other computer, server, web site or other
        medium for publication or distribution or for any commercial enterprise,
        without Enamine's express prior written consent.
      </p>
      <p>
        3.3 Unless otherwise specified in a valid agreement between client and
        Enamine, Catalog shall be used only for information purpose or
        purchasing of compounds from Enamine and its affiliates.
      </p>
      <h2>4. Purchaser Account Registration and Password Reset</h2>
      <p>
        4.1. Registration of the account is not required to search the catalog,
        browse through results, check compound details, and export structural
        data. You may make all the mentioned activity as a Guest user. However,
        it is recommended to register and authorize while visiting{" "}
        <a href="http://www.enaminestore.com">EnamineStore</a> and{" "}
        <a href="http://www.enamine.net">
          <img
            src="https://enamine.net/templates/navi-enamine-new/favicon.ico"
            alt="favicon"
          />
          Home - Enamine
        </a>{" "}
        , as authorization enables saving your custom sets of compounds and
        provides access to libraries for off-line use provided through{" "}
        <a href="http://enamine.net">
          <img
            src="https://enamine.net/templates/navi-enamine-new/favicon.ico"
            alt="favicon"
          />
          Home - Enamine
        </a>{" "}
        .
      </p>
      <p>
        4.2. To be able to process your order you should complete the User
        registration form and agree with these Terms of Use and related
        policies.
      </p>
      <p>
        4.3. Your Enamine account may be accessed only by use of your login and
        password. You are solely responsible and liable for any use and misuse
        of your login and password and for all activities that occur under your
        login and password. For security reasons, you must keep your login and
        password confidential and not disclose them to any person or permit any
        other person to use them, except an authorized representative of
        Enamine. Enamine recommends that you choose a password that is unique to
        you and not easily guessed by others. You must log out at the end of
        each session.
      </p>
      <p>
        4.4. If you have forgotten your login or password, you can use an easy
        password resetting procedure to retrieve login and a new password to
        your email address given in the process of registration. You can always
        change your personal data in the Profile section.
      </p>
      <p>
        4.5. While registering a new user we assume that the given email may be
        used as a primary electronic address. Consequently, we do not register
        any user with the same email. If you do not remember your registration
        information, please reset it using Password Reset option. Upon resetting
        password, a message with your login and new password will be sent to
        your email.
      </p>
      <h2>5. Accuracy of Information</h2>
      <p>
        We rely on the information you provide through this Site, including
        registration information (name and e-mail address) and
        transaction-related information (including but not limited to credit
        card numbers and expiration dates, shipping address etc.), which must be
        true, accurate, current and complete. You are required to update your
        registration information, if applicable, and will be solely responsible
        and liable for any and all loss, damage, and additional costs that you,
        we or any other person may incur as a result of your submission of any
        false, incorrect or incomplete information. You acknowledge that you
        will keep your contact information current and accurate in your Enamine
        account section of the Site.
      </p>
      <h2>
        6. Product Price, Availability, Quantity, Quality and Order Limits
      </h2>
      <p>
        6.1. Prices from Enamine catalog are usually actual and up to date.
        Prices at UORSY and FCH catalog are provided as a reference only and
        must be confirmed by our managers within 3 (three) days from the date of
        your order.
      </p>
      <p>
        6.2. Product prices and availability are subject to change at any time
        and without notice. Enamine may place a limit on the quantities that may
        be purchased per order, per account, per credit card, per purchaser.
      </p>
      <p>
        6.3. Enamine may refuse or reject any order at any time for reasons
        which include, but are not limited to, if you have not met the
        conditions specified at the time of the order, if the ordered products
        or services are not available, or for obvious errors on the Site or made
        in connection with your order. If we are unable to supply the products
        that you ordered, we will contact you and may offer you an alternative
        product to consider instead. If you do not choose to purchase the
        alternative product, we will cancel your order. In the event of obvious
        errors on the Site or made in connection with your order, we reserve the
        right to correct the error and charge you the correct price. In that
        situation, we will contact you and offer you the option of purchasing
        the product at the correct price or canceling your order.
      </p>
      <p>
        6.4. Quality of the product shall comply with the specifications set
        forth in the Catalog posted at the Site for a certain product.
      </p>
      <p>
        6.5. The Product chemical structure drawing, name, and stereochemistry are
        displayed in respect with IUPAC recommendations:{' '}
        <a href="https://www.degruyter.com/view/db/iupac">
          IUPAC Standards Online
        </a>{" "}
        and{" "}
        <a href="http://publications.iupac.org/pac/2006/pdf/7810x1897.pdf">
          "Graphical representation of stereochemical configuration"
        </a>
        : “_In the absence of any other sort of indicators, a structural diagram
        is expected to represent the specific molecular entity drawn. If
        stereochemical hashed wedged and/or solid wedged bonds are present at
        all stereogenic centers in the diagram, it will represent a single
        stereoisomer. If no stereochemical information is present in the
        diagram, it represents a molecular entity with unknown configuration_.”
        .
      </p>
      <h2>7. Payment options</h2>
      <p>
        7.1. The payment for the product shall be made within 45 days from the
        date the purchaser receives the product. In case of payment by way of
        credit card, the credit card shall be charged only after the delivery to
        the purchaser.
      </p>
      <p>
        7.2. If one or more products are unavailable, Enamine will notify the
        purchaser and the cost of unavailable and not dispatched products will
        be deducted from the total order.
      </p>
      <p>
        7.3. Different payment options are available for the purchaser. The
        purchaser can make the payment by credit card, wire transfer or
        cashier's check. Specific payment methods may be unavailable in certain
        region. You should select the payment option available to your region
        during check-out procedure.
      </p>
      <p>
        7.4. If the purchaser makes payment other than by a credit card, the
        purchaser has to have a valid purchase order issued and enter its number
        at the check-out stage, otherwise processing of the order may be
        delayed.
      </p>
      <p>
        7.5. Enamine does not keep bank card numbers used for purchase. This
        information is transferred directly to the processing agency and cannot
        be accessed by Enamine.
      </p>
      <h2>8. Terms and Conditions for Discount Programs:</h2>
      <ul>
        <li>
          <p>
            8.1. Promotional discounts are valid for online purchases at
            EnamineStore only. Any reference to special sales conditions
            announced at EnamineStore will be rejected if orders are received by
            fax, e-mail, or post.
          </p>
        </li>
        <li>
          <p>
            8.2. Promotional discounts are subject to availability of compounds
            in stock. In particular, they are applied to building blocks which
            amount in stock exceeds 1 gram.
          </p>
        </li>
        <li>
          <p>
            8.3. Promotional discounts can only be applied to purchases of
            batches of 2.5 grams and smaller.&nbsp;
          </p>
        </li>
        <li>
          <p>
            8.4. Promotional discounts from different discount programs can NOT
            be summed up. By default, a larger discount will be applied
            replacing all other discounts.&nbsp;
          </p>
        </li>
        <li>
          <p>
            8.5. The discounts are valid in the period specified in the
            promotional program. After the end of the promotion the discounts
            for new purchases will be automatically cancelled.&nbsp;
          </p>
        </li>
        <li>
          <p>
            8.6. Promotional programs are intended for the end users only. The
            discounts cannot be granted to Enamine partners and distributors,
            with whom Enamine has separate contracts in place. Nor are the
            discounts applicable to retailers and brokers.
          </p>
        </li>
        <li>
          <p>
            8.7. Enamine reserves the right to terminate the discounts in case
            of intentional abuse of the program by customer.
          </p>
        </li>
        <li>
          <p>
            8.8. Enamine reserves the right to change the terms and conditions
            for any its promotional program without prior notice.
          </p>
        </li>
        <li>
          <p>
            8.9. Any discounts available at EnamineStore for building blocks are
            only applied to standard pack sizes: 0.1 g; 0.25 g; 0.5 g; 1.0 g;
            2.5 g.
          </p>
        </li>
      </ul>
      <h2>9. Refund and Cancellation</h2>
      <p>
        9.1. If the quality of goods does not comply with the specification set
        forth by Enamine, Enamine shall refund 100 % of cost of the product. The
        total amount of the refund shall not exceed the invoice price of the
        product. Enamine shall be indemnified from any damages, fees, penalties
        or any loss caused by the quality of the product or caused by late
        delivery of the product.&nbsp;
      </p>
      <p>
        9.2. If the purchaser cancels the order, Enamine shall be entitled to
        charge penalty in the amount of up to 100% of the cost of the product,
        subject to the terms of delivery by Enamine has been complied with. The
        amount of penalty shall be assessed based on the actual loss incurred by
        Enamine as a result of cancellation.
      </p>
      <p>
        9.3. Credits or refunds will be made to the same method of payment and
        account used to place the order.
      </p>
      <p>9.4. Terms of Reclamation Acceptance</p>
      <p>
        9.4.1. Any reclamation concerning the product, including but not limited
        to, its quality, quantity, or compliance with the specifications, must
        be submitted by the Purchaser in writing to Enamine via Support Portal
        or any other writing means of communication within 7 days from the date
        of receipt of the product. Reclamation received beyond this period may
        not be accepted.
      </p>
      <p>
        9.4.2. Each reclamation must be accompanied by a detailed description of
        the issue and any supportive evidence or documentation, such as
        photographic proof or test results, as appropriate.
      </p>
      <p>
        9.4.3. Upon receipt of a valid reclamation, Enamine will conduct an
        internal investigation to determine the validity of the claim. Enamine
        reserves the right to request additional information or evidence from
        the Purchaser during this process.
      </p>
      <p>
        9.4.4. Enamine aims to respond to and resolve all valid reclamation
        issues as quickly as possible. The Purchaser will be informed of the
        outcome of the reclamation within 7 days from the date of receipt of the
        reclamation.
      </p>
      <p>&nbsp;</p>
      <h2>10. Packaging and Delivery</h2>
      <p>
        10.1. Products available in stock are usually delivered via FedEx, DHL
        or other courier service within 7 business days from the order date. The
        delivery of large sets of screening compound may take over 10 business
        days depending on the number of ordered compounds. The express delivery
        (up to 2 (two) business days) may be available for selected 1 g batches
        of building blocks in North America, EU countries, China and Japan.
      </p>
      <p>
        10.2. By default, screening compounds are formatted in 4 ml screw cap
        amber glass vials labeled with a barcode and a compound ID. It is
        possible to change formatting to Matrix 96-well microtube plates from
        the shopping cart by using "Group update" option. Different formatting
        options for screening compounds are not supported within the same order.
        Custom formatting options can be requested at the checkout stage using
        "Order comments" form (additional charges may apply). Building blocks
        are provided in glass vials sealed with ParafilmTM sealing tape.
        Packaging is performed under inert gas atmosphere. All items are
        packaged in cardboard boxes lined with foam-rubber for shipping.
      </p>
      <p>
        10.3. Shipping and handling charges added to the order are indicated at
        the checkout procedure. The amount of these charges depends on the
        shipping destination.
      </p>
      <p>
        10.4. The Purchaser by its own discretion may specify own delivery
        account number with particular courier service company in its profile.
      </p>
      <p>
        10.5. You indemnify Enamine for any late delivery of the product, if the
        product was dispatched by Enamine to the courier company on time, as
        well as for loss and damage of the product by such courier company.
      </p>
      <h2>11. Taxes</h2>
      <p>
        11.1. Prices shown on the Site exclude all taxes or charges ("Taxes")
        that may apply to your purchase. Prices shown on the Site also exclude
        delivery costs. Taxes and delivery costs will be added to the amount of
        your purchase and shown on the check-out page. You will have an
        opportunity to review the taxes and delivery costs before you confirm
        your purchase.
      </p>
      <p>
        11.2. The recipient of an international shipment may be subject to
        import duties and taxes, which are levied once a shipment reaches the
        destination country. For all orders, the purchaser is fully responsible
        for paying any taxes, customs duties, and/or associated charges for
        customs clearance. The purchaser takes full responsibility to comply
        with local rules and regulations applicable to ordered chemicals,
        including but not limited to safe and environmentally friendly handling
        and disposal.
      </p>
      <h2>12. Confidential information</h2>
      <p>
        You undertake treat and maintain as confidential all Confidential
        Information, which may only be used for the purposes of fulfilling your
        obligations under these Terms of Use. Notwithstanding the above, you may
        disclose Confidential Information pursuant to a valid court order or
        where the Confidential Information is already in the public domain
        through no fault of you.
      </p>
      <h2>13. Privacy and use of Cookies</h2>
      <p>
        Our&nbsp;
        <a href="https://enaminestore.com/privacypolicy">Privacy Policy</a>
        &nbsp;applies to use of this Site, and their terms make a part of this
        Terms of Use by reference. Additionally, by using the Site, you
        acknowledge and agree that Internet transmissions are never completely
        private or secure. You understand that any message or information you
        send to the Site may be read or intercepted by others, even if there is
        a special notice that a particular transmission (for example, bank card
        information) is encrypted.
      </p>
      <h2>14. DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h2>
      <p>
        14.1. WE TRY TO KEEP SITE, SERVICES, APPLICATIONS, AND TOOLS SAFE,
        SECURE, AND FUNCTIONING PROPERLY. YOU ACKNOWLEDGE THAT WE CANNOT
        GUARANTEE THE CONTINUOUS OPERATION OF OR ACCESS TO OUR SITE, SERVICES,
        APPLICATIONS OR TOOLS. YOU FURTHER ACKNOWLEDGE THAT OPERATION OF AND
        ACCESS TO OUR SITE, SERVICES, APPLICATIONS OR TOOLS MAY BE INTERFERED
        WITH AS A RESULT OF TECHNICAL ISSUES OR NUMEROUS FACTORS OUTSIDE OF OUR
        CONTROL. UPDATES AND OTHER NOTIFICATION FUNCTIONALITY IN APPLICATIONS
        MAY NOT OCCUR IN REAL TIME. SUCH FUNCTIONALITY IS SUBJECT TO DELAYS
        INCLUDING, WITHOUT LIMITATION, DELAYS OR LATENCY DUE TO YOUR PHYSICAL
        LOCATION OR YOUR WIRELESS DATA SERVICE PROVIDER'S NETWORK. YOU AGREE
        THAT YOU ARE MAKING USE OF OUR SITE, SERVICES, APPLICATIONS AND TOOLS AT
        YOUR OWN RISK, AND THAT THEY ARE BEING PROVIDED TO YOU ON AN "AS IS" AND
        "AS AVAILABLE" BASIS. ACCORDINGLY, TO THE EXTENT PERMITTED BY APPLICABLE
        LAW, WE EXCLUDE ALL EXPRESS OR IMPLIED WARRANTIES, TERMS AND CONDITIONS
        INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
        FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.&nbsp;
      </p>
      <p>
        14.2. IN ADDITION, TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE
        (INCLUDING OUR PARENT, SUBSIDIARIES, AND AFFILIATES, AND OUR AND THEIR
        OFFICERS, DIRECTORS, AGENTS AND EMPLOYEES) ARE NOT LIABLE, AND YOU AGREE
        NOT TO HOLD US RESPONSIBLE, FOR ANY DAMAGES OR LOSSES (INCLUDING, BUT
        NOT LIMITED TO, LOSS OF MONEY, GOODWILL OR REPUTATION, PROFITS, OR OTHER
        INTANGIBLE LOSSES OR ANY SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES)
        RESULTING DIRECTLY OR INDIRECTLY FROM:
      </p>
      <ul>
        <li>
          <p>
            14.2.1. YOUR USE OF OR YOUR INABILITY TO USE OUR SITE, SERVICES,
            APPLICATIONS AND TOOLS;
          </p>
        </li>
        <li>
          <p>
            14.2.2. DELAYS OR DISRUPTIONS IN OUR SITE, SERVICES, APPLICATIONS OR
            TOOLS;
          </p>
        </li>
        <li>
          <p>
            14.2.3. VIRUSES OR OTHER MALICIOUS SOFTWARE OBTAINED BY ACCESSING
            OUR SITE, SERVICES, APPLICATIONS OR TOOLS OR ANY SITE, SERVICES,
            APPLICATIONS OR TOOLS LINKED TO OUR SITE, SERVICES, APPLICATIONS OR
            TOOLS;
          </p>
        </li>
        <li>
          <p>
            14.2.4. GLITCHES, BUGS, ERRORS, OR INACCURACIES OF ANY KIND IN OUR
            SITE, SERVICES, APPLICATIONS AND TOOLS OR IN THE INFORMATION AND
            GRAPHICS OBTAINED FROM THEM;
          </p>
        </li>
        <li>
          <p>
            14.2.5. DAMAGE TO YOUR HARDWARE DEVICE(S) OR LOSS OF DATA THAT
            RESULTS FROM THE USE OF OUR SITE, SERVICES, APPLICATIONS AND TOOLS;
          </p>
        </li>
        <li>
          <p>
            14.2.6. THE CONTENT, ACTIONS, OR INACTIONS OF THIRD PARTIES,
            INCLUDING ITEMS LISTED USING OUR SITE, SERVICES, APPLICATIONS OR
            TOOLS, FEEDBACK PROVIDED BY THIRD PARTIES;
          </p>
        </li>
        <li>
          <p>
            14.2.7. A SUSPENSION OR OTHER ACTION TAKEN WITH RESPECT TO YOUR
            ACCOUNT;&nbsp;
          </p>
        </li>
        <li>
          <p>
            14.2.8. YOUR NEED TO MODIFY PRACTICES, CONTENT, OR BEHAVIOR OR YOUR
            LOSS OF OR INABILITY TO DO BUSINESS, AS A RESULT OF CHANGES TO THIS
            AGREEMENT OR OUR POLICIES; RESERVES THE RIGHT TO MODIFY ITS POLICIES
            AND THIS AGREEMENT AT ANY TIME CONSISTENT WITH THE PROVISIONS
            OUTLINED HEREIN.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <h2>15. No Agency&nbsp;</h2>
      <p>
        No agency, partnership, joint venture, employee-employer, or
        franchiser-franchisee relationship is intended or created by these
        Terms.
      </p>
      <h2>16. Notices</h2>
      <p>
        Except as explicitly stated otherwise, legal notices shall be served on
        Enamine contacts provided in the directory Contacts on the Site. You
        consent to receive communication from us electronically. We may
        communicate with you by email to the email address you have designated
        on your user account. Notice to you shall be deemed given 24 hours after
        the email is sent. Alternatively, we may give you legal notice by mail
        to the Registration Address associated with your account, as identified
        in your account or through digital communication channel or in any other
        way agreed by you. In such case, notice shall be deemed given three days
        after the date of mailing.
      </p>
      <h2>17. Breaches of these Terms of Use</h2>
      <p>
        17.1. Without prejudice to our other rights under these Terms of Use, if
        you breach these Terms of Use in any way, or if we reasonably suspect
        that you have breached any of these Terms of Use in any way, we may:
      </p>
      <ul>
        <li>
          <p>17.1.1. delete or edit any of your User Information;</p>
        </li>
        <li>
          <p>17.1.2. send you one or more formal warnings;</p>
        </li>
        <li>
          <p>
            17.1.3. temporarily suspend your account and/or your access to the
            Site;
          </p>
        </li>
        <li>
          <p>
            17.1.4. delete your account with the Site and/or permanently
            prohibit you from using the Site;
          </p>
        </li>
        <li>
          <p>
            17.1.5. block computers using your IP address from accessing the
            Site;&nbsp;
          </p>
        </li>
        <li>
          <p>
            17.1.6. contact your internet services provider and request that
            they block your access to the Site; and/or
          </p>
        </li>
        <li>
          <p>
            17.1.7. bring court proceeding against you for breach of contract or
            otherwise.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        17.2. Where we suspend or prohibit your access to the Site or a part of
        the Site, you must not take any action to circumvent such suspension or
        prohibition (including without limitation using a different account).
      </p>
      <h2>18. Applicable Law and Resolution of Disputes</h2>
      <p>
        18.1. If a dispute arises between you and Enamine, our goal is to
        provide you with a neutral and cost effective means of resolving the
        dispute quickly. Accordingly, you and Enamine agree that we will resolve
        any claim or controversy at law or equity that arises out of this Terms
        of Use by way of negotiations or in accordance with rules below or as we
        and you otherwise agree in writing. We will consider reasonable requests
        to resolve the dispute through alternative dispute resolution
        procedures, such as mediation or arbitration, as alternatives to
        litigation.&nbsp;
      </p>
      <p>
        18.2. These Terms of Use and any dispute or claim you have against
        Enamine shall be governed in all respects by the laws of Switzerland if
        you are residing in Europe or other countries of the world except United
        States of America; or by the laws of the New Jersey state, USA, if you
        are residing in the United States of America, without regard to conflict
        of laws principles thereof.
      </p>
      <p>
        18.3. You agree that any claim or dispute you may have against Enamine
        must be resolved by competent court in Zurich, Switzerland if you are
        residing in European Union or other countries of the world except United
        States of America; or by competent court of the New Jersey state, USA,
        if you are residing in the United States of America, except as otherwise
        agreed by the parties.
      </p>
      <h2>19. Miscellaneous</h2>
      <p>
        19.1. If any of the provisions of these Terms of Use are held by a court
        of competent jurisdiction to be void or unenforceable, such provisions
        shall be limited or eliminated to the minimum extent necessary and
        replaced with a valid provision that best embodies the intent of these
        Terms of Use, so that these Terms of Use shall remain in full force and
        effect. These Terms of Use constitute the entire agreement between you
        and Enamine with regard to your use of the Site, and any and all other
        written or oral agreements or understandings previously existing between
        you and Enamine with respect to such use are hereby superseded and
        cancelled. Enamine will not accept any counter-offers to these Terms of
        Use, and all such offers are hereby categorically rejected. Enamine's
        failure to insist on or enforce strict performance of these Terms of Use
        shall not be construed as a waiver by Enamine of any provision or any
        right it has to enforce these Terms of Use, nor shall any course of
        conduct between Enamine and you or any other party be deemed to modify
        any provision of these Terms of Use. These Terms of Use shall not be
        interpreted or construed to confer any rights or remedies on any third
        parties.
      </p>
      <p>
        19.2. We may amend these Terms of Use at any time by posting the amended
        Terms of Use on the Site. Our right to amend the Terms of Use includes
        the right to modify, add to, or remove conditions in these Terms of Use.
        It is your responsibility to revise the Terms of Use in regards to the
        amendments made.
      </p>
      <p>
        19.3. In our sole discretion, we may assign these Terms of Use, by
        providing notice of such assignment in accordance with the Notices
        Section. Headings are for reference purposes only and do not limit the
        scope or extent of such Section.
      </p>
      <p>
        19.4. These Terms of Use (including incorporated policies and rules) as
        well as all annexes and appendixes sets forth the entire understanding
        and agreement between us with respect to the subject matter hereof.
      </p>
      <h2>20. Termination</h2>
      <p>
        20.1. In addition to any of the rights and remedies that Enamine may
        have under these Terms of Use, Enamine may immediately terminate this
        agreement upon the giving of written notice of such termination to you,
        if it has reason to believe that you are not using the Site in
        accordance with these Terms of Use or if a competent regulatory
        authority requires Enamine to terminate use of the Site.
      </p>
      <p>
        20.2. The following Sections survive any termination of these Terms of
        Use: Content, Disclaimer of Warranties; Limitation of Liability, all
        indemnities and Applicable Law and Resolution of Disputes.
      </p>
    </div>
  );
};

export default Content;
