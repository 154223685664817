/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { Container, Section } from "../../ui";
import Content from "./Content";

const TermsOfUse = () => {
  return (
    <Section>
      <Container style={{ paddingBottom: "50px" }}>
        <h1>Terms Of Use</h1>

        <Content />
      </Container>
    </Section>
  );
};

export default TermsOfUse;
