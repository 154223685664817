import classNames from "classnames";
import { Wrapper } from "./dots-spinner.styles";

const DotsSpinner = () => {
  const classnames = classNames("dots-spinner");

  return (
    <Wrapper className={classnames}>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </Wrapper>
  );
};

export default DotsSpinner;
