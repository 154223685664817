import { carriersOrder } from "../../constants";

export const transformCarriers = (carriers) => {
  const newItems = [];

  carriersOrder.forEach((key) => {
    const carrierValue = carriers?.[key];

    if (carrierValue) {
      const newValue = {
        id: parseInt(key),
        title: carrierValue,
      };
      newItems.push(newValue);
    }
  });

  return newItems;
};
