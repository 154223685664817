import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { errors, errorsMessages } from "../../../../constants";
import {
  events,
  useAmplitudeState,
} from "../../../../context/amplitude-context";
import { useCheckNextOrJustClose } from "../../../../hooks";
import { checkoutActions, checkoutSelectors } from "../../../../redux/checkout";
import { userSelectors } from "../../../../redux/user";
import { utilSelectors } from "../../../../redux/util";
import useRequestAdditionalCosts from "../../useRequestAdditionalCosts";
import { isDisableOrderPlacement } from "../restriction-modal/utils";
import Shipping from "./Shipping";

const ShippingContainer = ({ postalCodeIssue }) => {
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();
  const shippingAddresses = useSelector(userSelectors.selectShippingAddresses);
  const accordionData = useSelector(checkoutSelectors.selectAccordion);
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);
  const [selectedId, setSelectedId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [buttonClickedOnce, setButtonClickedOnce] = useState(false);
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const [goNextOrJustClose, buttonLabel] = useCheckNextOrJustClose(3);
  const { requestAdditionalCosts } = useRequestAdditionalCosts();

  const doNextStep = () => {
    dispatch(checkoutActions.closeAccordion("step3"));
    if (goNextOrJustClose) {
      dispatch(checkoutActions.openAccordion("step4"));
    }

    amplitude.logEvent(events.checkout_shipping);
  };

  const handleSelectAddress = (id) => {
    setSelectedId(id);
  };

  const handleClick = async () => {
    if (!buttonClickedOnce) {
      setButtonClickedOnce(true);
    }

    if (selectedId) {
      setDisabled(true);

      const selectedItem = shippingAddresses.find((el) => el.id === selectedId);
      const newData = { ...checkoutData };
      newData.shipping = selectedItem;
      dispatch(checkoutActions.setData(newData));

      if (newData?.carrier?.title && newData?.shipping?.addressType) {
        const onSuccess = (data) => {
          setDisabled(false);

          if (data?.restriction) {
            if (!isDisableOrderPlacement(data?.restriction)) {
              doNextStep();
            }
          } else {
            doNextStep();
          }
        };

        const onError = (props) => {
          if (props.type === errors.SHIPPING_ZIP_NOT_VALID_ERROR) {
            postalCodeIssue.setTitle("Postal Code Issue");
            postalCodeIssue.setMessage(
              errorsMessages[errors.SHIPPING_ZIP_NOT_VALID_ERROR]
            );
            postalCodeIssue.setOpen(true);
          } else {
            toast.error(errorsMessages[errors.COMMON_ERROR], {
              autoClose: 3000,
            });
          }

          setDisabled(false);
        };

        requestAdditionalCosts({ onSuccess, onError });
      } else {
        doNextStep();
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    const removeShippingFromState = () => {
      const newData = { ...checkoutData };
      newData.shipping = null;
      dispatch(checkoutActions.setData(newData));
    };
    // If shipping address not exists in shipping addresses from backend, remove shipping from state
    if (checkoutData?.shipping && shippingAddresses?.length) {
      const isAddressExists = shippingAddresses.find(
        (el) => el.id == checkoutData?.shipping.id
      );

      if (!isAddressExists) {
        removeShippingFromState();
      }
    } else if (checkoutData?.shipping && !shippingAddresses?.length) {
      removeShippingFromState();
    }

    if (checkoutData.shipping) {
      setSelectedId(checkoutData.shipping.id);
    } else {
      setSelectedId(null);
    }
  }, [checkoutData, shippingAddresses]);

  useEffect(() => {
    if (
      !shippingAddresses?.length &&
      (accordionData?.step4?.open || accordionData?.step5?.open)
    ) {
      dispatch(checkoutActions.openAccordion("step3"));
    }
  }, [
    shippingAddresses?.length,
    accordionData?.step4?.open,
    accordionData?.step5?.open,
  ]);

  return (
    <Shipping
      items={shippingAddresses}
      handleSelectAddress={handleSelectAddress}
      selectedId={selectedId}
      handleClick={handleClick}
      buttonClickedOnce={buttonClickedOnce}
      buttonLabel={buttonLabel}
      disabled={disabled}
      countries={countries}
      usStates={usStates}
    />
  );
};

export default ShippingContainer;
