import { Header, Footer, Loader, Error } from "../../ui";
import { Wrapper, Content, LoaderWrapper } from "./Main.styles";

const Main = ({ children, isCheckingAuth, filled }) => {
  return (
    <Wrapper filled={filled}>
      <Header />
      <Content>
        <Error />
        {isCheckingAuth ? (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        ) : (
          <>{children}</>
        )}
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default Main;
