// @ts-check

import React from "react";
import {
  EmailIcon,
  MarkDangerIcon,
  MarkSuccessIcon,
  MarkWarningIcon,
  SafeLockIcon,
} from "ui/icons";
import { Wrapper, MarkIconWrapper } from "./styles";

export const ResetPasswordIconType = {
  MESSAGE_SUCCESS: "MESSAGE_SUCCESS",
  MESSAGE_WARNING: "MESSAGE_WARNING",
  MESSAGE_DANGER: "MESSAGE_DANGER",
  LOCK_SUCCESS: "LOCK_SUCCESS",
};

const EmailIconWrapper = ({ variant }) => {
  return (
    <Wrapper>
      <EmailIcon />
      <MarkIconWrapper>
        {variant === ResetPasswordIconType.MESSAGE_SUCCESS ? (
          <MarkSuccessIcon />
        ) : variant === ResetPasswordIconType.MESSAGE_WARNING ? (
          <MarkWarningIcon />
        ) : (
          <MarkDangerIcon />
        )}
      </MarkIconWrapper>
    </Wrapper>
  );
};

const LockIconWrapper = () => (
  <>
    <SafeLockIcon />
    <MarkIconWrapper>
      <MarkSuccessIcon />
    </MarkIconWrapper>
  </>
);

export const ResetPasswordIcon = ({ variant }) => {
  return variant === ResetPasswordIconType.LOCK_SUCCESS ? (
    <LockIconWrapper />
  ) : (
    <EmailIconWrapper variant={variant} />
  );
};
