import styled from "styled-components";
import { borderColor } from "ui/colors";

export const Wrapper = styled.div`
  .no-image__text {
    font-size: 32px;
    color: ${borderColor};
    margin: 0;
    text-align: center;
    font-weight: 700;
    transform: translateX(-10px);
  }
`;
