import classNames from "classnames";
import { Wrapper } from "./no-image.styles";
import { NoImageIcon } from "ui/icons";

const NoImage = ({ text = "Image not available" }) => {
  const classes = classNames("no-image");

  return (
    <Wrapper className={classes}>
      <div>
        <NoImageIcon />
      </div>
      <p className="no-image__text">{text}</p>
    </Wrapper>
  );
};

export default NoImage;
