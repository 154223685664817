import styled from "styled-components";
import { darkBlue, lightBlue, borderColor } from "ui/colors";

export const Wrapper = styled.div`
  padding: 24px 24px 32px;
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: ${darkBlue};
`;

export const SmallTitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  margin-right: 8px;
`;

export const BoxButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: normal;
  cursor: pointer;

  &:hover {
    background-color: ${lightBlue};
  }

  svg {
    path {
      fill: ${borderColor};
    }
  }

  ${(props) => {
    if (props.active) {
      return `
        background-color: ${lightBlue};

        svg {
          path {
            fill: ${darkBlue};
          }
        }
      `;
    }
  }};
`;
