import React from "react";
import { getFilterCollections } from "../../api/catalogApi";
import { TextField } from "@mui/material";
import AutocompleteMultiselect from "../../../../ui/AutocompleteMultiselect/AutocompleteMultiselect";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";

type FilterClassesProps = {
  formik: FormikProps<FormikValues>;
  fieldName: string;
};

const FilterCollections = ({ formik, fieldName }: FilterClassesProps) => {
  const { data: filterCollections } = getFilterCollections();

  return (
    <>
      {filterCollections && (
        <AutocompleteMultiselect
          options={filterCollections[0]}
          value={formik?.values[fieldName]}
          onChange={(_, value) => {
            formik?.setFieldValue("collections", value);
          }}
          getOptionLabel={(option: string) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Start typing collections"
            />
          )}
        />
      )}
    </>
  );
};

export default FilterCollections;
