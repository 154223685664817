import React from "react";
import { useSelector } from "react-redux";

import { useQuery } from "../../hooks/index";
import { utilSelectors } from "../../redux/util";
import {
  getTextSearchType,
  generateCatalogsArray,
  generateSstypeIfNotExists,
} from "../../utils";

import SearchCategories from "./SearchCategories";

const SearchCategoriesContainer = ({ theme }) => {
  const catalogs = useSelector(utilSelectors.selectCatalogs);
  const query = useQuery();
  const q = query.get("q");
  const cat = query.get("cat");
  const drawSearch = query.get("drawSearch");
  const type = getTextSearchType(q);
  const list = generateCatalogsArray(catalogs);
  const sstype = generateSstypeIfNotExists(query.get("sstype"));
  const searchBy = query.get("searchBy");

  return (
    <SearchCategories
      list={list}
      q={q}
      type={type}
      cat={cat}
      sstype={sstype}
      theme={theme}
      drawSearch={drawSearch}
      searchBy={searchBy}
    />
  );
};

export default SearchCategoriesContainer;
