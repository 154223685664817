import styled from "styled-components";
import { lightGray } from "ui/colors";
import { generateParamsByMode } from "./utils";

export const Wrapper = styled.div`
    user-select: none;
`;

export const Line = styled.div`
    height: 4px;
    background-color: ${lightGray};

    &::after {
        content: '';
        display: block;
        height: 100%;

        ${({ mode }) => {
            const { color, lineWidth } = generateParamsByMode(mode);

            return `
                width: ${lineWidth}%;
                background-color: ${color};
            `;
        }};
    }
`;

export const Columns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    font-weight: 400;

    ${({ mode }) => {
        const { color } = generateParamsByMode(mode);

        return `
            span:nth-child(2) {
                color: ${color};
            }
        `;
    }};
`;