import styled from "styled-components";

export const Wrapper = styled.div`
  &.one-in-row {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
    margin-bottom: 24px;

    &--noMarginBottom {
      margin-bottom: 0 !important;
    }

    &--compact {
      row-gap: 18px;
      margin-bottom: 18px;
    }
  }
`;
