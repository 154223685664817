const isEqual = (one, two) => {
  let equal = true;

  const arr = Object.keys(one);

  for (let i = 0; i < arr.length; i++) {
    if (one[arr[i]] !== two?.[arr[i]]) {
      equal = false;
      break;
    }
  }

  return equal;
};

export default isEqual;
