import styled from "styled-components";

export const Wrapper = styled.div`
  .empty-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
`;
Wrapper.displayName = "AnalogsWrapper";

export const ProductGridWrapper = styled.div`
  margin-bottom: 32px;
`;
ProductGridWrapper.displayName = "ProductGridWrapper";
