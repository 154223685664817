import styled from "styled-components";

export const ButtonWrapper = styled.div`
  padding-top: 28px;
  display: flex;
  justify-content: flex-end;

  & > *:not(last-child) {
    margin-left: 16px;
  }
`;
