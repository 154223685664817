import React, { useEffect } from "react";
import { useFormik } from "formik";
import { getValidationSchema } from "./utils";
import ContactPerson from "./ContactPerson";
import { userActions, userSelectors } from "../../../../redux/user";
import { useDispatch, useSelector } from "react-redux";
import { checkoutActions, checkoutSelectors } from "../../../../redux/checkout";
import { useCheckNextOrJustClose } from "../../../../hooks";
import {
  events,
  useAmplitudeState,
} from "../../../../context/amplitude-context";

const ContactPersonContainer = () => {
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const profile = useSelector(userSelectors.selectProfile);
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const [goNextOrJustClose, buttonLabel] = useCheckNextOrJustClose(1);

  const formik = useFormik({
    initialValues: {
      title: "Undefined",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      company: "",
    },
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      const newData = { ...checkoutData };
      newData.contactPerson = values;
      dispatch(checkoutActions.setData(newData));
      dispatch(checkoutActions.closeAccordion("step1"));
      if (goNextOrJustClose) {
        dispatch(checkoutActions.openAccordion("step2"));
      }

      amplitude.logEvent(events.checkout_contact);
    },
  });

  const handleClick = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    if (profile && isAuth && !checkoutData.contactPerson) {
      const { title, firstName, lastName, phone, company, email } = profile;

      formik.setValues((prevValues) => ({
        ...prevValues,
        title: title ? title : "Undefined",
        firstName,
        lastName,
        email,
        company,
        phone,
      }));
    }
  }, [profile, isAuth, checkoutData.contactPerson]);

  useEffect(() => {
    if (checkoutData && checkoutData.contactPerson) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...checkoutData.contactPerson,
      }));
    }
  }, [checkoutData.contactPerson]);

  useEffect(() => {
    if (!profile) {
      dispatch(userActions.requestProfile());
    }
  }, []);

  return (
    <ContactPerson
      formik={formik}
      handleClick={handleClick}
      buttonLabel={buttonLabel}
    />
  );
};

export default ContactPersonContainer;
