import styled from "styled-components";
import {
  successColor,
  hoverSuccessColor,
  dangerColor,
  accentColor,
  blue,
  darkBlue,
} from "../colors";

export const ButtonStyled = styled.button`
  font-family: Roboto, Helvetica, Arial, sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  color: white;
  background-color: ${accentColor};
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  padding: 0 24px;
  text-wrap: nowrap;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  margin: 0;
  position: relative;
  overflow: hidden;

  svg path {
    fill: white;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  // check loading
  ${({ isLoading }) => {
    if (isLoading) {
      return `
        opacity: 0.8;
        pointer-events: none;
      `;
    } else {
      return `
        &:not(:disabled):hover {
          background-color: ${successColor};
        }
      `;
    }
  }}

  // check size
  ${({ size }) => {
    if (size === "small") {
      return `
        height: 48px;
      `;
    }
    if (size === "extra-small") {
      return `
        height: 40px;
        font-size: 18px;
      `;
    }
  }}

  // check rounded
  ${({ rounded }) => {
    if (rounded) {
      return `
        border-radius: 100px;
      `;
    }
  }}

  // check type
  ${({ type, bold }) => {
    if (type === "outlined") {
      return `
        background-color: transparent;
        color: ${accentColor};
        border: 1px solid ${accentColor};
        font-weight: 400;

        svg path {
          fill: ${accentColor};
        }

        &:not(:disabled):hover {
          background-color: ${accentColor};
          color: white;

          svg path {
            fill: white;
          }
        }

        ${bold && `border-width: 2px;`}
      `;
    }

    if (type === "outlined-danger") {
      return `
        background-color: transparent;
        color: ${dangerColor};
        border: 1px solid ${dangerColor};
        font-weight: 400;

        &:not(:disabled):hover {
          background-color: ${dangerColor};
          color: white;
        }
      `;
    }

    if (type === "outlined-white") {
      return `
        background-color: white;
        color: ${accentColor};
        border: 1px solid ${accentColor};
        font-weight: 400;

        svg path {
          fill: ${accentColor};
        }

        &:not(:disabled):hover {
          background-color: ${accentColor};
          color: white;

          svg path {
            fill: white;
          }
        }
      `;
    }

    if (type === "transparent-gray") {
      return `
        background-color: white;
        color: ${blue};
        
        font-weight: 500;

        svg path {
          fill: ${blue};
        }

        &:not(:disabled):hover {
          color: ${successColor};
          background-color: transparent;

          svg path {
            fill: ${successColor};
          }
        }
      `;
    }

    if (type === "danger-white") {
      return `
        background-color: #fff;
        color: ${dangerColor};
        border: 1px solid ${dangerColor};
        font-weight: 400;

        &:not(:disabled):hover {
          background-color: ${dangerColor};
          color: #fff;
        }
      `;
    }

    if (type === "primary-dark") {
      return `
        background-color: ${darkBlue};

        &:not(:disabled):hover {
          background-color: ${accentColor};
        }
      `;
    }
    if (type === "success") {
      return `
        background-color: ${successColor};
        font-weight: 600;

        &:not(:disabled):hover {
          background-color: ${hoverSuccessColor};
        }
      `;
    }
  }}

  // check iconTop
  ${({ iconTop }) => {
    if (iconTop) {
      return `
        flex-direction: column;
      `;
    }
  }}

  // check inbox
  ${({ inbox }) => {
    if (inbox) {
      return `
        padding: 0;
        border: none;
        border-radius: inherit;
        height: 100%;
        width: 100%;
      `;
    }
  }}

  ${({ bold }) => {
    if (bold) {
      return `
        font-weight: 600;
      `;
    }
  }}
`;

export const Text = styled.span`
  margin: 0 4px;

  ${({ iconTop }) => {
    if (iconTop) {
      return `
        font-size: 12px;
        line-height: 14px;
        margin-top: 7px;
      `;
    } else {
      return `
        margin-bottom: -2px;
      `;
    }
  }}
`;

export const IconWrapper = styled.div`
  margin: 0 6px;

  svg {
    display: block;
  }

  ${({ iconTop }) => {
    if (iconTop) {
      return `
        margin-top: 2px;
      `;
    }
  }}
`;

export const LinearLoaderWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;

  ${({ isLoading }) => {
    if (isLoading) {
      return `
        display: block;
      `;
    }
  }}
`;
