// @ts-check
import React from "react";
import { PenIcon } from "ui/icons";

import { Button } from "ui";
import { Collapsible } from "ui/collapsible/collapsible";
import { MarvinImage } from "features/marvin-image";

import { BulkWrapper, ImageWrapper, Tag } from "./SidebarHeader.styles";
import { encode } from "utils";

export const SearchType = {
  STRUCTURE: "STRUCTURE",
  BULK: "BULK",
  PHRASE: "PHRASE",
};

const BulkSearch = ({ items = [] }) => {
  if (!items.length) return null;

  return (
    <Collapsible>
      <BulkWrapper>
        {items.map((tag) => (
          <Tag key={tag}>{tag}</Tag>
        ))}
      </BulkWrapper>
    </Collapsible>
  );
};

const StructureSearch = ({ structure }) => {
  return (
    <>
      <ImageWrapper>
        <MarvinImage smile={structure} />
      </ImageWrapper>
      <Button
        fullWidth
        iconLeft={<PenIcon />}
        type="outlined-white"
        size="small"
        href={`/draw-search?q=${encode(structure)}`}
      >
        Edit search
      </Button>
    </>
  );
};

const SidebarHeader = ({ items, structure, type }) => {
  return (
    <>
      {type === SearchType.STRUCTURE ? (
        <StructureSearch structure={structure}></StructureSearch>
      ) : (
        <BulkSearch items={items} />
      )}
    </>
  );
};

export default SidebarHeader;
