import React from "react";
import { Input } from "../../../ui";

const FirstName = ({ formik, dataTestAttr = "input-first-name", ...props }) => {
  return (
    <Input
      label="First Name"
      name="firstName"
      formik={formik}
      data-test={dataTestAttr}
      placeholder="Enter first name"
      {...props}
    />
  );
};

export default FirstName;
