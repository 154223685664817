import * as yup from "yup";
import { endpoints, errors, errorsMessages, rootPages } from "constants";
import validation from "constants/validation";
import customFetch, { RequestMethods } from "utils/customFetch";
import { checkoutActions } from "redux/checkout";

import { store } from "redux/configureStore";
import { userActions } from "redux/user";
import superCartActions from "features/super-cart/redux/actions";
import cartAPI from "features/super-cart/redux/api";
import { encodeToBase64 } from "utils/base64Encoding";

export const handleSubmit = async (
  data,
  {
    cartItems,
    redirectTo,
    currency,
    history,
    dispatch,
    setError,
    formik,
    logLoginFailure,
    logLoginSuccess,
  }
) => {
  const [signInData, err, code] = await fetchSignIn(data);

  const processUser = async () => {
    const cb = () => {
      dispatch(checkoutActions.setInitial());

      if (redirectTo) {
        history.push(redirectTo);

        return;
      }

      const fromPathname = history.location.state?.from.pathname;

      if (fromPathname) {
        if (fromPathname.includes(rootPages.CHECKOUT)) {
          history.push(rootPages.CHECKOUT);

          return;
        } else if (fromPathname.includes(rootPages.CART)) {
          history.push(rootPages.CART);

          return;
        } else if (fromPathname.includes(rootPages.PROFILE)) {
          history.push(rootPages.PROFILE);

          return;
        }
      }

      history.push(rootPages.SEARCH);
    };

    if (cartItems?.length) {
      const cartItemsWithModeParam = cartItems.map((el) => ({
        ...el,
        mode: "Add",
      }));

      await cartAPI.putContent({
        items: cartItemsWithModeParam,
        currency: currency.abbr,
      });

      const fetchedItems = await cartAPI.fetchItems(currency.abbr);

      dispatch(superCartActions.setItems(fetchedItems));

      cb();
    } else {
      const fetchedItems = await cartAPI.fetchItems(currency.abbr);

      dispatch(superCartActions.setItems(fetchedItems));

      cb();
    }
  };

  if (signInData?.refreshToken) {
    const currentUser = await fetchCurrentUser();

    if (currentUser?.[0]) {
      dispatch(
        userActions.setCurrentUser({
          id: currentUser?.[0]?.id,
        })
      );
    }

    store.dispatch(userActions.setIsAuth(true));
    store.dispatch(userActions.setRefreshToken(signInData?.refreshToken));
    logLoginSuccess();

    await processUser();
  } else {
    if (err) {
      let errorMessage = "Invalid email / password combination.";

      if (code !== 401) {
        errorMessage = errorsMessages[errors.COMMON_ERROR];
      }
      setError(errorMessage);
      formik.setSubmitting(false);
      logLoginFailure();
    }
  }
};

export const fetchSignIn = async (data) => {
  const preparedData = {
    ...data,
    email: encodeToBase64(data.email),
    password: encodeToBase64(data.password),
  };

  return await customFetch({
    method: RequestMethods.POST,
    url: endpoints.AUTH_SIGNIN,
    data: preparedData,
  });
};

export const fetchCurrentUser = () => {
  return customFetch({
    method: RequestMethods.GET,
    url: endpoints.CUSTOMERS_CURRENT,
  });
};

export const getValidationSchema = () => {
  return yup.object({
    email: validation.EMAIL,
    password: validation.PASSWORD,
    remember: yup.boolean(),
  });
};
