import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addressTypes, currencies } from "../constants";
import { userActions } from "../redux/user";
import { currencyActions } from "redux/currency";

function useCurrency() {
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.user.isAuth);
  const addresses = useSelector((state) => state.user.addresses);
  const cartInfo = useSelector((state) => state.superCart.cartInfo.data);
  const checkoutData = useSelector((state) => state.checkout.data);
  const [billingAddress, setBillingAddress] = useState(null);

  useEffect(() => {
    if (cartInfo) {
      dispatch(currencyActions.setCurrent(currencies[cartInfo?.currency]));
    }

    const billingAddress =
      addresses?.find((el) => el.addressType === addressTypes.BILLING) || null;
    setBillingAddress(billingAddress);
  }, [addresses, currencies, cartInfo?.currency, isAuth]);

  useEffect(() => {
    if (checkoutData?.billing && isAuth) {
      if (checkoutData?.billing.country == "CH") {
        dispatch(currencyActions.setDisableSelect(false));
      } else {
        dispatch(currencyActions.setDisableSelect(true));
      }
    } else if (billingAddress && isAuth) {
      if (billingAddress.country == "CH") {
        dispatch(currencyActions.setDisableSelect(false));
      } else {
        dispatch(currencyActions.setDisableSelect(true));
      }
    } else {
      dispatch(currencyActions.setDisableSelect(false));
    }

    return () => {
      dispatch(currencyActions.setDisableSelect(false));
    };
  }, [billingAddress, isAuth, checkoutData]);

  useEffect(() => {
    if (isAuth) {
      dispatch(userActions.requestAddresses());
    }
  }, [isAuth]);
}

export default useCurrency;
