import productTypes from "./productTypes";

const searchCategoriesOrder = [
  productTypes.BB,
  productTypes.MADE,
  productTypes.SCR,
  productTypes.REALDB,
  productTypes.EBC,
];

export default searchCategoriesOrder;
