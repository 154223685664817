import React from "react";
import PropTypes from "prop-types";
import { Wrapper } from "./LikeLinkButton.styled";

const LikeLinkButton = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

LikeLinkButton.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LikeLinkButton;
