import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { common, rootPages } from "../../constants";
import Header from "./header";
import { userSelectors } from "../../redux/user";
import { events, useAmplitudeState } from "../../context/amplitude-context";
import { useHandleLogout } from "../../hooks";
import superCartSelectors from "features/super-cart/redux/selectors";

const navLinks = [
  {
    id: "COMPUTATIONAL_SUPPORT",
    title: "Computational Support",
    href: common.COMPUTATIONAL_SUPPORT_HREF,
    dataTestAttr: "navigation-computational-support-link",
  },
  {
    id: "SCREENING_LIBRARIES",
    title: "Screening Libraries",
    dataTestAttr: "navigation-screening-libraries-link",
  },
  {
    id: "CONTACTS",
    title: "Contact Us",
    href: common.CONTACTS_HREF,
    dataTestAttr: "navigation-contact-us-link",
  },
];

const profileLink = `/${rootPages.PROFILE}/orders`;
const cartLink = `/${rootPages.CART}`;
const loginLink = `/${rootPages.SIGNIN}`;

const HeaderContainer = () => {
  const { amplitude } = useAmplitudeState();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const superCart = useSelector(superCartSelectors.selectSuperCart);
  const count = superCart.items.length;
  const handleLogout = useHandleLogout();

  const handleNavClick = (element) => {
    if (element?.id === "CONTACTS") {
      amplitude.logEvent(events.contact_us);
    } else if (element?.id === "SCREENING_LIBRARIES") {
      setModalOpen(true);
      amplitude.logEvent(events.screening_libraries);
    } else if (element?.to === "/") {
      amplitude.logEvent(events.Home_click);
    } else if (element?.to === profileLink) {
      amplitude.logEvent(events.profile_click);
    } else if (element?.to === "/search") {
      amplitude.logEvent(events.search_click);
    } else if (element?.to === loginLink) {
      amplitude.logEvent(events.login_click);
    }
  };

  const handleProfileClick = () => {
    amplitude.logEvent(events.personal_info_click);
  };

  return (
    <Header
      navLinks={navLinks}
      pathname={location.pathname}
      profileLink={profileLink}
      cartLink={cartLink}
      loginLink={loginLink}
      isAuth={isAuth}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      count={count}
      handleNavClick={handleNavClick}
      handleLogout={handleLogout}
      handleProfileClick={handleProfileClick}
      modal={{
        open: modalOpen,
        setOpen: setModalOpen,
      }}
    />
  );
};

export default HeaderContainer;
