import React from "react";
import { ProductTypesV2 } from "constants/productTypes";
import { DetailsListConfig } from "./ProductDetailsList.types";

export const BASE_CONFIG = {
  catalog: { title: "Product class", order: 1 },
  formula: { title: "Formula" },
  CAS: { title: "CAS number" },
  MFCD: { title: "ACD number" },
  molecularWeight: { title: "MW" },
  clogp: { title: "CLogP" },
  transportConditions: { title: "Transport conditions" },
  storageConditions: { title: "Storage conditions" },
};

export const DEFAULT_EXTENDED_CONFIG = {
  ...BASE_CONFIG,
  bbClasses: { title: "Class" },
  bbSubclasses: { title: "Subclass" },
  collection: { title: "Screening Collection" },
  tpsa: { title: "TPSA" },
  hba: { title: "HBA" },
  hbd: { title: "HBD" },
  rotb: { title: "RotB" },
  fsp3: {
    title: (
      <span>
        <span>
          Fsp<sup>3</sup>
        </span>
      </span>
    ),
  },
};

export const DETAILS_LIST_CONFIG: DetailsListConfig = {
  [ProductTypesV2.BB]: {
    ...BASE_CONFIG,
    bbClasses: { title: "Class" },
    bbSubclasses: { title: "Subclass" },
    transportationByAir: { title: "Transportation by air" },
    dangerousGood: { title: "Dangerous Good" },
    dangerousGroup: { title: "Group of Dangerous Good" },
    unNumber: { title: "UN Number" },
  },
  [ProductTypesV2.REALDB]: {
    catalog: { title: "Product class", order: 1 },
    molecularWeight: { title: "MW" },
    clogp: { title: "CLogP" },
    tpsa: { title: "TPSA" },
    hba: { title: "HBA" },
    hbd: { title: "HBD" },
    rotb: { title: "RotB" },
    fsp3: {
      title: (
        <span>
          <span>
            Fsp<sup>3</sup>
          </span>
        </span>
      ),
    },
    transportConditions: { title: "Transport conditions" },
    storageConditions: { title: "Storage conditions" },
  },
  [ProductTypesV2.SCR]: {
    ...BASE_CONFIG,
    collection: { title: "Screening Collection" },
  },
  [ProductTypesV2.MADE]: BASE_CONFIG,
  [ProductTypesV2.EBC]: BASE_CONFIG,
  DEFAULT: DEFAULT_EXTENDED_CONFIG,
};
