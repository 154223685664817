import styled from "styled-components";
import { accentColor, borderColor, lightBlue, lightGray } from "ui/colors";

export const Wrapper = styled.div`
  .product-page__small-button {
    border-radius: 6px;
    background-color: ${lightBlue};
    border: 1px solid ${lightBlue};
    color: ${accentColor};
    font-size: 16px;
    line-height: 19px;
    padding: 6px 10px;
    text-transform: uppercase;
    cursor: pointer;
    position: absolute;
    bottom: 18px;
    right: 20px;

    &:hover {
      border-color: ${accentColor};
    }
  }

  .product-page__not-found {
    display: flex;
    flex-direction: column;
    border: 1px solid ${borderColor};
    border-radius: 8px;
    padding: 100px 40px;
    align-items: center;

    h2 {
      color: ${borderColor};
      font-weight: 700;
      font-size: 32px;
      line-height: 28px;
      margin: 0;
      margin-top: 10px;
      margin-bottom: 32px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 23px;
      margin: 0;
      margin-bottom: 35px;
    }
  }

  .product-page__not-found-buttons {
    display: grid;
    align-items: center;
    column-gap: 24px;
    grid-template-columns: auto auto;
  }
`;

export const ProductSection = styled.div`
  background-color: white;
  padding-top: 5px;
  padding-bottom: 100px;
  padding-right: 20px;
  padding-left: 20px;
`;

export const Columns = styled.div`
  display: flex;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${borderColor};
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  max-height: 400px;
`;

export const Column = styled.div`
  display: flex;

  ${(props) => {
    if (props.card) {
      return `
        width: 432px;
        padding-left: 16px;
      `;
    }
  }}

  ${(props) => {
    if (props.image) {
      return `
        flex-grow: 1;
        padding-right: 16px;
      `;
    }
  }}
`;

export const ProductName = styled.div`
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background-color: ${lightGray};
  font-size: 12px;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
