import styled from "styled-components";
import { borderColor } from "../colors";

/**
 * @todo y.kozhura: as we spoke with n.dromenko, empty message component
 * should be revisited, since in Figma two variants exist with
 * different paddings (20px 28px vs 24px). In scope of SHOP-3567 the decision
 * was to support both variants, until component will be revisited.
 * For now `alternativePaddings` will be used only
 * for analogs and in-stock equivalent tabs
 */
export const Wrapper = styled.div`
  &.empty-message {
    border: 1px solid ${borderColor};
    border-radius: 8px;
    padding: 20px 28px;
    line-height: 24px;
  }

  &.empty-message--variant--solidWhite {
    border: none;
    background-color: white;
  }

  &.empty-message--variant--alternativePaddings {
    padding: 24px;
  }
`;
