const currencies = {
  USD: {
    abbr: "USD",
    symbol: "$",
  },
  EUR: {
    abbr: "EUR",
    symbol: "€",
  },
};

export default currencies;
