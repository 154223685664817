import React from "react";
import {
  FilterContainer,
  FilterDividerWrapper,
  FilterInputContainer,
  FilterInputsContainer,
  FilterLabel,
} from "./FilterFromTo.styles";
import FromToInput from "../../../../ui/FromToInput/FromToInput";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";

type FilterFromToProps = {
  formik: FormikProps<FormikValues>;
  fieldName: string;
  label?: string;
  inputHeight?: string;
  placeholderFrom?: string;
  placeholderTo?: string;
};
const FilterFromTo = ({
  formik,
  fieldName,
  label,
  inputHeight,
  placeholderFrom,
  placeholderTo,
}: FilterFromToProps) => {
  return (
    <FilterContainer>
      {label && <FilterLabel> {label}</FilterLabel>}
      <FilterInputsContainer>
        <FilterInputContainer>
          <FromToInput
            formik={formik}
            name={fieldName}
            subName="from"
            placeholder={placeholderFrom || "From"}
            type="number"
            step="0.01"
            inputHeight={inputHeight}
          />
        </FilterInputContainer>

        <FilterDividerWrapper>—</FilterDividerWrapper>

        <FilterInputContainer>
          <FromToInput
            formik={formik}
            name={fieldName}
            subName="to"
            placeholder={placeholderTo || "To"}
            type="number"
            step="0.01"
            inputHeight={inputHeight}
          />
        </FilterInputContainer>
      </FilterInputsContainer>
    </FilterContainer>
  );
};

export default FilterFromTo;
