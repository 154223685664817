import React from "react";
import {
  Wrapper,
  TextareaStyled,
  Label,
  ErrorMessage,
} from "./textarea.styles";

const Textarea = ({
  label,
  formik,
  name,
  type = "text",
  disabled,
  value = "",
  handleChange,
  className,
  ...rest
}) => {
  const hasError = formik?.touched[name] && Boolean(formik?.errors[name]);
  const errorMessage = formik?.touched[name] && formik?.errors[name];

  return (
    <Wrapper className={className}>
      <Label htmlFor={name}>{label}</Label>
      <TextareaStyled
        hasError={hasError}
        name={name}
        id={name}
        onChange={formik?.handleChange || handleChange}
        type={type}
        disabled={disabled}
        value={formik?.values[name] || value}
        {...rest}
      />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

export default Textarea;
