import React from "react";
import { Link as RouterLink } from "react-router-dom";

import { LinkStyled } from "./Link.styles";

const Link = (props) => {
  return <LinkStyled component={props.to ? RouterLink : null} {...props} />;
};

export default Link;
