import styled from "styled-components";
import {
  dangerColor,
  blue,
  borderColor,
  accentColor,
  darkBlue,
} from "ui/colors";

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const TextareaStyled = styled.textarea`
  border: 1px solid ${borderColor};
  width: 100%;
  min-height: 80px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 16px;
  padding-top: 14px;
  padding-bottom: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  outline: none;
  resize: none;
  font-family: inherit;
  display: block;
  color: ${darkBlue};

  &:hover:not(:disabled) {
    border-color: ${accentColor};
  }

  &:focus:not(:disabled) {
    border-color: ${accentColor};
    outline: none;
  }

  &::placeholder {
    color: ${borderColor};
  }

  ${(props) => {
    if (props.hasError) {
      return `
        border-color: ${dangerColor};

        &::placeholder {
          color: ${dangerColor};
        }
      `;
    } else {
      return `
        
      `;
    }
  }}

  ${({ disabled }) => {
    if (disabled) {
      return `
        color: ${blue};
        background-color: #F6F7F8;
      `;
    }
  }}
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 6px;
  display: inline-block;
`;

export const ErrorMessage = styled.p`
  color: ${dangerColor};
  margin: 0;
  margin-top: 4px;
  font-size: 14px;
`;
