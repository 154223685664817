import { takeEvery } from "redux-saga/effects";
import {
  LOGOUT,
  REQUEST_ADDRESSES,
  REQUEST_PROFILE,
  REQUEST_USER,
} from "./user/types";
import { REQUEST_VERSION } from "./version/types";
import {
  GET_PRODUCT_LIST,
  REQUEST_PRODUCTS_WITH_PRICES, SET_PAGE_SIZE,
} from "./catalog/types";
import { REQUEST_IMAGE, REQUEST_PRODUCT_WITH_PRICES } from "./product/types";
import {
  REQUEST_CARRIERS,
  REQUEST_CATALOGS,
  REQUEST_CATALOG_WEIGHTS,
  REQUEST_COUNTRIES,
  REQUEST_IMAGES,
  REQUEST_US_STATES,
  REQUEST_VAT_PATTERNS,
} from "./util/types";
import {
  REQUEST_ADDITIONAL_COSTS,
  REQUEST_PAYMENT_METHODS,
} from "./checkout/types";
import {
  requestUser,
  requestProfile,
  requestAddresses,
  logout,
} from "./user/sagas";
import { requestImage, requestProductWithPrices } from "./product/sagas";
import {
  getProductList, requestProductsWithPrices,
  updateCurrentItemsWithPageSize,
} from "./catalog/sagas";
import {
  requestCarriers,
  requestCatalogs,
  requestCatalogWeights,
  requestCountries,
  requestImages,
  requestUsStates,
  requestVatPatterns,
} from "./util/sagas";
import { requestVersion } from "./version/sagas";
import {
  requestAdditionalCosts,
  requestPaymentMethods,
} from "./checkout/sagas";

import {
  POST_CART_CONTENT,
  REQUEST_CART_INFO,
  REQUEST_ITEMS_AND_IMAGES,
} from "features/super-cart/redux/types";
import {
  postCartContent,
  requestCartInfo,
  requestItemsAndImages,
} from "features/super-cart/redux/sagas";

function* sagaWatcher() {
  yield takeEvery(REQUEST_USER, requestUser);
  yield takeEvery(REQUEST_PROFILE, requestProfile);
  yield takeEvery(REQUEST_ADDRESSES, requestAddresses);
  yield takeEvery(REQUEST_CATALOGS, requestCatalogs);
  yield takeEvery(REQUEST_COUNTRIES, requestCountries);
  yield takeEvery(REQUEST_VAT_PATTERNS, requestVatPatterns);
  yield takeEvery(REQUEST_US_STATES, requestUsStates);
  yield takeEvery(REQUEST_CATALOG_WEIGHTS, requestCatalogWeights);
  yield takeEvery(REQUEST_IMAGES, requestImages);
  yield takeEvery(REQUEST_PRODUCTS_WITH_PRICES, requestProductsWithPrices);
  yield takeEvery(SET_PAGE_SIZE, updateCurrentItemsWithPageSize);
  yield takeEvery(GET_PRODUCT_LIST, getProductList);
  yield takeEvery(REQUEST_PRODUCT_WITH_PRICES, requestProductWithPrices);
  yield takeEvery(REQUEST_IMAGE, requestImage);
  yield takeEvery(REQUEST_CARRIERS, requestCarriers);
  yield takeEvery(LOGOUT, logout);
  yield takeEvery(REQUEST_VERSION, requestVersion);
  yield takeEvery(REQUEST_ADDITIONAL_COSTS, requestAdditionalCosts);
  yield takeEvery(REQUEST_PAYMENT_METHODS, requestPaymentMethods);
  yield takeEvery(REQUEST_CART_INFO, requestCartInfo);
  yield takeEvery(POST_CART_CONTENT, postCartContent);
  yield takeEvery(REQUEST_ITEMS_AND_IMAGES, requestItemsAndImages);
}

export default sagaWatcher;
