import * as React from "react";
import { Wrapper, Spinner, RightCell, Title } from "./SearchCard.styles";
import { ArrowRight2Icon } from "ui/icons";

const SearchCard = ({
  title,
  isLoading,
  handleClick,
  dataTestAttr,
  isExists,
}) => {
  return (
    <Wrapper
      onClick={handleClick}
      disabled={!isExists}
      data-test={dataTestAttr}
      isLoading={isLoading}
    >
      <Title>{title}</Title>
      <RightCell>
        {isLoading ? <Spinner /> : <>{isExists ? <ArrowRight2Icon /> : null}</>}
      </RightCell>
    </Wrapper>
  );
};

export default SearchCard;
