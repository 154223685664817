import {
  marvinImageStorageItemsLimit,
  marvinImageStorageKey,
} from "../constants";

const storage = {
  setItem: (itemKey, value) => {
    let items = storage.getItems();

    if (Object.keys(items)?.length > marvinImageStorageItemsLimit) {
      storage.clear();

      items = {};
    }

    items[itemKey] = value;

    const newItemsString = JSON.stringify(items);

    sessionStorage.setItem(marvinImageStorageKey, newItemsString);
  },
  getItem: (itemKey) => {
    const items = storage.getItems();
    const item = items?.[itemKey];

    return item || null;
  },
  getItems: () => {
    const itemsString = sessionStorage.getItem(marvinImageStorageKey);
    const itemsObj = JSON.parse(itemsString);

    return itemsObj || {};
  },
  clear: () => {
    sessionStorage.removeItem(marvinImageStorageKey);
  },
};

export default storage;
