import * as React from "react";
import { Select } from "../../ui";

const PriceSelect = ({
  isCartItem,
  prices,
  currentPrice,
  setCurrentPrice,
  currency,
  variant,
  type = "normal",
  ...rest
}) => {
  const { all: allProductPrices } = prices;

  const handleChange = (event) => {
    const selectedPrice = allProductPrices.find((productPrice) => {
      return productPrice?.weight.amount === parseFloat(event.target.value);
    });

    setCurrentPrice(selectedPrice);
  };

  const options = allProductPrices?.map(
    ({ price, discountedPrice, weight }) => {
      const measureText = `${weight.amount} ${weight.measure}`;
      const priceText = ` - ${currency.symbol}${discountedPrice || price}`;
      let title = `${measureText}${priceText}`;

      const stockText =
        weight?.available === "IN_STOCK" ? "In stock" : "Synthesis";

      title += ` - ${stockText}`;

      const stockLocations = weight?.stockLocations
        ?.map((location) => location)
        .join(", ");

      title +=
        type === "extended" && stockLocations?.length > 0
          ? ` - ${stockLocations}`
          : "";

      return {
        title,
        selectedTitle: measureText,
        value: weight?.amount,
      };
    }
  );

  if (!options?.length) return <div style={{ height: "50px" }}></div>;

  return (
    <Select
      isCartItem={isCartItem}
      options={options}
      value={currentPrice?.weight?.amount}
      handleChange={handleChange}
      variant={variant}
      {...rest}
    />
  );
};

export default PriceSelect;
