import classNames from "classnames";
import { Wrapper } from "./flex.styles";

const Flex = ({ children, type, marginBottom, ...rest }) => {
  const classes = classNames("flex", {
    "g-flex g-flex-vertical-center g-flex-justify-between":
      type === "center-between",
    "flex--marginBottom": marginBottom,
  });

  return (
    <Wrapper className={classes} {...rest}>
      {children}
    </Wrapper>
  );
};

export default Flex;
