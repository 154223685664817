import styled from "styled-components";
import { borderColor } from "ui/colors";
import { NotFoundIcon } from "ui/icons";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 28px 0 60px;
  row-gap: 16px;
`;

export const Title = styled.div`
  color: ${borderColor};
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`;

export const NotFoundImage = styled(NotFoundIcon)`
  width: 180px;
  height: 180px;
`;
