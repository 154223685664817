// @ts-check
import { uuid } from "utils";
import convertCatalogToCat from "../../../utils/convetCatalogToCat";

export const addItem = (items, itemToAdd) => {
  const newItems = [...items];

  const candidate = {
    product: itemToAdd.product,
    prices: itemToAdd.prices,
    code: itemToAdd.code,
    catalog: itemToAdd.catalog,
    productType: itemToAdd.productType,
    amount: itemToAdd.amount,
    measure: itemToAdd.measure,
    quantity: itemToAdd.quantity,
    smile: itemToAdd.smile,
    cdId: itemToAdd?.cdId,
    restriction: itemToAdd?.restriction,
  };

  /**
   *
   * Check if item exists. Get finded index.
   */
  // @TODO Check do we need amount here
  // const findedIdx = items.findIndex(
  //   (item) => item.code === candidate.code && item?.amount === candidate?.amount
  // );

  const findedIdx = items.findIndex((item) => item.code === candidate.code);

  /**
   *
   * - Increase quantity, if item exists.
   * - Generate key and push new item to array, if item not exists.
   */
  if (findedIdx !== -1) {
    const finded = items[findedIdx];
    const newItem = {
      ...finded,
      quantity: candidate.quantity + finded.quantity,
    };
    newItems[findedIdx] = newItem;
  } else {
    candidate.key = uuid();

    newItems.push(candidate);
  }

  return newItems;
};

export const addPrices = (prices, pricesToAdd) => {
  if (prices) {
    const newPrices = { ...prices };

    Object.keys(pricesToAdd).forEach((priceKey) => {
      newPrices[priceKey] = pricesToAdd[priceKey];
    });

    return newPrices;
  } else {
    return pricesToAdd;
  }
};

export const addImages = (items, itemsToAdd) => {
  return addPrices(items, itemsToAdd);
};

export const allToCart = (items, itemsToAdd) => {
  let newItems = [...items];

  itemsToAdd.forEach((itemToAdd) => {
    newItems = addItem(newItems, itemToAdd);
  });

  return newItems;
};

export const deleteItem = (items, idToDelete) => {
  const newItems = [...items];

  /**
   *
   * Check if item exists. Get finded index.
   */
  const findedIdx = items.findIndex((item) => item.key === idToDelete);

  /**
   *
   * - Delete, if item exists.
   * - Generate key and push new item to array, if item not exists.
   */
  if (findedIdx !== -1) {
    newItems.splice(findedIdx, 1);
  }

  return newItems;
};

export const getImagelessItems = ({ items, images }) => {
  const imageless = [];
  const currentDate = Date.now();

  items.forEach((item) => {
    // const key = `${item.code}_${item.productType}`;
    const key = `${item.code}_${item.catalog}`;

    if (!images?.[key] || currentDate - images?.[key]?.updatedAt > 3_600_000) {
      imageless.push(item);
    }
  });
  return imageless;
};

export const generateImagesObj = (items) => {
  const imagesObj = {};

  items.forEach((item) => {
    const key = `${item.code}_${item.catalog}`;
    const itemToObj = {
      updatedAt: Date.now(),
      data: item,
      key,
    };
    imagesObj[key] = itemToObj;
  });

  return imagesObj;
};

export const seedPrices = ({ items, currency, prices }) => {
  const newItems = [];

  items.forEach((item) => {
    const key = `${item.code}_${item.productType}_${currency}`;
    const itemPrices = prices?.[key]?.data;

    newItems.push({
      ...item,
      prices: itemPrices,
    });
  });

  return newItems;
};

export const seedImages = ({ items, images }) => {
  const newItems = [];

  items.forEach((item) => {
    const key = `${item.code}_${item?.product?.catalog}`;
    const image = images?.[key]?.data;

    newItems.push({
      ...item,
      image,
    });
  });

  return newItems;
};

export const updateItem = (items, data) => {
  const newItems = [...items];

  /**
   *
   * Check if item exists. Get finded index.
   */
  const findedIdx = items.findIndex((item) => item.key === data.key);

  /**
   *
   * - Increase quantity, if item exists.
   * - Generate key and push new item to array, if item not exists.
   */
  if (findedIdx !== -1) {
    const finded = items[findedIdx];
    const newItem = {
      ...finded,
      ...data.data,
    };
    newItems[findedIdx] = newItem;
  }

  return newItems;
};

export const calculateTotal = ({ items, prices, currency }) => {
  const totalSum = items.reduce(function (accumulator, currentValue) {
    const { prices } = currentValue;

    const currentPrice = prices?.all?.find(
      (el) => el.weight.amount === currentValue.amount
    );

    const valueToAdd = currentPrice?.price * currentValue.quantity || 0;

    return accumulator + valueToAdd;
  }, 0);

  return totalSum;
};

/**
 * @returns {import("typings/DTO/data-contracts").ProductRequest}
 */
export const prepareItemToAdd = ({ item, currentPrice, quantity = 1 }) => {
  /** @type {import("typings/DTO/data-contracts").ProductRequest } */

  const { product, prices } = item;

  const itemToAdd = {
    // @ TODO Check logic how to get prices
    product: product,
    prices: prices,
    code: product?.code,
    productType: convertCatalogToCat(product?.catalog),
    catalog: product?.catalog,
    amount: currentPrice?.weight.amount,
    measure: currentPrice?.weight.measure,
    smile: product?.smile,
    cdId: product?.cdId,
    quantity,
    mode: "Add",
    id: null,
  };

  return itemToAdd;
};

export const setItems = (items) => {
  const newItems = items.map((el) => {
    const newItem = {
      ...el,
      key: uuid(),
    };

    return newItem;
  });

  return newItems;
};

export const getPrice = (item, currency) => {
  let res = "";

  const allPrices = item?.prices?.all.find(
    (el) => el.weight.amount === item.amount
  );

  const quantity = item?.quantity;
  const isInquiry = item?.isInquery;
  const measure = item?.measure;
  const amount = item?.amount;
  const measureFactor = measure === "g" ? 1000 : 1;

  const stock = item?.stock;
  const desire = quantity * amount * measureFactor;

  if (isInquiry || stock < desire) {
    return "Inquiry";
  }

  if (allPrices?.price) {
    res = `${allPrices?.price.toFixed(2)} ${currency.abbr}`;
  }

  return res;
};

export const getDiscountPrice = (item, currency) => {
  let res = "";

  const sample = item?.prices?.all.find(
    (el) => el.weight.amount === item.amount
  );

  if (sample?.price) {
    if (sample?.priceOld > sample?.price) {
      res = `${sample?.priceOld.toFixed(2)} ${currency.abbr}`;
    }
  }

  return res;
};

export const generateSubtotal = ({ price, abbr }) => {
  let res = "";

  if (price) {
    res = `${price?.toFixed(2)} ${abbr}`;
  } else {
    res = `0.00 ${abbr}`;
  }

  return res;
};

export const getUniqueCodes = (items) => {
  const codesObj = {};
  const resultArr = [];

  items.forEach((item) => {
    codesObj[`${item.code}---${item.productType}`] = true;
  });

  Object.keys(codesObj).forEach((item) => {
    const splitted = item.split("---");

    resultArr.push({
      code: splitted[0],
      productType: splitted[1],
    });
  });

  return resultArr;
};

export const generateItemsWithImages = (superCart) => {
  const listWithImages = seedImages({
    items: superCart.items,
    images: superCart.images,
  });

  return listWithImages;
};
