import styled from 'styled-components';

export const AccordionWrapper = styled.div`
  padding-right: 25px;
  padding-left: 25px;
`;

export const Paper = styled.div`
  background: #ffffff;
  border-radius: 16px;
`;

export const Title = styled.h1`
  font-size: 32px;
  line-height: 32px;
  margin: 0;
`;

export const Page = styled.div`
  padding-top: 0px;
  padding-bottom: 100px;
  padding-right: 20px;
  padding-left: 20px;
`;

export const Header = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const GoBackGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  flex-grow: 1;
  padding-right: 30px;
  width: 50%;
`;

export const Sidebar = styled.div``;

export const InputRow = styled.div`
  padding-bottom: 20px;
`;

export const CheckOrderWrapper = styled.div`
  padding-top: 30px;
  padding-left: 25px;
  padding-right: 25px;
`;

export const StepButtonWrapper = styled.div`
  padding-top: 10px;
`;
