import createSagaMiddleware from "@redux-saga/core";
import { applyMiddleware, compose, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";

import rootReducer from "./rootReducer";
import sagaWatcher from "./sagaWatcher";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["search", "catalog", "error", "analogs", "loaders", "onboarding"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const saga = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk, saga))
);

const persistor = persistStore(store);

saga.run(sagaWatcher);

const configureStore = () => {
  return { store, persistor };
};

export default configureStore;
