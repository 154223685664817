import { useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Wrapper, Backdrop } from "./dropdown.styles";

const Dropdown = ({
  control = <button>Default control</button>,
  children = <div>Default content</div>,
  disabled,
  variant = "default",
  fullWidthDropdown,
  dataTestAttr,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const classnames = classNames("dropdown", {
    "is-disabled": disabled,
    "dropdown--fullWidthDropdown": fullWidthDropdown,
    [`dropdown--variant--${variant}`]: variant,
  });
  const backdropClass = classNames("dropdown__backdrop", {
    "is-open": open,
  });
  const contentClass = classNames("dropdown__content", {
    "is-open": open,
  });

  const handleClose = () => setOpen(false);
  const handleClick = () => setOpen((isOpen) => !isOpen);

  return (
    <>
      <Wrapper className={classnames} {...rest}>
        <div
          onClick={handleClick}
          className="dropdown__control"
          data-test={dataTestAttr}
        >
          {control}
        </div>
        <div className={contentClass} onClick={handleClose}>
          <div className="dropdown__content-inner">{children}</div>
        </div>
      </Wrapper>
      <Backdrop isOpen={open} onClick={handleClose} className={backdropClass} />
    </>
  );
};

Dropdown.MenuItem = ({ icon, children, to, dataTestAttr, className, ...rest }) => {
  const commonContent = (
    <div  data-test={dataTestAttr} {...rest} className={`dropdown__menu-item ${className ? className : ''}`}>
      {icon ? <span className="dropdown__menu-item-icon">{icon}</span> : null}
      <span className="dropdown__menu-item-text">{children}</span>
    </div>
  );

  return (
    <>
      {to ? (
        <Link to={to} data-test={dataTestAttr}>
          {commonContent}
        </Link>
      ) : (
        commonContent
      )}
    </>
  );
};

export default Dropdown;
