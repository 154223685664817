import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  padding-top: 32px;
`;
Wrapper.displayName = "SummaryWrapper";

export const Title = styled.h1`
  margin: 0;
`;
