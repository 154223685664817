const convertToHrMin12h = (dateString) => {
  const date = new Date(dateString);

  let hours24 = date.getHours();
  let hours = hours24 % 12;

  hours = hours ? hours : 12; // the hour '0' should be '12'
  if (hours > 0 && hours < 10) {
    hours = "0" + hours;
  }
  let minutes = date.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const ampm = (hours24 >= 12 ? "pm" : "am").toUpperCase();
  const result = hours + ":" + minutes + " " + ampm;

  return result;
};

export default convertToHrMin12h;
