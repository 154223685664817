// @ts-check
import React, { useState } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";
import validation from "../../constants/validation";
import Analogs from "./analogs";
import { CustomerRequestDialog } from "./components/CustomerRequestDialog";
import { userSelectors } from "redux/user";
import { customFetch } from "utils";
import { toast } from "react-toastify";
import { CircleCheckIcon } from "ui/icons";
import { productSelectors } from "../../redux/product";

const initParams = {
  retry: 5,
  retryDelay: 300,
};

const request_urls = {
  registered: "/customers/current/requests/PRODUCT_ANALOGS",
  anonymous: "/customers/anonymous/requests/PRODUCT_ANALOGS",
};

const AnalogsContainer = ({ analogs, code, cat, currency }) => {
  const isAuth = useSelector(userSelectors.selectIsAuth);

  // @TODO Check pagination and implement in on FE
  // const analogs = useFetchData({ code, cat, currency });

  // useEffect(() => {
  //   const {
  //     manualTriggerAll,
  //     setManualTriggerAll,
  //     data: { items, expanded },
  //   } = analogs;
  //
  //   if (manualTriggerAll && !items?.length && !expanded) {
  //     analogs.handle.loadMoreClick();
  //     setManualTriggerAll(false);
  //   }
  // }, [analogs]);

  const [isModalOpened, openModal] = useState(false);
  const formik = useFormik({
    initialValues: {
      text:
        code &&
        `I’d like to have an analogs list prepared for a product ${code}`,
      subject: code,
      email: "",
      name: "",
      isAuth,
    },
    validationSchema: yup.object({
      email: yup.string().when("isAuth", { is: false, then: validation.EMAIL }),
      name: yup.string().when("isAuth", {
        is: false,
        then: validation.FULL_NAME.required("Full name is required"),
      }),
      text: validation.COMMENT.required("Comment is required"),
    }),
    onSubmit: async (values) => {
      const params = isAuth
        ? {
            url: request_urls.registered,
            method: "post",
            data: {
              text: values.text,
              subject: values.subject,
            },
          }
        : {
            url: request_urls.anonymous,
            method: "post",
            data: {
              email: values.email,
              name: values.name,
              text: values.text,
              subject: values.subject,
            },
          };

      const [, error] = await customFetch(params, initParams);
      if (error) {
        toast.error(error);
      } else {
        toast.success("Your request has been successfully sent", {
          icon: <CircleCheckIcon />,
        });
        openModal(false);
      }
    },
  });

  return (
    <>
      <Analogs
        // loading={analogs.loading}
        items={analogs.items}
        // expanded={analogs.data.expanded}
        // pagination={analogs.data.pagination}
        // handleClickLoadMore={analogs.handle.loadMoreClick}
        // handleClickPagination={analogs.handle.paginationClick}
        handleCustomerRequest={() => openModal(true)}
      />
      <CustomerRequestDialog
        isOpened={isModalOpened}
        open={openModal}
        formik={formik}
        isAuth={isAuth}
      />
    </>
  );
};

export default AnalogsContainer;
