export default function isBase64Valid(data: unknown) {
  if (typeof data === "string") {
    const base64Regex =
      //eslint-disable-next-line
      /^[-A-Za-z0-9+/]*={0,3}$/i;
    return base64Regex.test(data);
  }

  return false;
}
