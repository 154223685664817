import styled from "styled-components";
import { Link } from "../index";
import { accentColor } from "ui/colors";

export const Wrapper = styled.div`
  text-align: center;
`;

export const LinkStyled = styled(Link)`
  color: ${accentColor};

  &:hover {
    text-decoration: underline !important;
  }
`;
