import React from "react";
import classNames from "classnames";
import { Wrapper } from "./counter.styles";
import { LinearLoader } from "../index";
import { MinusIcon, PlusIcon } from "ui/icons";

const Counter = ({ counter, isLoading, fullWidth, variant }) => {
  const classnames = classNames("counter", {
    [`is-loading`]: isLoading,
    [`counter--variant--${variant}`]: variant,
    "counter--fullWidth": fullWidth,
  });

  const decrementClasses = classNames(
    "counter__control",
    "counter__control--left",
    {
      ["counter__control--disabled"]: counter.count <= 1,
    }
  );

  return (
    <Wrapper className={classnames}>
      <div className="counter__cells">
        <button
          className={decrementClasses}
          onClick={counter.decrement}
          disabled={counter.count <= 1}
        >
          <MinusIcon />
        </button>
        <input
          className="counter__input"
          value={counter.count}
          onChange={(event) => {
            counter.setCount(event.target.value);
          }}
        />
        <button
          className="counter__control counter__control--right"
          onClick={counter.increment}
        >
          <PlusIcon />
        </button>
      </div>
      <div className="counter__loader">
        <LinearLoader />
      </div>
    </Wrapper>
  );
};

export default Counter;
