import styled from "styled-components";

export const Wrapper = styled.span`
  color: ${(props) => props.theme.linkColor};
  cursor: pointer;
  user-select: none;

  &:hover {
    color: ${(props) => props.theme.linkColorDarken};
  }
`;
