import styled, { keyframes } from "styled-components";
import { lineColor } from "ui/colors";

const circleRotation = keyframes`
  100% {
    box-shadow: 2em 0em 0 0em, 1.73205081em 1em 0 -0.16041667em,
      1em 1.73205081em 0 -0.14583333em, 0em 2em 0 -0.13125em,
      -1em 1.73205081em 0 -0.11666667em, -1.73205081em 1em 0 -0.10208333em,
      -2em 0em 0 -0.0875em, -1.73205081em -1em 0 -0.07291667em,
      -1em -1.73205081em 0 -0.05833333em, 0em -2em 0 -0.04375em,
      1em -1.73205081em 0 -0.02916667em, 1.73205081em -1em 0 -0.01458333em;
  }

  91.6667% {
    box-shadow: 2em 0em 0 -0.16041667em, 1.73205081em 1em 0 -0.14583333em,
      1em 1.73205081em 0 -0.13125em, 0em 2em 0 -0.11666667em,
      -1em 1.73205081em 0 -0.10208333em, -1.73205081em 1em 0 -0.0875em,
      -2em 0em 0 -0.07291667em, -1.73205081em -1em 0 -0.05833333em,
      -1em -1.73205081em 0 -0.04375em, 0em -2em 0 -0.02916667em,
      1em -1.73205081em 0 -0.01458333em, 1.73205081em -1em 0 0em;
  }
  83.3333% {
    box-shadow: 2em 0em 0 -0.14583333em, 1.73205081em 1em 0 -0.13125em,
      1em 1.73205081em 0 -0.11666667em, 0em 2em 0 -0.10208333em,
      -1em 1.73205081em 0 -0.0875em, -1.73205081em 1em 0 -0.07291667em,
      -2em 0em 0 -0.05833333em, -1.73205081em -1em 0 -0.04375em,
      -1em -1.73205081em 0 -0.02916667em, 0em -2em 0 -0.01458333em,
      1em -1.73205081em 0 0em, 1.73205081em -1em 0 -0.16041667em;
  }
  75% {
    box-shadow: 2em 0em 0 -0.13125em, 1.73205081em 1em 0 -0.11666667em,
      1em 1.73205081em 0 -0.10208333em, 0em 2em 0 -0.0875em,
      -1em 1.73205081em 0 -0.07291667em, -1.73205081em 1em 0 -0.05833333em,
      -2em 0em 0 -0.04375em, -1.73205081em -1em 0 -0.02916667em,
      -1em -1.73205081em 0 -0.01458333em, 0em -2em 0 0em,
      1em -1.73205081em 0 -0.16041667em, 1.73205081em -1em 0 -0.14583333em;
  }
  66.6667% {
    box-shadow: 2em 0em 0 -0.11666667em, 1.73205081em 1em 0 -0.10208333em,
      1em 1.73205081em 0 -0.0875em, 0em 2em 0 -0.07291667em,
      -1em 1.73205081em 0 -0.05833333em, -1.73205081em 1em 0 -0.04375em,
      -2em 0em 0 -0.02916667em, -1.73205081em -1em 0 -0.01458333em,
      -1em -1.73205081em 0 0em, 0em -2em 0 -0.16041667em,
      1em -1.73205081em 0 -0.14583333em, 1.73205081em -1em 0 -0.13125em;
  }
  58.3333% {
    box-shadow: 2em 0em 0 -0.10208333em, 1.73205081em 1em 0 -0.0875em,
      1em 1.73205081em 0 -0.07291667em, 0em 2em 0 -0.05833333em,
      -1em 1.73205081em 0 -0.04375em, -1.73205081em 1em 0 -0.02916667em,
      -2em 0em 0 -0.01458333em, -1.73205081em -1em 0 0em,
      -1em -1.73205081em 0 -0.16041667em, 0em -2em 0 -0.14583333em,
      1em -1.73205081em 0 -0.13125em, 1.73205081em -1em 0 -0.11666667em;
  }
  50% {
    box-shadow: 2em 0em 0 -0.0875em, 1.73205081em 1em 0 -0.07291667em,
      1em 1.73205081em 0 -0.05833333em, 0em 2em 0 -0.04375em,
      -1em 1.73205081em 0 -0.02916667em, -1.73205081em 1em 0 -0.01458333em,
      -2em 0em 0 0em, -1.73205081em -1em 0 -0.16041667em,
      -1em -1.73205081em 0 -0.14583333em, 0em -2em 0 -0.13125em,
      1em -1.73205081em 0 -0.11666667em, 1.73205081em -1em 0 -0.10208333em;
  }
  41.6667% {
    box-shadow: 2em 0em 0 -0.07291667em, 1.73205081em 1em 0 -0.05833333em,
      1em 1.73205081em 0 -0.04375em, 0em 2em 0 -0.02916667em,
      -1em 1.73205081em 0 -0.01458333em, -1.73205081em 1em 0 0em,
      -2em 0em 0 -0.16041667em, -1.73205081em -1em 0 -0.14583333em,
      -1em -1.73205081em 0 -0.13125em, 0em -2em 0 -0.11666667em,
      1em -1.73205081em 0 -0.10208333em, 1.73205081em -1em 0 -0.0875em;
  }
  33.3333% {
    box-shadow: 2em 0em 0 -0.05833333em, 1.73205081em 1em 0 -0.04375em,
      1em 1.73205081em 0 -0.02916667em, 0em 2em 0 -0.01458333em,
      -1em 1.73205081em 0 0em, -1.73205081em 1em 0 -0.16041667em,
      -2em 0em 0 -0.14583333em, -1.73205081em -1em 0 -0.13125em,
      -1em -1.73205081em 0 -0.11666667em, 0em -2em 0 -0.10208333em,
      1em -1.73205081em 0 -0.0875em, 1.73205081em -1em 0 -0.07291667em;
  }
  25% {
    box-shadow: 2em 0em 0 -0.04375em, 1.73205081em 1em 0 -0.02916667em,
      1em 1.73205081em 0 -0.01458333em, 0em 2em 0 0em,
      -1em 1.73205081em 0 -0.16041667em, -1.73205081em 1em 0 -0.14583333em,
      -2em 0em 0 -0.13125em, -1.73205081em -1em 0 -0.11666667em,
      -1em -1.73205081em 0 -0.10208333em, 0em -2em 0 -0.0875em,
      1em -1.73205081em 0 -0.07291667em, 1.73205081em -1em 0 -0.05833333em;
  }
  16.6667% {
    box-shadow: 2em 0em 0 -0.02916667em, 1.73205081em 1em 0 -0.01458333em,
      1em 1.73205081em 0 0em, 0em 2em 0 -0.16041667em,
      -1em 1.73205081em 0 -0.14583333em, -1.73205081em 1em 0 -0.13125em,
      -2em 0em 0 -0.11666667em, -1.73205081em -1em 0 -0.10208333em,
      -1em -1.73205081em 0 -0.0875em, 0em -2em 0 -0.07291667em,
      1em -1.73205081em 0 -0.05833333em, 1.73205081em -1em 0 -0.04375em;
  }
  8.33333% {
    box-shadow: 2em 0em 0 -0.01458333em, 1.73205081em 1em 0 0em,
      1em 1.73205081em 0 -0.16041667em, 0em 2em 0 -0.14583333em,
      -1em 1.73205081em 0 -0.13125em, -1.73205081em 1em 0 -0.11666667em,
      -2em 0em 0 -0.10208333em, -1.73205081em -1em 0 -0.0875em,
      -1em -1.73205081em 0 -0.07291667em, 0em -2em 0 -0.05833333em,
      1em -1.73205081em 0 -0.04375em, 1.73205081em -1em 0 -0.02916667em;
  }
  0% {
    box-shadow: 2em 0em 0 0em, 1.73205081em 1em 0 -0.16041667em,
      1em 1.73205081em 0 -0.14583333em, 0em 2em 0 -0.13125em,
      -1em 1.73205081em 0 -0.11666667em, -1.73205081em 1em 0 -0.10208333em,
      -2em 0em 0 -0.0875em, -1.73205081em -1em 0 -0.07291667em,
      -1em -1.73205081em 0 -0.05833333em, 0em -2em 0 -0.04375em,
      1em -1.73205081em 0 -0.02916667em, 1.73205081em -1em 0 -0.01458333em;
  }
`;

export const SpinnerStyled = styled.span`
  display: inline-block;
  height: 0.75em;
  width: 0.75em;
  color: ${lineColor};
  line-height: 1;
  vertical-align: middle;
  margin: auto 0;
  border-radius: 1em;
  transition: box-shadow 10ms linear 0s;
  transform: scale(0);
  opacity: 0;
  box-shadow: 2em 0em 0 0em, 1.73205081em 1em 0 -0.16041667em,
    1em 1.73205081em 0 -0.14583333em, 0em 2em 0 -0.13125em,
    -1em 1.73205081em 0 -0.11666667em, -1.73205081em 1em 0 -0.10208333em,
    -2em 0em 0 -0.0875em, -1.73205081em -1em 0 -0.07291667em,
    -1em -1.73205081em 0 -0.05833333em, 0em -2em 0 -0.04375em,
    1em -1.73205081em 0 -0.02916667em, 1.73205081em -1em 0 -0.01458333em;

  &.loading {
    transform: scale(0.5);
    opacity: 1;
    animation: ${circleRotation} 1s linear 150ms normal infinite forwards
      running;
  }
`;

export const Wrapper = styled.div`
  display: none;
  font-size: ${(props) => props.size ?? "16"}px;
  &.loading {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: baseline;
    text-align: center;
    height: ${(props) => props.height || "2em"};
  }
`;
