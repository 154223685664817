import productTypes from "constants/productTypes";

export const categoriesOrder = [
  productTypes.BB,
  productTypes.MADE,
  productTypes.REALDB,
  productTypes.SCR,
  productTypes.EBC,
];

export const generateList = (categories, selectedCategory) => {
  const categoriesList = Object.entries(categories);
  return categoriesOrder.reduce((list, currentCategoryKey) => {
    const item = categoriesList.find(([key]) => key === currentCategoryKey);

    if (item) {
      const [key, value] = item;
      return [
        ...list,
        {
          id: key,
          title: value,
          active: selectedCategory === key,
        },
      ];
    }
    return list;
  }, []);
};
