import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Button } from "ui";
import { FormLayout } from "ui/form-layout/form-layout";
import { rootPages } from "../../../../constants";
import { events, useAmplitudeState } from "context/amplitude-context";
import { Password, RepeatPassword } from "parts/form-inputs";

import { checkResetPasswordStatus, handleSubmit } from "./sendPassword.utils";

import { ButtonWrapper, Form } from "./SendPassword.styled";
import {
  ResetPasswordStatus,
  SendPasswordFormValues,
} from "../../ResetPassword.types";
import * as yup from "yup";
import validation from "../../../../constants/validation";

export default function SendPassword() {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  const formik = useFormik<SendPasswordFormValues>({
    initialValues: {
      password: "",
      repeatPassword: "",
    },
    validationSchema: yup.object({
      password: validation.PASSWORD,
      repeatPassword: validation.REPEAT_PASSWORD,
    }),
    onSubmit: (values) => {
      handleSubmit({ ...values, token }, { formik, history });
      amplitude.logEvent(events.forgot_password, { new_pass_set: true });
    },
  });

  useEffect(() => {
    checkResetPasswordStatus(token).then((status: ResetPasswordStatus) => {
      if (status === ResetPasswordStatus.USED) {
        history.push(`/${rootPages.RESET_PASSWORD}/link-already-used`);
      } else if (status !== ResetPasswordStatus.OK) {
        history.push(`/${rootPages.RESET_PASSWORD}/link-not-valid`);
      }
    });
    // eslint-disable-next-line
  }, [token]);

  useEffect(
    () => {
      amplitude.logEvent(events.forgot_password, { opened_email_link: true });
    },
    // eslint-disable-next-line
    []);

  return (
    <FormLayout title="Enter new password">
      <Form onSubmit={formik.handleSubmit}>
        <Password formik={formik} checkStrength />
        <RepeatPassword formik={formik} />
        <ButtonWrapper>
          <Button
            size="small"
            type="submit"
            fullWidth
            disabled={!formik.isValid || formik.isSubmitting}
            isLoading={formik.isSubmitting}
          >
            Continue
          </Button>
        </ButtonWrapper>
      </Form>
    </FormLayout>
  );
}
