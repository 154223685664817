import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { Wrapper } from "./Tabs.styles";

/**
 * @typedef TabsNamespace
 * @property {React.FC<React.PropsWithChildren
 *  & { active: boolean }
 *  & import("react-router-dom").LinkProps>} Button
 */

/**
 * @type {React.FC<React.PropsWithChildren
 *  & { navigation: React.ReactNode}>
 *  & TabsNamespace }
 */
const Tabs = ({ children, navigation }) => {
  return (
    <Wrapper className="tabs">
      <div className="tabs__navigation">{navigation}</div>
      {children ? children : null}
    </Wrapper>
  );
};

Tabs.Button = ({ children, to, active, ...rest }) => {
  const buttonClasses = classNames("tabs__button", {
    "is-active": active,
  });

  if (to) {
    return (
      <Link to={to} className={buttonClasses} {...rest}>
        {children}
      </Link>
    );
  } else {
    return (
      <div className={buttonClasses} {...rest}>
        {children}
      </div>
    );
  }
};

export default Tabs;
