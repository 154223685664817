// @ts-check
import React from "react";
import { Toast } from "ui";

export const NewsletterToast = ({ title, description }) => {
  return (
    <Toast>
      <Toast.Title>{title}</Toast.Title>
      <Toast.Text>{description}</Toast.Text>
    </Toast>
  );
};
