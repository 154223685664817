import styled from "styled-components";
import { accentColor } from "../colors.js";

export const Wrapper = styled.div`
  position: relative;
`;

export const Count = styled.div`
  background-color: ${accentColor};
  position: absolute;
  border: 3px solid #fff;
  color: #fff;
  height: 20px;
  min-width: 20px;
  border-radius: 20px;
  box-sizing: content-box;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  top: -10px;
  right: -10px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;
