import React from "react";
import { getDiscountPrice, getPrice } from "../redux/utils";
import CartItem from "./CartItem";

const CartItemContainer = ({
  item,
  handleChangePriceSelect,
  handleChangeCount,
  handleDelete,
  currency,
  cartInfoLoading,
}) => {
  const price = getPrice(item, currency);
  const discountPrice =
    price !== "Inquiry" ? getDiscountPrice(item, currency) : "";

  const callback = (value) => {
    handleChangeCount({
      key: item.key,
      data: {
        ...item,
        quantity: value,
      },
    });
  };

  return (
    <CartItem
      item={item}
      handleChangePriceSelect={handleChangePriceSelect}
      handleDelete={handleDelete}
      currency={currency}
      callback={callback}
      cartInfoLoading={cartInfoLoading}
      price={price}
      discountPrice={discountPrice}
    />
  );
};

export default CartItemContainer;
