const selectData = (state) => {
  return state.checkout.data;
};

const selectPaymentMethods = (state) => {
  return state.checkout.paymentMethods;
};

const selectAccordion = (state) => {
  return state.checkout.accordion;
};

export default {
  selectData,
  selectAccordion,
  selectPaymentMethods
};
