import styled from "styled-components";
import { blue, darkBlue } from "ui/colors";

export const Wrapper = styled.div``;

export const Group = styled.div`
  display: flex;
  align-items: center;
`;

export const Cell = styled.div``;

export const Info = styled.div`
  padding-left: 18px;
  font-size: 14px;
  line-height: 20px;
`;

export const Title = styled.h3`
  margin: 0;
  margin-bottom: 6px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${darkBlue};
`;

export const Image = styled.div`
  width: 88px;
  height: 88px;
  background-size: cover;
  background-position: center;
`;

export const Row = styled.div`
  min-width: 200px;
  color: ${blue};
`;

export const MessageRow = styled.div`
  font-size: 12px;
  font-weight: 400;
  max-width: 240px;
  line-height: 18px;
  color: ${blue};
`;

export const SubInfo = styled.h4`
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${darkBlue};
  margin-bottom: 6px;
`;
