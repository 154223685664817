// @ts-check

/**
 *
 * @param {string} q
 * @returns {Array.<string>}
 */
const getArrayFromBulkSearch = (q) =>
  decodeURIComponent(q)
    .split(/[;,\s]+/)
    .filter(Boolean);

export default getArrayFromBulkSearch;
