// @ts-check

import React, { useCallback, useState } from "react";
import common from "constants/common";
import { ExpandButton } from "pages/home/components/ExpandButton";
import {
  SectionDescription,
  SectionTitle,
} from "pages/home/components/Section.styles";

import {
  Answer,
  ContactLink,
  ContactMessage,
  Question,
  QuestionTitle,
  QuestionHeader,
  QuestionsList,
  Wrapper,
} from "./Faq.styles";

const FaqSections = [
  {
    question: "How long does it take to process the order?",
    answer:
      "We aim to process orders the same day we receive them, so you can expect dispatch within 1-2 days.",
  },
  {
    question: "What payment methods are accepted?",
    answer: "We accept payment by Check, Wire transfer and credit card.",
  },
  {
    question: "What if a specific compound is not in stock?",
    answer:
      "You can go ahead with adding that product to cart and our team will contact you regarding the potential replacements or synthesis lead-time.",
  },
  {
    question: "How do I make changes to my order?",
    answer:
      "Unfortunately, once the order is placed, there’s no way to make any changes via our store.",
  },
  {
    question: "What is the status of the order I’ve placed?",
    answer:
      "You can check the status of your order by visiting the 'Order' tab in your profile. Our team will also notify you as soon as there are updates regarding your order.",
  },
  {
    question: "How can I claim a return?",
    answer: (
      <>
        You can contact us{" "}
        <ContactLink href={common.CONTACTS_HREF} target="_blank">
          here
        </ContactLink>{" "}
        to claim a return. It may take up to 5 days to process your request.
      </>
    ),
  },
];

/**
 * @type { React.FC }
 */
export const FaqList = () => {
  const [expandedSections, expandSection] = useState([]);

  const expandSectionHandler = useCallback(
    (sectionId, expanded) =>
      expandSection((sections) => {
        const newSections = [...sections];
        newSections[sectionId] = expanded;
        return newSections;
      }),
    []
  );

  return (
    <Wrapper>
      <SectionTitle>Frequently Asked Questions</SectionTitle>
      <SectionDescription>
        Below you can find questions the most frequently asked questions.
      </SectionDescription>
      <QuestionsList>
        {FaqSections.map((section, idx) => {
          const expanded = expandedSections[idx];
          return (
            <Question key={idx} expanded={expanded}>
              <QuestionHeader
                onClick={() => expandSectionHandler(idx, !expanded)}
              >
                <QuestionTitle>{section.question}</QuestionTitle>
                <ExpandButton expanded={expanded} />
              </QuestionHeader>
              <Answer expanded={expanded}>{section.answer}</Answer>
            </Question>
          );
        })}
      </QuestionsList>

      <ContactMessage>
        Didn't find what you're looking for? Contact our customer support{" "}
        <ContactLink href={common.CONTACTS_HREF} target="_blank">
          here
        </ContactLink>
        .
      </ContactMessage>
    </Wrapper>
  );
};
