import styled from "styled-components";

export const Header = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const Title = styled.h2`
  font-size: 48px;
  font-weight: 500;
  margin: 0;
`;

export const Content = styled.div`
  background-color: white;
  padding-top: 20px;
  padding-bottom: 100px;
`;
