import { useEffect, useState } from "react";

// @TODO Uncomment after naming changed to tsx
// export interface ProductPrices {
//   currency: string;
//   all: ProductPrice[];
//   default: ProductPrice;
// }
//
// export interface ProductPrice {
//   weight: ProductWeight;
//   price: number;
// }
//
// export interface ProductWeight {
//   amount: number;
//   measure: string;
//   available: string;
// }
//
// type UseCurrentPriceProps = {
//   prices: ProductPrices;
// };
const useCurrentPrice = ({ prices }) => {
  const [currentPrice, setCurrentPrice] = useState(null);

  const defaultPrice = prices?.default;
  const allPrices = prices?.all;
  const matchedPrice =
    allPrices?.length && defaultPrice
      ? allPrices.find((productPrice) => {
          return (
            productPrice?.weight.measure === defaultPrice?.weight.measure &&
            productPrice?.weight.amount === defaultPrice?.weight.amount
          );
        })
      : null;

  useEffect(() => {
    if (matchedPrice) {
      setCurrentPrice(matchedPrice);
    } else if (prices && allPrices?.length) {
      setCurrentPrice(allPrices[0]);
    } else {
      setCurrentPrice("empty");
    }
  }, [prices]);

  return [currentPrice, setCurrentPrice];
};

export default useCurrentPrice;
