// @ts-check
import React from "react";
import {
  DocumentationWrapper,
  NavigationWrapper,
} from "./documentation.styles";
import Markdown from "markdown-to-jsx";
import { Navigation } from "./Navigation";
import { slugify } from "../hooks/toc";
import { useDocumentation } from "../hooks/useDocumentation";

export const Documentation = ({ docList }) => {
  const { documents, navigation } = useDocumentation(docList);

  return (
    <DocumentationWrapper>
      <NavigationWrapper>
        <Navigation items={navigation} />
      </NavigationWrapper>
      <article>
        {documents.map(({ document, id }) => {
          return (
            <Markdown
              key={id}
              options={{
                wrapper: "section",
                slugify: slugify(id),
              }}
            >
              {document}
            </Markdown>
          );
        })}
      </article>
    </DocumentationWrapper>
  );
};
