import CountdownTimer from "ui/countdown-timer/countdown-timer";
import { Wrapper, Text } from "./PendingTimer.styles";

const PendingTimer = ({ targetDate, callback }) => {
  return (
    <Wrapper>
      <Text>Previous payment transaction pending.</Text>
      <Text>
        Please try again in{" "}
        <CountdownTimer targetDate={targetDate} callback={callback} />
      </Text>
    </Wrapper>
  );
};

export default PendingTimer;
