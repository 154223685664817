import { endpoints } from "../../constants";
import { customFetch, decode, makeParams } from "../../utils";
import { filterCatalogResult } from "./utils";
import { RequestMethods } from "../../utils/customFetch";
import { ProductTypesV2 } from "../../constants/productTypes";
import { SearchAsTypes, SearchAsTypesV2 } from "../../constants/searchAsTypes";
import { customFetchDelayedMiddleware } from "../../context/sseContext";
import common from "../../constants/common";

export const fetchProducts = async ({
  code,
  cat,
  type,
  sstype,
  currency,
  catalogFilters,
  searchBy,
  similarity,
}) => {
  const url = searchBy
    ? `${endpoints.CATALOG}/search/in/${ProductTypesV2[cat]}/by/${searchBy}`
    : type
    ? `${endpoints.CATALOG}/search/in/${ProductTypesV2[cat]}/by/SMILEs/${
        SearchAsTypesV2[sstype]
      }/${sstype === SearchAsTypes.SIM && similarity ? similarity : ""}`
    : `${endpoints.CATALOG}/search/by/codes`;

  const res = await customFetchDelayedMiddleware({
    method: RequestMethods.POST,
    url: url,
    data: {
      compounds: decode(code),
      currency: currency?.abbr,
      criterias: catalogFilters,
    },
  });

  const updatedResponse = [...res];

  if (updatedResponse[0]) {
    const parsedResult = updatedResponse[0]?.delayedResult
      ? JSON.parse(updatedResponse[0]?.delayedResult)?.results
      : updatedResponse[0]?.results;

    updatedResponse[0] = {
      searchResultQuantity: parsedResult.products.length,
      searchResults: parsedResult.products,
    };
  }

  if (updatedResponse[0]) {
    return filterCatalogResult(updatedResponse[0]);
  }

  return false;
};

export function fetchRelated({ code, cat, type, count = 1, curPage = 1 }) {
  const queryParams = makeParams({
    q: code,
    cat,
    type,
    curPage,
    pageSize: count,
  });

  return customFetch({
    url: `${endpoints.CATALOG_RELATED}?${queryParams}`,
  });
}

export const fetchProductList = async ({ id }) => {
  const res = await customFetch({
    url: `catalog/products/lists/${id}`,
  });

  return res;
};

export const analyzeCompounds = async (searchPhrase: string) => {
  const res = await customFetch(
    {
      method: RequestMethods.POST,
      url: `catalog/search/analyze-compounds`,
      data: {
        compounds: searchPhrase,
      },
    },
    { baseURL: common.API_V2_URL }
  );

  return {
    analysedCompounds: res[0],
    typesSearchBy: Object.keys(res[0]),
  };
};
