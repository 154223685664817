import styled from "styled-components";

export const Inner = styled.div`
  padding-top: 0;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 20px;
`;

export const ButtonWrapper = styled.div`
  padding-left: 16px;
`;
