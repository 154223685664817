import * as yup from "yup";
import validation from "../../../../constants/validation";

export const getValidationSchema = () => {
  const validationSchema = yup.object({
    carrierId: validation.CARRIER_ID,
    carrier: validation.CARRIER,
  });

  return validationSchema;
};
