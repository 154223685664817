import React, { ReactNode } from "react";
import { Wrapper } from "./container.styles";
import classNames from "classnames";

type ContainerProps = {
  children: ReactNode;
  fullHeight?: boolean;
  className?: string;
};

const Container = ({
  children,
  fullHeight,
  className,
  ...rest
}: ContainerProps) => {
  return (
    <Wrapper
      className={classNames("container", className)}
      fullHeight={fullHeight}
      {...rest}
    >
      <div className="container__inner">{children}</div>
    </Wrapper>
  );
};

export default Container;
