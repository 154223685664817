import cartAPI from "./api";
import superCartActions from "./actions";
import { call, put, select } from "redux-saga/effects";
import {
  addImages,
  calculateTotal,
  getImagelessItems,
  getUniqueCodes,
  setItems,
} from "./utils";
import fetchImages from "utils/fetch/fetchImages";
import { runIfIsFunction } from "utils";

export function* requestCartInfo(payloadParam) {
  const payload = payloadParam.payload;
  const state = yield select();
  const isAuth = state.user.isAuth;

  try {
    const currency = state.currency.current;
    yield put(
      superCartActions.setCartInfo({
        loading: true,
      })
    );

    if (isAuth) {
      const res = yield call(() => cartAPI.fetchCartInfo(currency?.abbr));

      if (res[1]) {
        if (typeof payload?.onError === "function") {
          yield payload?.onError();
        }
      } else {
        if (res[0]) {
          yield put(
            superCartActions.setCartInfo({
              loading: false,
              data: res[0],
              error: "",
            })
          );
        }

        if (typeof payload?.onSuccess === "function") {
          yield payload?.onSuccess(res[0]);
        }
      }
    } else {
      const calculatedTotalSum = calculateTotal({
        items: state.superCart.items,
      });

      yield put(
        superCartActions.setCartInfo({
          loading: false,
          data: {
            subtotal: calculatedTotalSum,
          },
          error: "",
        })
      );
    }
  } catch (e) {
    console.log("error", e);
  } finally {
  }
}

export function* postCartContent({ payload }) {
  const { items, currency, cb } = payload;
  try {
    const res = yield cartAPI.postCartContent({
      items,
      currency: currency.abbr,
    });

    if (res[0]) {
      yield put(superCartActions.allToCart(res[0]));
    }

    if (cb && typeof cb === "function") {
      cb(res);
    }
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}

export function* requestItemsAndImages({ payload }) {
  const state = yield select();
  const superCart = state.superCart;

  try {
    const cartItems = yield cartAPI.fetchItems();

    const currentImageless = getImagelessItems({
      items: cartItems,
      images: superCart.images,
    });

    const uniqueCodes = getUniqueCodes(currentImageless);
    const images = yield fetchImages(uniqueCodes);

    yield put(
      superCartActions.setSuperCart({
        ...superCart,
        images: addImages(superCart.images, images),
        items: setItems(cartItems),
      })
    );

    runIfIsFunction(payload?.onSuccess);
  } catch (e) {
    console.log("Something went wrong!", e);

    runIfIsFunction(payload?.onError);
  }
}
