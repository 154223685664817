const catalogViewModes = {
  LIST: "list",
  CARDS: "cards",
};

export enum CatalogViewModes {
  LIST = "list",
  CARDS = "cards",
}

export default catalogViewModes;
