import { SET_CURRENT, SET_DISABLE_SELECT } from "./types";

const setCurrent = (payload) => {
  return {
    type: SET_CURRENT,
    payload,
  };
};

const setDisableSelect = (isDisabled) => {
  return {
    type: SET_DISABLE_SELECT,
    payload: isDisabled,
  };
};

export default {
  setCurrent,
  setDisableSelect,
};
