import classNames from "classnames";
import { Wrapper } from "./radio-card.styles";
import RadioCircle from "ui/radio-circle/radio-circle";

const RadioCard = ({ active, hide, children, handleClick, ...rest }) => {
  const classnames = classNames("radio-card", {
    "is-active": active,
    "is-hidden": hide,
  });

  return (
    <Wrapper className={classnames} onClick={handleClick} {...rest}>
      <RadioCircle active={active} />
      {children}
    </Wrapper>
  );
};

export default RadioCard;
