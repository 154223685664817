import React from "react";
import {
  ToastContainer as ToastStyled,
  CloseButton as CloseButtonStyled,
  Wrapper,
} from "./toast.styles";
import { CloseIcon } from "ui/icons";

const CloseButton = ({ closeToast }) => (
  <CloseButtonStyled className="close-icon" onClick={closeToast}>
    <CloseIcon />
  </CloseButtonStyled>
);

const ToastContainer = () => <ToastStyled closeButton={CloseButton} />;

const Toast = ({ children }) => {
  return <Wrapper className="toast">{children}</Wrapper>;
};

Toast.Title = ({ children }) => {
  return <div className="toast__title">{children}</div>;
};

Toast.Text = ({ children }) => {
  return <div className="toast__text">{children}</div>;
};

export default Toast;

export { ToastContainer };
