import Cookies from "js-cookie";
import { common } from "../constants";
import { events } from "../context/amplitude-context";

const handleGoToOldVersion = (amplitude) => {
  amplitude.logEvent(events.new_design_rollback);
  Cookies.remove("redirect", { domain: common.OLD_SHOP_COOKIE_DOMAIN });

  window.location.replace(common.OLD_SHOP_HREF);
};

export default handleGoToOldVersion;
