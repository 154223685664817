import React from "react";
import { Input } from "../../../ui";

const City = ({ formik, dataTestAttr = "input-city", ...props }) => {
  return (
    <Input
      label="City"
      name="city"
      formik={formik}
      data-test={dataTestAttr}
      placeholder="Enter city"
      {...props}
    />
  );
};

export default City;
