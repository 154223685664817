import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { utilSelectors } from "../../../../redux/util";
import { checkoutActions, checkoutSelectors } from "../../../../redux/checkout";
import Carrier from "./Carrier";
import { useCheckNextOrJustClose } from "../../../../hooks";
import { getValidationSchema } from "./utils";
import {
  events,
  useAmplitudeState,
} from "../../../../context/amplitude-context";
import useRequestAdditionalCosts from "../../useRequestAdditionalCosts";
import { isDisableOrderPlacement } from "../restriction-modal/utils";
import { errors, errorsMessages } from "../../../../constants";
import {
  DhlLogoIcon,
  EnamineLogoIcon,
  FedexLogoIcon,
  UpsLogoIcon,
} from "ui/icons";

const images = {
  0: EnamineLogoIcon,
  2: FedexLogoIcon,
  1: DhlLogoIcon,
  4: UpsLogoIcon,
};

export const shipmentOptions = {
  STANDARD: "STANDARD",
  PARTIAL: "PARTIAL",
};

export const deliveryOptions = {
  STANDARD: "STANDARD",
  RUSH: "RUSH",
};

const CarrierContainer = ({ postalCodeIssue }) => {
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();
  const carriers = useSelector(utilSelectors.selectCarriers);
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const [selectedId, setSelectedId] = useState(
    checkoutData?.carrier ? checkoutData.carrier.id : carriers?.[0]?.id
  );
  const [selectedItem, setSelectedItem] = useState(
    checkoutData?.carrier || null
  );
  const [shipmentOption, setShipmentOption] = useState(
    checkoutData?.carrierShipmentOption || shipmentOptions.STANDARD
  );
  const [deliveryOption, setDeliveryOption] = useState(
    checkoutData?.carrierDeliveryOption || deliveryOptions.STANDARD
  );
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [goNextOrJustClose] = useCheckNextOrJustClose(4);
  const { requestAdditionalCosts } = useRequestAdditionalCosts();
  const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);

  const doNextStep = (newData) => {
    dispatch(checkoutActions.closeAccordion("step4"));
    if (goNextOrJustClose) {
      dispatch(checkoutActions.openAccordion("step5"));
    }

    amplitude.logEvent(events.checkout_carrier, {
      parameter: newData?.carrier.title,
    });
  };

  const handleSelectShipmentOption = (value) => {
    setShipmentOption(value);
  };

  const handleSelectDeliveryOption = (value) => {
    setDeliveryOption(value);
  };

  const formik = useFormik({
    initialValues: {
      carrierId: checkoutData?.carrierId || "",
      carrier: null,
    },
    validationSchema: getValidationSchema(),
    onSubmit: (values) => {
      setIsLoading(true);
      const newData = { ...checkoutData };
      newData.carrierId =
        selectedItem.title === "Enamine" ? "" : values.carrierId;
      newData.carrier = selectedItem;
      if (selectedItem.title === "Enamine") {
        newData.carrierShipmentOption = shipmentOption;
        newData.carrierDeliveryOption = deliveryOption;
      } else {
        newData.carrierShipmentOption = shipmentOptions.STANDARD;
        newData.carrierDeliveryOption = deliveryOptions.STANDARD;
        setShipmentOption(shipmentOptions.STANDARD);
        setDeliveryOption(deliveryOptions.STANDARD);
      }
      dispatch(checkoutActions.setData(newData));

      const onSuccess = (data) => {
        setIsLoading(false);

        if (data?.restriction) {
          if (!isDisableOrderPlacement(data?.restriction)) {
            doNextStep(newData);
          }
        } else {
          doNextStep(newData);
        }
      };

      const onError = (props) => {
        if (props.type === errors.SHIPPING_ZIP_NOT_VALID_ERROR) {
          postalCodeIssue.setTitle("Postal Code Issue");
          postalCodeIssue.setMessage(
            errorsMessages[errors.SHIPPING_ZIP_NOT_VALID_ERROR]
          );
          postalCodeIssue.setOpen(true);
        } else {
          toast.error(errorsMessages[errors.COMMON_ERROR], {
            autoClose: 3000,
          });
        }

        setIsLoading(false);
      };

      requestAdditionalCosts({ onSuccess, onError });
    },
  });

  useEffect(() => {
    if (carriers?.length) {
      const newItems = carriers.map((carrier) => ({
        id: carrier.id,
        title: carrier.title,
        image: images?.[carrier.id],
      }));

      setItems(newItems);
    }
  }, [carriers]);

  const handleClick = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    const item = carriers.find((el) => el.id === selectedId);

    if (item) {
      setSelectedItem(item);
      formik.setValues((prev) => ({
        ...prev,
        carrier: item.id,
      }));
    }
  }, [selectedId]);

  useEffect(() => {
    if (!checkoutData?.shipping) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [checkoutData?.shipping]);

  return (
    <Carrier
      items={items}
      selectedId={selectedId}
      setSelectedId={setSelectedId}
      handleClick={handleClick}
      formik={formik}
      isLoading={isLoading}
      shipmentOptions={shipmentOptions}
      handleSelectShipmentOption={handleSelectShipmentOption}
      shipmentOption={shipmentOption}
      deliveryOptions={deliveryOptions}
      deliveryOption={deliveryOption}
      handleSelectDeliveryOption={handleSelectDeliveryOption}
      deliveryModalOpen={deliveryModalOpen}
      setDeliveryModalOpen={setDeliveryModalOpen}
    />
  );
};

export default CarrierContainer;
