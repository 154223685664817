// @ts-check
import { prepareData } from "./utils";
import { customFetch } from "../../utils";
import { endpoints } from "../../constants";

export const fetchCosts = async (data, currency) => {
  const newData = prepareData(data);
  newData.currency = currency.abbr;

  return customFetch({
    method: "post",
    url: `${endpoints.CART_ADDITIONAL_COSTS}`,
    data: newData,
  });
};

export const fetchAvailablePaymentMethods = async (currency) => {
  return customFetch({
    method: "get",
    url: `${endpoints.CART_PAYMENT_METHODS}`,
    data: null,
    config: {
      params: {
        currency: currency.abbr,
      },
    },
  });
};
