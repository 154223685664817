import { rootPages } from "constants";
import { events, useAmplitudeState } from "context/amplitude-context";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { userSelectors } from "redux/user";

const useQuote = () => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const [open, setOpen] = useState(false);

  const handleRequestQuote = () => {
    amplitude.logEvent(events.cart_request_quote_click);

    if (isAuth) {
      setOpen((prev) => !prev);
    } else {
      history.push(`/${rootPages.SIGNIN}`, {
        from: {
          pathname: `/${rootPages.CART}`,
        },
      });
    }
  };

  return {
    open,
    setOpen,
    handleRequestQuote,
  };
};

export default useQuote;
