import roles from "../constants/roles";

const isRegisteredUser = (items) => {
  let registered = false;

  if (items) {
    items.forEach((role) => {
      if (role.authority === roles.ROLE_CUSTOMER) {
        registered = true;

        return registered;
      }
    });
  }

  return registered;
};

export default isRegisteredUser;
