import {
  SET_LOGOUT,
  SET_ADDRESSES,
  SET_ADDRESSES_LOADING,
  SET_PROFILE,
  SET_PROFILE_LOADING,
  SET_USER,
  SET_USER_LOADING,
  SET_USER_READY,
  SET_REFRESH_TOKEN,
  SET_PROFILE_ERROR,
  SET_IS_AUTH,
  SET_REDIRECT_TO,
  SET_CURRENT_USER,
} from "./types";

const initialState = {
  isAuth: false,
  user: null,
  userLoading: null,
  userReady: false,
  profile: null,
  profileLoading: null,
  profileError: "",
  addresses: null,
  addressesLoading: null,
  refreshToken: null,
  redirectTo: null,
  current: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_READY:
      return {
        ...state,
        userReady: action.payload,
      };
    case SET_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case SET_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
      };
    case SET_LOGOUT:
      return {
        ...initialState,
        redirectTo: action.payload?.redirectTo,
      };
    case SET_REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.payload,
      };
    case SET_USER_LOADING:
      return {
        ...state,
        userLoading: action.payload,
      };
    case SET_ADDRESSES_LOADING:
      return {
        ...state,
        addressesLoading: action.payload,
      };
    case SET_PROFILE_LOADING:
      return {
        ...state,
        profileLoading: action.payload,
      };
    case SET_PROFILE_ERROR:
      return {
        ...state,
        profileError: action.payload,
      };
    case SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
      };
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
