import Carrier from "./parts/carrier/Carrier.container";
import ContactPerson from "./parts/contact-person/ContactPerson.container";
import Billing from "./parts/billing/Billing.container";
import Shipping from "./parts/shipping/Shipping.container";
import Payment from "./parts/payment/Payment.container";

export const ACCORDION_STEPS = [
  {
    title: "Contact Person for Shipping and Billing",
    component: ContactPerson,
  },
  {
    title: "Billing Address",
    component: Billing,
  },
  {
    title: "Shipping Address",
    component: Shipping,
  },
  {
    title: "Shipping Options",
    component: Carrier,
  },
  {
    title: "Payment Type",
    component: Payment,
  },
];

export const MODES = {
  CHECKOUT: "CHECKOUT",
  CHECK_ORDER: "CHECK_ORDER",
  SENDING_ORDER: "SENDING_ORDER",
  THANK: "THANK",
  ERROR: "ERROR",
};

export const INITIAL_ORDER_SUMMARY = {
  subtotal: "-",
  handling: "-",
  shipping: "-",
  tax: "-",
  discount: "-",
  total: "-",
};
