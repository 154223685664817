import { useState } from "react";
import { Image, Wrapper } from "./marvin-image.styles";

const MarvinImage = ({ src, variant }) => {
  const [hasError, setErrorLoading] = useState(false);

  return (
    <Wrapper>
      {src && !hasError && (
        <Image
          src={src}
          alt="Structure"
          variant={variant}
          onError={() => setErrorLoading(true)}
        />
      )}
    </Wrapper>
  );
};

export default MarvinImage;
