import React from "react";
import styled from "styled-components";

import Phone from "../../../../../../parts/form-inputs/phone/Phone";
import CountrySelect from "../../../../../../parts/form-inputs/country-select/CountrySelect";
import Company from "../../../../../../parts/form-inputs/company/Company";
import City from "../../../../../../parts/form-inputs/city/City";
import Zip from "../../../../../../parts/form-inputs/zip/Zip";
import Vat from "../../../../../../parts/form-inputs/vat/Vat";
import StateSelect from "../../../../../../parts/form-inputs/state-select/StateSelect";
import Address from "../../../../../../parts/form-inputs/address/Address";
import { ErrorMessage, TwoInRow, OneInRow } from "../../../../../../ui";

const ErrorWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 10px;
`;

export default function Form({
  formik,
  vatPattern,
  showStateSelect,
  children,
}) {
  return (
    <form onSubmit={formik.handleSubmit}>
      <OneInRow compact>
        <div>
          <CountrySelect formik={formik} />
        </div>
      </OneInRow>
      <TwoInRow compact>
        {showStateSelect ? (
          <div>
            <StateSelect formik={formik} />
          </div>
        ) : null}
        <div>
          <City formik={formik} />
        </div>
        <div>
          <Zip formik={formik} />
        </div>
        <div>
          <Address formik={formik} />
        </div>
        <div>
          <Phone
            formik={formik}
            label="Contact Phone"
            placeholder="Enter phone"
          />
        </div>
        <div>
          <Company formik={formik} />
        </div>
        {vatPattern ? (
          <div>
            <Vat formik={formik} />
          </div>
        ) : null}
      </TwoInRow>

      {formik.errors.common ? (
        <ErrorWrapper>
          <ErrorMessage>{formik.errors.common}</ErrorMessage>
        </ErrorWrapper>
      ) : null}

      {children && children}
    </form>
  );
}
