import React from "react";
import { Button } from "ui";
import { Wrapper, Content, CheckIcon, Title, Text } from "./styles";

const Thank = ({ handleClose }) => {
  return (
    <Wrapper data-test="success_screen">
      <Content>
        <CheckIcon />
        <Title>Thank you!</Title>
        <Text>Your quote has been sent.</Text>
        <Button onClick={handleClose}>Return to Cart</Button>
      </Content>
    </Wrapper>
  );
};

export default Thank;
