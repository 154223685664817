const mapOrderStatus = (orderStatus) => {
  let res = orderStatus;

  if (orderStatus === "Shipped") {
    res = "Shipped";
  } else if (orderStatus === "Cancelled") {
    res = "Cancelled";
  } else if (orderStatus === "In_Progress") {
    res = "In progress";
  } else if (orderStatus === "New") {
    res = "New";
  }

  return res;
};

export default mapOrderStatus;
