import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const Text = styled.p`
  margin: 0;
  line-height: 28px;

  ${({ marginBottom }) => {
    if (marginBottom) {
      return `margin-bottom: 30px;`;
    }
  }}
`;

export const Column = styled.div`
  ${({ withImage }) => {
    if (withImage) {
      return `
        padding-right: 40px;
        margin-top: -59px;
      `;
    }
  }}
`;
