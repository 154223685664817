import styled from "styled-components";
import { lightBlue } from "../../ui/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${({ filled }) => {
    if (filled) {
      return `
        background-color: ${lightBlue};
      `;
    }
  }}
`;

export const Content = styled.div`
  flex-grow: 1;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 100px;
  opacity: 0.5;
`;

export const ErrorWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;
