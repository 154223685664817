import { useSelector } from "react-redux";
import { utilSelectors } from "redux/util";

const AddressContent = ({ item }) => {
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);

  return (
    <>
      <p>
        {countries?.[item?.country]}, {item?.city}
        {item?.state ? <>, {usStates?.[item?.state]}</> : null}
      </p>
      <p>
        {item?.address}
        {item?.postalCode ? <>, {item?.postalCode}</> : null}
      </p>
      {item?.company ? <p>{item?.company}</p> : null}
      {item?.vat ? <p>{item?.vat}</p> : null}
    </>
  );
};

export default AddressContent;
