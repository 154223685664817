const getTextSearchType = (phrase) => {
  // eslint-disable-next-line no-useless-escape
  let string = phrase.replace(/\-/g, "");

  const matchDigits = string.match(/^\d+$/g);

  if (matchDigits) return "CAS";

  const match = string.match(/^(MFCD|EN|BBV|BRC|FCH|Z|T|PV|EBC)|[1-9]+$/g);

  if (match && match[0]) {
    switch (match[0]) {
      case "MFCD":
        return "MFCD";
      case "EN":
      case "BBV":
      case "Z":
      case "T":
      case "EBC":
      case "BRC":
      case "FCH":
      case "PV":
        return "ID";
    }
  }

  return "SMARTS";
};

export default getTextSearchType;
