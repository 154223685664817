import { searchAsTypes } from ".";

const searchAsTypesEvents = {};

Object.keys(searchAsTypes).forEach((el) => {
  if (el === searchAsTypes.SUB) {
    searchAsTypesEvents[searchAsTypes.SUB] = "Substructure_draw";
  }
  if (el === searchAsTypes.SIM) {
    searchAsTypesEvents[searchAsTypes.SIM] = "Similarity_draw";
  }
  if (el === searchAsTypes.EXACT) {
    searchAsTypesEvents[searchAsTypes.EXACT] = "Exact_draw";
  }
});

export default searchAsTypesEvents;
