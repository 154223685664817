import React, { useEffect, useState } from "react";
import { Input, PasswordStrength } from "ui/index";
import { CharmEyeSlashIcon, CharmEyeIcon, MarkDangerIcon } from "ui/icons";
import { useDebounce } from "use-debounce";
import {
  Wrapper,
  VisibilitySwitcher,
  IconsWrapper,
  DangerIconWrapper,
} from "./styles";
import { customFetch } from "utils";
import { passwordStrengthModes } from "ui/password-strength/password-strength";
import { errorMessages } from "./errorMessages";
import { encodeToBase64 } from "utils/base64Encoding";

const inputTypes = {
  PASSWORD: "password",
  TEXT: "text",
};

const Password = ({
  formik,
  checkStrength,
  type = inputTypes.PASSWORD,
  ...props
}) => {
  const [inputType, setInputType] = useState(type);
  const [init, setInit] = useState(false);
  const [text, setText] = useState("");
  const [count, setCount] = useState(0);
  const [strength, setStrength] = useState(0);
  
  // hide/show element with password strength when password field loose/gain focus
  const [isStrengthShown, showStrength] = useState(true);

  const [passwordErrors, setPasswordErrors] = useState("");

  const [debouncedValue] = useDebounce(text, 500);

  const switchInputType = () => {
    const newInputType =
      inputType === inputTypes.PASSWORD ? inputTypes.TEXT : inputTypes.PASSWORD;

    setInputType(newInputType);
  };

  const callback = async (newValue) => {
    const res = await customFetch({
      method: "post",
      url: "auth/passwords/assessment",
      data: {
        password: encodeToBase64(newValue),
      },
    });

    if (!newValue) {
      setPasswordErrors("");
    } else {
      setCount((prev) => prev + 1);

      if (res[0]?.badReasons?.length) {
        const errors = [];

        res[0]?.badReasons.forEach((err) => {
          if (errorMessages?.[err]) {
            errors.push(errorMessages?.[err]);
          }
        });

        setPasswordErrors(errors);
        formik.setFieldTouched("password");
        setStrength(0);
      } else if (res[0]?.strength) {
        if (res[0].strength === "PASSWORD_IS_WEAK") {
          setStrength(1);
        } else if (res[0].strength === "PASSWORD_IS_MEDIUM") {
          setStrength(2);
        } else if (res[0].strength === "PASSWORD_IS_STRONG") {
          setStrength(3);
        } else {
          setStrength(0);
        }

        setPasswordErrors("");
        formik.validateForm();
      }
    }
  };

  useEffect(() => {
    if (init && checkStrength) {
      callback(debouncedValue);
    } else {
      setInit(true);
    }
  }, [debouncedValue]);

  useEffect(() => {
    setText(formik.values.password);
  }, [formik.values.password]);

  useEffect(() => {
    if (passwordErrors) {
      formik.setErrors({
        ...formik.errors,
        password: [formik.errors.password || "", ...passwordErrors],
      });
    } else {
      if (!formik.values.password) {
        formik.validateForm();
      }
    }
  }, [passwordErrors, count, formik.isValidating]);

  useEffect(() => {
    if (formik.errors?.password?.length > 0) {
      setStrength(0);
    }
  }, [formik.errors?.password]);

  const handleFocus = (e) => {
    showStrength(true);
  };

  const handleBlur = (e) => {
    showStrength(false);
    formik.handleBlur(e);
  };

  return (
    <Wrapper>
      <>
        <Input
          label="Password"
          name="password"
          type={inputType}
          formik={formik}
          data-test="password_input"
          placeholder="Enter password"
          {...props}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        <IconsWrapper>
          <VisibilitySwitcher onClick={switchInputType}>
            {inputType === inputTypes.PASSWORD ? (
              <CharmEyeSlashIcon />
            ) : (
              <CharmEyeIcon />
            )}
          </VisibilitySwitcher>
          {checkStrength &&
          formik.errors.password &&
          formik.touched.password ? (
            <DangerIconWrapper>
              <MarkDangerIcon />
            </DangerIconWrapper>
          ) : null}
        </IconsWrapper>
      </>
      {checkStrength && (
        <div
          style={{
            marginTop: "12px",
            display: isStrengthShown ? "block" : "none",
          }}
        >
          <PasswordStrength mode={passwordStrengthModes[strength]} />
        </div>
      )}
    </Wrapper>
  );
};

export default Password;
