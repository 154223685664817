import styled from "styled-components";

export const InputWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const ButtonWrapper = styled.div`
  padding-top: 28px;
`;

export const FormWrapper = styled.div`
  padding-top: 5px;
`;
