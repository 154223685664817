import styled from "styled-components";
import constants from "../constants";

export const Wrapper = styled.div`
  &.container {
    width: ${constants.maxWidth}px;
    max-width: 100%;
    margin: 0 auto;
  }

  .container__inner {
    padding-right: 64px;
    padding-left: 64px;
  }
`;
