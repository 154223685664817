import styled from "styled-components";

export const ProductDescription = styled.p`
  margin: 18px 0 32px 0;
  padding: 0;
  line-height: 28px;
  white-space: pre-wrap;
`;

export const DownloadSdsWrapper = styled.div`
  margin-top: 28px;
`;

export const FullDescriptionWrapper = styled.div`
  & > * {
    margin-bottom: 28px;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 50%;
  img {
    width: 100%;
  }
`;
