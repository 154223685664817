import React from "react";
import { Input } from "../../../ui";

const Phone = ({ formik, dataTestAttr = "input-phone", ...props }) => {
  return (
    <Input
      label="Phone Number"
      name="phone"
      formik={formik}
      data-test={dataTestAttr}
      placeholder="Enter phone number"
      {...props}
    />
  );
};

export default Phone;
