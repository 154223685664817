import React from "react";

import {
  Wrapper,
  Image,
  PriceList,
  Code,
  InfoWrapper,
  AddToCartButton,
  ImageWrapper,
} from "./CatalogListCard.styled";
import PriceSelect from "parts/price-select/PriceSelect";
import { CartIcon } from "ui/icons";
import Spinner from "../../spinner/Spinner";

export const CatalogListCard = ({
  item,
  image,
  code,
  handleClick,
  href,
  readyToCart,
  isAddingToCart,
  handleAdd,
  setCurrentPrice,
  currentPrice,
  currency,
}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        {image ? (
          <Image
            style={{ backgroundImage: `url(${image?.data || image?.url})` }}
            onClick={handleClick}
            to={href}
          />
        ) : (
          <Spinner size={36} isLoading />
        )}
      </ImageWrapper>
      <InfoWrapper>
        <Code to={href} onClick={handleClick}>
          {code}
        </Code>
        <PriceList>
          <PriceSelect
            prices={item?.prices}
            setCurrentPrice={setCurrentPrice}
            currentPrice={currentPrice}
            currency={currency}
            label={false}
            fullWidth
          />
        </PriceList>
        <AddToCartButton
          iconLeft={<CartIcon />}
          fullWidth
          size="small"
          onClick={handleAdd}
          isLoading={isAddingToCart}
          disabled={!readyToCart}
          data-test="add_to_cart_button"
        />
      </InfoWrapper>
    </Wrapper>
  );
};
