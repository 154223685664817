import styled from "styled-components";
import {
  accentColor,
  dangerColor,
  lightBlue,
  lightDanger,
  lightSuccess,
  successColor,
} from "../colors";

export const Wrapper = styled.div`
  &.tag {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 33px;
    padding: 8px 26px;
    display: inline-flex;
    justify-content: center;
  }

  &.tag--type--primary {
    border: 1px solid ${accentColor};
    background-color: ${lightBlue};
    color: ${accentColor};
  }

  &.tag--type--success {
    border: 1px solid ${successColor};
    background-color: ${lightSuccess};
    color: ${successColor};
  }

  &.tag--type--danger {
    border: 1px solid ${dangerColor};
    background-color: ${lightDanger};
    color: ${dangerColor};
  }
`;
