import styled from "styled-components";

export const Wrapper = styled.div``;

export const ButtonWrapper = styled.div`
  min-width: 200px;
  margin-top: 15px;
`;

export const Text = styled.div``;

export const ModalInner = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
