export const defaultTheme = {
  grayBg: "#F2F2F2",
  darkGrayBg: "#d6d6d6",
  lightText: "#77777C",
  borderColor: "#BABABA",
  linkColor: "rgba(11, 150, 217, 1)",
  linkColorDarken: "rgba(0, 114, 170, 1)",
  sizes: {
    medium: 48,
  },
};

export const darkTheme = {
  grayBg: "#000000",
  lightText: "#77777C",
  borderColor: "#BABABA",
};
