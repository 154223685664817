import { Link, Tag } from "ui";
import { convertToDateAndTime, mapOrderStatus } from "utils";
import { getTagTypeBasedOnOrderStatus } from "../../orders/utils";

const OrderHeader = ({
  backToOrdersLink,
  loading,
  orderId,
  date,
  type,
  status,
}) => {
  const isQuote = type === "QUOTE";
  return (
    <header className="order-page__header">
      <div>
        <div>
          <Link
            className="order-page__back-link"
            to={backToOrdersLink}
            disableDecoration
            data-test="back-to-orders-link"
          >
            Back to orders
          </Link>
        </div>
        <div className="g-flex g-flex-vertical-center order-page__order-info">
          <span className="order-page__order-number">
            {isQuote ? "Quote" : "Order"} #
            <span data-test="order-id">{orderId}</span>
          </span>
          {!loading ? <Tag>{type}</Tag> : null}
          {!loading ? (
            <Tag type={getTagTypeBasedOnOrderStatus(status)}>
              {mapOrderStatus(status)}
            </Tag>
          ) : null}
        </div>
        <div>{!loading ? <>{convertToDateAndTime(date)}</> : null}</div>
      </div>
      <div>
        {/* <Button 
              size="small"
              type="danger-white"
            >Cancel order</Button> */}
      </div>
    </header>
  );
};

export default OrderHeader;
