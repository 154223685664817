// @ts-check

import ApiIntroduction from "./search-api/api-introduction.md";
import Authentication from "./search-api/authentication.md";
import AvailableEndpoints from "./search-api/available-endpoints.md";
import DelayedResults from "./search-api/delayed-results.md";
import FeedFiltering from "./search-api/feed-filtering-using-criterias.md";
import CodeSearch from "./search-api/code-search.md";
import TextSearch from "./search-api/text-search.md";
import CASSearch from "./search-api/cas-search.md";
import MFCDSearch from "./search-api/mfcd-search.md";
import ExactSMILESSearch from "./search-api/exact-smiles-search.md";
import SubstructureSMILESSearch from "./search-api/substructure-smiles-search.md";
import SimilaritySMILESSearch from "./search-api/similarity-smiles-search.md";

const list = [
  { id: "api-introduction", doc: ApiIntroduction },
  { id: "authentication", doc: Authentication },
  { id: "available-endpoints", doc: AvailableEndpoints },
  { id: "delayed-results", doc: DelayedResults },
  { id: "feed-filtering-using-criterias", doc: FeedFiltering },
  { id: "code-search", doc: CodeSearch },
  { id: "text-search", doc: TextSearch },
  { id: "cas-search", doc: CASSearch },
  { id: "mfcd-search", doc: MFCDSearch },
  { id: "exact-smiles-search", doc: ExactSMILESSearch },
  { id: "substructure-smiles-search", doc: SubstructureSMILESSearch },
  { id: "similarity-smiles-search", doc: SimilaritySMILESSearch },
];

export default list;
