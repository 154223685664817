const getCountriesOptions = (usStatesObj) => {
  const resultArr = [];

  if (usStatesObj) {
    Object.keys(usStatesObj)
      .filter((key) => {
        return key !== "EMPTY";
      })
      .map((key) => {
        resultArr.push({
          value: key,
          title: usStatesObj[key],
        });
      });
  }

  return resultArr.sort((a, b) => a.title.localeCompare(b.title));
};

export default getCountriesOptions;
