import styled from "styled-components";
import { media } from "../../constants";
import { darkBlue, lightBlue } from "../colors";

export const Wrapper = styled.div`
  &.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 12px 24px;
    border-radius: 8px;

    &:hover {
      background-color: ${lightBlue};
    }
  }

  .nav-link__text {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-weight: 400;
    white-space: nowrap;
    color: ${darkBlue};

    @media ${media.TABLET} {
      font-size: 18px;
    }
  }
`;
