import styled from "styled-components";
import { darkBlue } from "../../../ui/colors";

export const CatalogTitle = styled.div`
  color: ${darkBlue};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const OptionWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 12px;
  & > *:first-child {
    flex-grow: 1;
  }
`;

export const WrapperGroupUpdate = styled.div`
  margin-bottom: 18px;
`;

export const WrapperActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  & > *:first-child {
    margin-right: 16px;
  }
`;
