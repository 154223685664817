import styled from "styled-components";

export const InputWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  padding-top: 18px;
`;
