import React from "react";
import { Button, DotsSpinner, InputWrapper, Modal } from "ui/index";
import { Wrapper, Loader, LoaderText, ButtonWrapper } from "./styles";
import { Email, FullName, Comment } from "parts/form-inputs";
import Thank from "../thank";

const QuoteModal = ({ open, setOpen, handleClose, formik, mode, modes }) => {
  return (
    <Modal open={open} setOpen={setOpen} title="Request Quote">
      <Wrapper>
        <InputWrapper>
          <FullName formik={formik} />
        </InputWrapper>
        <InputWrapper>
          <Email formik={formik} />
        </InputWrapper>
        <InputWrapper>
          <Comment formik={formik} />
        </InputWrapper>
        <ButtonWrapper>
          <Button
            type="outlined"
            bold
            fullWidth
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            data-test="request_quote_submit_button"
          >
            Request Quote
          </Button>
        </ButtonWrapper>
      </Wrapper>

      {mode === modes.SENDING && (
        <Loader show={formik.isSubmitting}>
          <DotsSpinner />
        </Loader>
      )}

      {mode === modes.SUCCESS && <Thank handleClose={handleClose} />}

      {mode === modes.ERROR && (
        <Loader show={true} data-test="error_screen">
          <LoaderText>
            Some error! <br />
            Please reload this page and try again.
          </LoaderText>
        </Loader>
      )}
    </Modal>
  );
};

export default QuoteModal;
