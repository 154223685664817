import { common } from "constants/index";

const isDev = () => {
  return (
    common.ENV === "development" ||
    (common.ENV !== "production" && common.ENV !== "test")
  );
};

export default isDev;
