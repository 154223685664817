import styled from "styled-components";
import { Link } from "ui";
import { accentColor, lineColor, blue, darkBlue } from "ui/colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${lineColor};

  &:last-child {
    border-bottom: none;
  }
`;

export const Column = styled.div`
  padding: 0 8px;
  flex-grow: 0;
  min-height: 90px;

  &:nth-child(1) {
    flex-grow: 0;
  }

  &:nth-child(2) {
    text-align: left;
    flex-grow: 1;
  }

  &:nth-child(3) {
    min-width: 130px;
    max-width: 240px;
  }

  &:nth-child(5) {
    text-align: left;
    min-width: 95px;
  }
`;

export const ImageLink = styled(Link)`
  width: 120px;
  height: 120px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
  display: block;
`;

export const Code = styled(Link)`
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none !important;
  white-space: nowrap;
  margin-bottom: 18px;
  color: ${darkBlue};

  &:hover {
    color: ${accentColor};
  }
`;

export const Price = styled.span`
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  text-decoration: ${(props) => (props.isDiscount ? "line-through" : "none")};
`;

export const DiscountPrice = styled.span`
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
  margin-left: 10px;
`;

export const PriceContainer = styled.div`
  display: flex;
`;

export const PriceSelectWrapper = styled.div`
  margin-right: 10px;
  width: 300px;
`;

export const PriceWrapper = styled.div`
  text-align: right;
`;

export const IconWrapper = styled.div`
  position: relative;
  top: -3px;
  text-align: right;
`;

export const StockStatusWrapper = styled.div`
  text-align: center;
    color ${blue}
 `;
