import React from "react";
import { Modal, Button } from "../index";
import { Text, Wrapper, ButtonWrapper, ModalInner } from "./error.styles";

const Error = ({ items, open, setOpen, handleClose }) => {
  return (
    <Wrapper>
      {items?.length ? (
        <Modal open={open} setOpen={setOpen} boxProps={{ maxWidth: "650px" }}>
          <ModalInner>
            {items.map((item, idx) => {
              return <Text key={idx}>{item}</Text>;
            })}
            <ButtonWrapper>
              <Button size="small" fullWidth onClick={handleClose}>
                Ok
              </Button>
            </ButtonWrapper>
          </ModalInner>
        </Modal>
      ) : null}
    </Wrapper>
  );
};

export default Error;
