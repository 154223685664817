import React, { useEffect } from "react";
import Cookies from "js-cookie";
import WelcomePopup from "./markup";
import { useDispatch, useSelector } from "react-redux";
import { onboardingActions, onboardingSelectors } from "../redux";

const WelcomePopupContainer = () => {
  const dispatch = useDispatch();
  const open = useSelector(onboardingSelectors.selectShowWelcomePopup);

  const setOpen = (isOpen) => {
    dispatch(onboardingActions.setShowWelcomePopup(isOpen));
  };

  const setOpenSorryPopup = (isOpen) => {
    dispatch(onboardingActions.setShowSorryPopup(isOpen));
  };

  const handleLinkClick = (event) => {
    setOpen(false);
    setOpenSorryPopup(true);
  };

  const onClose = () => {
    Cookies.set("user-visited", "true", { expires: 365 * 20 });
  };

  useEffect(() => {
    const visited = Cookies.get("user-visited");

    if (visited != "true") {
      setOpen(true);
    }
  }, []);

  return (
    <WelcomePopup
      open={open}
      setOpen={setOpen}
      handleLinkClick={handleLinkClick}
      onClose={onClose}
    />
  );
};

export default WelcomePopupContainer;
