import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Product from "./Product";
import { useDispatch, useSelector } from "react-redux";
import { toastNoCartModify } from "../../utils";
import { productActions, productSelectors } from "../../redux/product";
import { useCounter, useCurrentPrice, useQuery } from "../../hooks";
import { userSelectors } from "../../redux/user";
import { events, useAmplitudeState } from "../../context/amplitude-context";

import { getTextSearchType, getCatalogType, isEmpty } from "../../utils";
import { currencySelectors } from "redux/currency";
import superCartActions from "features/super-cart/redux/actions";
import { prepareItemToAdd } from "features/super-cart/redux/utils";
import { ProductTypesV2 } from "../../constants/productTypes";
import convertCatalogToCat from "../../utils/convetCatalogToCat";

const initialBreadcrumbs: any = [
  {
    href: "/search",
    title: "Search",
  },
];

const ProductContainer = () => {
  const { amplitude } = useAmplitudeState();
  const query = useQuery();
  const { id: code } = useParams<{ id: string }>();
  const catQuery = query.get("cat");
  const type = getTextSearchType(code);
  const cat = catQuery || getCatalogType(code);
  const dispatch = useDispatch();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const currency = useSelector(currencySelectors.selectCurrent);
  const { product, prices } = useSelector((state) =>
    productSelectors.selectProduct(state, {
      catalog: ProductTypesV2[cat],
      code,
    })
  );
  const image = useSelector(productSelectors.selectImage);

  const [currentPrice, setCurrentPrice] = useCurrentPrice({
    prices: prices,
  });
  const counter = useCounter();
  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const [isAddingToCart, setAddingToCart] = useState(false);
  const [loading, setLoading] = useState(true);
  const [imageReady, setImageReady] = useState(false);

  const fetchProduct = () => {
    dispatch(
      productActions.requestProduct({
        code,
        type,
        cat,
        onSuccess: () => {
          setLoading(false);
        },
      })
    );
  };

  const saveOnServer = async (item) => {
    setAddingToCart(true);

    dispatch(
      superCartActions.postCartContent({
        items: [item],
        currency,
        cb: (meta) => {
          if (meta) {
            if (meta?.[0]) {
              toast("Product was added to cart!");
              setAddingToCart(false);
            } else if (meta?.[2] === 400) {
              toastNoCartModify();
            } else {
              toast.error("Issue with adding product to cart");
              setAddingToCart(false);
            }
          }
        },
      })
    );
  };

  const saveOnLocalStorage = (item) => {
    dispatch(superCartActions.addItem(item));
    toast("Product was added to cart!");
  };

  const handleAdd = async () => {
    const itemToAdd = prepareItemToAdd({
      item: { product, prices },
      currentPrice,
      quantity: counter.count,
    });

    amplitude.logEvent(events.catalog_item_add, {
      itemtype: convertCatalogToCat(product?.catalog),
      amount: counter?.count,
    });

    if (isAuth) {
      saveOnServer(itemToAdd);
    } else {
      saveOnLocalStorage(itemToAdd);
    }
  };

  useEffect(() => {
    if (currency && code) {
      fetchProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, currency]);

  useEffect(() => {
    if (code) {
      setLoading(true);
      setImageReady(false);

      setBreadcrumbs([
        ...initialBreadcrumbs,
        {
          title: code,
        },
      ]);
    }
  }, [code]);

  useEffect(() => {
    if (!isEmpty(product)) {
      dispatch(
        productActions.requestImage({
          finally: () => {
            setImageReady(true);
          },
        })
      );
    }
  }, [product, dispatch]);

  useEffect(() => {
    if (product?.code === code) {
      amplitude.logEvent(events.product_details_opened, {
        itemtype: convertCatalogToCat(product?.catalog),
      });
    }
  }, [product, amplitude, code]);

  return (
    <Product
      loading={loading}
      item={product}
      image={image}
      counter={counter}
      handleAdd={handleAdd}
      breadcrumbs={breadcrumbs}
      isAddingToCart={isAddingToCart}
      price={{
        currentPrice,
        currency,
        prices,
        setCurrentPrice,
      }}
      code={code}
      cat={cat}
      currency={currency}
      imageReady={imageReady}
    />
  );
};

export default ProductContainer;
