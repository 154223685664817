const regexPatterns = {
  PHONE: /.*/,
  EMAIL:
    /^[\w!#$%&’*+/=?`{|}~^-]+(?:\.[\w!#$%&’*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,40}$/,
  LATIN_PASSWORD:
    /^([A-Za-z0-9_@?!%$./#&+-\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/,
  ZIP: /[\\w\\p{L}0-9\- ]{0,10}/,
  INVOICE_NUMBERS: /^[a-zA-Z0-9, ]*$/,
  TOTAL_SUM_INVOICES: /^((?!0)\d{1,10}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/, // ten digints before dot and 2 digits after
};

export default regexPatterns;
