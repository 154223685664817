import React from "react";
import {
  Wrapper,
  Box,
  Input,
  Text,
  Label,
  ErrorMessage,
} from "./checkbox.styles";
import { ReactComponent as CheckIcon } from "../../icons/check-checkbox.svg";

const Checkbox = ({ name, formik, text }) => {
  const isChecked = formik.values[name];
  const error = formik.touched[name] && formik.errors[name];

  return (
    <Wrapper>
      <Label>
        <Box checked={isChecked}>
          <CheckIcon />
        </Box>
        <Input
          type="checkbox"
          onChange={formik.handleChange}
          name={name}
          checked={isChecked}
        />
        {text && <Text>{text}</Text>}
      </Label>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};

export default Checkbox;
