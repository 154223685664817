import {
  CLOSE_ACCORDION,
  OPEN_ACCORDION,
  SET_DATA,
  SET_INITIAL,
  CHANGE_INPUT,
  SET_ADDITIONAL_COSTS_LOADING,
  SET_ADDITIONAL_COSTS_ERROR,
  SET_ADDITIONAL_COSTS,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_METHODS_LOADING,
  SET_PAYMENT_METHODS_ERROR,
} from "./types";
import { closeAccordion, openAccordion } from './utils';

const initialState = {
  data: {},
  paymentMethods: {
    data: [],
    loading: "init",
    error: "",
  },
  additionalCosts: {
    data: null,
    loading: "init",
    error: "",
  },
  accordion: {
    step1: {
      open: true,
      valid: false,
      disabled: false,
    },
    step2: {
      open: false,
      valid: false,
      disabled: true,
    },
    step3: {
      open: false,
      valid: false,
      disabled: true,
    },
    step4: {
      open: false,
      valid: false,
      disabled: true,
    },
    step5: {
      open: false,
      valid: false,
      disabled: true,
    },
  },
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case CHANGE_INPUT:
      return {
        ...state,
        data: {
          ...state.data,
          carrierId: action.payload,
        },
      };
    case CLOSE_ACCORDION:
      return {
        ...state,
        accordion: closeAccordion(state.accordion, action.payload),
      };
    case OPEN_ACCORDION:
      return {
        ...state,
        accordion: openAccordion(state.accordion, action.payload),
      };
    case SET_INITIAL:
      return {
        ...initialState,
      };
    case SET_ADDITIONAL_COSTS_LOADING:
      return {
        ...state,
        additionalCosts: {
          ...state.additionalCosts,
          loading: action.payload,
        },
      };
    case SET_ADDITIONAL_COSTS_ERROR:
      return {
        ...state,
        additionalCosts: {
          ...state.additionalCosts,
          error: action.payload,
        },
      };
    case SET_ADDITIONAL_COSTS:
      return {
        ...state,
        additionalCosts: {
          ...state.additionalCosts,
          data: action.payload,
        }
      };
    case SET_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          loading: action.payload,
        }
      };
    case SET_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          error: action.payload,
        }
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: {
          ...state.paymentMethods,
          data: action.payload,
        }
      };
    default:
      return state;
  }
};

export default checkoutReducer;
