import { CustomizedTooltip } from "ui";
import { InfoIcon } from "ui/icons";
import { addZeroes } from "utils";
import ZeroCostsCalculatedTooltip from "./ZeroCostsCalculatedTooltip";
import { InfoCell } from "features/super-cart/Summary/Summary.styled";

const OrderPrice = ({ details, isAnyItemInquery }) => {
  const { price, currency, type } = details;

  const showZeroCostsTooltip =
    price?.costsCalculated === false && type === "QUOTE";

  return (
    <>
      <div className="order-page__price-row">
        <InfoCell>
          <span>Subtotal</span>
          {isAnyItemInquery && (
            <CustomizedTooltip
              title="Order includes feasible compounds which will be confirmed by our sales representative"
              placement="right"
            >
              <InfoIcon />
            </CustomizedTooltip>
          )}
        </InfoCell>
        <span>
          {addZeroes(price?.subtotal)} {currency}
        </span>
      </div>
      <div className="order-page__divider"></div>
      <div className="order-page__price-row">
        <span>
          Handling
          {showZeroCostsTooltip && <ZeroCostsCalculatedTooltip />}
        </span>
        <span>
          {addZeroes(price?.handling || 0)} {currency}
        </span>
      </div>
      <div className="order-page__price-row">
        <span>
          Shipping
          {showZeroCostsTooltip && <ZeroCostsCalculatedTooltip />}
        </span>
        <span>
          {addZeroes(price?.shipping)} {currency}
        </span>
      </div>
      <div className="order-page__divider"></div>
      <div className="order-page__price-row">
        <span>Tax</span>
        <span>
          {addZeroes(price?.tax)} {currency}
        </span>
      </div>
      <div className="order-page__price-row">
        <span>Discount</span>
        <span>
          {addZeroes(price?.discount)} {currency}
        </span>
      </div>
      <div className="order-page__divider"></div>
      <div className="order-page__price-row">
        <span>
          <b>Total price</b>
        </span>
        <span>
          <b>
            {addZeroes(price?.total)} {currency}
          </b>
        </span>
      </div>
    </>
  );
};

export default OrderPrice;
