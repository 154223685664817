import React from "react";
import { useState } from "react";
import { Wrapper, TabItem, ImageWrapper, Title } from "./LabledTabs.styles";

const LabledTabs = ({ items = [], activeId, handleTabClick }) => {
  const [hoveredId, setHoveredId] = useState(null);

  return (
    <Wrapper>
      {items.length ? (
        <>
          {items.map((el) => {
            const isActive = el.id === activeId;
            const Image = el.image;

            return (
              <TabItem key={el.id}>
                <ImageWrapper
                  isActive={isActive}
                  isHovered={hoveredId === el.id}
                  onClick={() => handleTabClick(el.id)}
                  className="labled-tabs__image-wrapper"
                >
                  <Image />
                </ImageWrapper>
                <Title
                  onClick={() => handleTabClick(el.id)}
                  onMouseOver={() => setHoveredId(el.id)}
                  onMouseLeave={() => setHoveredId(null)}
                  isActive={isActive}
                >
                  {el.title}
                </Title>
              </TabItem>
            );
          })}
        </>
      ) : (
        <div>Reload page to show carriers</div>
      )}
    </Wrapper>
  );
};

export default LabledTabs;
