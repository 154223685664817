import styled from 'styled-components';
import { dangerColor } from 'ui/colors';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Box = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #a2acbd;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    height: auto;
    transform: translateY(1px);
    opacity: 0;

    ${(props) => {
      if (props.checked) {
        return `
          opacity: 1;
        `;
      }
    }}

    path {
      fill: #172b4e;
    }
  }
`;

export const Input = styled.input`
  display: none;
`;

export const Text = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  margin-left: 10px;
  color: #0f1019;
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const ErrorMessage = styled.p`
  color: ${dangerColor};
  margin: 0;
  margin-top: 4px;
  margin-left: 5px;
  font-size: 14px;
`;
