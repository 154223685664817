import React from "react";
import { Loader } from "../../../../ui";
import { Wrapper } from "./SendingOrder.styles";

const Thank = () => {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

export default Thank;
