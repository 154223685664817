import React from "react";
import SyntheticReagentCard from "./SyntheticReagentCard";
import { SyntheticReagentsWrapper } from "./SyntheticReagents.styled";
import Container from "../../ui/container/container";
import { PageHeader } from "../../ui/page-header/page-header.styles";
import { Breadcrumbs, Flex, Title } from "../../ui";
import { syntheticReagentsConfig } from "./SyntheticReagents.config";

const breadcrumbs = [
  {
    title: "Synthetic reagents",
  },
];

const SyntheticReagents = () => {
  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <Container>
        <PageHeader className="catalog-header">
          <Flex type="center-between">
            <Title variant="h1">Synthetic Reagents</Title>
          </Flex>
        </PageHeader>
        <SyntheticReagentsWrapper>
          {syntheticReagentsConfig.map((syntheticReagent, index) => (
            <SyntheticReagentCard key={index} cardItem={syntheticReagent} />
          ))}
        </SyntheticReagentsWrapper>
      </Container>
    </>
  );
};

export default SyntheticReagents;
