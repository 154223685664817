// @ts-check
import React, { useEffect, useRef, useState } from "react";
import {
  CollapsibleWrapper,
  CollapsibleFooter,
  ShowAllButton,
} from "./collapsible.styles";

const DEFAULT_HEIGHT = 184;
const DEFAULT_COLLAPSE_FOOTER_HEIGHT = 96;

export const Collapsible = ({ children }) => {
  const [height, setHeight] = useState(DEFAULT_HEIGHT);
  const [collapsedHeight, setCollapsedHeight] = useState(0);
  const [open, setOpen] = useState(false);

  const myRef = useRef(
    /** @type {React.ReactHTMLElement<HTMLDivElement>} */ null
  );
  const innerRef = useRef(
    /** @type {React.ReactHTMLElement<HTMLDivElement>} */ null
  );

  useEffect(() => {
    if (myRef?.current) {
      setHeight(myRef.current.offsetHeight);
    }
  }, [myRef?.current]);

  useEffect(() => {
    if (innerRef?.current) {
      setCollapsedHeight(innerRef.current.offsetHeight);
    }
  }, [innerRef?.current]);

  return (
    <CollapsibleWrapper
      ref={myRef}
      style={{
        maxHeight: open ? `${collapsedHeight}px` : `${height}px`,
        height: open ? `${collapsedHeight}px` : "auto",
        opacity: height && collapsedHeight ? "1" : "0",
      }}
    >
      <div ref={innerRef}>{children}</div>
      {collapsedHeight > height ? (
        <CollapsibleFooter
          isOpened={open}
          height={DEFAULT_COLLAPSE_FOOTER_HEIGHT}
          containerHeight={height}
        >
          <ShowAllButton onClick={() => setOpen((prev) => !prev)}>
            Show all
          </ShowAllButton>
        </CollapsibleFooter>
      ) : null}
    </CollapsibleWrapper>
  );
};
