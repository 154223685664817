import { SET_SHOW_SORRY_POPUP, SET_SHOW_WELCOME_POPUP } from "./types";

const initialState = {
  showWelcomePopup: false,
  showSorryToSeeYouGoPopup: false,
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_WELCOME_POPUP:
      return {
        ...state,
        showWelcomePopup: action.payload,
      };
    case SET_SHOW_SORRY_POPUP:
      return {
        ...state,
        showSorryToSeeYouGoPopup: action.payload,
      };
    default:
      return state;
  }
};

export default onboardingReducer;
