/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import superCartSelectors from "../../redux/selectors";
import { useEffect, useState } from "react";
import superCartActions from "../../redux/actions";
import { useDownloadSdfFromCart, usePagination } from "hooks";
import { currencySelectors } from "redux/currency";
import {
  addImages,
  generateImagesObj,
  generateSubtotal,
  getImagelessItems,
  seedImages,
} from "../../redux/utils";
import fetchImages from "utils/fetch/fetchImages";
import animateScrollTo from "animated-scroll-to";
import { userSelectors } from "redux/user";
import cartAPI from "../../redux/api";
import useCartHandlers from "../../hooks/useCartHandlers";
import useQuote from "../../quote/useQuote";
import CartPage from "../CartPage";
import React from "react";

const CartPageContainer = () => {
  const dispatch = useDispatch();
  const superCart = useSelector(superCartSelectors.selectSuperCart);
  const currency = useSelector(currencySelectors.selectCurrent);
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const [curPage, setCurPage] = useState(1);
  const [list, setList] = useState([]);
  const pageSize = 10;
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingRest, setLoadingRest] = useState(true);

  const [isModalGroupUpdateOpen, setIsModalGroupUpdateOpen] = useState(false);

  const subtotal = generateSubtotal({
    price: superCart.cartInfo.data?.subtotal,
    abbr: currency?.abbr,
  });
  const cartInfoLoading = superCart.cartInfo.loading;

  const items = superCart.items;

  const downloadSdf = useDownloadSdfFromCart({ items });
  const quote = useQuote();

  const [pagination] = usePagination({
    total: superCart.items.length,
    pageSize,
    curPage,
  });

  const discount = superCart?.cartInfo?.data?.discount;

  const {
    handleDelete,
    handleChangeCount,
    handleChangePriceSelect,
    handleClearCart,
  } = useCartHandlers();

  const handleClickPagination = (page) => {
    setCurPage(page);
  };

  useEffect(() => {
    /*
            Decrease curPage, if pagination total more than cart total.
            This functionality actual, when user delete last item from last
            paginated page. For example: current page is 8 and the total
            items on this page is 1. After deleting this 1 item you 
            expect to see the current page is 7.
        */
    if ((curPage - 1) * pageSize >= superCart.items.length) {
      setCurPage((state) => {
        if (state > 1) {
          return state - 1;
        } else {
          return 1;
        }
      });
    }
  }, [superCart.items]);

  useEffect(() => {
    if (init) {
      setLoading(true);
      animateScrollTo(0);
    }
  }, [curPage]);

  useEffect(() => {
    if (init) {
      const start = (curPage - 1) * pageSize;
      const currentList = items.slice(start, start + pageSize);
      const { images } = superCart;
      // @TODO BE has to implement cur param to refetch data for cart

      const currentImageless = getImagelessItems({
        items: currentList,
        images,
      });

      (async () => {
        const resImages = await fetchImages(currentImageless);

        const imagesObj = generateImagesObj(resImages);

        dispatch(superCartActions.setImages(imagesObj));

        const listWithImages = seedImages({
          items: currentList,
          images: addImages(images, imagesObj),
        });

        setList(listWithImages);
        setLoading(false);

        if (!isAuth) {
          dispatch(superCartActions.requestCartInfo());
        }

        setLoadingRest(false);
      })();
    }
  }, [items, curPage, currency?.abbr, init]);

  useEffect(() => {
    if (isAuth) {
      (async () => {
        const fetchedItems = await cartAPI.fetchItems(currency.abbr);

        dispatch(superCartActions.setItems(fetchedItems));

        setInit(true);

        dispatch(superCartActions.requestCartInfo());
      })();
    } else {
      setInit(true);
    }
  }, [isAuth, currency]);

  const handleToggleGroupUpdateModal = () => {
    setIsModalGroupUpdateOpen((prevState) => !prevState);
  };
  const isAnyItemInquery = items?.some((item) => !!item?.isInquery);

  return (
    <CartPage
      totalItemsCount={items?.length}
      allItems={items}
      pageItems={list}
      subtotal={subtotal}
      pagination={pagination}
      loading={loading}
      loadingRest={loadingRest}
      currency={currency}
      handleClickPagination={handleClickPagination}
      handleChangePriceSelect={handleChangePriceSelect}
      handleChangeCount={handleChangeCount}
      handleDelete={handleDelete}
      handleClearCart={handleClearCart}
      handleGroupUpdate={handleToggleGroupUpdateModal}
      isModalGroupUpdateOpen={isModalGroupUpdateOpen}
      downloadSdf={downloadSdf}
      discount={discount}
      quote={quote}
      cartInfoLoading={cartInfoLoading}
      isAnyItemInquery={isAnyItemInquery}
    />
  );
};

export default CartPageContainer;
