import styled from "styled-components";
import { accentColor, lightBlue } from "../colors";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 24px;
  font-size: 18px;
  line-height: 24px;

  &.message--variant--default {
    background-color: ${lightBlue};
    color: ${accentColor};
    border-radius: 8px;
  }

  .message__icon {
    margin-right: 12px;
  }
`;
