import classNames from "classnames";
import { Wrapper } from "./empty-message.styled";

const EmptyMessage = ({ children, variant }) => {
  const classnames = classNames("empty-message", {
    [`empty-message--variant--${variant}`]: variant,
  });

  return <Wrapper className={classnames}>{children}</Wrapper>;
};

export default EmptyMessage;
