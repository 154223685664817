import { endpoints } from "../constants";
import { customFetch } from "../utils";

const initParams = {
  retry: 5,
  retryDelay: 300,
};

function useCart() {
  const getCartList = () => {
    return customFetch(
      {
        url: endpoints.CART_CONTENT,
      },
      initParams
    );
  };

  const updateCartContent = ({ items, currency }) => {
    return customFetch(
      {
        method: "put",
        url: `${endpoints.CART_CONTENT}`,
        data: {
          currency,
          products: items,
          scrAmount: 0,
          bbAmount: 0,
          scrpack: "",
          bbMeasure: "",
          isStockEnough: true,
          bbpack: "",
        },
      },
      initParams
    );
  };

  const postCartContent = ({ items, currency }) => {
    return customFetch(
      {
        method: "post",
        url: `${endpoints.CART_CONTENT}`,
        data: {
          currency,
          products: items,
        },
      },
      initParams
    );
  };

  const clearCart = () => {
    return customFetch(
      {
        method: "put",
        url: `${endpoints.CART_CONTENT}`,
        data: {
          currency: "USD",
          products: [
            {
              productType: "SCR",
              code: "string",
              quantity: 0,
              amount: 0,
              measure: "g",
              mode: "Add",
              id: 0,
              cdId: 0,
            },
          ],
          scrAmount: 0,
          bbAmount: 0,
          scrpack: "",
          bbMeasure: "",
          isStockEnough: true,
          bbpack: "",
        },
      },
      initParams
    );
  };

  return {
    getCartList,
    updateCartContent,
    postCartContent,
    clearCart,
  };
}

export default useCart;
