import React from "react";
import {
  SearchReagentsTitle,
  SyntheticReagentCardWrapper,
} from "./SyntheticReagents.styled";
import { SyntheticReagentsConfigType } from "./SyntheticReagentsTypes";
import { useHistory } from "react-router-dom";
import rootPages from "../../constants/rootPages";
import { encode } from "utils";
import { events, useAmplitudeState } from "../../context/amplitude-context";

type SyntheticReagentCardType = {
  cardItem: SyntheticReagentsConfigType;
};

const SyntheticReagentCard = ({
  cardItem: { codes, catalogUrl, title },
}: SyntheticReagentCardType) => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();

  const handleNavigate = () => {
    const modifiedTitle = title.replace("/", "-");
    history.push(
      `/${rootPages.SYNTHETIC_REAGENTS}/${modifiedTitle}/?q=${encode(
        codes.join(",")
      )}`
    );

    amplitude.logEvent(events.category_click, {
      type: title,
    });
  };

  return (
    <SyntheticReagentCardWrapper onClick={handleNavigate}>
      <SearchReagentsTitle>{title}</SearchReagentsTitle>
    </SyntheticReagentCardWrapper>
  );
};

export default SyntheticReagentCard;
