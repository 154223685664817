import React from "react";
import { Container, Link } from "../index";
import { Wrapper, List, ListItem } from "./breadcrumbs.styles";

const Breadcrumbs = ({ items }) => {
  return (
    <Wrapper>
      <Container fullHeight>
        <List>
          {items.map((el, index) => {
            return (
              <ListItem key={index} hasHref={el.href}>
                {el.href ? <Link to={el.href}>{el.title}</Link> : el.title}
              </ListItem>
            );
          })}
        </List>
      </Container>
    </Wrapper>
  );
};

export default Breadcrumbs;
