import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkoutSelectors } from "../../../../redux/checkout";
import { utilSelectors } from "../../../../redux/util";
import CheckOrder from "./CheckOrder";

const CheckOrderContainer = ({ handleEdit }) => {
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);
  const [paymentTypeName, setPaymentTypeName] = useState("");

  useEffect(() => {
    const paymentTypeKey = checkoutData?.payment?.type;

    switch (paymentTypeKey) {
      case "wire":
        setPaymentTypeName("Wire transfer");
        break;
      case "card":
        setPaymentTypeName("Bank card");
        break;
      case "check":
        setPaymentTypeName("Check");
        break;
    }
  }, [checkoutData?.payment?.type]);

  return (
    <CheckOrder
      data={checkoutData}
      handleEdit={handleEdit}
      countries={countries}
      usStates={usStates}
      paymentTypeName={paymentTypeName}
    />
  );
};

export default CheckOrderContainer;
