import * as yup from "yup";

export const getValidationSchema = () => {
  const validationSchema = yup.object({});

  return validationSchema;
};

export const hasAtLeastOneInquiry = (items = []) =>
  items.some((item) => item.isInquiry);
