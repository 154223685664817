import React from "react";
import {
  Wrapper,
  Header,
  Content,
  Footer,
  Title,
  LogoWrapper,
} from "./login-signup-panel.styles";
import { ReactComponent as LogoIcon } from "../../icons/logo.svg";

/**
 * @deprecated - This panel contains outdated styles, should be dropped in favor of FormLayout
 */
const LoginSignupPanel = ({ title, children, footerContent }) => {
  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        <LogoWrapper>
          <LogoIcon />
        </LogoWrapper>
      </Header>
      <Content>{children}</Content>
      {footerContent && <Footer>{footerContent}</Footer>}
    </Wrapper>
  );
};

export default LoginSignupPanel;
