import * as yup from "yup";
import sleep from "utils/sleep";
import validation from "constants/validation";
import customFetch from "utils/customFetch";
import { store } from "redux/configureStore";
import { userActions } from "redux/user";
import { encodeToBase64 } from "utils/base64Encoding";

export const handleSubmit = async (
  data,
  { setShowCheckEmail, formik, history }
) => {
  const [respData, err] = await fetchSendEmail(data);

  if (err?.status === 401) {
    history.push("reset-password/email-not-found");
    return;
  }

  await sleep(300);

  formik.setSubmitting(false);
  store.dispatch(userActions.logout());

  setShowCheckEmail(true);
};

export const fetchSendEmail = async (data) => {
  const encodedEmail = encodeToBase64(data.email);

  return await customFetch({
    method: "post",
    url: `auth/passwords/resets/begin/as-customer/${encodedEmail}`,
  });
};

export const getValidationSchema = () => {
  const validationSchema = yup.object({
    email: validation.EMAIL,
  });

  return validationSchema;
};
