import { useState } from "react";

const useCounter = (options) => {
  const [count, setCount] = useState(options?.count || 1);

  const customSetCount = (value, runCallback = true) => {
    const newValue = prepareValue(value);
    setCount(newValue);
    options?.callback && runCallback && options.callback(newValue);
  };

  const prepareValue = (value) => {
    let result = value;

    if (result < 1) {
      result = 1;
    }

    result = parseInt(result);

    return result;
  };

  const increment = () => {
    setCount((prev) => {
      let newCount = prev + 1;
      if (options?.callback) {
        options.callback(newCount);
      }

      return newCount;
    });
  };

  const decrement = () => {
    if (options?.enableNegative) {
      setCount((prev) => {
        let newCount = prev - 1;

        if (options?.callback) {
          options.callback(newCount);
        }

        return newCount;
      });
    } else {
      if (count > 1) {
        setCount((prev) => {
          let newCount = prev - 1;

          if (options?.callback) {
            options.callback(newCount);
          }

          return newCount;
        });
      } else {
        let newCount = 1;

        if (options?.callback) {
          options.callback(newCount);
        }

        setCount(newCount);
      }
    }
  };

  return {
    count,
    increment,
    decrement,
    setCount: customSetCount,
  };
};

export default useCounter;
