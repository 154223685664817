import classNames from "classnames";
import { Wrapper } from "./paper.styles";
import { ReactNode } from "react";
import React from "react";

type PaperProps = {
  children: ReactNode;
  marginBottom?: boolean;
  scrollableX?: boolean;
  smallPaddings?: boolean;
  className?: string;
  [key: string]: any;
};

export const Paper = ({
  children,
  marginBottom,
  scrollableX,
  smallPaddings,
  className,
  ...rest
}: PaperProps) => {
  const classes = classNames("paper", className, {
    "paper--marginBottom": marginBottom,
    "paper--scrollableX": scrollableX,
    "paper--smallPaddings": smallPaddings,
  });

  return (
    <Wrapper className={classes} {...rest}>
      {children}
    </Wrapper>
  );
};

export default Paper;
