import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: white;
  padding-top: 100px;
  padding-bottom: 100px;
`;
