import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { rootPages } from "constants/index";
import { customFetch } from "utils";
import { checkoutActions } from "redux/checkout";

const useHandleDiscardOrder = ({ orderId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleDiscard = async () => {
    const res = await customFetch({
      url: `orders/by/orderNumber/${orderId}/discard`,
      method: "patch",
    });

    if (res[0]) {
      dispatch(checkoutActions.setInitial());
      history.push(`/${rootPages.CART}`);
    }
  };

  return { handleDiscard };
};

export default useHandleDiscardOrder;
