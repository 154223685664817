import React, { useState } from "react";
import { Section } from "../../ui";

import SignUpComplete from "./parts/sign-up-complete/SignUpComplete";
import SignUpForm from "./parts/sign-up-form/SignUpForm";

const SignUp = () => {
  const [isRegistered, setIsRegistered] = useState(false);

  return (
    <Section ySpacing>
      {isRegistered ? (
        <SignUpComplete />
      ) : (
        <SignUpForm setIsRegistered={setIsRegistered} />
      )}
    </Section>
  );
};

export default SignUp;
