import { IconButton } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 500px;
  max-width: 100%;
  margin-top: -10px;
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 21;

  svg {
    fill: black;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 19px;
`;

export const Loader = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(256, 256, 256, 0.8);
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 20;

  ${(props) =>
    props.show &&
    `
    display: flex;
  `}
`;

export const LoaderText = styled.p`
  text-align: center;
`;
