import { put, call } from "redux-saga/effects";
import { userActions } from "./index";
import { fetchUser, fetchProfile, fetchAddresses } from "./api";
import superCartActions from "../../features/super-cart/redux/actions";
import loadersActions from "../../features/Loaders/redux/actions";
import { select } from "@redux-saga/core/effects";

export function* requestUser() {
  try {
    yield put(userActions.setUserLoading(true));
    const payload = yield call(fetchUser);
    yield put(userActions.setUser(payload));
    yield put(userActions.setUserReady(true));
  } catch (e) {
    console.log("Something went wrong!", e);
  } finally {
    yield put(userActions.setUserLoading(false));
  }
}

export function* requestProfile() {
  try {
    yield put(userActions.setProfileError(""));
    yield put(userActions.setProfileLoading(true));
    const payload = yield call(fetchProfile);

    yield put(userActions.setProfile(payload));
  } catch (e) {
    yield put(userActions.setProfileError("Error while loading profile data"));
    console.log(e);
  } finally {
    yield put(userActions.setProfileLoading(false));
  }
}

export function* requestAddresses() {
  try {
    yield put(userActions.setAddressesLoading(true));
    const payload = yield call(fetchAddresses);
    yield put(userActions.setAddresses(payload));
  } catch (e) {
    console.log("Something went wrong!", e);
  } finally {
    yield put(userActions.setAddressesLoading(false));
  }
}

export function* logout({ payload }) {
  try {
    const { cb, redirectTo } = payload || {};

    const state = yield select();

    const { isAuth } = state.user;

    if (isAuth) {
      yield put(superCartActions.clear());
    }

    yield put(loadersActions.setLoaderFullPage(true));

    yield put(userActions.setLogout({ redirectTo }));

    if (cb && typeof cb === "function") {
      yield cb();
    }

    yield put(loadersActions.setLoaderFullPage(false));
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}
