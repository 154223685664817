// @ts-check
import React from "react";
import { useDispatch } from "react-redux";
import {
  events,
  useAmplitudeState,
} from "../../../../context/amplitude-context";
import { checkoutActions } from "../../../../redux/checkout";
import { CartMessage } from "ui/cart-message/cart-message";

const Thank = () => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();

  const handleOrdersClick = () => {
    dispatch(checkoutActions.setInitial());
    amplitude.logEvent(events.checkout_to_my_orders);
  };

  return (
    <CartMessage variant="success">
      <CartMessage.Title>Thank you, the order is placed</CartMessage.Title>
      <CartMessage.Description>
        Wait for order confirmation on your email address.
        <br />
        We are already working as fast as we can to process and ship it to you.
      </CartMessage.Description>
      <CartMessage.ActionWrapper>
        <CartMessage.Link to={"/profile/orders"} onClick={handleOrdersClick}>
          Check my orders
        </CartMessage.Link>
        <CartMessage.Link to={"/"}>Search compounds</CartMessage.Link>
      </CartMessage.ActionWrapper>
    </CartMessage>
  );
};

export default Thank;
