import PriceSelect from "parts/price-select/PriceSelect";
import React, { useMemo } from "react";
import { getPriceText } from "shared/utils/Cart.utils";
import { CustomizedTooltip, IconButton } from "ui";
import { CloseIcon, InfoIcon } from "ui/icons";
import DebounceInput from "../counter";
import { InfoCell } from "../Summary/Summary.styled";
import {
  Code,
  Column,
  DiscountPrice,
  ImageLink,
  Price,
  PriceContainer,
  PriceSelectWrapper,
  StockStatusWrapper,
  Wrapper,
} from "./CartItem.styled";

const CartItem = ({
  item,
  handleChangePriceSelect,
  handleDelete,
  currency,
  callback,
  cartInfoLoading,
  price,
}) => {
  const currentPriceAvailability = useMemo(() => {
    return item?.prices?.all?.find(
      (price) =>
        price?.weight.amount === item.amount &&
        price?.weight.measure === item.measure
    )?.weight?.available === "IN_STOCK"
      ? "In stock"
      : "Synthesis";
  }, [item]);

  const isDiscount = item?.price > item?.discPrice;

  return (
    <Wrapper>
      <Column>
        <ImageLink
          style={{
            backgroundImage: `url(${item?.image?.url})`,
          }}
          alt="product"
          to={`/catalog/${item.code}`}
        />

        <StockStatusWrapper>{currentPriceAvailability}</StockStatusWrapper>
      </Column>

      <Column>
        <Code to={`/catalog/${item.code}`}>{item.code}</Code>

        <PriceContainer>
          <PriceSelectWrapper>
            <PriceSelect
              isCartItem
              prices={item?.prices}
              currency={currency}
              currentPrice={{
                weight: { amount: item?.amount, measure: item?.measure },
              }}
              setCurrentPrice={(value) => {
                handleChangePriceSelect({
                  key: item.key,
                  data: {
                    amount: value?.weight.amount,
                    code: item.code,
                    productType: item.productType,
                    mode: "Add",
                    quantity: item.quantity,
                    id: item.id,
                    measure: value?.weight.measure,
                  },
                });
              }}
              disabled={cartInfoLoading}
            />
          </PriceSelectWrapper>

          {Boolean(item?.prices?.all?.length) && (
            <DebounceInput
              callback={callback}
              count={item.quantity}
              hideButtons
              disabled={cartInfoLoading}
            />
          )}
        </PriceContainer>
      </Column>

      {price ? (
        <Column>
          <InfoCell>
            <Price isDiscount={isDiscount}>{price}</Price>
            {isDiscount && (
              <DiscountPrice>
                {getPriceText(item?.discPrice, currency?.abbr)}
              </DiscountPrice>
            )}
            {Boolean(item?.isInquery) && (
              <CustomizedTooltip
                title="Feasible compound. Price will be confirmed by our sales representative"
                placement="top"
              >
                <InfoIcon />
              </CustomizedTooltip>
            )}
          </InfoCell>
        </Column>
      ) : null}

      <Column>
        <IconButton
          icon={<CloseIcon />}
          onClick={() =>
            handleDelete({
              key: item.key,
              data: {
                code: item.code,
                id: item.id,
              },
            })
          }
          size="24px"
          iconSize="24px"
          disabled={cartInfoLoading}
        />
      </Column>
    </Wrapper>
  );
};

export default CartItem;
