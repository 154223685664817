import React, { useEffect } from "react";

import { Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "../../redux/user";

function PrivateRoute({ children, ...rest }) {
  const dispatch = useDispatch();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const redirectTo = useSelector(userSelectors.selectRedirectTo);

  useEffect(() => {
    return () => {
      dispatch(userActions.setRedirectTo(null));
    };
  }, []);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectTo || "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
