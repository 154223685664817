import { useSelector } from "react-redux";
import { utilSelectors } from "../redux/util";
import beautifyFormula from "./beautifyFormula";
import { NormalizedCatalogName } from "../constants/productTypes";
import { formatToTitleCase } from "ui/ProductDetails/ProductDetails.utils";

const leadTime = {
  LEAD_TIME_FROM_7_DAYS: "from 7 days delivery time",
  LEAD_TIME_FROM_1_TO_5_DAYS: "1-5 days delivery time",
};

/**
 * @template T
 * @param {string} itemKey
 * @param {T | string} itemValue
 * @returns {import('react').ReactNode | string}
 */
const BeautifyValue = (itemKey, itemValue) => {
  const catalogs = useSelector(utilSelectors.selectCatalogs);

  if (itemValue) {
    switch (itemKey) {
      case "formula":
        return beautifyFormula(itemValue);
      case "catalog":
        return NormalizedCatalogName[itemValue];
      case "bbClasses":
      case "bbSubclasses":
        return itemValue.join(", ");
      case "purity":
        // show empty space if itemValue not exists or equal
        return itemValue ? `${itemValue}%` : " ";
      case "stocks":
        return (itemValue || []).join(", ");
      case "leadTime":
        return leadTime[itemValue] || leadTime[LEAD_TIME_FROM_7_DAYS];
      case "transportationByAir":
        return formatToTitleCase(itemValue);
      default:
        return itemValue;
    }
  }
};

export default BeautifyValue;
