import { useEffect, useState } from "react";
import { useQuery } from "hooks";

const useBackToOrdersLink = () => {
  const toOrdersLinkBase = "/profile/orders";

  const query = useQuery();
  const fromPage = parseInt(query.get("fromPage"));
  const [backToOrdersLink, setBackToOrdersLink] = useState(toOrdersLinkBase);

  useEffect(() => {
    if (fromPage) {
      const newLink = `${toOrdersLinkBase}?curPage=${fromPage}`;
      setBackToOrdersLink(newLink);
    } else {
      setBackToOrdersLink(toOrdersLinkBase);
    }
  }, [fromPage]);

  return backToOrdersLink;
};

export default useBackToOrdersLink;
