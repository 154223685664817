const searchAsTypes = {
  EXACT: "EXACT",
  SUB: "SUB",
  SIM: "SIM",
};

export enum SearchAsTypes {
  EXACT = "EXACT",
  SUB = "SUB",
  SIM = "SIM",
}

export enum SearchAsTypesV2 {
  EXACT = "EXACT",
  SUB = "SUBSTRUCTURE",
  SIM = "SIMILARITY",
}

export default searchAsTypes;
