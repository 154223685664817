import styled from "styled-components";
import media from "../../constants/media.js";
import { blue, darkBlue, lightBlue } from "../colors.js";

export const Wrapper = styled.div`
  position: relative;

  &.is-disabled {
    .dropdown__control {
      pointer-events: none;
    }
  }

  &.dropdown--fullWidthDropdown {
    .dropdown__content-inner,
    .dropdown__content {
      min-width: 100%;
    }
  }

  .dropdown__content {
    display: none;
    z-index: 1000;
    position: absolute;
    overflow: hidden;
    right: -20px;
    padding: 20px;
    padding-top: 0;

    &.is-open {
      display: flex;
    }
  }

  &.dropdown--variant--default {
    .dropdown__content {
      transform: translateY(6px);
    }
  }

  &.dropdown--variant--header {
    .dropdown__content {
      transform: translateY(19px);

      @media ${media.MOBILE} {
        transform: translateY(0);
      }
    }

    .dropdown__content-inner {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding-top: 0;

      &::before {
        content: "";
        width: 100%;
        height: 10px;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.03) 100%
        );
        pointer-events: none;
        overflow: hidden;
      }

      &::after {
        border-radius: 8px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .dropdown__content-inner {
    background-color: #fff;
    padding-bottom: 8px;
    padding-top: 8px;
    border-radius: 8px;
    position: relative;

    a {
      text-decoration: none;
    }

    &::after {
      content: "";
      position: absolute;
      border-radius: 8px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      pointer-events: none;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    }
  }

  .dropdown__control {
    display: inline-flex;
    * {
      outline: none;
    }
  }

  .dropdown__backdrop.is-open {
    display: flex;
  }

  .dropdown__menu-item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px 16px;
    cursor: pointer;
    color: ${darkBlue};
    font-weight: 400;
    font-size: 18px;

    &:hover {
      background-color: ${lightBlue};
    }

    svg {
      width: 24px;

      path {
        fill: ${blue};
      }
    }

      &.fillNone {
          svg {
              path {
                  fill: none;
              }
          }
      }
  }

  .dropdown__menu-item-text {
    text-decoration: none;
  }

  .dropdown__menu-item-icon {
    margin-right: 16px;
    display: inline-flex;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
`;
