import styled from "styled-components";
import { lineColor } from "ui/colors";

export const Wrapper = styled.div`
  &.products-loader {
    border: 1px solid ${lineColor};
    border-radius: 8px;
    display: flex;
  }

  .products-loader__card {
    width: 25%;

    &:not(:last-child) {
      border-right: 1px solid ${lineColor};
    }
  }

  .products-loader__header {
    height: 136px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .products-loader__content {
    padding: 24px;
    padding-top: 18px;
  }

  .products-loader__box {
    background-color: #f1f2f5;
    margin-bottom: 21px;
    background: linear-gradient(to right, #f1f2f5 8%, #f8f8f8 18%, #f1f2f5 33%);
    background-size: 800px 100px;
    animation: wave-lines 1.6s infinite ease-out;

    &--small {
      width: 53%;
      height: 25px;
    }

    &--big {
      height: 48px;
    }
  }

  @keyframes wave-lines {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
`;
