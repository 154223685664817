import React from 'react';
import Password from '../password/Password';

const RepeatPassword = ({ formik, ...props }) => {
  
  return (
    <Password
      label='Repeat Password'
      name='repeatPassword'
      type='password'
      formik={formik}
      data-test='repeat_password_input'
      placeholder='Enter password'
      {...props}
    />
  );
};

export default RepeatPassword;
