import styled from "styled-components";

export const Wrapper = styled.div`
  ${({ fullWidth }) => {
    if (fullWidth) {
      return `
        display: flex;
        align-items: center;
        justify-content: center;
      `;
    } else {
      return `
        display: inline-block;
      `;
    }
  }}

  ${({ paddings }) => {
    if (paddings) {
      return `
        padding: 40px 20px;
      `;
    }
  }}

  .MuiCircularProgress-root {
    display: block;
  }
`;
