import React from "react";
import { ButtonWrapper, Wrapper, ModalButton } from "./NewAddressForm.styles";
import Form from "../../../../../profile/pages/address-book/parts/address-dialog/Form";
import { Button, Modal } from "../../../../../../ui";

const NewAddressForm = ({
  showForm,
  setShowForm,
  newAddressFormProps,
  isAddingAddress,
}) => {
  return (
    <>
      {showForm ? (
        <Modal
          open={showForm}
          setOpen={setShowForm}
          boxProps={{ width: "800px", maxWidth: "100%" }}
          title="Add new address"
        >
          <Wrapper>
            <Form {...newAddressFormProps}>
              <ModalButton>
                <Button disabled={isAddingAddress} size="small" fullWidth>
                  Save
                </Button>
              </ModalButton>
            </Form>
          </Wrapper>
        </Modal>
      ) : (
        <ButtonWrapper>
          <Button
            type="outlined"
            onClick={() => setShowForm(true)}
            size="small"
            fullWidth
          >
            Add new address
          </Button>
        </ButtonWrapper>
      )}
    </>
  );
};

export default NewAddressForm;
