import React from "react";
import { Wrapper } from "./address-card.styles";

import { IconButton, Table } from "../index";
import { DeleteIcon, PencilIcon } from "../icons";

const AddressCard = ({ list, handleEdit, handleDelete, dataTestAttr }) => {
  return (
    <Wrapper className="address-card" dataTestAttr={dataTestAttr}>
      {list && list?.length ? (
        <>
          {list.map((item, index) => {
            const minWidth = index === 0 || index === list.length - 1;
            const nowrap = index === 0 || index === list.length - 1;

            return (
              <Table.Cell
                key={index}
                title={item.title}
                minWidth={minWidth}
                verticalCenter
              >
                {nowrap ? (
                  <span style={{ whiteSpace: "nowrap" }}>{item.value}</span>
                ) : (
                  <>{item.value}</>
                )}
              </Table.Cell>
            );
          })}
        </>
      ) : null}
      <Table.Cell minWidth verticalCenter>
        <div className="address-card__controls">
          <IconButton
            icon={<PencilIcon />}
            size="small"
            onClick={handleEdit}
            dataTestAttr="edit-address-button"
          />
          <IconButton
            icon={<DeleteIcon />}
            size="small"
            onClick={handleDelete}
            dataTestAttr="delete-address-button"
          />
        </div>
      </Table.Cell>
    </Wrapper>
  );
};

export default AddressCard;
