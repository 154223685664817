import styled from "styled-components";

export const InputWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const Wrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 24px;
  padding-left: 24px;
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  flex-grow: 1;
  margin-top: 5px;
  margin-bottom: 8px;
`;
