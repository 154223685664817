import styled from "styled-components";

export const Wrapper = styled.div`
  &.title {
    h1 {
      margin: 0;
      font-size: 32px;
      line-height: 32px;
      font-weight: 700;
    }

    h2 {
      margin: 0;
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
    }

    h3 {
      margin: 0;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
    }
  }
`;
