export const REQUEST_PRODUCT_WITH_PRICES = "PRODUCT/REQUEST_PRODUCT";
export const REQUEST_IMAGE = "PRODUCT/REQUEST_IMAGE";
export const SET_IMAGE = "PRODUCT/SET_IMAGE";

export const SET_INSTOCK_EQUIVALENTS = "PRODUCT/SET_INSTOCK_EQUIVALENTS";
export const SET_PRODUCT_ANALOGS = "PRODUCT/SET_PRODUCT_ANALOGS";
export const SET_PRODUCT_SALT_FORMS = "PRODUCT/SET_PRODUCT_SALT_FORMS";


export const SET_PRODUCT_WITH_PRICES = "PRODUCT/SET_PRODUCT_WITH_PRICES";
