import {
  addZeroes,
  convertToDateAndTime,
  mapOrderStatus,
} from "../../../../../utils";
import { IconButton, Table, Tag } from "../../../../../ui";
import { getTagTypeBasedOnOrderStatus } from "../utils";
import { ArrowRightIcon } from "../../../../../ui/icons";
import { paymentTypes } from "constants/index";

const ListItem = ({ item }) => {
  return (
    <Table.Row dataTestAttr="order-list-item">
      <Table.Cell
        title={item?.type === "QUOTE" ? "Quote Number" : "Order Number"}
      >
        {item?.number}
      </Table.Cell>
      <Table.Cell title="Order Date">
        {convertToDateAndTime(item?.updated)}
      </Table.Cell>
      <Table.Cell verticalCenter>
        <Tag
          style={{ minWidth: "125px" }}
          type={getTagTypeBasedOnOrderStatus(item?.status)}
        >
          {mapOrderStatus(item?.status)}
        </Tag>
      </Table.Cell>
      <Table.Cell title="PO Number">
        {item?.purchaseOrderNumber || "-"}
      </Table.Cell>
      <Table.Cell title="Payment Type">
        {paymentTypes?.[item?.paymentType]}
      </Table.Cell>
      <Table.Cell title="Amount">
        <b>
          {addZeroes(item?.price?.total)} {item?.currency}
        </b>
      </Table.Cell>
      <Table.Cell minWidth verticalCenter>
        <IconButton
          icon={<ArrowRightIcon />}
          to={item?.detailsLink}
          variant="transparent"
          dataTestAttr="details-link"
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListItem;
