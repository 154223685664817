import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  margin: 0;
  line-height: 28px;
  font-size: 18px;
`;

export const PluginWrapper = styled.div`
  padding-top: 28px;
  padding-right: 85px;
  padding-left: 85px;
`;

export const Buttons = styled.div`
  padding-top: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
