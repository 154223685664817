import React, { useEffect } from "react";
import AddressDialog from "./AddressDialog";
import { useAddressForm } from "../../../../../../hooks";

export default function FormDialog({
  open,
  mode,
  setOpen,
  title,
  formValues,
  handleSubmit,
}) {
  const { formik, vatPattern, showStateSelect, initialValues } = useAddressForm(
    { handleSubmit }
  );

  useEffect(() => {
    if (mode === "EDIT" && !open) {
      setTimeout(() => {
        formik.setValues(
          {
            ...initialValues,
            ...formValues,
          },
          true
        );
      }, 300);
    }
  }, [open]);

  useEffect(() => {
    if (mode === "ADD") {
      formik.resetForm();

      formik.setValues((prev) => {
        return {
          ...initialValues,
          ...formValues,
        };
      }, false);
    } else {
      formik.setValues(
        {
          ...initialValues,
          ...formValues,
        },
        true
      );
    }
  }, [formValues]);

  return (
    <AddressDialog
      open={open}
      setOpen={setOpen}
      title={title}
      formik={formik}
      vatPattern={vatPattern}
      showStateSelect={showStateSelect}
    />
  );
}
