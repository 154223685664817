import React from "react";

import MarvinFrame from "../../components/marvin-frame/MarvinFrame.container";
import { Breadcrumbs, Container, Section } from "../../ui";
import { Header, Title, Content } from "./DrawSearch.styles";

const breadcrumbs = [{ title: "Draw Structure" }];

const DrawSearch = () => {
  return (
    <Section deleteXSpacing fullHeight>
      <Breadcrumbs items={breadcrumbs} />
      <Content>
        <Section>
          <Container>
            <Header>
              <Title>Draw Structure</Title>
            </Header>
            <MarvinFrame url="/sketch.html" width={"100%"} height={400} />
          </Container>
        </Section>
      </Content>
    </Section>
  );
};

export default DrawSearch;
