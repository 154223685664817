import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const VisibilitySwitcher = styled.div`
  cursor: pointer;
  user-select: none;

  svg {
    display: block;
  }
`;

export const IconsWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 35px;
  display: flex;
  align-items: center;
`;

export const DangerIconWrapper = styled.div`
  margin-left: 4px;
  
  svg {
    width: 24px;
    height: auto;
    display: block;
    margin-top: -1px;
  }
`;