//@ts-check

import React from "react";
import styled from "styled-components";
import { accentColor } from "ui/colors";

export const ExpandButtonIcon = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  cursor: pointer;

  &.expanded {
    &:before {
      transform: translatey(-50%) rotate(-90deg);
      opacity: 0;
    }
    &:after {
      transform: translatey(-50%) rotate(0);
    }
  }

  &:before,
  &:after {
    content: "";
    display: block;
    background-color: ${accentColor};
    position: absolute;
    top: 50%;
    left: 0;
    transition: 0.35s;
    width: 100%;
    height: 2px;
  }

  &:before {
    transform: translatey(-50%);
  }

  &:after {
    transform: translatey(-50%) rotate(90deg);
  }
`;

export const Wrapper = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
