import fetchImage from "./fetchImage";
import { ProductTypesV2 } from "../../constants/productTypes";

const fetchImages = (items) => {
  const requests = [];
  const codes = [];
  const newImages = [];

  if (items?.length) {
    items.forEach((el) => {
      requests.push(
        fetchImage({
          code: el.code,
          catalog: el?.product?.catalog
            ? el?.product?.catalog
            : ProductTypesV2[el?.productType],
        })
      );
      codes.push({
        code: el.code,
        catalog: el?.product?.catalog || ProductTypesV2[el?.productType],
      });
    });
  }

  return Promise.all(requests).then((data) => {
    data.forEach(([res, err], idx) => {
      newImages.push({
        url: res?.image || "/not-found-product.png",
        ...codes[idx],
      });
    });

    return newImages;
  });
};

export default fetchImages;
