import React from "react";
import { Button, Modal } from "ui";
import { Buttons, PluginWrapper, Text, Wrapper } from "./styles";

const SorryToSeeYouGoPopup = ({ open, setOpen, handleGoToOldStore }) => (
  <div>
    <Modal
      open={open}
      setOpen={setOpen}
      boxProps={{ width: "710px" }}
      backdropProps={{ style: { pointerEvents: "none" } }}
      title="Sorry to see you go"
    >
      <Wrapper>
        <Text>
          We are sorry that we did not meet your expectations. Please take a
          quick survey so we can understand what went wrong and improve our
          service.
        </Text>
        <PluginWrapper>
          <div id="sorry-to-see-you-go-hotjar"></div>
        </PluginWrapper>
        <Buttons>
          <Button size="small" type="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button size="small" onClick={handleGoToOldStore}>
            Go to old store
          </Button>
        </Buttons>
      </Wrapper>
    </Modal>
  </div>
);

export default SorryToSeeYouGoPopup;
