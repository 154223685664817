import { SET_SHOW_SORRY_POPUP, SET_SHOW_WELCOME_POPUP } from "./types";

const setShowWelcomePopup = (payload) => {
  return {
    type: SET_SHOW_WELCOME_POPUP,
    payload,
  };
};

const setShowSorryPopup = (payload) => {
  return {
    type: SET_SHOW_SORRY_POPUP,
    payload,
  };
};

const onboardingActions = {
  setShowWelcomePopup,
  setShowSorryPopup,
};

export default onboardingActions;
