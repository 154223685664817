const selectProduct = (state, { catalog, code }) => {
  if (
    catalog === state.product.item?.product?.catalog &&
    code === state.product.item?.product?.code
  ) {
    return state.product.item;
  }

  return {};
};

const selectImage = (state) => {
  const code = state.product.item?.product?.code;
  const image = state.product?.image;

  if (image?.code === code) {
    return image;
  }

  return {};
};

const selectInStockEquivalents = (state) => {
  const code = state.product.item?.product?.code;
  return (
    state.product.inStockEquivalents?.[code] ?? { items: [], loaded: false }
  );
};

const selectProductAnalogs = (state) => {
  const code = state.product.item?.product?.code;
  return state.product.analogs?.[code] ?? { items: [], loaded: false };
};

const selectProductSaltForms = (state) => {
  const code = state.product.item?.product?.code;
  return state.product.saltForms?.[code] ?? { items: [], loaded: false };
};

export default {
  selectProduct,
  selectImage,
  selectInStockEquivalents,
  selectProductAnalogs,
  selectProductSaltForms,
};
