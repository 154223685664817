import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { rootPages } from "../../../../constants";
import { checkoutActions } from "../../../../redux/checkout";
import RestrictionModal from "./RestrictionModal";

const RestrictionModalContainer = ({ modal }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickBackToCart = () => {
    modal.setOpen(false);
    history.push(rootPages.CART);
  };

  const handleClickChangeAddress = () => {
    modal.setOpen(false);
    dispatch(checkoutActions.openAccordion("step3"));
  };

  return (
    <RestrictionModal
      modal={modal}
      handleClickBackToCart={handleClickBackToCart}
      handleClickChangeAddress={handleClickChangeAddress}
    />
  );
};

export default RestrictionModalContainer;
