// @ts-check

import React from "react";
import { Wrapper, Title, Description } from "./NewsletterSubscribe.styles";
import { NewsletterInput } from "./components/NewsletterInput";

export const NewsletterSubscribe = () => (
  <Wrapper>
    <Title>Enamine Newsletter</Title>
    <Description>
      Get latest news on our services, catalog updates and new products.
    </Description>
    <NewsletterInput />
  </Wrapper>
);
