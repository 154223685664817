import React from "react";
import { useCountdown } from "hooks";
import { Wrapper } from "./countdown-timer.styles";

const ShowCounter = ({ minutes, seconds }) => {
  const secondsText = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <Wrapper className="countdown-timer">
      {minutes}
      <span>:</span>
      {secondsText}
    </Wrapper>
  );
};

const CountdownTimer = ({ targetDate, callback }) => {
  const { minutes, seconds, setStop, setCountDownDate } =
    useCountdown(targetDate);

  React.useEffect(() => {
    if (minutes + seconds <= 0) {
      setStop(true);
      callback();
      setTimeout(() => {
        setStop(false);
      }, 300);
    }
  }, [minutes, seconds]);

  if (minutes + seconds <= 0) {
    return <ShowCounter minutes={0} seconds={0} />;
  } else {
    return <ShowCounter minutes={minutes} seconds={seconds} />;
  }
};

export default CountdownTimer;
