import { dangerColor, darkWarningColor, successColor } from "ui/colors";
import { strengthTypes } from "./password-strength";

export const generateParamsByMode = (mode) => {
    let color;
    let lineWidth;

    if (mode === strengthTypes.WEAK) {
        color = dangerColor;
        lineWidth = 15;
    } else if (mode === strengthTypes.MEDIUM) {
        color = darkWarningColor;
        lineWidth = 50;
    } else if (mode === strengthTypes.STRONG) {
        color = successColor;
        lineWidth = 100;
    }

    return {
        color,
        lineWidth
    }
}   