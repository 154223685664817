import styled from "styled-components";
import { accentColor, borderColor, lightBlue } from "../colors.js";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 28px;
  border: 1px solid ${borderColor};
  border-radius: 8px;

  &.is-active {
    border-color: ${accentColor};
    background-color: ${lightBlue};
  }

  &:not(.is-active):hover {
    border-color: ${accentColor};

    .radio-circle {
      border-color: ${accentColor};
    }

    [data-element="card-wrapper"] {
      border-color: ${accentColor};
      cursor: pointer;
    }
  }

  &.is-hidden {
    display: none;
  }
`;
