// @ts-check

import styled from "styled-components";
import { blue, darkBlue } from "ui/colors";

export const SectionTitle = styled.h1`
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  color: ${darkBlue};
`;

export const SectionDescription = styled.p`
  margin: 18px 0 0;
  font-size: 18px;
  line-height: 30px;
  color: ${blue};
`;
