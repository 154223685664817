import classNames from "classnames";
import { Wrapper } from "./message.styled";
import { Check2Icon } from "ui/icons";

const Message = ({ children, variant = "default" }) => {
  const classnames = classNames("message", {
    [`message--variant--${variant}`]: variant,
  });

  const getIcon = (variant) => {
    let icon = null;

    switch (variant) {
      case "default":
        icon = Check2Icon;
        break;

      default:
        break;
    }

    return icon;
  };

  const Icon = getIcon(variant);

  return (
    <Wrapper className={classnames}>
      {Icon ? (
        <div className="message__icon">
          <Icon />
        </div>
      ) : null}
      <div>{children}</div>
    </Wrapper>
  );
};

export default Message;
