import {
  CLOSE_ACCORDION,
  OPEN_ACCORDION,
  SET_DATA,
  SET_INITIAL,
  CHANGE_INPUT,
  REQUEST_ADDITIONAL_COSTS,
  SET_ADDITIONAL_COSTS_LOADING,
  SET_ADDITIONAL_COSTS,
  SET_ADDITIONAL_COSTS_ERROR,
  SET_PAYMENT_METHODS_LOADING,
  SET_PAYMENT_METHODS,
  SET_PAYMENT_METHODS_ERROR,
  REQUEST_PAYMENT_METHODS,
} from './types';

const setData = (payload) => {
  return {
    type: SET_DATA,
    payload,
  };
};

const requestAdditionalCosts = (payload) => {
  return {
    type: REQUEST_ADDITIONAL_COSTS,
    payload,
  };
};

const requestPaymentMethods = (payload) => {
  return {
    type: REQUEST_PAYMENT_METHODS,
    payload,
  };
};

const setAdditionalCostsLoading = (payload) => {
  return {
    type: SET_ADDITIONAL_COSTS_LOADING,
    payload,
  };
};

const setAdditionalCosts = (payload) => {
  return {
    type: SET_ADDITIONAL_COSTS,
    payload,
  };
};

const setAdditionalCostsError = (payload) => {
  return {
    type: SET_ADDITIONAL_COSTS_ERROR,
    payload,
  };
}

const setPaymentMethodsLoading = (payload) => {
  return {
    type: SET_PAYMENT_METHODS_LOADING,
    payload,
  };
}

const setPaymentMethods = (payload) => {
  return {
    type: SET_PAYMENT_METHODS,
    payload,
  };
}

const setPaymentMethodsError = (payload) => {
  return {
    type: SET_PAYMENT_METHODS_ERROR,
    payload,
  };
}

const changeInput = (payload) => {
  return {
    type: CHANGE_INPUT,
    payload,
  };
};

const setInitial = () => {
  return {
    type: SET_INITIAL,
  };
};

const closeAccordion = (accordionKey) => {
  return {
    type: CLOSE_ACCORDION,
    payload: accordionKey,
  };
};

const openAccordion = (accordionKey) => {
  return {
    type: OPEN_ACCORDION,
    payload: accordionKey,
  };
};

export default {
  setData,
  closeAccordion,
  openAccordion,
  setInitial,
  changeInput,
  requestAdditionalCosts,
  requestPaymentMethods,
  setAdditionalCostsLoading,
  setAdditionalCosts,
  setAdditionalCostsError,
  setPaymentMethodsLoading,
  setPaymentMethodsError,
  setPaymentMethods
};
