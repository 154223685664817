/* eslint-disable react/no-unescaped-entities */
import React from "react";

const Content = () => {
  return (
    <div className="content">
      <p>
        <i>version 2.5 from 2023/11/07</i>
      </p>
      <p>
        Enamine ltd (“Enamine”) respects the privacy of visitors to our Sites (
        <a href="https://enamine.net/">www.enamine.net</a>,&nbsp;
        <a href="https://enaminestore.com/">www.enaminestore.com</a>,{" "}
        <a href="http://new.enaminestore.com">new.enaminestore.com</a>). Your
        personal information is collected, used, stored, transfered and
        disclosed by Enamine only in accordance with this privacy policy.
      </p>
      <ol>
        <li>
          <p>Identity of Enamine</p>
          <p>
            Enamine is a research-and-production company specialized in fine
            organic synthesis and providing additional services for drug
            discovery with the following address:&nbsp;
          </p>
          <p>ENAMINE Ltd.</p>
          <p>
            78 Chervonotkatska Street
            <br />
            02094 Kyiv
            <br />
            Ukraine
          </p>
          <p>
            Phone: +380 44 5373218 <br />
            E-mail: <a href="mailto:privacy@enamine.net">privacy@enamine.net</a>
            <br />
            Company registration number 30402329
          </p>
          <p>This company is your data controller and processor.&nbsp;</p>
          <p>
            The address of its representative in the European Union (EU) is as
            follows:&nbsp;
          </p>
          <p>
            SIA "ENAMINE"
            <br />
            Vestienas iela 2 B<br />
            LV-1035 Riga
            <br />
            Latvia
          </p>
          <p>
            Tel. +371 66000186
            <br />
            VAT: LV40103730175&nbsp;
          </p>
          <p>
            This company is a representative of Enamine in the EU and, depending
            on the logistics, may be your data processor if you reside in the
            European Union, Switzerland, Norway, Serbia, Bosnia&amp;Herzegovina,
            Montenegro, Albania, Macedonia, Kosovo, India, China,
            Australia.&nbsp;
          </p>
          <p>
            The address of its representative in the United States of America is
            as follows:&nbsp;
          </p>
          <p>Enamine US Inc.</p>
          <p>1 Distribution Way</p>
          <p>Monmouth Junction</p>
          <p>NJ 08852, USA&nbsp;</p>
          <p>Toll free number: 1 (888) 745-6132&nbsp;</p>
          <p>
            This company is a representative of Enamine and your data processor
            if you reside in the United States of America, Canada, South and
            Central America countries. Depending on the logistics, it may be
            your data processor if you reside in Asia or Africa.&nbsp;
          </p>
        </li>
        <li>
          <p>What information do we collect?</p>
          <p>
            <strong>Information we get from your use of the Sites</strong>
          </p>
          <p>
            It is primarily non-personally-identifying information of the sort
            that web browsers, servers, and services like Google Analytics
            typically make available, such as the browser type, language
            preference, referring site, and the time of each visit. Other
            non-identifying information that we might have access to includes
            how you use the Sites (e.g. opening a product page, logging out),
            your approximate location, cookies, etc.&nbsp;
          </p>
          <p>
            We collect this non-personally-identifying information in order to
            track visits to our Sites, understand and evaluate the customer
            experience, improve the content and layout of our Sites and
            customize the web experience.&nbsp;
          </p>
          <p>
            When you use the Sites, we also collect potentially personally
            identifying information in the form of Internet Protocol (IP)
            addresses.&nbsp;
          </p>
          <p>
            Enamine will only attempt to link the logs to identifiable
            individuals if that is necessary for investigating a data security
            breach, a breach of contract, a violation of Enamine’s policies and
            procedures, or a contravention of the laws.&nbsp;
          </p>
          <p>
            <strong>Information you provide to us directly</strong>
          </p>
          <p>
            Certain visitors to our Sites choose to interact with them in ways
            that may require them to provide us with personally identifying
            information.&nbsp;
          </p>
          <p>
            When you register for an account on our Sites, place an order or
            subscribe to our newsletter, basic contact details are collected
            such as the e-mail address and your name, job function, company
            name, shipping address, billing address, information on contact
            person for shipping and billing.&nbsp;
          </p>
          <p>
            You may also want to provide credit card information to pay for
            products. We never store this information and all credit card
            information goes through our Sites to the authorized third party
            payment processor services – First Data and Authorize.net (for
            payments in US dollars) without being stored locally. So the
            controllers of this data are the corresponding companies. Please
            refer to their privacy policy for more information (
            <a href="http://www.firstdata.com/en_gb/privacy.html)">
              https://www.firstdata.com/en_gb/privacy.html
            </a>{" "}
            and{" "}
            <a href="https://www.authorize.net/about-us/dpa.html">
              https://www.authorize.net/about-us/dpa.html
            </a>
            ).&nbsp;
          </p>
          <p>
            <strong>Information provided by other sources</strong>
          </p>
          <p>
            We collect information about you from other users of the Sites. For
            example, when another user provides your contact information when he
            designates you as a billing or shipping contact when placing the
            order.&nbsp;
          </p>
        </li>
        <li>
          <p>What do we use your information for?</p>
          <p>
            Any of the information we collect from you may be used for one or
            more of the following purposes:&nbsp;
          </p>
          <ol className="content__ol-letters">
            <li>
              <p>
                To personalize your experience (the information will help
                Enamine better respond to your individual needs);
              </p>
            </li>
            <li>
              <p>
                To improve our Sites (Enamine continually strives to improve our
                Sites offerings based on the information and feedback we receive
                from our customers);
              </p>
            </li>
            <li>
              <p>To identify you as a contracting party;</p>
            </li>
            <li>
              <p>
                To enable secure login for you into the account on our Sites;
              </p>
            </li>
            <li>
              <p>To establish a primary channel of communication with you;</p>
            </li>
            <li>
              <p>
                To enable Enamine to issue valid VAT invoices and to process
                transactions;
              </p>
            </li>
            <li>
              <p>
                To transfer information to others as described in this policy or
                to satisfy our legal, regulatory, compliance, or auditing
                requirements, including to disclose information to law
                enforcement authorities upon validly served legal process or a
                valid judicial instruction (for example, pursuant to a court
                order);
              </p>
            </li>
            <li>
              <p>To enable automated handling of the subscriptions;</p>
            </li>
            <li>
              <p>
                To send periodic e-mails (The e-mail address you provide for
                order processing, may be used to send you information and
                updates pertaining to your order, in addition to receiving
                occasional company news (if accepted), updates, related product
                or service information, etc.)
              </p>
            </li>
            <li>
              <p>
                If at any time you would like to unsubscribe from receiving
                future e-mails, you can cancel your subscription by following
                the link in the footer of any email received from us.&nbsp;
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>Legal basis</p>
          <ol className="content__ol-letters">
            <li>
              <p>EU General Data Protection Regulation (GDPR)</p>
              <p>
                The processing of your data is either based on your consent or
                in case the processing is necessary for the performance of a
                contract to which you are a party, or in order to take steps at
                your request prior to entering into a contract, cf. GDPR art.
                6(1)(a)-(b).&nbsp;
              </p>
              <p>
                If the processing is based on your consent, you may at any time
                withdraw your consent by contacting us using the contact
                information in clause 1.&nbsp;
              </p>
              <p>
                In order to enter into a contract regarding the purchase of
                Enamine’s products, you must provide us with the required
                personal data. If you do not to provide us with all the required
                information, it will not be possible to sell the products or
                provide you the quotation.&nbsp;
              </p>
            </li>
            <li>
              <p>California Online Privacy Protection Act Compliance</p>
              <p>
                Because Enamine values your privacy we have taken the necessary
                precautions to be in compliance with the California Online
                Privacy Protection Act. We therefore will not distribute any
                personal information to outside parties without your consent
                except as stated in clause 7.&nbsp;
              </p>
              <p>
                As part of the California Online Privacy Protection Act, all
                users of our Sites may make any changes to their information at
                any time by logging into their account and navigating to the
                “profile page”.&nbsp;
              </p>
            </li>
            <li>
              <p>Children’s Online Privacy Protection Act Compliance</p>
              <p>
                Enamine is in compliance with the requirements of the Children’s
                Online Privacy Protection Act. We will not intentionally collect
                any information from anyone under 13 years of age. Our website,
                products and services are all directed at people who are at
                least 18 years old or older.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>How do we protect your information?</p>
          <p>
            Enamine implements the following technical, physical and
            organizational measures to maintain the safety of your personal data
            against accidental or unlawful destruction, loss, alteration,
            unauthorized use, unauthorized modification, disclosure or access
            and against all other unlawful forms of processing.&nbsp;
          </p>
          <ol className="content__ol-letters">
            <li>
              <p>Availability</p>
              <p>
                To ensure high availability automated daily backup of the Sites
                database is arranged. The backuped data are stored for 14 days
                at the same data centre as the main database. All failover
                mechanisms are fully automated.&nbsp;
              </p>
              <p>
                System and project files are backuped on a daily basis, backups
                are stored for 90 days at a Amazon Web Services data centre (see
                “9. Where do we store the information?” for more details).
              </p>
            </li>
            <li>
              <p>Integrity</p>
              <p>
                To ensure integrity, Enamine strives to provide a secure online
                environment for the transmission of personal information such as
                credit card details, usernames, and passwords. For Enamine Sites
                security is provided by Transport Layer Security (TLS/SSL)
                encryption and verified by security certificate issued by Thawte
                Consulting Certificate Authority. Encrypted connection can be
                identified by 'https' prefix in the address bar of your browser;
                it prevents all the data from being intercepted while
                transferred between your computer and our Sites.&nbsp;
              </p>
            </li>
            <li>
              <p>Confidentiality</p>
              <p>
                All personnel are subject to full confidentiality and any
                subcontractors and subprocessors are required to sign a
                confidentiality agreement if not full confidentiality is part of
                the main agreement between the parties.&nbsp;
              </p>
              <p>
                Whenever personal data is accessed by our company or
                representative companies personnel the access is only possible
                after password authentification. Access is granted to the
                personnel whose job functions require it by assigning the
                appropriate role. The log of roles assignment is maintened and
                may be accessible on request.&nbsp;
              </p>
              <p>
                There are additional security measures in place for the
                personnel with system administrator functions. When accessing
                the data in a database, the IP number of the person accessing
                the data, as well as login/password must be pre-authorized to
                obtain access.&nbsp;
              </p>
              <p>
                Personal data are never stored on mobile media like USB sticks
                and DVD’s.&nbsp;
              </p>
            </li>
            <li>
              <p>Monitoring</p>
              <p>
                Administrative operations, including system access, are logged
                to provide an audit trail if unauthorized or accidental changes
                are made. System performance and availability are monitored by
                qualified personnel.&nbsp;
              </p>
            </li>
            <li>
              <p>Personal Data breach notification</p>
              <p>
                In the event that your data is compromised, Enamine will notify
                you and competent Supervisory Authority(ies) within 72 hours by
                e-mail with information about the extent of the breach, affected
                data, any impact on your experience using the Sites and
                Enamine's action plan for measures to secure the data and limit
                any possible detrimental effect on the data subjects.&nbsp;
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>How we use cookies</p>
          <p>
            This website uses cookies. We use cookies to personalize content and
            ads, to provide social media features, and to analyze our traffic.
            We also share information about your use of our site with our social
            media, advertising, and analytics partners. They may combine it with
            other information that you've provided to them or that they've
            collected from your use of their services.&nbsp;
          </p>
          <p>
            Cookies are small text files that can be used by websites to make a
            user's experience more efficient. The law states that we can store
            cookies on your device if they are strictly necessary for the
            operation of this site. For all other types of cookies, we need your
            permission.
          </p>
          <p>
            There are four categories of cookies used: essential, performance,
            advertising, and content cookies, and web beacons.
          </p>
          <ul>
            <li>
              <p>
                Essential cookies, web beacons, and other tracking technologies
                are necessary for the operation of the website. These tracking
                technologies enable you to move around on the site and use the
                website's features. You may not opt-out of these types of
                cookies because they are required to operate the website.
              </p>
            </li>
            <li>
              <p>
                Performance cookies, web beacons, and other tracking
                technologies collect information about how you have used the
                website. We use performance cookies, web beacons, and other
                tracking technologies to improve the user experience with our
                website.
              </p>
            </li>
            <li>
              <p>
                Advertising cookies, web beacons, and other tracking
                technologies are used by our third-party service providers to
                deliver advertising to you on other third-party websites as well
                as help measure the effectiveness of our advertising campaigns.
                We also use these types of cookies to analyze website visits and
                ad conversions from third websites.
              </p>
            </li>
            <li>
              <p>
                Content cookies enable us to display content most relevant to
                the audience.
              </p>
            </li>
          </ul>
        </li>
      </ol>
      <p>
        The table below lists the types of cookies and third-party service
        providers currently used but may not include all cookies or third-party
        service providers. In addition, the below list of cookies and
        third-party service providers is subject to change at any time.
      </p>
      <table>
        <tbody>
          <tr>
            <th rowspan="1" colspan="1">
              <p>
                <strong>Cookie Type</strong>
              </p>
            </th>
            <th rowspan="1" colspan="1">
              <p>
                <strong>Who serves it</strong>
              </p>
            </th>
            <th rowspan="1" colspan="1">
              <p>
                <strong>What it does</strong>
              </p>
            </th>
            <th rowspan="1" colspan="1">
              <p>
                <strong>More Information</strong>
              </p>
            </th>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Performance</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Google Analytics</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>We use Google Analytics to analyze the use of our website.</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>
                For more information about Google’s privacy practices, see{" "}
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>
                .
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Performance</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Amplitude</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>We use Amplitude to analyze the use of our website.</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>
                For more information about Amplitude’s privacy practices, see{" "}
                <a href="https://amplitude.com/privacy">
                  https://amplitude.com/privacy
                </a>
                .
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Performance</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Hotjar</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>We use Hotjar to analyze the use of our website.</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>
                For more information about Hotjar’s privacy practices, see{" "}
                <a href="https://www.hotjar.com/legal/policies/privacy/">
                  https://www.hotjar.com/legal/policies/privacy/
                </a>
                .
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Advertising</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>LinkedIn</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>
                We use LinkedIn to share presentations and track conversions and
                website visits from our advertisements displayed on LinkedIn.
              </p>
            </td>
            <td rowspan="1" colspan="1">
              <p>
                For more information about LinkedIn’s privacy practices, please
                see{" "}
                <a href="https://www.linkedin.com/legal/privacy-policy">
                  https://www.linkedin.com/legal/privacy-policy
                </a>
                .
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Content</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Google Maps</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>We use Google Maps to provide our locations</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>
                For more information about Google’s privacy practices, see{" "}
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>
                .
              </p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Content</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Youtube</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>We use Youtube to share our videos</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>
                For more information about Google’s privacy practices, see{" "}
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>
                .
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>7. Do we discloase any information to outside parties?</p>
      <p>
        Enamine does not sell, trade or otherwise transfer to outside parties
        any personally identifiable information.
      </p>
      <p>
        This does not include trusted third parties or subcontractors who assist
        us in operating our website, conducting our business, or servicing you.
        Such trusted parties may have access to personally identifiable
        information on a need-to-know basis and will be contractually obliged to
        keep your information confidential.
      </p>
      <p>
        We may also release your information when we believe release is
        appropriate to comply with the law, enforce our site policies, or
        protect our or others’ rights, property, or safety.
      </p>
      <p>7.1 Subcontractors/trusted third parties</p>
      <p>
        The list of main subcotractors/trusted third parties with whom we share
        your information is provided below.
      </p>
      <table>
        <tbody>
          <tr>
            <th rowspan="1" colspan="1">
              <p>
                <strong>Category</strong>
              </p>
            </th>
            <th rowspan="1" colspan="1">
              <p>
                <strong>Company Name</strong>
              </p>
            </th>
            <th rowspan="1" colspan="1">
              <p>
                <strong>Country</strong>
              </p>
            </th>
            <th rowspan="1" colspan="1">
              <p>
                <strong>Legal Basis</strong>
              </p>
            </th>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Logistics</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>SIA Enamine</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Latvia, EU</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Contract</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Logistics</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Enamine US Inc.</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>USA</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Contract</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Data storage</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Amazon Web Services Inc.</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>USA</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Consent (by agreeing to the Privacy Policy)</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Card Processing</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>FirstData</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>USA</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Consent (Terms of Use)</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Card Processing</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Authorize.net</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>UAS</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Consent (Terms of Use)</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Analytics&amp;Marketing</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Google Inc. (Google Analytics)</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>USA</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Consent (by Cookies opt-in)</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Analytics&amp;Marketing</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Amplitude Inc.</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>USA</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Consent (by Cookies opt-in)</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Analytics&amp;Marketing</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>HotJar Inc.</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>USA</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Consent (by Cookies opt-in)</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Analytics&amp;Marketing</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>
                LinkedIn Ireland Unlimited Company or LinkedIn Corporation
                (LinkedIn, SlideShare)
              </p>
            </td>
            <td rowspan="1" colspan="1">
              <p>USA</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Consent (by Cookies opt-in)</p>
            </td>
          </tr>
          <tr>
            <td rowspan="1" colspan="1">
              <p>Analytics&amp;Marketing</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>The Rocket Science Group LLC (MailChimp)</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>USA</p>
            </td>
            <td rowspan="1" colspan="1">
              <p>Consent (by Cookies opt-in)</p>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
      <p>
        Other subcontractors may be engaged in personal data handling based on
        your consent or in order to fulfil our contractual obligations.
      </p>
      <p>7.2. Legally required disclosure</p>
      <p>
        Enamine will not disclose the customer’s data to law enforcement except
        when instructed by you or where it is required by law. When governments
        make a lawful demand for customer data from Enamine, Enamine strives to
        limit the disclosure. Enamine will only release specific data mandated
        by the relevant legal demand.
      </p>
      <p>
        If compelled to disclose your data, Enamine will promptly notify you and
        provide a copy of the demand unless legally prohibited from doing so.
      </p>
      <p>8.Third party links</p>
      <p>
        Occasionally, at our discretion, we may include or offer third party
        products or services on our website. These third party sites have
        separate independent privacy policies. We therefore have no
        responsibility or liability for the content and activities of these
        linked websites. Nonetheless, we seek to protect the integrity of our
        website and welcome any feedback about these websites.
      </p>
      <p>9. Where do we store the information?</p>
      <p>9.1 Personal data location</p>
      <p>
        All data are stored in databases and file repositories hosted in Amazon
        Web Services cloud owned by Amazon Web Services Inc.. Each site is
        staffed 24/7/365 with on-site physical security to protect against
        unauthorized entry. Other security features include, but are not limited
        to: full CCTV coverage externally and internally, biometric readers with
        two-factor authentication. Technical support staff do not have access to
        the backend hypervisors where virtual servers reside nor direct access
        to the NAS/SAN storage systems where snapshots and backup images reside.
        Only select engineering teams have direct access to the backend
        hypervisors based on their role. For more information regarding security
        measures and compliance of Amazon Web Services Inc., follow the
        link:&nbsp;<a href="https://aws.amazon.com/security/">Security | AWS</a>
      </p>
      <p>9.2 International Data Transfers</p>
      <p>
        By using our Sites or by interacting with us in other ways, you
        authorize Enamine to transfer Personal Information outside of the
        European Economic Area.
      </p>
      <p>10. Your rights</p>
      <p>
        Your rights according to the GDPR are the right to information about the
        processing (provided to you here, in this Privacy Policy); the right to
        access personal data relating to you; the right to have such data
        rectified, erased or restricted from processing; the right to object to
        our processing of personal data; as well as the right to not be subject
        to automatic decisions and the right to data portability. To exercise
        your rights please contact us via&nbsp;
        <a href="mailto:privacy@enamine.net">privacy@enamine.net</a>. If you
        want to know more about this, or have concerns about your rights, please
        do not hesitate to contact us.
      </p>
      <p>
        You may also access and update certain information about you stored in
        your account on the Sites by login in to it (Profile/View Profile on{" "}
        <a href="http://enaminestore.com">http://enaminestore.com</a> or by
        clicking on your name in the upper right corner of{" "}
        <a href="http://enamine.net">http://enamine.net</a> ).
      </p>
      <p>
        At any time, you can cancel your subscription to our email newsletter by
        following the link in the footer of any email received from us.
      </p>
      <p>11. Data retention</p>
      <p>
        Enamine stores your account information until you request to delete your
        account.
      </p>
      <p>
        Some information, needed for complying with our legal obligations, is
        stored according to legal requirements of the country of business
        registration and cannot be deleted upon your request.
      </p>
      <p>
        Marketing information, as email preferences, is stored until you opt-out
        receiving our email newsletter.
      </p>
      <p>
        Sites usage data logs with identifiable(SDS/CoA requests, custom
        requests, Order placements) and non-identifiable personal information
        (Results scrolling, add to cart actions).Initial data logs are stored
        for 60 days and are automatically deleted afterwards. Specific
        identifiable data can be deleted/removed upon request.
      </p>
      <p>
        Enamine do not store any search queries(Catalog IDs, CAS, SMILES and
        queries via structure search) made within the web or API service.
      </p>
      <p>12. Terms of Service</p>
      <p>
        Please also visit our&nbsp;Terms of Service (link to our Terms of
        Service)&nbsp;section establishing the use, disclaimers, and limitations
        of liability governing the use of our Sites.
      </p>
      <p>13. Your consent</p>
      <p>By using our Sites, you consent to this Privacy Policy.</p>
      <p>14. Changes to our Privacy Policy</p>
      <p>
        If we decide to change our Privacy Policy, we will post the amended
        Privacy Policy on the Sites and update the Privacy Policy modification
        date and version.
      </p>
      <p>15. Complaint</p>
      <p>
        You may at any time lodge a complaint with a supervisory authority
        regarding Enamine’s collection and processing of your personal data.
      </p>
      <p>
        You may find the list of competent authorities on the protection of
        personal data in each EU Member State by following the link:&nbsp;
        <a href="https://ec.europa.eu/digital-single-market/en/news/list-personal-data-protection-competent-authorities">
          https://ec.europa.eu/digital-single-market/en/news/list-personal-data-protection-competent-authorities
        </a>
        .
      </p>
      <p>
        Enamine US Inc. is subject to the investigatory and enforcement powers
        of the Federal Trade Commission.
      </p>
    </div>
  );
};

export default Content;
