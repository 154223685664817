import React from "react";
import beautifyValue from "../../utils/beautifyValue";

import { Wrapper, ListItem, Title, Value, Purity } from "./info-list.styles";
import { BeakerAlertIcon, BeakerCheckIcon } from "ui/icons";
import classNames from "classnames";
import { CatalogSearchResult } from "../../typings/DTO/data-contracts";
import { InfoListConfig } from "./types";

function setPurityMeta(value) {
  return {
    isPurity: true,
    purityGood: Number(value.slice(0, -1)) >= 90,
  };
}

export const convertDataByConfig = (
  item: CatalogSearchResult,
  config: InfoListConfig = null
) => {
  if (config) {
    const { config: infoListConfig, separators, limit } = config;
    let infoList = Object.entries(item)
      .reduce((acc, [key, value]) => {
        const keyExists = Object.prototype.hasOwnProperty.call(
          infoListConfig,
          key
        );

        const separatorExists = separators.includes(infoListConfig[key]?.order);

        if (keyExists) {
          // add separator into list:
          if (separatorExists) {
            acc.push({
              key: `separator-${infoListConfig[key]?.order}`,
              title: "separator",
              order: infoListConfig[key]?.order,
            });
          }

          const resObj = {
            key: key,
            title: infoListConfig[key]?.title,
            value: beautifyValue(key, value),
            raw: value,
            order: infoListConfig[key]?.order,
            meta:
              key === "purity" && value
                ? setPurityMeta(beautifyValue(key, value))
                : null,
          };

          acc.push(resObj);
        }
        return acc;
      }, [])
      .sort((a, b) => (a?.order >= b.order ? 1 : -1));

    if (limit && limit > 0) {
      infoList = infoList.slice(0, limit);
    }

    return infoList;
  } else {
    return Object.entries(item).map(([key, value]) => {
      const keyLowerCase = key.toLowerCase();
      return {
        key: keyLowerCase,
        title: keyLowerCase,
        value: beautifyValue(keyLowerCase, value),
      };
    });
  }
};

type InfoListProps = {
  item: CatalogSearchResult;
  config: InfoListConfig;
  size: number;
};

const InfoList = ({ item, size, config = null }: InfoListProps) => {
  const lists = convertDataByConfig(item, config);

  const classnames = classNames("info-list", {
    [`info-list--size--${size}`]: size,
  });

  return (
    <Wrapper className={classnames}>
      {lists.map((item) => {
        return item.title !== "separator" ? (
          <ListItem key={item.key}>
            <Title>{item.title}</Title>
            {item?.key === "purity" ? (
              <Purity>
                {item?.meta?.purityGood ? (
                  <BeakerCheckIcon />
                ) : (
                  <BeakerAlertIcon />
                )}{" "}
                {item.value}
              </Purity>
            ) : (
              <Value>{item.value ? item.value : "-"}</Value>
            )}
          </ListItem>
        ) : (
          <div key={item.key} className="info-list__divider"></div>
        );
      })}
    </Wrapper>
  );
};

export default InfoList;
