import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import { Radio } from "@mui/material";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";
import { CatalogFilterFormGroup } from "pages/catalog/components/CatalogFilters/CatalogFilters.styles";
import { FilterOption } from "./FilterLocation.types";

const filterOptions = [...Object.values(FilterOption)];

type FilterLocationProps = {
  fieldName: string;
  formik: FormikProps<FormikValues>;
};
const FilterLocation = ({ fieldName, formik }: FilterLocationProps) => {
  return (
    <CatalogFilterFormGroup>
      {filterOptions?.map((location, index) => (
        <FormControlLabel
          key={index}
          control={
            <Radio
              name={fieldName}
              onChange={formik?.handleChange}
              checked={formik?.values[fieldName] === location}
              value={location}
            />
          }
          label={location}
        />
      ))}
    </CatalogFilterFormGroup>
  );
};

export default FilterLocation;
