import styled from "styled-components";
import { blue } from "ui/colors";

export const Wrapper = styled.div`
  ${({ disable }) => {
    if (disable) {
      return `
        opacity: 0.8;
        pointer-events: none;
      `;
    }
  }}
`;

export const InputWrapper = styled.div`
  padding-top: 20px;
`;

export const ButtonWrapper = styled.div`
  padding-top: 28px;
`;

export const Title = styled.h3`
  font-size: 22px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 18px;
`;

export const RadioCardText = styled.span`
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 16px;
  padding-left: 16px;
`;

export const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 18px;
`;

export const MessageWrapper = styled.div`
  padding-top: 18px;
`;

export const TextAsLink = styled.span`
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &:active {
    color: ${blue};
  }
`;
