import React from "react";
import { Autocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DownArrowIcon } from "../icons";
import { AutocompleteProps } from "@mui/material/Autocomplete/Autocomplete";

type AutocompleteMultiselectProps = AutocompleteProps;

const AutocompleteMultiselect = ({ ...rest }: AutocompleteMultiselectProps) => {
  return (
    <Autocomplete
      multiple
      ChipProps={{
        deleteIcon: <CloseIcon />,
      }}
      popupIcon={<DownArrowIcon />}
      {...rest}
    />
  );
};

export default AutocompleteMultiselect;
