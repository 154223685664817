import React from "react";
import { LinkStyled } from "./text-link.styles";

const TextLink = ({ children, to, ...rest }) => (
  <LinkStyled to={to} disableDecoration {...rest}>
    {children}
  </LinkStyled>
);

export default TextLink;
