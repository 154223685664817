import React, { Fragment } from "react";
import {
  ButtonsWrapper,
  TableTitle,
  TableStyled,
  TableCell,
  TotalSum,
} from "./FailedInvoicesPopup.styles";
import { Button, Modal } from "ui";
import { CheckIcon, DangerIcon } from "ui/icons";

const FailedInvoicesPopup = ({
  open,
  setOpen,
  title,
  list,
  sum,
  invoicesToPayCount,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      boxProps={{ width: "850px", maxWidth: "100%" }}
      title={title}
      dataTestAttr="delete-modal"
      hideCloseIcon
    >
      <TableTitle>There were issues with some of your invoices:</TableTitle>
      <TableStyled>
        {list.map((el) => {
          return (
            <Fragment key={el.title}>
              <TableCell>
                <span title={el.title}>{el.title}</span>
              </TableCell>
              <TableCell>
                {el?.failed ? <DangerIcon /> : <CheckIcon />} {el.text}
              </TableCell>
            </Fragment>
          );
        })}
      </TableStyled>
      <TotalSum>
        <span>{`Total sum to pay by ${invoicesToPayCount} invoice(s)`}</span>
        <span>{sum}</span>
      </TotalSum>
      <ButtonsWrapper>
        <Button
          onClick={handleClose}
          size="small"
          type="outlined"
          dataTestAttr="go-back-button"
        >
          Go back and edit
        </Button>
        <Button
          onClick={handleConfirm}
          size="small"
          dataTestAttr="proceed-payment-button"
        >
          Proceed to payment anyway
        </Button>
      </ButtonsWrapper>
    </Modal>
  );
};

export default FailedInvoicesPopup;
