import { Toast, Link } from "ui/index";

const ToastNoCartModify = () => {
  return (
    <Toast>
      <Toast.Title>Your current order has a pending checkout</Toast.Title>
      <Toast.Text>
        Finish or discard it before starting a new one.{" "}
        <Link to="/checkout">Click here</Link> to proceed
      </Toast.Text>
    </Toast>
  );
};

export default ToastNoCartModify;
