import { catalogViewModes } from "../../constants";
import {
  ADD_CATALOG_ITEMS,
  SET_CATALOG_FILTERS,
  SET_LOADING_ITEMS,
  SET_PAGE_SIZE,
  SET_PRODUCT_LIST,
  SET_VIEW_MODE,
} from "./types";

const initialState = {
  items: null,
  loadingItems: true,
  pageSize: 9,
  viewMode: catalogViewModes.CARDS,
  productLists: {},
  catalogFilters: {},
};

const catalogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_ITEMS:
      return {
        ...state,
        loadingItems: action.payload,
      };

    case SET_PAGE_SIZE:
      return {
        ...state,
        ...(action.payload?.size && { pageSize: action.payload.size }),
      };

    case SET_VIEW_MODE:
      return {
        ...state,
        viewMode: action.payload,
      };

    case ADD_CATALOG_ITEMS: {
      const { items, allItems, key } = action.payload;

      const filteredItems = items.filter((el) => el);

      return {
        ...state,
        items: { [key]: filteredItems },
        ...(allItems && { allItems: allItems }),
      };
    }

    case SET_PRODUCT_LIST: {
      const productListKey = action.payload?.id;

      return {
        ...state,
        productLists: {
          ...state.productLists,
          [productListKey]: action.payload,
        },
      };
    }

    case SET_CATALOG_FILTERS: {
      return {
        ...state,
        catalogFilters: action.payload,
      };
    }

    default:
      return state;
  }
};

export default catalogReducer;
