import styled from "styled-components";
import { Button } from "ui";
import { TextField } from "@mui/material";
import { accentColor, borderColor, lightBlue } from "../../../../ui/colors";

export const Wrapper = styled.div`
  padding: 0 26px;
`;

export const SearchButton = styled(Button)`
  width: 48px;
  height: 48px;
  align-self: flex-start;
  margin-top: 4px;
`;

export const StructureSearchButton = styled(Button)`
  min-width: 214px;
  padding: 10px 12px;
  border-color: ${borderColor};
  background-color: #fff;
  color: ${accentColor};
  &:hover {
    border-radius: 8px;
    border: 1px solid ${accentColor};
    background-color: ${lightBlue} !important;
    color: ${accentColor} !important;
  }
  svg path {
    fill: ${accentColor} !important;
  }
  & > div {
    margin-left: 0;
  }
`;

export const SearchTextField = styled(TextField)`
  min-height: 56px;
`;
