import styled from "styled-components";
import { lineColor } from "ui/colors";

const paddingXValue = 64;

const paddingXStyles = `
  padding-right: ${paddingXValue}px;
  padding-left: ${paddingXValue}px;
`;

export const Wrapper = styled.div`
  width: 640px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 50px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 15px;
  ${paddingXStyles}
`;

export const Content = styled.div`
  ${paddingXStyles}
`;

export const Footer = styled.footer`
  ${paddingXStyles}
  border-top: 1px solid ${lineColor};
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const LogoWrapper = styled.div`
  svg {
    width: 80px;
    height: auto;
    display: block;
  }
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 28px;
  margin: 0;
`;
