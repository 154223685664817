import React from "react";
import { TextLink } from "../index";
import { Wrapper } from "./message-with-link.styles";

const MessageWithLink = ({ message, linkText, linkHref }) => (
  <Wrapper>
    <span>{message}</span>
    &nbsp;
    <TextLink to={linkHref}>{linkText}</TextLink>
  </Wrapper>
);

export default MessageWithLink;
