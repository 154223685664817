import React, { useEffect } from "react";
import { handleGoToOldVersion } from "utils/index";
import { useAmplitudeState } from "context/amplitude-context";
import RedirectToOldStorePage from "./markup";

const RedirectToOldStorePageContainer = () => {
  const { amplitude } = useAmplitudeState();

  useEffect(() => {
    setTimeout(() => {
      handleGoToOldVersion(amplitude);
    }, 2500);
  }, []);

  return <RedirectToOldStorePage />;
};

export default RedirectToOldStorePageContainer;
