// @ts-check

import styled from "styled-components";
import { accentColor, darkBlue } from "ui/colors";
import { Link } from "ui";
import { Paper } from "../paper/paper";

export const Wrapper = styled(Paper)`
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

export const Title = styled.h2`
  margin: 0;
  margin-top: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: ${darkBlue};
  text-align: center;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: ${darkBlue};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  padding: 10px 0;
`;

export const ExternalLink = styled.a`
  color: ${accentColor};
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
`;
