const selectShowWelcomePopup = (state) => {
  return state.onboarding.showWelcomePopup;
};

const selectShowSorryPopup = (state) => {
  return state.onboarding.showSorryToSeeYouGoPopup;
};

const onboardingSelectors = {
  selectShowWelcomePopup,
  selectShowSorryPopup,
};

export default onboardingSelectors;
