import React, { useMemo } from "react";
import {
  Container,
  CatalogHeader,
  Pagination,
  Flex,
  Title,
  Breadcrumbs,
} from "ui";
import NotFound from "./parts/not-found/NotFound";
import SidebarPart from "./parts/sidebar/Sidebar";
import {
  Page,
  Group,
  GroupItem,
  Paper,
  ListItem,
  List,
  PaginationWrapper,
  CatalogSpinner,
} from "./Catalog.styles";
import { ActionBar } from "./parts/action-bar/action-bar";
import { PageHeader, PageSidebar } from "ui/page-header/page-header.styles";
import { CatalogCardContainer } from "./components/Card/Card.container";
import { listErrorTypes } from "./Catalog.container";
import { CatalogViewModes } from "../../constants/catalogViewModes";
import { useLocation } from "react-router";

type CatalogProps = {
  q: string;
  id: string;
  items: any[];
  images: any[];
  isLoading: boolean;
  drawSearch: string;
  cat: string;
  sstype: string;
  isNothingFound: boolean;
  onHandleSetPageSize: (size: number) => void;
  onHandleSetViewMode: (mode: CatalogViewModes) => void;
  pageSize: number | string;
  viewMode: CatalogViewModes;
  pagination: any;
  onHandleClickPagination: (id: number) => void;
  onHandleDownloadSdf: () => void;
  isLoadingSdf: boolean;
  onHandleAllToCart: () => void;
  isLoadingAllToCart: boolean;
  listName: string;
  listErrorType: string;
};

const Catalog = ({
  q,
  id,
  items = [],
  isLoading,
  images,
  drawSearch,
  cat,
  sstype,
  isNothingFound,
  onHandleSetPageSize,
  onHandleSetViewMode,
  pageSize,
  viewMode,
  pagination,
  onHandleClickPagination,
  onHandleDownloadSdf,
  isLoadingSdf,
  onHandleAllToCart,
  isLoadingAllToCart,
  listName,
  listErrorType,
}: CatalogProps) => {
  const { state } = useLocation<CatalogWithBreadcrumbsState>();
  const { pathname } = useLocation();

  const pathTokens = useMemo(() => {
    const paths = [];

    if (pathname === "/" || !pathname.match(/synthetic-reagents/g)?.length)
      return undefined;

    pathname.split("/").reduce((prev, curr) => {
      if (curr) {
        const urlTitle = curr.split("-").join(" ");
        const title = `${urlTitle.charAt(0).toUpperCase()}${urlTitle.slice(1)}`;
        const currPath = `${prev}/${curr}`;
        paths.push({
          href: currPath + "/" === pathname ? "" : currPath,
          title,
        });
        return currPath;
      } else {
        return prev;
      }
    });

    return paths;
  }, [pathname]);

  const catalogTitle = useMemo(() => {
    return pathTokens?.length
      ? pathTokens[pathTokens.length - 1].title
      : state?.catalogTitle
      ? state?.catalogTitle
      : id
      ? listName
      : "Search Results";
  }, [state?.catalogTitle, id, listName, pathTokens]);

  return (
    <Page>
      {(state?.breadCrumbs || pathTokens) && (
        // Temporary solution with Breadcrumbs for SyntheticReagents
        <div style={{ marginBottom: "-14px" }}>
          <Breadcrumbs items={state?.breadCrumbs || pathTokens} />
        </div>
      )}
      <Container>
        {listErrorType ? (
          <>
            <Paper style={{ marginTop: "50px", paddingTop: "30px" }}>
              <NotFound
                message={
                  listErrorType === listErrorTypes.DISABLED
                    ? "Product list disabled"
                    : "Product list not found"
                }
              />
            </Paper>
          </>
        ) : (
          <>
            <PageHeader className="catalog-header">
              <Flex type="center-between">
                <Title variant="h1">{catalogTitle}</Title>
                <ActionBar
                  onHandleDownloadSdf={onHandleDownloadSdf}
                  isLoadingSdf={isLoadingSdf}
                  onHandleAllToCart={onHandleAllToCart}
                  isLoadingAllToCart={isLoadingAllToCart}
                />
              </Flex>
            </PageHeader>
            <Group>
              <GroupItem sidebar>
                <PageSidebar>
                  <SidebarPart
                    q={q}
                    sstype={sstype}
                    cat={cat}
                    drawSearch={drawSearch}
                  />
                </PageSidebar>
              </GroupItem>
              <GroupItem main>
                <Paper contentBlock hasMinHeight={isLoading || isNothingFound}>
                  <CatalogHeader
                    pageSize={pageSize}
                    onHandleSetPageSize={onHandleSetPageSize}
                    onHandleSetViewMode={onHandleSetViewMode}
                    viewMode={viewMode}
                  />
                  <CatalogSpinner
                    size={32}
                    height="100%"
                    isLoading={isLoading}
                  />
                  {items && items?.length && !isLoading ? (
                    <List
                      hasFooter={
                        !pagination && viewMode === CatalogViewModes.LIST
                      }
                    >
                      {items.map((item, index) => {
                        const { product } = item;
                        return (
                          <ListItem
                            key={product?.code || index}
                            fullWidth={viewMode === CatalogViewModes.LIST}
                          >
                            <CatalogCardContainer
                              item={item}
                              images={images}
                              listMode={viewMode === CatalogViewModes.LIST}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  ) : null}
                  {isNothingFound ? <NotFound /> : null}
                  {pagination && !isLoading ? (
                    <PaginationWrapper>
                      <Pagination
                        pagination={pagination}
                        onHandleClickPagination={onHandleClickPagination}
                      />
                    </PaginationWrapper>
                  ) : null}
                </Paper>
              </GroupItem>
            </Group>
          </>
        )}
      </Container>
    </Page>
  );
};

export default Catalog;
