import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkoutActions, checkoutSelectors } from "redux/checkout";
import Checkout from "./Checkout";
import { events, useAmplitudeState } from "context/amplitude-context";
import { useCurrency } from "hooks";
import { ACCORDION_STEPS, MODES, INITIAL_ORDER_SUMMARY } from "./constants";
import useHandleConfirmCheckout from "./useHandleConfirmCheckout";
import { checkAllAccordionsClosed } from "./utils";
import { getPriceText } from "shared/utils/Cart.utils";
import { rootPages } from "constants";
import { useHistory } from "react-router-dom";
import useModal from "./useModal";
import { currencySelectors } from "redux/currency";
import { customFetch } from "utils";
import useHandleDiscardOrder from "./useHandleDiscardOrder";
import superCartSelectors from "features/super-cart/redux/selectors";
import superCartActions from "features/super-cart/redux/actions";
import { generateItemsWithImages } from "features/super-cart/redux/utils";

const CheckoutPage = () => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();
  const dispatch = useDispatch();
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const accordionData = useSelector(checkoutSelectors.selectAccordion);
  const superCart = useSelector(superCartSelectors.selectSuperCart);
  const cartItems = generateItemsWithImages(superCart);
  const cartInfo = superCart.cartInfo;
  const currency = useSelector(currencySelectors.selectCurrent);
  const [mode, setMode] = useState(MODES.CHECKOUT);
  const [items, setItems] = useState([]);
  const [init, setInit] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState("Place Order");
  const [readyToConfirm, setReadyToConfirm] = useState(false);
  const [orderSummary, setOrderSummary] = useState(INITIAL_ORDER_SUMMARY);
  useCurrency();
  const { handleConfirm } = useHandleConfirmCheckout({
    setMode,
    checkoutData,
    cartItems,
    currency,
    cartInfo: superCart.cartInfo,
  });
  const restriction = useModal();
  const postalCodeIssue = useModal();

  const [whenCanBePaid, setWhenCanBePaid] = useState("");
  const [isDisabledConfirmButton, setDisabledConfirmButton] = useState(true);
  const [showDiscard, setShowDiscard] = useState(false);
  const { handleDiscard } = useHandleDiscardOrder({
    orderId: cartInfo?.data?.id,
  });
  /* 
    SECTION.
    use for "Edit" button on the each checkout step
  */
  const handleEdit = (accordionKey) => {
    setMode(MODES.CHECKOUT);
    dispatch(checkoutActions.openAccordion(accordionKey));
  };
  /* === End === */

  const pendingTimerCallback = () => {
    setTimeout(() => {
      setWhenCanBePaid("");
      setDisabledConfirmButton(false);
    }, 1000);
  };

  /* 
    SECTION.
    redirect to cart page if empty cartItems
  */
  useEffect(() => {
    if (!cartItems?.length && mode !== MODES.THANK) {
      history.push(`/${rootPages.CART}`);
    }
  }, [cartItems, mode]);
  /* === End === */

  useEffect(() => {
    /*  
      SECTION.
      create items array to show steps on the page
    */
    const newItems = ACCORDION_STEPS.map((step, idx) => {
      const accordionItemKey = `step${idx + 1}`;

      return {
        ...step,
        ...accordionData[accordionItemKey],
      };
    });
    setItems(newItems);
    /* === End === */

    /*  
      SECTION.
      check if all accordion closed and show "Submit Order" button
    */
    const isAllAccordionsClosed = checkAllAccordionsClosed(accordionData);

    if (isAllAccordionsClosed) {
      setReadyToConfirm(true);
      setMode(MODES.CHECK_ORDER);
    } else {
      setReadyToConfirm(false);
    }
    /* === End === */
  }, [accordionData]);

  /*  
    SECTION. 
    Set order summary based on cart info
  */
  useEffect(() => {
    if (cartInfo?.data && !cartInfo?.loading) {
      const res = {};
      const { subtotal, discount, total } = cartInfo?.data;
      const currencyAbbr = cartInfo?.data.currency;

      res.subtotal = getPriceText(subtotal, currencyAbbr);
      res.discount = `${discount > 0 ? "-" : ""}${getPriceText(
        discount,
        currencyAbbr
      )}`;
      res.total = getPriceText(total, currencyAbbr);

      if (checkoutData?.shipping?.addressType && checkoutData?.carrier?.title) {
        const { handlingAmount, shippingAmount, taxAmount } = cartInfo?.data;

        res.handling = getPriceText(handlingAmount, currencyAbbr);
        res.shipping = getPriceText(shippingAmount, currencyAbbr);
        res.tax = getPriceText(taxAmount, currencyAbbr);
      }

      setOrderSummary((prev) => ({
        ...prev,
        ...res,
      }));
    }
  }, [cartInfo?.data, cartInfo?.loading]);
  /* === End === */

  /*  
    SECTION. 
    Show restriction modal if restricrion exists in cartInfo
  */
  useEffect(() => {
    if (cartInfo?.data?.restriction && !cartInfo?.loading && init) {
      dispatch(checkoutActions.openAccordion("step3"));
      setMode(MODES.CHECKOUT);
      restriction.setTitle("Shipping restrictions for selected compounds");
      const messages =
        "Your cart contains a compound which is restricted by US customs regulations and cannot be shipped, please contact support for more details.";
      restriction.setMessage(messages);
      restriction.setOpen(true);
    }
  }, [cartInfo?.data?.restriction, !cartInfo?.loading]);
  /* === End === */

  useEffect(() => {
    if (init && currency) {
      /*  
      SECTION. 
      Load cart info and set to local storage and load cart content in callback
      */
      const onSuccess = () => {
        dispatch(superCartActions.requestItemsAndImages());
      };
      dispatch(superCartActions.requestCartInfo({ onSuccess }));
      /* === End === */
    }
  }, [currency]);

  /*  
    SECTION. 
    Change confirmation button title
  */
  useEffect(() => {
    if (checkoutData?.payment?.type === "card") {
      setConfirmTitle("Pay Now");
      setDisabledConfirmButton(true);
    } else {
      setConfirmTitle("Place Order");
      setDisabledConfirmButton(false);
    }
  }, [checkoutData?.payment?.type]);
  /* === End === */

  useEffect(() => {
    if (mode === MODES.CHECK_ORDER && checkoutData?.payment?.type === "card") {
      (async () => {
        if (cartInfo?.data?.id) {
          const orderId = cartInfo?.data?.id;
          const res = await customFetch({
            url: `orders/by/orderNumber/${orderId}/payments`,
          });

          if (
            res[0] &&
            !res[0]?.summary?.canBePayed &&
            res[0]?.summary?.whenCanBePayedAfterTimeout
          ) {
            setWhenCanBePaid(res[0]?.summary?.whenCanBePayedAfterTimeout);
          } else if (res[0] && res[0]?.summary?.canBePayed) {
            setWhenCanBePaid("");
            setDisabledConfirmButton(false);
          }

          if (res[0]) {
            const payments = res[0];
            if (
              payments?.summary?.payedByCustomer === false &&
              payments?.others?.length > 0
            ) {
              setShowDiscard(true);
            } else {
              setShowDiscard(false);
            }
          }
        }
      })();
    } else {
      setWhenCanBePaid("");
      setShowDiscard(false);
    }
  }, [mode, cartInfo?.data?.id, checkoutData?.payment?.type]);

  useEffect(() => {
    /*  
    SECTION. 
    Load cart info and set to local storage and load cart content in callback
    */
    const onSuccess = () => {
      dispatch(superCartActions.requestItemsAndImages());
    };
    dispatch(superCartActions.requestCartInfo({ onSuccess }));
    /* === End === */

    /*  
      SECTION. 
      send amplitude event when page opened
    */
    amplitude.logEvent(events.order_start);
    /* === End === */

    setInit(true);
  }, []);

  return (
    <Checkout
      modeRegulator={{
        mode,
        modes: MODES,
        setMode,
      }}
      handleConfirm={handleConfirm}
      items={items}
      cartItems={cartItems}
      handleEdit={handleEdit}
      readyToConfirm={readyToConfirm}
      orderSummary={orderSummary}
      restriction={restriction}
      confirmTitle={confirmTitle}
      postalCodeIssue={postalCodeIssue}
      whenCanBePaid={whenCanBePaid}
      isDisabledConfirmButton={isDisabledConfirmButton}
      pendingTimerCallback={pendingTimerCallback}
      handleDiscard={handleDiscard}
      showDiscard={showDiscard}
    />
  );
};

export default CheckoutPage;
