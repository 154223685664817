import React from "react";
import { Container, Loader, Paper } from "ui";
import {
  AddressContent,
  OrderHeader,
  OrderItems,
  OrderPrice,
  ContactPerson,
} from "./parts/index";
import { Wrapper } from "./Order.styles";
import { paymentTypes } from "constants/index";

const Order = ({ backToOrdersLink, loading, details, items, orderId }) => {
  const isAnyItemInquery = items?.some((item) => !!item?.isInquery);
  return (
    <Container>
      <Wrapper className="order-page">
        <OrderHeader
          orderId={orderId}
          loading={loading}
          backToOrdersLink={backToOrdersLink}
          date={details?.updated}
          type={details?.type}
          status={details?.status}
        />

        <div className="order-page__grid">
          {loading ? (
            <div className="order-page__grid-item order-page__grid-item--full-width">
              <Paper>
                <Loader fullWidth />
              </Paper>
            </div>
          ) : (
            <>
              <div className="order-page__grid-item order-page__grid-item--full-width">
                <Paper>
                  <h2 className="order-page__paper-title order-page__paper-title--small-margin">
                    Items
                  </h2>
                  <OrderItems items={items} />
                </Paper>
              </div>

              <div className="order-page__grid-item order-page__grid-item--full-width">
                <Paper>
                  <h2 className="order-page__paper-title">Price</h2>
                  <OrderPrice
                    details={details}
                    isAnyItemInquery={isAnyItemInquery}
                  />
                </Paper>
              </div>

              <div className="order-page__grid-item order-page__grid-item--full-width">
                <Paper>
                  <h2 className="order-page__paper-title">
                    Contact Person for Shipping and Billing
                  </h2>
                  <div className="order-page__text">
                    <ContactPerson item={details?.shippingPerson} />
                  </div>
                </Paper>
              </div>

              <Paper>
                <h2 className="order-page__paper-title">Billing Address</h2>
                <div className="order-page__text">
                  {details?.addresses?.billing ? (
                    <AddressContent item={details.addresses.billing} />
                  ) : null}
                </div>
              </Paper>

              <Paper>
                <h2 className="order-page__paper-title">Shipping Address</h2>
                <div className="order-page__text">
                  {details?.addresses?.shipping ? (
                    <AddressContent item={details.addresses.shipping} />
                  ) : null}
                </div>
              </Paper>

              <Paper>
                <h2 className="order-page__paper-title">Shipping Options</h2>
                <div className="order-page__text">
                  {details?.carrier?.name}
                  {details?.carrier?.id ? (
                    <>. Carrier ID: {details?.carrier?.id}</>
                  ) : null}
                </div>
              </Paper>

              <Paper>
                <h2 className="order-page__paper-title">Payment Type</h2>
                <div className="order-page__text">
                  {paymentTypes?.[details?.paymentType]}
                </div>
              </Paper>
            </>
          )}
        </div>
      </Wrapper>
    </Container>
  );
};

export default Order;
