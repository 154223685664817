import styled from "styled-components";
import { accentColor } from "../colors";

export const CollapsibleWrapper = styled.div`
  overflow: hidden;
  position: relative;
  opacity: 0;
  transition: 0.3s;
`;

/**
 * @typedef CollapsibleFooterProps
 * @property {boolean} isOpened
 * @property {number} containerHeight
 * @property {number} height
 */

export const CollapsibleFooter =
  /** @type {import("styled-components").ThemedStyledFunction<'div', CollapsibleFooterProps>} */
  styled.div`
    display: ${(props) => (props.isOpened ? "none" : "flex")};
    top: ${(props) => props.containerHeight - props.height}px;
    height: ${(props) => props.height}px;
    background: linear-gradient(transparent 0%, white 80%, white 100%);
    width: 100%;
    position: absolute;
    align-items: flex-end;
    justify-content: center;
    user-select: none;
  `;

export const ShowAllButton = styled.span`
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    color: ${accentColor};
  }
`;
