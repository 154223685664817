import styled from "styled-components";

export const Wrapper = styled.div`
  .icons__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 50px;
    row-gap: 20px;
  }
`;
