// @ts-check
import React from "react";
import { productAnalogs as descriptionText } from "constants/productGroups";
import { Button, EmptyMessage, ProductCard, ProductsLoader } from "ui";
import { ProductGridWrapper, Wrapper } from "./analogs.styles";
import { ProductDescription } from "ui/ProductDetails/ProductDetails.styled";
import { ProductGrid, ProductItem } from "ui/product-grid/product-grid.styles";
import { proposalKeys } from "constants/index";

const Analogs = ({
  loading,
  // handleClickLoadMore,
  // handleClickPagination,
  handleCustomerRequest,
  // expanded,
  items,
  // pagination,
}) => {
  return (
    <Wrapper>
      <ProductDescription>{descriptionText}</ProductDescription>
      {loading ? (
        <ProductsLoader />
      ) : (
        <>
          {items?.length ? (
            <ProductGridWrapper>
              <ProductGrid>
                {items.map((item) => {
                  return (
                    <ProductItem key={item?.product?.code}>
                      <ProductCard
                        item={item}
                        image={item?.image}
                        proposal={proposalKeys.analog}
                      />
                    </ProductItem>
                  );
                })}
              </ProductGrid>
            </ProductGridWrapper>
          ) : (
                <EmptyMessage variant="alternativePaddings">
                  Unfortunately we weren’t able to find any analogs for this
                  particular product. Try contact our team so we can pick
                  analogs for you.
                  <Button
                    type="outlined"
                    size="small"
                    onClick={handleCustomerRequest}
                  >
                    Contact us
                  </Button>
                </EmptyMessage>
          )}

          {/* // @TODO Check pagination and implement in on FE*/}
          {/*{expanded ? (*/}
          {/*  <Pagination*/}
          {/*    pagination={pagination}*/}
          {/*    onHandleClickPagination={handleClickPagination}*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <Button*/}
          {/*    type="outlined"*/}
          {/*    size="small"*/}
          {/*    fullWidth*/}
          {/*    onClick={handleClickLoadMore}*/}
          {/*    dataTestAttr="load-more-button"*/}
          {/*  >*/}
          {/*    {items?.length ? "Load more" : "Show analogs"}*/}
          {/*  </Button>*/}
          {/*)}*/}
        </>
      )}
    </Wrapper>
  );
};

export default Analogs;
