import React from "react";
import { Button, RadioCard } from "ui/index";
import {
  Wrapper,
  ButtonWrapper,
  CardInner,
  CardTitle,
  CardWrapper,
} from "./Payment.styles";

const Payment = ({
  handleClick,
  selectedId,
  handleSelect,
  items,
  isLoading,
}) => {
  return (
    <Wrapper disable={isLoading}>
      {items.map((item, idx) => {
        const isActive = item.id === selectedId;

        return (
          <CardWrapper key={idx}
          >
            <RadioCard onClick={() => handleSelect(item?.id)} active={isActive}>
              <CardInner data-element="card-wrapper">
                <CardTitle>
                  <b>{item.title}</b> {item.icon}
                </CardTitle>
                {item.text}
              </CardInner>
            </RadioCard>
          </CardWrapper>
        );
      })}

      <ButtonWrapper>
        <Button onClick={handleClick} size="small" fullWidth>
          Next
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Payment;
