const getCountriesOptions = (countriesObj) => {
  const resultArr = [{ value: "", title: "" }];

  if (countriesObj) {
    Object.keys(countriesObj).map((key) => {
      resultArr.push({
        value: key,
        title: countriesObj[key],
      });
    });
  }

  return resultArr.sort((a, b) => a.title.localeCompare(b.title));
};

export default getCountriesOptions;
