import addressTypes from "../../../../constants/addressTypes";
import endpoints from "../../../../constants/endpoints";
import customFetch from "../../../../utils/customFetch";
import handleError from "../../../../utils/handleError";
import convertEmptyStringsToNull from "../../../../utils/convertEmptyStringsToNull";

const initParams = {
  retry: 5,
  retryDelay: 300,
};

export const handleDelete = async ({
  id,
  addresses,
  setAddresses,
  logAddressEdit,
}) => {
  const [deleteResult] = await fetchDeleteAddress(id);

  if (deleteResult) {
    logAddressEdit("delete");
    setAddresses(addresses.filter((el) => el.id !== id));
  }
};

export const handleAdd = async (
  data,
  {
    formik,
    setOpen,
    setAddresses,
    setIsBillingAdding,
    setIsShippingAdding,
    logAddressAdd,
  }
) => {
  const preparedData = convertEmptyStringsToNull(data);
  const setIsAdding =
    data.addressType === addressTypes.SHIPPING
      ? setIsShippingAdding
      : setIsBillingAdding;

  setIsAdding(true);
  const [postAddressData, err] = await fetchPostAddress(preparedData);

  if (handleError(err, formik)) {
    setIsAdding(false);
    return;
  }
  if (postAddressData.data !== "OK") {
    handleError("Can not add address", formik);
    return;
  }

  formik.setSubmitting(false);
  setOpen(false);

  logAddressAdd(data.addressType);

  const [addresses] = await fetchUserAddresses();

  setIsAdding(false);
  setAddresses(addresses);
};

export const handleUpdate = async (
  data,
  { formik, setOpen, setAddresses, logAddressEdit }
) => {
  const preparedData = convertEmptyStringsToNull(data);

  const [putAddressData, err] = await fetchPutAddress(preparedData);

  if (handleError(err, formik)) return;
  if (putAddressData.data !== "OK") {
    handleError("Can not add address", formik);
    return;
  }

  formik.setSubmitting(false);
  setOpen(false);

  logAddressEdit("edit");

  const [addresses] = await fetchUserAddresses();

  setAddresses(addresses);
};

export const fetchPostAddress = async (data) => {
  return await customFetch(
    {
      method: "post",
      url: endpoints.USER_ADDRESS,
      data,
    },
    initParams
  );
};

export const fetchPutAddress = async (data) => {
  return await customFetch(
    {
      method: "put",
      url: `${endpoints.USER_ADDRESS}/${data.id}`,
      data,
    },
    initParams
  );
};

export const fetchUserAddresses = async () => {
  return customFetch(
    {
      url: endpoints.USER_ADDRESS,
    },
    initParams
  );
};

export const fetchDeleteAddress = async (id) => {
  return customFetch(
    {
      method: "delete",
      url: `${endpoints.USER_ADDRESS}/${id}`,
    },
    initParams
  );
};

export const filterAddressesByType = (addresses = [], type) => {
  const res = addresses.filter((el) => el.addressType === type).reverse();

  return res;
};

export const seedVat = (list) => {
  const foundVat = list.find((element) => element?.vat);

  if (foundVat) {
    const seededList = list.map((el) => {
      if (el?.vat) {
        return el;
      } else {
        return {
          ...el,
          vat: "--",
        };
      }
    });

    return seededList;
  } else {
    return list;
  }
};
