import { searchAsTypes } from "../constants";

const generateSstypeIfNotExists = (sstype) => {
  let res = null;

  if (sstype) {
    res = sstype;
  } else {
    res = searchAsTypes.EXACT;
  }

  return res;
};

export default generateSstypeIfNotExists;
