// @ts-check

import React from "react";
import {
  MarkSuccess,
  CartSuccess,
  MarkWarning,
  CartWarning,
  CartEmpty,
  IconWrapper,
} from "./cart-image.styles";

/**
 * @type { React.FC<{ variant: "success" | "warning" | "empty" }> }
 */
export const CartImage = ({ variant }) => {
  return (
    <IconWrapper>
      {variant === "success" ? (
        <>
          <MarkSuccess />
          <CartSuccess />
        </>
      ) : variant === "warning" ? (
        <>
          <MarkWarning />
          <CartWarning />
        </>
      ) : variant === "empty" ? (
        <CartEmpty />
      ) : null}
    </IconWrapper>
  );
};
