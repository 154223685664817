import React, { useCallback, useEffect, useState } from "react";
import FilterLocation from "../../parts/FilterLocation/FilterLocation";
import CatalogFilter from "./CatalogFilter";
import FilterFromTo from "../../parts/FilterFromTo/FilterFromTo";
import { useFormik } from "formik";
import { Button } from "ui";
import {
  CatalogFiltersForm,
  CatalogFiltersSubmitWrapper,
} from "./CatalogFilters.styles";
import FilterClasses from "../../parts/FilterClasses/FilterClasses";
import FilterCollections from "../../parts/FilterCollections/FilterCollections";
import { FilterTypes } from "../../types";
import { useDispatch, useSelector } from "react-redux";
import { catalogActions, catalogSelectors } from "../../../../redux/catalog";
import FilterSsTypes from "../../parts/FilterSsTypes/FilterSsTypes";
import FilterCategory from "../../parts/FilterCategory/FilterCategory";
import {
  events,
  useAmplitudeState,
} from "../../../../context/amplitude-context";
import { useSearchRedirect } from "../../catalog.hooks";
import {
  catalogFiltersAvailableConfig,
  catalogFiltersRegisterSchema,
} from "./filtersConfig";
import FilterSubClasses from "../../parts/FilterSubClasses/FilterSubClasses";
import { FilterOption } from "pages/catalog/parts/FilterLocation/FilterLocation.types";

type CatalogFiltersProps = {
  sstype: string;
  cat: string;
  drawSearch: string;
};

const CatalogFilters = ({ sstype, cat, drawSearch }: CatalogFiltersProps) => {
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();
  const { makeRedirect } = useSearchRedirect();
  const catalogFilters = useSelector(catalogSelectors.selectCatalogFilters);

  const [category, setCategory] = useState(cat);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...(catalogFilters && catalogFilters),
      sstype,
      location: FilterOption.ALL,
    },
    validationSchema: catalogFiltersRegisterSchema,
    onSubmit: (values) => {
      dispatch(catalogActions.setCatalogFilters(values));
      amplitude.logEvent(events.search_type, { type: values.sstype });
      amplitude.logEvent(events.search_category, {
        category_type: category,
        page: "Filters",
      });

      makeRedirect({ sstype: values.sstype, cat: category });
      window?.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
  });

  const configHasFilterType = useCallback(
    (filterType: FilterTypes) => {
      if (category)
        return Object.prototype.hasOwnProperty.call(
          catalogFiltersAvailableConfig[category],
          filterType
        );
    },
    [category]
  );

  const handleChangeCategory = (category: string) => {
    setCategory(category);
    formik.resetForm({ values: { sstype, location: FilterOption.ALL } });
  };

  useEffect(() => {
    return () => {
      dispatch(catalogActions.setCatalogFilters({}));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <CatalogFiltersForm onSubmit={formik.handleSubmit}>
      <CatalogFilter
        filterListItemWrapperClassName="smallMarginBottom"
        title="SEARCH AS"
        disabled={!drawSearch}
      >
        <FilterSsTypes
          fieldName={FilterTypes.sstype}
          formik={formik}
          sstype={sstype}
        />
      </CatalogFilter>
      <CatalogFilter
        filterListItemWrapperClassName="smallMarginBottom"
        title="CATEGORY"
        disabled={!drawSearch}
      >
        <FilterCategory
          fieldName={FilterTypes.category}
          formik={formik}
          category={category}
          handleChangeCategory={handleChangeCategory}
        />
      </CatalogFilter>

      {drawSearch && (
        <>
          {configHasFilterType(FilterTypes.LOCATION) && (
            <CatalogFilter
              filterListItemWrapperClassName="smallMarginBottom"
              title="LOCATION"
              disabled={!drawSearch}
            >
              <FilterLocation
                fieldName={FilterTypes.LOCATION}
                formik={formik}
              />
            </CatalogFilter>
          )}
          {configHasFilterType(FilterTypes.classes) && (
            <CatalogFilter title="CLASSES" disabled={!drawSearch}>
              <FilterClasses fieldName={FilterTypes.classes} formik={formik} />
            </CatalogFilter>
          )}
          {configHasFilterType(FilterTypes.subclasses) && (
            <CatalogFilter title="SUB CLASSES" disabled={!drawSearch}>
              <FilterSubClasses
                fieldName={FilterTypes.subclasses}
                formik={formik}
              />
            </CatalogFilter>
          )}
          {configHasFilterType(FilterTypes.collections) && (
            <CatalogFilter title="COLLECTIONS" disabled={!drawSearch}>
              <FilterCollections
                fieldName={FilterTypes.collections}
                formik={formik}
              />
            </CatalogFilter>
          )}
          {configHasFilterType(FilterTypes.MW) && (
            <CatalogFilter title="MW" disabled={!drawSearch}>
              <FilterFromTo
                fieldName={FilterTypes.MW}
                formik={formik}
                placeholderFrom="0.00"
                placeholderTo="&#8734;"
              />
            </CatalogFilter>
          )}
          {configHasFilterType(FilterTypes.CLogP) && (
            <CatalogFilter title="CLogP" disabled={!drawSearch}>
              <FilterFromTo
                fieldName={FilterTypes.CLogP}
                formik={formik}
                placeholderFrom="-100.0000"
                placeholderTo="100.0000"
              />
            </CatalogFilter>
          )}

          {configHasFilterType(FilterTypes.HBA) && (
            <CatalogFilter title="HBA" disabled={!drawSearch}>
              <FilterFromTo fieldName={FilterTypes.HBA} formik={formik} />
            </CatalogFilter>
          )}

          {configHasFilterType(FilterTypes.HBD) && (
            <CatalogFilter title="HBD" disabled={!drawSearch}>
              <FilterFromTo fieldName={FilterTypes.HBD} formik={formik} />
            </CatalogFilter>
          )}

          {configHasFilterType(FilterTypes.RotB) && (
            <CatalogFilter title="RotB" disabled={!drawSearch}>
              <FilterFromTo fieldName={FilterTypes.RotB} formik={formik} />
            </CatalogFilter>
          )}

          {configHasFilterType(FilterTypes.TPSA) && (
            <CatalogFilter title="TPSA" disabled={!drawSearch}>
              <FilterFromTo fieldName={FilterTypes.TPSA} formik={formik} />
            </CatalogFilter>
          )}

          {configHasFilterType(FilterTypes.HAC) && (
            <CatalogFilter title="HAC" disabled={!drawSearch}>
              <FilterFromTo fieldName={FilterTypes.HAC} formik={formik} />
            </CatalogFilter>
          )}

          {configHasFilterType(FilterTypes.Fsp3) && (
            <CatalogFilter title="Fsp3" disabled={!drawSearch}>
              <FilterFromTo fieldName={FilterTypes.Fsp3} formik={formik} />
            </CatalogFilter>
          )}
        </>
      )}

      <CatalogFiltersSubmitWrapper>
        <Button disabled={!drawSearch} size="small" fullWidth type="submit">
          Apply Filters
        </Button>
      </CatalogFiltersSubmitWrapper>
    </CatalogFiltersForm>
  );
};

export default CatalogFilters;
