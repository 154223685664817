// @ts-check
import { ArrowRight2Icon } from "ui/icons";
import { Wrapper } from "./SlidingArrow.styles";

export const SlidingArrow = ({ onClick }) => {
  return (
    <Wrapper>
      <ArrowRight2Icon className="sliding-arrow" onClick={onClick} />
    </Wrapper>
  );
};
