import styled from "styled-components";
import { lineColor } from "ui/colors";

export const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
ProductGrid.displayName = "ProductGrid";

export const ProductItem = styled.div`
  width: 25%;
  border-right: 1px solid ${lineColor};
  border-bottom: 1px solid ${lineColor};
  box-sizing: border-box;

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4) {
    border-top: 1px solid ${lineColor};
  }

  &:nth-child(4n + 1) {
    border-left: 1px solid ${lineColor};
  }

  &:nth-child(1) {
    border-top-left-radius: 8px;
  }

  &:nth-child(4) {
    border-top-right-radius: 8px;
  }

  &:last-child {
    border-bottom-right-radius: 8px;
  }

  &:last-child:nth-child(3),
  &:last-child:nth-child(2),
  &:last-child:nth-child(1) {
    border-top-right-radius: 8px;
  }

  &:first-child:nth-last-child(3) {
    border-bottom-left-radius: 8px;
  }

  &:nth-child(4n + 1):nth-last-child(1),
  &:nth-child(4n + 1):nth-last-child(2),
  &:nth-child(4n + 1):nth-last-child(3),
  &:nth-child(4n + 1):nth-last-child(4) {
    border-bottom-left-radius: 8px;
  }

  &:nth-child(4n):nth-last-child(2),
  &:nth-child(4n):nth-last-child(3),
  &:nth-child(4n):nth-last-child(4) {
    border-bottom-right-radius: 8px;
  }
`;
ProductItem.displayName = "ProductItem";
