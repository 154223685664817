import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { Input, Wrapper } from "./styles";
import { Label } from "../../../ui/select/select.styles";

const DebounceInput = ({
  callback = () => {},
  count,
  hideButtons,
  disabled,
  min,
  label,
}) => {
  const [inputValue, setInputValue] = React.useState(count);
  const [debouncedValue] = useDebounce(inputValue, 500);
  const [init, setInit] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    if (init) {
      if (
        !debouncedValue ||
        debouncedValue <= 0 ||
        !Number.isInteger(parseInt(debouncedValue))
      ) {
        setInputValue(1);
      } else {
        callback(parseInt(debouncedValue));
      }
    } else {
      setInit(true);
    }
  }, [debouncedValue]);

  return (
    <Wrapper>
      {!hideButtons ? (
        <button
          onClick={() => setInputValue((prev) => --prev)}
          disabled={inputValue <= 1}
        >
          -
        </button>
      ) : null}

      {label && (
        <Label>
          {label}
        </Label>
      )}

      <Input
        type="number"
        min={min || '1'}
        value={inputValue}
        onChange={handleInputChange}
        disabled={disabled}
      />

      {!hideButtons ? (
        <button onClick={() => setInputValue((prev) => ++prev)}>+</button>
      ) : null}
    </Wrapper>
  );
};

export default DebounceInput;
