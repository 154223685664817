import metadata from "../../metadata.json";

export const getVersionString = () => {
  const {
    versionMajor,
    versionMinor,
    versionRevision,
    versionBuild,
    versionTag,
  } = metadata;
  const result = `${versionMajor}.${versionMinor}.${versionRevision}.${versionBuild} ${versionTag}`;

  return result;
};
