import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCheckNextOrJustClose } from "hooks";
import { events, useAmplitudeState } from "context/amplitude-context";
import { checkoutActions, checkoutSelectors } from "redux/checkout";
import { BankCardsIcon, BankIcon, MoneyIcon } from "ui/icons";
import { currencySelectors } from "redux/currency";

import Payment from "./Payment";

const items = [
  {
    id: "wire",
    default: true,
    title: "Wire transfer",
    text: "When selecting this payment method make sure that the Purchase Order number is filled during final step of the checkout to help speed up the processing.",
    icon: <BankIcon />,
  },
  {
    id: "card",
    title: "Bank card",
    text: "Enamine does not keep credit card numbers used for purchase. This information is transferred directly to the processing agency and cannot be accessed by Enamine.",
    icon: <BankCardsIcon />,
  },
  {
    id: "check",
    default: true,
    title: "Check",
    text: "When selecting this payment method make sure that the Purchase Order number is filled during final step of the checkout to help speed up the processing.",
    icon: <MoneyIcon />,
  },
];

const PaymentContainer = () => {
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const paymentMethodsAvailable = useSelector(
    checkoutSelectors.selectPaymentMethods
  );
  const currency = useSelector(currencySelectors.selectCurrent);
  const [, buttonLabel] = useCheckNextOrJustClose(5);
  const [selectedId, setSelectedId] = useState("wire");

  const [paymentMethods, setPaymentMethods] = useState(
    items.filter((item) => item.default)
  );

  useEffect(() => {
    if (paymentMethodsAvailable.data) {
      setPaymentMethods(
        items.filter((paymentMethod) =>
          paymentMethodsAvailable.data.includes(paymentMethod.id.toUpperCase())
        )
      );
    }
  }, [paymentMethodsAvailable?.data]);

  useEffect(() => {
    dispatch(checkoutActions.requestPaymentMethods());
  }, [currency]);

  useEffect(() => {
    setPaymentMethods(items.filter((paymentMethod) => paymentMethod.default));
  }, [paymentMethodsAvailable?.error]);

  const handleClick = () => {
    const newData = { ...checkoutData };
    if (newData?.payment?.type) {
      newData.payment.type = selectedId;
    } else {
      newData.payment = {
        type: selectedId,
      };
    }
    dispatch(checkoutActions.setData(newData));
    dispatch(checkoutActions.closeAccordion("step5"));

    amplitude.logEvent(events.checkout_payment, {
      parameter: newData?.payment.type,
    });
  };

  const handleSelect = (id) => {
    setSelectedId(id);
  };

  useEffect(() => {
    if (checkoutData?.payment?.type) {
      setSelectedId(checkoutData.payment.type);
    }
  }, [checkoutData.payment]);

  return (
    <Payment
      handleClick={handleClick}
      buttonLabel={buttonLabel}
      items={paymentMethods}
      selectedId={selectedId}
      handleSelect={handleSelect}
      isLoading={paymentMethodsAvailable?.loading}
    />
  );
};

export default PaymentContainer;
