import { InfoCell } from "features/super-cart/Summary/Summary.styled";
import { CustomizedTooltip, Table } from "ui";
import { InfoIcon } from "ui/icons";
import { addZeroes } from "utils";

const OrderItems = ({ items }) => {
  return (
    <Table variant="border-bottom">
      {items &&
        items?.length &&
        items.map((el, index) => {
          return (
            <Table.Row key={index}>
              <Table.Cell noXPaddings verticalCenter>
                <div className="g-flex g-flex-vertical-center">
                  <img
                    alt="Product"
                    className="order-page__product-image"
                    src={el?.image}
                  />
                  <b style={{ marginLeft: "25px" }}>{el?.code}</b>
                </div>
              </Table.Cell>
              <Table.Cell verticalCenter>
                Count: {el?.quantity}
                <span style={{ marginLeft: "25px" }}>
                  Amount: {el?.amount} {el?.measure}
                </span>
              </Table.Cell>
              <Table.Cell verticalCenter>Purity: {el?.purity}%</Table.Cell>
              <Table.Cell verticalCenter>
                <InfoCell>
                  {Boolean(el?.isInquery) && (
                    <CustomizedTooltip
                      title="Feasible compound. Price will be confirmed by our sales representative"
                      placement="top"
                    >
                      <InfoIcon />
                    </CustomizedTooltip>
                  )}
                  <b>
                    Price: {addZeroes(el?.price)} {el?.currency}
                  </b>
                </InfoCell>
              </Table.Cell>
            </Table.Row>
          );
        })}
    </Table>
  );
};

export default OrderItems;
