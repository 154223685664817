import React from "react";

import AddressDialog from "./parts/address-dialog/AddressDialog.container";
import DeleteDialog from "./parts/delete-dialog/DeleteDialog";
import addressTypes from "../../../../constants/addressTypes";

import {
  Paper,
  Flex,
  Title,
  Button,
  Table,
  AddressCard,
  EmptyMessage,
} from "../../../../ui";
import { FullWidthLoader } from "../../../../parts";

const AddressBook = ({
  dialogTitles,
  showDialog,
  setShowDialog,
  dialogTitle,
  dialogFormValues,
  dialogMode,
  dialogHandle,
  isBillingFetching,
  billingAddresses,
  isShippingFetching,
  onHandleUpdateDialog,
  onHandleAddDialog,
  isBillingAdding,
  isShippingAdding,
  shippingAddresses,
  setDialogTitle,
  setDialogMode,
  setDialogFormValues,
  setDialogHandle,
  deleteDialog,
}) => {
  return (
    <>
      <Paper marginBottom>
        <Flex type="center-between" marginBottom>
          <Title>Billing Address</Title>
          {!isBillingFetching && billingAddresses?.length < 1 ? (
            <Button
              type="outlined"
              size="small"
              onClick={() => {
                setDialogTitle(dialogTitles.ADD_BILLING_ADDRESS);
                setDialogFormValues({ addressType: addressTypes.BILLING });
                setShowDialog(true);
                setDialogMode("ADD");
                setDialogHandle(onHandleAddDialog);
              }}
              dataTestAttr="add-billing-address-button"
            >
              Add Address
            </Button>
          ) : null}
        </Flex>
        {isBillingAdding ? <FullWidthLoader /> : null}
        {isBillingFetching ? (
          <FullWidthLoader />
        ) : (
          <>
            {billingAddresses?.length ? (
              <Table variant="bordered">
                {billingAddresses.map((el, idx) => (
                  <AddressCard
                    key={el.id}
                    dataTestAttr="billing-address-card"
                    index={idx + 1}
                    id="billing"
                    item={el}
                    handleClickDelete={() => {
                      deleteDialog.setIdToDelete(el.id);
                      deleteDialog.setOpen(true);
                      deleteDialog.setTitle(
                        "Are you sure you want to delete billing address?"
                      );
                    }}
                    handleClickEdit={() => {
                      setDialogTitle(dialogTitles.EDIT_BILLING_ADDRESS);
                      setDialogFormValues(el);
                      setShowDialog(true);
                      setDialogMode("EDIT");
                      setDialogHandle(onHandleUpdateDialog);
                    }}
                  />
                ))}
              </Table>
            ) : (
              <EmptyMessage>
                You haven’t add any billing address yet.
              </EmptyMessage>
            )}
          </>
        )}
      </Paper>
      <Paper>
        <Flex type="center-between" marginBottom>
          <Title>Shipping Addresses</Title>
          {!isShippingFetching && shippingAddresses?.length < 5 ? (
            <Button
              type="outlined"
              size="small"
              onClick={() => {
                setDialogTitle(dialogTitles.ADD_SHIPPING_ADDRESS);
                setDialogFormValues({ addressType: addressTypes.SHIPPING });
                setShowDialog(true);
                setDialogMode("ADD");
                setDialogHandle(onHandleAddDialog);
              }}
              dataTestAttr="add-shipping-address-button"
            >
              Add Address
            </Button>
          ) : null}
        </Flex>
        {isShippingAdding ? <FullWidthLoader /> : null}
        {isShippingFetching ? (
          <FullWidthLoader />
        ) : (
          <>
            {shippingAddresses?.length ? (
              <Table variant="bordered">
                {shippingAddresses.map((el, idx) => (
                  <AddressCard
                    key={el.id}
                    dataTestAttr="shipping-address-card"
                    index={idx + 1}
                    item={el}
                    id={`shipping-${idx + 1}`}
                    handleClickDelete={() => {
                      deleteDialog.setIdToDelete(el.id);
                      deleteDialog.setOpen(true);
                      deleteDialog.setTitle(
                        "Are you sure you want to delete shipping address?"
                      );
                    }}
                    handleClickEdit={() => {
                      setDialogTitle(dialogTitles.EDIT_SHIPPING_ADDRESS);
                      setDialogFormValues(el);
                      setShowDialog(true);
                      setDialogMode("EDIT");
                      setDialogHandle(onHandleUpdateDialog);
                    }}
                  />
                ))}
              </Table>
            ) : (
              <EmptyMessage>
                You haven’t add any shipping address yet.
              </EmptyMessage>
            )}
          </>
        )}
      </Paper>
      <AddressDialog
        open={showDialog}
        setOpen={setShowDialog}
        title={dialogTitle}
        formValues={dialogFormValues}
        mode={dialogMode}
        handleSubmit={dialogHandle}
      />
      <DeleteDialog
        open={deleteDialog.open}
        setOpen={deleteDialog.setOpen}
        title={deleteDialog.title}
        handleDelete={deleteDialog.handleDelete}
      />
    </>
  );
};

export default AddressBook;
