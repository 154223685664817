import styled from "styled-components";
import { borderColor } from "ui/colors";

export const Wrapper = styled.div<{ isColumnView: boolean }>`
  padding-top: 40px;
  padding-bottom: 100px;
  width: 1030px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: ${(props) => (props.isColumnView ? "column" : "row")};
  align-items: ${(props) => (props.isColumnView ? "center" : "flex-start")};
  gap: 35px;
`;

export const ImageWrapper = styled.div`
  border: 1px solid ${borderColor};
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 24px;
  box-sizing: border-box;
`;

export const Column = styled.div`
  width: 50%;
`;
