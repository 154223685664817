// @ts-check

import animateScrollTo from "animated-scroll-to";
import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { fetchAllDocuments } from "./toc";
import { useMemo } from "react";

export const useDocumentation = (list) => {
  const { hash } = useLocation();

  const [documents, updateDocumentsList] = useState([]);
  const [navigation, updateNavigation] = useState([]);

  useEffect(() => {
    if (Array.isArray(list) && list.length > 0) {
      fetchAllDocuments(list).then((documents) => {
        const navigation = documents.map(
          ({ navigation: [navigation] }) => navigation
        );
        updateDocumentsList(documents);
        updateNavigation(navigation);
      });
    }
  }, [list]);

  useEffect(() => {
    if (!documents) {
      return;
    }
    if (hash === "") {
      animateScrollTo(document.body, {});
    } else {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        animateScrollTo(element, { verticalOffset: -116 });
      }
    }
  }, [hash, documents]);

  const value = useMemo(
    () => ({ documents, navigation }),
    [documents, navigation]
  );

  return value;
};
