import { endpoints } from "../../constants";
import { customFetch } from "../../utils";
import { ProductTypesV2 } from "../../constants/productTypes";

export async function fetchCatalogs() {
  const res = await customFetch({
    url: endpoints.UTIL_CATALOGS,
  });

  return res[0];
}

export async function fetchCountries() {
  const res = await customFetch({
    url: endpoints.UTIL_COUNTRIES,
  });

  return res[0];
}

export async function fetchCarriers() {
  const res = await customFetch({
    url: endpoints.UTIL_CARRIERS,
  });

  return res[0];
}

export async function fetchVatPatterns() {
  const res = await customFetch({
    url: endpoints.UTIL_VAT_PATTERNS,
  });

  return res[0];
}

export async function fetchCatalogWeights() {
  const res = await customFetch({
    url: endpoints.UTIL_CATALOG_WEIGHTS,
  });

  return res[0];
}

export async function fetchUsStates() {
  const res = await customFetch({
    url: endpoints.UTIL_US_STATES,
  });

  return res[0];
}

export async function fetchImages({ items }) {
  const requests = [];
  const codes = [];
  const newImages = [];

  if (items) {
    items.forEach((el) => {
      requests.push(
        fetchImage({
          code: el.code,
          catalog: el.catalog,
        })
      );
      codes.push(el.code);
    });
  }

  return Promise.all(requests).then((data) => {
    data.forEach(([res, err], idx) => {
      newImages.push({
        data: res.image,
        code: codes[idx],
      });
    });

    return newImages;
  });
}

export function fetchImage({
  code,
  catalog,
}: {
  code: string;
  catalog: ProductTypesV2;
}) {
  return customFetch({
    url: `${endpoints.CATALOG_PRODUCTS}/${catalog}/code/${code}/image`,
  });
}
