import React, { useEffect, useState } from "react";

import addressTypes from "../../../../constants/addressTypes";

import {
  handleDelete,
  handleUpdate,
  handleAdd,
  fetchUserAddresses,
  filterAddressesByType,
  seedVat,
} from "./utils";
import AddressBook from "./AddressBook";
import {
  events,
  useAmplitudeState,
} from "../../../../context/amplitude-context";

const dialogTitles = {
  ADD_SHIPPING_ADDRESS: "Add Shipping Address",
  ADD_BILLING_ADDRESS: "Add Billing Address",
  EDIT_SHIPPING_ADDRESS: "Edit Shipping Address",
  EDIT_BILLING_ADDRESS: "Edit Billing Address",
};

const AddressBookContainer = () => {
  const { amplitude } = useAmplitudeState();
  const [addresses, setAddresses] = useState([]);
  const [isBillingFetching, setIsBillingFetching] = useState(true);
  const [isShippingFetching, setIsShippingFetching] = useState(true);
  const [isBillingAdding, setIsBillingAdding] = useState(false);
  const [isShippingAdding, setIsShippingAdding] = useState(false);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogFormValues, setDialogFormValues] = useState(null);
  const [dialogHandle, setDialogHandle] = useState(() => () => {});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDialogTitle, setDeleteDialogTitle] = useState("");
  const [idToDelete, setIdToDelete] = useState("");

  useEffect(() => {
    (async () => {
      const [fetchedAddresses] = await fetchUserAddresses();

      setAddresses(fetchedAddresses);

      setIsBillingFetching(false);
      setIsShippingFetching(false);
    })();
  }, []);

  useEffect(() => {
    setBillingAddresses(filterAddressesByType(addresses, addressTypes.BILLING));
    const filteredByType = filterAddressesByType(
      addresses,
      addressTypes.SHIPPING
    );
    const seededData = seedVat(filteredByType);
    setShippingAddresses(seededData);
  }, [addresses]);

  const logAddressAdd = (type) => {
    amplitude.logEvent(events.profile_address_add, { [type]: true });
  };

  const logAddressEdit = (type) => {
    amplitude.logEvent(events.profile_address_edit, { [type]: true });
  };

  const onHandleDelete = () => {
    handleDelete({
      id: idToDelete,
      addresses,
      setAddresses,
      logAddressEdit,
    });
  };

  const onHandleAddDialog = () => (data, formik) => {
    handleAdd(data, {
      setOpen: setShowDialog,
      setAddresses,
      setIsBillingAdding,
      setIsShippingAdding,
      formik,
      logAddressAdd,
    });
  };

  const onHandleUpdateDialog = () => (data, formik) => {
    handleUpdate(data, {
      setOpen: setShowDialog,
      setAddresses,
      setIsBillingAdding,
      setIsShippingAdding,
      formik,
      logAddressEdit,
    });
  };

  return (
    <AddressBook
      dialogTitles={dialogTitles}
      showDialog={showDialog}
      setShowDialog={setShowDialog}
      dialogTitle={dialogTitle}
      dialogFormValues={dialogFormValues}
      dialogMode={dialogMode}
      dialogHandle={dialogHandle}
      isBillingFetching={isBillingFetching}
      billingAddresses={billingAddresses}
      isShippingFetching={isShippingFetching}
      onHandleUpdateDialog={onHandleUpdateDialog}
      onHandleAddDialog={onHandleAddDialog}
      shippingAddresses={shippingAddresses}
      isBillingAdding={isBillingAdding}
      isShippingAdding={isShippingAdding}
      setDialogTitle={setDialogTitle}
      setDialogMode={setDialogMode}
      setDialogFormValues={setDialogFormValues}
      setDialogHandle={setDialogHandle}
      deleteDialog={{
        setOpen: setShowDeleteDialog,
        open: showDeleteDialog,
        idToDelete: idToDelete,
        setIdToDelete: setIdToDelete,
        title: deleteDialogTitle,
        setTitle: setDeleteDialogTitle,
        handleDelete: onHandleDelete,
      }}
    />
  );
};

export default AddressBookContainer;
