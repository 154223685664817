import { useEffect, useState } from "react";

const usePagination = ({ total, pageSize, curPage }) => {
  const [pagination, setPagination] = useState(null);

  useEffect(() => {
    const items = [];
    const divisionResult = total / pageSize;
    const rounded = Math.round(divisionResult);
    const pages = rounded;

    if (pages > 7) {
      if (curPage <= 3) {
        for (let i = 1; i <= pages; i++) {
          if (i <= 4) {
            items.push({
              id: i,
              title: i,
              active: curPage === i,
            });
          }

          if (pages >= 6) {
            if (i == 5) {
              items.push({
                id: i,
                title: "...",
                active: false,
              });
            }
          }

          if (i === pages) {
            items.push({
              id: i,
              title: i,
              active: false,
            });
          }
        }
      }

      if (curPage > 3 && curPage < pages - 2) {
        for (let i = 1; i <= pages; i++) {
          if (i === 1) {
            items.push({
              id: i,
              title: i,
              active: false,
            });
          }

          if (i === curPage - 2) {
            items.push({
              id: curPage - 2,
              title: "...",
              active: false,
            });
          }

          if (i === curPage - 1) {
            items.push({
              id: curPage - 1,
              title: curPage - 1,
              active: false,
            });
          }

          if (i === curPage) {
            items.push({
              id: curPage,
              title: curPage,
              active: true,
            });
          }

          if (i === curPage + 1) {
            items.push({
              id: curPage + 1,
              title: curPage + 1,
              active: false,
            });
          }

          if (i === curPage + 2) {
            items.push({
              id: curPage + 2,
              title: "...",
              active: false,
            });
          }

          if (i === pages) {
            items.push({
              id: i,
              title: i,
              active: false,
            });
          }
        }
      }

      if (curPage > pages - 3) {
        for (let i = 1; i <= pages; i++) {
          if (i === 1) {
            items.push({
              id: i,
              title: i,
              active: false,
            });
          }

          if (i === pages - 4 && pages > 5) {
            items.push({
              id: pages - 4,
              title: "...",
              active: false,
            });
          }

          if (i === pages - 3) {
            items.push({
              id: pages - 3,
              title: pages - 3,
              active: false,
            });
          }

          if (i === pages - 2) {
            items.push({
              id: pages - 2,
              title: pages - 2,
              active: curPage === pages - 2,
            });
          }

          if (i === pages - 1) {
            items.push({
              id: pages - 1,
              title: pages - 1,
              active: curPage === pages - 1,
            });
          }

          if (i === pages) {
            items.push({
              id: pages,
              title: pages,
              active: curPage === pages,
            });
          }
        }
      }
    } else {
      for (let i = 1; i <= pages; i++) {
        items.push({
          id: i,
          title: i,
          active: curPage === i,
        });
      }
    }

    if (divisionResult > pages) {
      const lastItemId = pages + 1;

      items.push({
        id: lastItemId,
        title: lastItemId,
        active: curPage === lastItemId,
      });
    }

    if (items?.length > 1) {
      setPagination(items);
    } else {
      // otherwise pagination isn't reset in case of
      // not found items
      setPagination(null);
    }
  }, [total, pageSize, curPage]);

  return [pagination];
};

export default usePagination;
