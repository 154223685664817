const getCatalogType = (phrase) => {
  const string = phrase.replace(/\\-/g, "");
  const match = string.match(/^(EN|BBV|BRC|FCH|Z|T|EBC)|[1-9]+$/g);

  if (match && match[0]) {
    switch (match[0]) {
      case "EN":
        return "BB";
      case "Z":
      case "T":
        return "SCR";
      case "BBV":
      case "FCH":
        return "MADE";
      case "EBC":
      case "BRC":
        return "EBC";
    }
  }

  return "REALDB";
};

export default getCatalogType;
