import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
`;

export const FilterInputContainer = styled.div`
  flex-basis: 45%;
`;
export const FilterInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const FilterDividerWrapper = styled.div`
  position: relative;
  top: 15px;
`;

export const FilterLabel = styled.div`
  font-size: 20px;
  align-self: center;

  max-width: calc(100% - 304px);
  width: 100%;
`;
