import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useQuery } from "hooks";
import { encode } from "utils";
import MiddlePage from "./MiddlePage";

const MiddlePageContainer = () => {
  const query = useQuery();
  const history = useHistory();
  const q = query.get("q");
  const drawSearch = query.get("drawSearch");
  const backLink = drawSearch === "true" ? `/draw-search?q=${encode(q)}` : "/";

  const handleEditSearchClick = () => {
    history.push(backLink);
  };
  return <MiddlePage handleEditSearchClick={handleEditSearchClick} q={q} />;
};

export default MiddlePageContainer;
