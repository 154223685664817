import { REQUEST_VERSION, SET_VERSION, SET_VERSION_READY } from "./types";

const requestVersion = (payload) => {
  return {
    type: REQUEST_VERSION,
    payload,
  };
};

const setVersion = (payload) => {
  return {
    type: SET_VERSION,
    payload,
  };
};

const setVersionReady = (payload) => {
  return {
    type: SET_VERSION_READY,
    payload,
  };
};

export default {
  requestVersion,
  setVersion,
  setVersionReady,
};
