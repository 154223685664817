import * as yup from "yup";
import validation from "../../../../constants/validation";

export const getValidationSchema = () => {
  const validationSchema = yup.object({
    email: validation.EMAIL,
    firstName: validation.FIRST_NAME,
    lastName: validation.LAST_NAME,
    company: validation.COMPANY,
    phone: validation.PHONE,
  });

  return validationSchema;
};
