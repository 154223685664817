import React from "react";
import { DownArrowIcon } from "../icons";
import { Dropdown, IconButton } from "../index";

const CurrencySelect = ({ currency, list, handleClickOption, disabled }) => {
  return (
    <>
      {currency ? (
        <Dropdown
          control={
            <IconButton
              icon={<DownArrowIcon />}
              iconSize="small"
              variant="outlined"
              textLeft={`${currency?.symbol} ${currency?.abbr}`}
              disabled={disabled}
            />
          }
          disabled={disabled}
          fullWidthDropdown
          variant="header"
          dataTestAttr="currency-dropdown"
        >
          {list &&
            currency &&
            list.map((el, index) => {
              return (
                <Dropdown.MenuItem
                  key={index}
                  onClick={() => handleClickOption(el.abbr)}
                  selected={currency.abbr === el.abbr}
                  variant="header"
                  dataTestAttr={`currency-dropdown-item-${el.abbr?.toLowerCase()}`}
                >
                  {el.symbol} {el.abbr}
                </Dropdown.MenuItem>
              );
            })}
        </Dropdown>
      ) : null}
    </>
  );
};

export default CurrencySelect;
