import classNames from "classnames";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Button, Modal } from "ui";
import { FileUploadIcon } from "ui/icons";
import { Wrapper } from "./UploadModal.styled";

type UploadModalProps = {
  open: boolean;
  setOpen: (state: boolean) => void;
  handleChange: (file: File) => Promise<void>;
};

const UploadModal = ({ open, setOpen, handleChange }: UploadModalProps) => {
  const [hover, setHover] = useState(false);
  const classes = classNames("upload-modal", {
    "upload-modal--hover": hover,
  });

  const onDraggingStateChange = (hover: boolean) => {
    if (hover) {
      setHover(true);
    } else {
      setHover(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      boxProps={{ width: "800px", maxWidth: "100%" }}
      title="Upload Tax Free Certificates"
      dataTestAttr="delete-modal"
    >
      <Wrapper className={classes}>
        <FileUploader
          handleChange={handleChange}
          name="file"
          classes="upload-modal__file-uploader"
          onDraggingStateChange={onDraggingStateChange}
        >
          <div className="upload-modal__file-uploader-wrapper">
            <div className="upload-modal__icon-wrapper">
              <FileUploadIcon />
            </div>
            <p className="upload-modal__uploader-title">
              Select a file or drag and drop here
            </p>
            <p className="upload-modal__uploader-subtitle">
              JPG, PNG or PDF, file size no more than 10MB
            </p>
            <div className="upload-modal__uploader-button-wrapper">
              <Button
                type="outlined"
                size="small"
                dataTestAttr="uploader-button"
              >
                Select file
              </Button>
            </div>
          </div>
        </FileUploader>
        <div className="upload-modal__button-wrapper">
          <Button
            onClick={() => setOpen(false)}
            size="small"
            type="outlined"
            dataTestAttr="cancel-button"
          >
            Cancel
          </Button>
        </div>
      </Wrapper>
    </Modal>
  );
};

export default UploadModal;
