import styled from "styled-components";
import { accentColor, borderColor, lightBlue, blue } from "../colors.js";

export const Wrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 8px;

  &.icon-button.is-disabled {
    cursor: default;
    pointer-events: none;
  }

  &.icon-button--size--medium {
    padding: 0 15px;
    min-height: 48px;
  }

  &.icon-button--variant--default {
    &:not(.is-disabled):hover {
      svg {
        path {
          fill: ${accentColor};
        }
      }

      .icon-button__text {
        color: ${accentColor};
      }
    }
  }

  &.icon-button--variant--transparent {
    &:hover {
      background-color: ${lightBlue};

      svg {
        path {
          fill: ${accentColor};
        }
      }
    }
  }

  &.icon-button--variant--lightBlue {
    background-color: ${lightBlue};
    border: 1px solid ${lightBlue};

    svg {
      path {
        fill: ${accentColor};
      }
    }

    &:hover {
      border-color: ${accentColor};
    }
  }

  &.icon-button--variant--outlined {
    background-color: transparent;
    border: 1px solid ${borderColor};

    &.is-disabled {
      border-color: #e9e9e9;

      .icon-button__text {
        color: #c3c3c3;
      }

      svg {
        path {
          fill: #c3c3c3;
        }
      }
    }

    .icon-button__text {
      color: ${blue};
    }

    svg {
      path {
        fill: ${blue};
      }
    }

    &:not(.is-disabled):hover {
      border-color: ${accentColor};

      svg {
        path {
          fill: ${accentColor};
        }
      }

      .icon-button__text {
        color: ${accentColor};
      }
    }
  }

  &.icon-button--icon-size--medium {
    svg {
      width: 26px;
    }
  }

  &.icon-button--icon-size--small {
    svg {
      width: 18px;
    }
  }

  svg {
    display: inline-block;
    height: auto;
  }

  .icon-button__text {
    white-space: nowrap;
    font-size: 20px;

    &--left {
      margin-right: 9px;
    }

    &--right {
      margin-left: 9px;
    }
  }
`;
