import React from "react";
import { DrawSearchIcon } from "ui/icons";
import { SearchInputWithHint } from "./SearchInputWithHint";
import { Wrapper, StructureSearchButton } from "./SearchForm.styled";

type SearchFormProps = {
  isLoading: boolean;
  control: any;
  onStructureSearch: () => void;
  submitHandler: () => void;
};

export const SearchForm = ({
  isLoading,
  control,
  onStructureSearch,
  submitHandler,
}: SearchFormProps) => {
  return (
    <Wrapper>
      <SearchInputWithHint
        isLoading={isLoading}
        control={control}
        onEnter={submitHandler}
      >
        <StructureSearchButton
          onClick={onStructureSearch}
          type="outlined-white"
          iconLeft={<DrawSearchIcon />}
        >
          Structure Search
        </StructureSearchButton>
      </SearchInputWithHint>
    </Wrapper>
  );
};
