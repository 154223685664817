import classNames from "classnames";
import { CartIcon } from "ui/icons";
import { Button } from "ui/index";
import { Wrapper } from "./products-loader.styles";
import Spinner from "ui/spinner/Spinner";

const ProductsLoader = () => {
  const classes = classNames("products-loader");

  return (
    <Wrapper className={classes}>
      {[0, 1, 2, 3].map((_, idx) => {
        return <ProductsLoaderCard key={idx} />;
      })}
    </Wrapper>
  );
};

export const ProductsLoaderCard = () => {
  return (
    <div className="products-loader__card">
      <div className="products-loader__header">
        <Spinner isLoading size={40} />
      </div>
      <div className="products-loader__content">
        <div className="products-loader__box products-loader__box--small"></div>
        <div className="products-loader__box products-loader__box--big"></div>
        <Button iconLeft={<CartIcon />} size="small" fullWidth disabled>
          Add to cart
        </Button>
      </div>
    </div>
  );
};

export default ProductsLoader;
