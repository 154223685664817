import styled from "styled-components";

export const SearchCategoriesTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const Wrapper = styled.div``;

export const Item = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;
