const selectCountries = (state) => state.util.countries;
const selectCatalogs = (state) => state.util.catalogs;
const selectImages = (state) => state.util.images;
const selectCarriers = (state) => state.util.carriers;
const selectCatalogWeights = (state) => state.util.catalogWeights;
const selectVatPatterns = (state) => state.util.vatPatterns;
const selectUsStates = (state) => state.util.usStates;

export default {
  selectCountries,
  selectCatalogs,
  selectImages,
  selectCatalogWeights,
  selectVatPatterns,
  selectUsStates,
  selectCarriers,
};
