import { endpoints } from "../../constants";
import customFetch from "../../utils/customFetch";
import { shipmentOptions } from "./parts/carrier/Carrier.container";

export const createCart = ({ cartItems, currency }) => {
  const products = generateProductsData(cartItems);

  return customFetch({
    url: endpoints.CART_NEW,
    method: "post",
    data: {
      currency,
      products,
    },
  });
};

export const generateProductsData = (cartItems) => {
  return cartItems.map((el) => {
    return {
      productType: el.item.productType,
      code: el.item.code,
      quantity: el.count,
      amount: el.amount,
      measure: el.measure,
    };
  });
};

export const prepareData = (data) => {
  const newData = {};
  newData.po = data?.additional?.poNumber;
  newData.comment = data?.additional?.comment;
  newData.paymentType = data.payment.type.toUpperCase();
  newData.carrier = data.carrier.title;
  newData.carrierId = data.carrierId || "";
  newData.currency = data.currency;

  if (
    !data.carrierId &&
    data?.carrierShipmentOption === shipmentOptions.PARTIAL
  ) {
    newData.partialShippingAllowed = true;
  } else {
    newData.partialShippingAllowed = false;
  }

  const person = {
    title: data.contactPerson.title,
    firstName: data.contactPerson.firstName,
    lastName: data.contactPerson.lastName,
    phone: data.contactPerson.phone,
    email: data.contactPerson.email,
  };

  newData.billingPerson = person;
  newData.shippingPerson = person;

  const shippingAddress = {
    company: data.shipping.company,
    country: data.shipping.country,
    city: data.shipping.city,
    addressLine1: data.shipping.addressLine1,
    zip: data.shipping.zip,
  };

  if (data.shipping.state) {
    shippingAddress.state = data.shipping.state;
  }

  newData.shippingAddress = shippingAddress;

  const billingAddress = {
    company: data.billing.company,
    country: data.billing.country,
    city: data.billing.city,
    addressLine1: data.billing.addressLine1,
    zip: data.billing.zip,
  };

  if (data.billing.state) {
    billingAddress.state = data.billing.state;
  }

  newData.billingAddress = billingAddress;

  return newData;
};

export const checkAllAccordionsClosed = (data) => {
  let closed = true;

  Object.keys(data).forEach((key) => {
    if (data[key].open || data[key].disabled) {
      closed = false;
    }
  });

  return closed;
};
