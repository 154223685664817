import { useState } from "react";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { customFetch, getArrayFromBulkSearch } from "../utils";
import { common } from "constants";

const useDownloadSdf = ({
  catalogItems,
  drawSearch,
  q,
  searchType,
  productType,
  sstype,
  logDownloadSdf,
  similarityThreshold,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const data = generateData({
    catalogItems,
    drawSearch,
    q,
    searchType,
    productType,
    sstype,
    similarityThreshold,
  });

  const handleDownload = () => {
    setLoading(true);
    setError(false);
    logDownloadSdf();

    customFetch({
      method: "post",
      url: "/catalog/getSDF",
      data,
      config: {
        responseType: "blob",
      },
    })
      .then((res) => {
        if (res[0]) {
          fileDownload(res[0], `result-search.sdf`);
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    isLoading,
    handleDownload,
    error,
  };
};

export default useDownloadSdf;

export const generateData = ({
  drawSearch,
  q,
  searchType,
  productType,
  sstype,
  similarityThreshold,
}) => {
  let newData = [];

  if (drawSearch) {
    newData.push({
      code: "",
      productType,
      smart: q,
      searchType,
      structureSearchType: sstype,
      similarityThreshold:
        sstype === "SIM"
          ? similarityThreshold
          : common.REALDB_SIMILARITY_THRESHOLD,
    });
  } else {
    const items = getArrayFromBulkSearch(q);

    newData = items.map((el) => {
      return {
        code: el,
        productType: null,
        smart: null,
        searchType: null,
        structureSearchType: null,
        similarityThreshold: null,
      };
    });
  }

  return newData;
};
