import { useState } from "react";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";

import { customFetch } from "../utils";

const useDownloadSdfFromCart = ({ items, isDefaultError = true }) => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const data = generateData({ items });

  const handleDownload = () => {
    setLoading(true);
    setError(false);

    customFetch({
      method: "post",
      url: "/catalog/getSDF",
      data,
      config: {
        responseType: "blob",
      },
    })
      .then((res) => {
        if (res[0]) {
          fileDownload(res[0], `cart-items.sdf`);
        } else {
          setError(true);
          if (isDefaultError) {
            toast.error("Some error");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    isLoading,
    handleDownload,
    error,
  };
};

export default useDownloadSdfFromCart;

export const generateData = ({ items }) => {
  let newData = [];

  newData = items.map((el) => {
    return {
      code: el.code,
      productType: el.productType,
    };
  });

  return newData;
};
