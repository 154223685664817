import * as yup from "yup";

import validation from "../../constants/validation";

export const getValidationSchema = (mode, { vatPattern } = {}) => {
  const initialObj = {
    phone: validation.PHONE,
    company: validation.COMPANY,
    country: validation.COUNTRY,
    addressLine1: validation.ADDRESS,
    city: validation.CITY,
    zip: validation.ZIP,
  };

  let validationSchema;

  if (mode === "vat") {
    initialObj.vat = yup
      .string("Enter vat")
      .matches(vatPattern, `Invalid VAT number.`)
      .required("Vat is required");
  }

  if (mode === "us") {
    initialObj.state = validation.STATE;
  }

  validationSchema = yup.object(initialObj);

  return validationSchema;
};

export const getVatPattern = ({ vatPatterns, country }) => {
  return vatPatterns?.[country];
};
