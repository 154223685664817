import PendingTimer from "./PendingTimer";

const PendingTimerContainer = ({ whenCanBePaid, callback }) => {
  const NOW_IN_MS = new Date(whenCanBePaid).getTime();

  const dateTimeAfterThreeDays = NOW_IN_MS;

  if (!whenCanBePaid) return null;

  return (
    <PendingTimer targetDate={dateTimeAfterThreeDays} callback={callback} />
  );
};

export default PendingTimerContainer;
