import { FullWidthLoader } from "parts";
import { useState } from "react";
import { useSelector } from "react-redux";
import { checkoutSelectors } from "redux/checkout";
import { currencySelectors } from "redux/currency";
import { Modal, Table, Title } from "ui";
import constants from "ui/constants";
import { customFetch } from "utils";

/*
List item example:
{
  catalogId: "EN300-123123",
  packing: "Reaction Vial, 2 Dram, 17x60mm, 15-425 Thread, with Red Pressure Relief Cap",
  amount: 5,
  location: "USA",
  deliveryTime: "1-3 days"
}
*/

const RushDeliveryModal = ({ open, setOpen }) => {
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const currency = useSelector(currencySelectors.selectCurrent);
  const [rushDeliveryList, setRushDeliveryList] = useState([]);
  const [standardDeliveryList, setStandardDeliveryList] = useState([]);
  const [loading, setLoading] = useState(true);

  const onModalOpen = async () => {
    setLoading(true);
    const shipping = checkoutData?.shipping;
    const data = prepareData({ shipping, currency });

    const res = await customFetch({
      method: "post",
      url: `cart/deliveryTerms`,
      data,
    });

    if (res[0]) {
      const transformed = transformData(res[0]);

      if (transformed?.rush?.length) {
        setRushDeliveryList(transformed.rush);
      }

      if (transformed?.standard?.length) {
        setStandardDeliveryList(transformed.standard);
      }
    }

    setLoading(false);
  };

  const getTable = (list) => {
    const tableElement = (
      <Table variant="headed-light-blue">
        <Table.Row>
          <Table.Cell>Catalog ID</Table.Cell>
          <Table.Cell>Packing</Table.Cell>
          <Table.Cell>Amount</Table.Cell>
          <Table.Cell>Location</Table.Cell>
          <Table.Cell>Delivery Time</Table.Cell>
        </Table.Row>
        {list.map((el, idx) => {
          return (
            <Table.Row key={idx}>
              <Table.Cell>{el.catalogId}</Table.Cell>
              <Table.Cell>{el.packing}</Table.Cell>
              <Table.Cell>{el.amount}</Table.Cell>
              <Table.Cell>{el.location}</Table.Cell>
              <Table.Cell>{el.deliveryTime}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table>
    );

    return tableElement;
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      width={constants.maxWidth - 128} // 128 - magic number. Container padding x * 2
      onTransitionEnter={onModalOpen}
    >
      {loading ? (
        <FullWidthLoader />
      ) : (
        <>
          {rushDeliveryList.length ? (
            <>
              <Title variant="h2">Available for Rush Delivery</Title>

              <div style={{ height: 28 }}></div>

              {getTable(rushDeliveryList)}
            </>
          ) : null}

          {standardDeliveryList.length ? (
            <>
              <div style={{ height: 28 }}></div>

              <Title variant="h2">Regular Delivery only</Title>

              <div style={{ height: 28 }}></div>

              {getTable(standardDeliveryList)}
            </>
          ) : null}
        </>
      )}
    </Modal>
  );
};

export default RushDeliveryModal;

export const prepareData = ({ shipping, currency }) => {
  const data = {
    currency: currency.abbr,
    shippingAddress: {
      id: shipping?.id,
      company: shipping?.company,
      country: shipping?.country,
      state: shipping?.state,
      city: shipping?.city,
      addressLine1: shipping?.addressLine1,
      addressLine2: shipping?.addressLine2,
      zip: shipping?.zip,
    },
  };

  return data;
};

export const transformData = (data) => {
  const rush = data?.rush.map((el) => {
    return extractDataFromElement(el);
  });

  const standard = data?.standard.map((el) => {
    return extractDataFromElement(el);
  });

  return { rush, standard };
};

export const extractDataFromElement = (el) => ({
  catalogId: el.code,
  packing: el.name,
  amount: el.amount,
  location: el.location || "-",
  deliveryTime: el.deliveryTermType?.terms,
});
