import { Title } from "ui";
import { Wrapper } from "./styles";
import ModifyCartSelect from "../modifyCartSelect";

const Header = ({ handleClearCart, downloadSdf, showSelect, handleGroupUpdate, totalItems }) => {
  return (
    <Wrapper>
      <Title variant="h1">Shopping cart</Title>

      {/* <div>
                {totalItems} items in your cart
            </div> */}

      {/* <div>
                <Link to="/catalog?q=%5B%236%5D-1-%5B%236%5D-%5B%236%5D-%5B%236%5D-%5B%236%5D-1&cat=BB&type=SMARTS&sstype=SUB&drawSearch=true">Popular products</Link>
                <br />
                <Link to="/catalog?q=EN300-57255%0AEN300-72902%0AEN300-70217%0AEN300-185319%0AEN300-67024%0AEN300-116382%0AEN300-19196">Hot proposal</Link>
                <br />
            </div> */}
      {showSelect ? (
        <ModifyCartSelect
          handleClearCart={handleClearCart}
          handleDownloadSdf={downloadSdf.handleDownload}
          isSdfLoading={downloadSdf.isLoading}
          handleGroupUpdate={handleGroupUpdate}
        />
      ) : null}
    </Wrapper>
  );
};

export default Header;
