import React from "react";
import { EmptyMessage, Pagination, Table } from "../../../../ui";
import { PaginationWrapper } from "./Orders.styles";
import { FullWidthLoader } from "../../../../parts";
import ListItem from "./parts/ListItem";

const Orders = ({ list, isLoading, pagination, onHandleClickPagination }) => {
  return (
    <>
      {isLoading ? (
        <FullWidthLoader />
      ) : (
        <>
          {!list.length ? (
            <EmptyMessage variant="solidWhite">
              You haven’t made any order yet.
            </EmptyMessage>
          ) : (
            <Table variant="paper">
              {list.map((item) => (
                <ListItem key={item.number} item={item} />
              ))}
            </Table>
          )}
          {pagination && (
            <PaginationWrapper>
              <Pagination
                pagination={pagination}
                onHandleClickPagination={onHandleClickPagination}
              />
            </PaginationWrapper>
          )}
        </>
      )}
    </>
  );
};

export default Orders;
