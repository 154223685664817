import styled from "styled-components";
import { lineColor } from "ui/colors";

export const Wrapper = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Group = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${lineColor};
  }
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  flex-grow: 1;
  margin-left: 16px;
`;

export const TextRow = styled.p`
  margin: 0;
  margin-bottom: 5px;
  font-size: 18px;
`;
