import productTypes from "./productTypes";

export const productDetails = {
  [productTypes.BB]:
    "This compound originates from our building block collection. Most likely it has been synthesized by Enamine and we can produce its analogues via the same synthesis route.\nPlease contact us to discuss possible options including synthesis of compound libraries using it.",
  [productTypes.SCR]:
    "This compound originates from our screening collection. It has been synthesized in-house in over 90% purity. Please contact us for analytical data, resynthesis, or synthesis of analogues.",
  [productTypes.EBC]:
    "This compound has been annotated with biological activity in our database. Compounds with similar action can be searched in our internal multi-parameter database and provided upon",
  [productTypes.REALDB]:
    "This make-on-demand compound has been enumerated based on highly validated reactions of the qualified building blocks. Most likely we will synthesize it within 3 weeks. Statistically meaningful REAL Compound selections are typically synthesized with over 80% success rate.",
  [productTypes.MADE]:
    "This make-on-demand compound can be synthesized using the same synthesis route as we used before for synthesis of its close analogues. Most likely we still have necessary starting compounds in our stock so that we can start immediately. All this makes synthesis in a short term realistic.",
};

export default productDetails;
