import convertToDdMmYy from "./convertToDdMmYy";
import convertToHrMin12h from "./convertToHrMin12h";

const convertToDateAndTime = (dateString) => {
  if (!dateString) return "-";

  const dateResult = convertToDdMmYy(dateString, ".");
  const timeResult = convertToHrMin12h(dateString);

  return `${dateResult} ${timeResult}`;
};

export default convertToDateAndTime;
