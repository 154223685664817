import classNames from "classnames";
import { Link } from "react-router-dom";
import { Wrapper } from "./icon-button.styles";

const IconButton = ({
  to,
  onClick,
  icon,
  variant = "default",
  size = "medium",
  iconSize = "medium",
  textLeft,
  textRight,
  disabled,
  dataTestAttr,
  ...rest
}) => {
  const classnames = classNames("icon-button", {
    [`icon-button--size--${size}`]: size,
    [`icon-button--variant--${variant}`]: variant,
    [`icon-button--icon-size--${iconSize}`]: iconSize,
    "is-disabled": disabled,
  });

  const commonContent = (
    <Wrapper
      className={classnames}
      onClick={onClick}
      data-test={to ? null : dataTestAttr}
      {...rest}
    >
      {textLeft ? (
        <span className="icon-button__text icon-button__text--left">
          {textLeft}
        </span>
      ) : null}
      {icon}
      {textRight ? (
        <span className="icon-button__text icon-button__text--right">
          {textRight}
        </span>
      ) : null}
    </Wrapper>
  );

  return (
    <>
      {to ? (
        <Link
          to={to}
          style={{
            pointerEvents: disabled ? "none" : "auto",
          }}
          data-test={dataTestAttr}
        >
          {commonContent}
        </Link>
      ) : (
        commonContent
      )}
    </>
  );
};

export default IconButton;
