import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { rootPages } from "../../constants";
import useLocalStorage from "../../hooks/useLocalStorage";
import { customFetch, sleep } from "../../utils";

import PaymentStatus from "./PaymentStatus";

const PaymentStatusContainer = () => {
  const history = useHistory();
  const [orderId, saveOrderIdToLocalStorage] = useLocalStorage("order-id");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  function doRequests(iterations = 1000, timeout = 1000) {
    return new Promise((resolve, reject) => {
      let prevResult = null;

      async function repeat(count) {
        if (!count) return reject(prevResult);

        const res = await customFetch({
          url: `orders/by/orderNumber/${orderId}/payments`,
        });

        prevResult = res;

        if (res[0]) {
          const summary = res[0].summary;
          const newTimeout = res[0]?.timeout ? res[0]?.timeout * 1000 : timeout;

          if (!summary.isPaidByCustomer) {
            await sleep(newTimeout);
            repeat.bind(null, count - 1)();
          } else if (summary.isPaidByCustomer) {
            return resolve(res);
          }
        } else {
          return reject(res);
        }
      }

      repeat(iterations);
    });
  }

  useEffect(() => {
    if (paymentId) {
      (async () => {
        doRequests()
          .then((data) => {
            setSuccess(true);
            saveOrderIdToLocalStorage("");
          })
          .catch((error) => {
            if (error?.type === "WARNING") {
              history.push(`/${rootPages.CART}`);
            } else {
              setError(JSON.stringify(error?.message));
              console.log("Error message in data:", error?.message);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      })();
    } else {
      setError("We cannot find any payment id");
      setLoading(false);
    }
  }, []);

  return <PaymentStatus loading={loading} error={error} success={success} />;
};

export default PaymentStatusContainer;
