import { createGlobalStyle } from "styled-components";
import { accentColor, darkBlue } from "./ui/colors";

const GlobalStyle = createGlobalStyle`
  * {
      box-sizing: border-box;
  }
    
    html {
    height: 100%;
    margin: 0;
  }

  body {
    padding-top: 88px;
    height: 100%;
    box-sizing: border-box;
    color: ${darkBlue};
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }

  #root {
    height: 100%;
  }

  .g-flex {
    display: flex;
  }

  .g-flex-vertical-center {
    align-items: center;
  }

  .g-flex-justify-between {
    justify-content: space-between;
  }

  .MuiTextField-root {
    .MuiInputBase-input {
      background-color: white;
      border-radius: inherit;
    }
  }

  .MuiSelect-select {
    background-color: white;
  }

  .content {

    &__ol-letters {
      & li {
        list-style-type: lower-alpha;
      }
    }

    table, th, td {
      border: 1px solid black;
    }

    table {
      border-collapse: collapse;
    }

    table p {
      line-height: 20px;
    }

    table {
      th, td {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    a {
      color: ${accentColor};
    }

    p {
      line-height: 24px;
    }
  }
`;

export default GlobalStyle;
