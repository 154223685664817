import React from "react";
import { LikeLinkButton } from "../../components";
import {
  Step,
  StepNumber,
  StepTitle,
  Header,
  Content,
  Wrapper,
} from "./accordion.styles";

const Accordion = ({ items, handleEdit, modeRegulator, ...rest }) => (
  <Wrapper>
    {items.map((item, idx) => {
      return (
        <Step
          key={idx}
          last={idx + 1 === items?.length}
          disabled={item.disabled}
          open={item.open}
        >
          <Header>
            <StepNumber
              open={item.open || !item.disabled}
              disabled={item.disabled}
            >
              {idx + 1}
            </StepNumber>
            <StepTitle>{item.title}</StepTitle>
            {item.open === false && item.disabled !== true && (
              <LikeLinkButton
                onClick={() => {
                  handleEdit(`step${idx + 1}`);
                }}
              >
                Edit
              </LikeLinkButton>
            )}
          </Header>

          <Content open={item.open}>
            {item.component &&
              item.component({ modeRegulator, index: idx, ...rest })}
          </Content>
        </Step>
      );
    })}
  </Wrapper>
);

export default Accordion;
