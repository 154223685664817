import classNames from "classnames";
import { Wrapper } from "./table.styled";

const Table = ({ children, variant }) => {
  const classnames = classNames("table", {
    [`table--variant--${variant}`]: variant,
  });

  return (
    <Wrapper className={classnames}>
      <table className="table__table">
        <tbody>{children}</tbody>
      </table>
    </Wrapper>
  );
};

Table.Row = ({ children, dataTestAttr, className }) => {
  const classes = classNames("table__row", {
    [className]: className,
  });

  return (
    <>
      <tr className={classes} data-test={dataTestAttr}>
        {children}
      </tr>
      <tr className="table__spacing-row">
        <td className="table__spacing-td"></td>
      </tr>
    </>
  );
};

Table.Cell = ({ children, title, minWidth, verticalCenter, noXPaddings }) => {
  const classes = classNames("table__td", {
    "table__td--minWidth": minWidth,
    "table__td--verticalCenter": verticalCenter,
    "table__td--noXPaddings": noXPaddings,
  });

  if (title) {
    return (
      <td className={classes}>
        <div>
          <Table.TdLabel>{title}</Table.TdLabel>
        </div>
        <div>{children}</div>
      </td>
    );
  }

  return (
    <td className={classes}>
      <div>{children}</div>
    </td>
  );
};

Table.TdLabel = ({ children }) => {
  return <div className="table__td-label">{children}</div>;
};

export default Table;
