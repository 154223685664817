import { Columns, Line, Wrapper } from './password-strength.styles';

export const strengthTypes = {
    WEAK: "WEAK",
    MEDIUM: "MEDIUM",
    STRONG: "STRONG",
}

export const strengthTypesTitles = {
    [strengthTypes.WEAK]: "Weak",
    [strengthTypes.MEDIUM]: "Medium",
    [strengthTypes.STRONG]: "Strong",
}

export const passwordStrengthModes = [
    "HIDDEN",
    strengthTypes.WEAK,
    strengthTypes.MEDIUM,
    strengthTypes.STRONG
];

const PasswordStrength = ({ mode = passwordStrengthModes[0]}) => {
    if (mode === passwordStrengthModes[0]) return null;

    return (
        <Wrapper>
            <Line mode={mode} />
            <Columns mode={mode}>
                <span>Password strength</span>
                <span>{strengthTypesTitles[mode]}</span>
            </Columns>
        </Wrapper>
    )
}

export default PasswordStrength;