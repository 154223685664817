import styled from "styled-components";

export const ButtonWrapper = styled.div`
  text-align: center;
  padding-top: 10px;
`;

export const Wrapper = styled.div`
  padding-top: 0;
  margin-top: -20px;
`;

export const ModalButton = styled.div`
  padding-top: 28px;
`;
