import endpoints from "../../../../constants/endpoints";
import { customFetch } from "../../../../utils";

export const fetchOrders = async (data) => {
  return await customFetch({
    url: `${endpoints.CUSTOMERS_ORDERS}?page=${data.curPage}&pageSize=${data.pageSize}`,
  });
};

export const getTagTypeBasedOnOrderStatus = (orderStatus) => {
  let tagType = "primary";

  if (orderStatus === "Shipped") {
    tagType = "success";
  } else if (orderStatus === "Cancelled") {
    tagType = "danger";
  }

  return tagType;
};
