import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 30px 0;
  margin: 0 auto;
  text-align: center;

  @keyframes slide {
    0%,
    100% {
      transform: rotate(90deg) translate(0, 0);
    }

    50% {
      transform: rotate(90deg) translate(10px, 0);
    }
  }

  .sliding-arrow {
    cursor: pointer;
    transform: rotate(90deg);
    height: 36px;
    width: 36px;
    animation: slide 1.25s ease-in-out infinite;
  }
`;
