import React from "react";
import { useEffect, useState } from "react";
import getCountriesOptions from "../../../utils/getCountriesOptions";
import { useSelector } from "react-redux";
import { Select } from "../../../ui";
import { utilSelectors } from "../../../redux/util";

const CountrySelect = ({
  formik,
  dataTestAttr = "country-select",
  ...rest
}) => {
  const [countryOptions, setCountryOptions] = useState([]);
  const countries = useSelector(utilSelectors.selectCountries);

  useEffect(() => {
    setCountryOptions(getCountriesOptions(countries));
  }, [countries]);

  return (
    <Select
      formik={formik}
      label="Country"
      name="country"
      options={countryOptions}
      dataTestAttr={dataTestAttr}
      placeholder="Select country"
      {...rest}
    />
  );
};

export default CountrySelect;
