// @ts-check

import React from "react";
// import { CartImage } from "./cart-image";
import {
  Wrapper,
  Title,
  Text,
  ButtonWrapper,
  ExternalLink,
  ImageWrapper
} from "./user-message.styles";

/**
 * @type { React.FC<{
 *   variant: "success" | "warning" | "empty"; children: import("react").ReactNode
 * }> & any }
 */
export const UserMessage = ({ children }) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
};

UserMessage.Title = Title;
UserMessage.Description = Text;
UserMessage.ActionWrapper = ButtonWrapper;
UserMessage.ExternalLink = ExternalLink;
UserMessage.ImageWrapper = ImageWrapper;
