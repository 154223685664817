import React from "react";
import { Button, Modal } from "../../../../../../ui";
import { ButtonWrapper } from "./AddressDialog.styles";
import Form from "./Form";

export default function FormDialog({
  open,
  setOpen,
  title,
  formik,
  vatPattern,
  showStateSelect,
}) {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      boxProps={{ width: "800px", maxWidth: "100%" }}
      title={title}
      dataTestAttr="edit-modal"
    >
      <Form
        formik={formik}
        vatPattern={vatPattern}
        showStateSelect={showStateSelect}
      />
      <ButtonWrapper>
        <Button
          disabled={formik.isSubmitting}
          onClick={formik.handleSubmit}
          size="small"
          fullWidth
          dataTestAttr="save-address-button"
        >
          Save
        </Button>
      </ButtonWrapper>
    </Modal>
  );
}
