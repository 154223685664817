import React from "react";
import { Input } from "../../../ui";

const CarrierId = ({ formik, ...props }) => {
  return (
    <Input label="Carrier ID" name="carrierId" formik={formik} {...props} />
  );
};

export default CarrierId;
