import React from "react";
import PropTypes from "prop-types";

import BoxLoader from "./parts/box-loader/BoxLoader";
import CircularLoader from "./parts/circular-loader/CircularLoader";

const Loader = ({ type }) => {
  let component;

  if (type === Loader.types.BOX) {
    component = <BoxLoader />;
  } else {
    component = <CircularLoader />;
  }

  return component;
};

Loader.propTypes = {
  type: PropTypes.oneOf(["BOX"]),
};

Loader.types = {
  BOX: "BOX",
};

export default Loader;
