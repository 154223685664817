import React from "react";
import { Link, Modal } from "ui";
import { lightBlue } from "ui/colors";
import { RocketIcon } from "ui/icons";
import { Text, Wrapper, Column } from "./styles";

const WelcomePopup = ({ open, setOpen, handleLinkClick, onClose }) => (
  <div>
    <Modal
      open={open}
      setOpen={setOpen}
      boxProps={{ width: "855px", backgroundColor: "grey" }}
      backdropProps={{ style: { pointerEvents: "none" } }}
      title="Welcome to New EnamineStore"
      styles={{ backgroundColor: lightBlue }}
      onClose={onClose}
    >
      <Wrapper>
        <Column withText>
          <Text marginBottom>
            We are moving forward with our new platform and will be happy to
            hear your thoughs on the new one. Old store will still be available
            via{" "}
            <Link to="/" onClick={handleLinkClick}>
              link
            </Link>
            , and we expect it to be turned off later this year.
          </Text>
          <Text>We’ll be glad to hear about your experience!</Text>
        </Column>
        <Column withImage>
          <RocketIcon />
        </Column>
      </Wrapper>
    </Modal>
  </div>
);

export default WelcomePopup;
