import styled from "styled-components";

export const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding: 0;
`;
