import React from "react";

import { Wrapper, IframeStyled } from "./MarvinFrame.styles";

const MarvinFrame = ({ url, width, height, onLoad }) => {
  return (
    <Wrapper>
      <IframeStyled
        src={url}
        width={width}
        height={height}
        data-toolbars="reaction"
        onLoad={onLoad}
      />
    </Wrapper>
  );
};

export default MarvinFrame;
