import { CustomizedTooltip, IconButton } from "ui";
import { InfoIcon } from "ui/icons";
import { Wrapper } from "./Components.styles";

const Components = () => {
  return (
    <Wrapper className="ui-components">
      <h2>Tooltips</h2>
      <h3>Default tooltip</h3>
      <CustomizedTooltip
        title="Discarding an order will cancel your credit card 
session and you'll be able to start a new one."
      >
        <IconButton icon={<InfoIcon />} variant="lightBlue" />
      </CustomizedTooltip>
    </Wrapper>
  );
};

export default Components;
