import { useState } from "react";
import fileDownload from "js-file-download";
import { events, useAmplitudeState } from "context/amplitude-context";
import { customFetch } from "utils";

const useDownloadSds = ({ code }) => {
  const { amplitude } = useAmplitudeState();
  const [isDownloadingSds, setDownloadingSds] = useState(false);
  const [showNotFoundModal, setShowNotFoundModal] = useState(false);

  const handleDownloadSds = () => {
    amplitude.logEvent(events.download_sds);

    setDownloadingSds(true);
    customFetch({
      method: "post",
      url: "/catalog/productcertificate",
      data: {
        code,
        lang: "en",
        type: "MSDS",
        dgBlock: false,
      },
      config: {
        responseType: "blob",
      },
    })
      .then((res) => {
        if (res[0]) {
          fileDownload(res[0], `${code.toLowerCase()}.pdf`);
        } else if (res[1] && res[2] === 404) {
          setShowNotFoundModal(true);
        }
      })
      .finally(() => {
        setDownloadingSds(false);
      });
  };

  return {
    handleDownloadSds,
    isLoadingSds: isDownloadingSds,
    notFoundModal: {
      open: showNotFoundModal,
      setOpen: setShowNotFoundModal,
    },
  };
};

export default useDownloadSds;
