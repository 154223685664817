import * as yup from "yup";
import endpoints from "../../constants/endpoints";
import validation from "../../constants/validation";
import customFetch from "../../utils/customFetch";
import handleError from "../../utils/handleError";
import sleep from "../../utils/sleep";
import { encodeToBase64 } from "utils/base64Encoding";

export const handleSubmit = async (
  data,
  { formik, setDisabled, setIsRegistered, logSignUpComplete }
) => {
  const { repeatPassword: _, ...preparedData } = data;

  setDisabled(true);

  const [signUpData, err] = await fetchSignUp(preparedData);

  setDisabled(false);

  if (handleError(err, formik)) return;
  if (signUpData.data !== "OK") {
    handleError("Do not registered user", formik);
    return;
  }

  await sleep(300);

  logSignUpComplete();

  setIsRegistered(true);
};

export const fetchSignUp = async (data) => {
  const preparedData = {
    ...data,
    email: encodeToBase64(data.email),
    password: encodeToBase64(data.password),
  };

  return await customFetch({
    method: "post",
    url: endpoints.AUTH_SIGNUP,
    data: preparedData,
  });
};

export const getValidationSchema = () => {
  const validationSchema = yup.object({
    email: validation.EMAIL,
    firstName: validation.FIRST_NAME,
    lastName: validation.LAST_NAME,
    company: validation.COMPANY,
    phone: validation.PHONE,
    password: yup
      .string("Enter your password")
      .max(40, "Password should be of maximum 40 characters length")
      .required("Password is required"),
    terms: yup.bool().oneOf([true], "Field must be checked"),
    repeatPassword: validation.REPEAT_PASSWORD,
  });

  return validationSchema;
};
