import React from "react";
import { Button } from "../index";
import { useHistory } from "react-router-dom";
import { CartMessage } from "ui/cart-message/cart-message";
import { ButtonWrapper } from "./empty-cart.styles";

const Header = () => {
  const history = useHistory();
  const handleSearchClick = () => {
    history.push("/");
  };

  return (
    <CartMessage variant="empty">
      <CartMessage.Title>Your cart is empty</CartMessage.Title>
      <CartMessage.Description>
        It seems you have not added anything to your cart yet. Explore our
        catalog below.
      </CartMessage.Description>
      <ButtonWrapper>
        <Button size="small" type="submit" onClick={handleSearchClick}>
          Search compounds
        </Button>
      </ButtonWrapper>
    </CartMessage>
  );
};

export default Header;
