import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 4px;
  padding-bottom: 36px;
`;

export const ButtonWrapper = styled.div`
  padding-top: 18px;
`;
