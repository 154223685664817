import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { utilSelectors } from "../../redux/util";
import { getValidationSchema, getVatPattern } from "./utils";

const initialValues = {
  country: "",
  state: "",
  city: "",
  zip: "",
  addressLine1: "",
  addressLine2: null,
  company: "",
  phone: "",
  vat: "",
};

const initialSchema = getValidationSchema();

const useAddressForm = (props) => {
  const [schema, setSchema] = useState(initialSchema);
  const [vatPattern, setVatPattern] = useState("");
  const [showStateSelect, setShowStateSelect] = useState(false);
  const vatPatterns = useSelector(utilSelectors.selectVatPatterns);

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      props?.handleSubmit(values, formik);
    },
  });

  useEffect(() => {
    const pattern = getVatPattern({
      vatPatterns,
      country: formik.values.country,
    });

    setVatPattern(pattern);

    if (pattern) {
      const newSchema = getValidationSchema("vat", { vatPattern: pattern });

      setSchema(newSchema);
    } else {
      if (formik.values.country === "US") {
        setShowStateSelect(true);
        const newSchema = getValidationSchema("us");

        setSchema(newSchema);
      } else {
        setShowStateSelect(false);
        setSchema(initialSchema);
      }
    }

    if (!pattern) {
      formik.setValues((prev) => {
        return {
          ...prev,
          vat: initialValues.vat,
        };
      }, false);
    }

    if (formik.values.country !== "US") {
      setShowStateSelect(false);

      formik.setValues((prev) => {
        return {
          ...prev,
          vat: !pattern ? "" : prev.vat,
          state: initialValues.state,
        };
      }, false);
    }
  }, [vatPatterns, formik.values.country, formik.values.state]);

  return {
    formik,
    vatPattern,
    showStateSelect,
    initialValues,
  };
};

export default useAddressForm;
