import styled from "styled-components";

export const PaginationWrapper = styled.div`
  padding-bottom: 30px;
  padding-top: 30px;
`;

export const NotFoundOrders = styled.p`
  text-align: center;
`;
