import React from "react";
import { Input } from "../../../ui";

const Email = ({ formik, dataTestAttr = "input-email", ...props }) => {
  return (
    <Input
      label="Email"
      name="email"
      formik={formik}
      data-test={dataTestAttr}
      placeholder="Enter email"
      {...props}
    />
  );
};

export default Email;
