import React from "react";
import { Wrapper } from "./section.styles";

const Section = ({ children, fullHeight, ySpacing, deleteXSpacing }) => {
  return (
    <Wrapper
      fullHeight={fullHeight}
      ySpacing={ySpacing}
      deleteXSpacing={deleteXSpacing}
    >
      {children}
    </Wrapper>
  );
};

export default Section;
