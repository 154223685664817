import styled from "styled-components";
import { accentColor } from "ui/colors";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Buttons = styled.div`
  display: flex;
  padding-top: 10px;
`;

export const ButtonWrapper = styled.div`
  padding: 0 6px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;

export const Popup = styled.div`
  position: absolute;
  top: 100%;
  z-index: 20;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  z-index: 101;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 18px;
`;

export const Line = styled.div`
  max-width: 40px;
  width: 100%;
  top: 100%;
  left: 50%;
  height: 2px;
  background-color: ${accentColor};
  position: absolute;
  transform: translateY(-2px) translateX(-50%);
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: ${(props) => (props.isOpen ? "flex" : "none")};
`;
