import React from "react";
import { Button } from "ui";
import common from "constants/common";
import { CartMessage } from "ui/cart-message/cart-message";
import { ButtonWrapper, ContactLink } from "./Error.styles";

const OrderWarning = ({ modeRegulator }) => {
  return (
    <CartMessage variant="warning">
      <CartMessage.Title>
        Sorry, there was an issue when placing your order
      </CartMessage.Title>
      <CartMessage.Description>
        Please try again in a few moments or{" "}
        <ContactLink href={common.CONTACTS_HREF} target="_blank">
          contact our team.
        </ContactLink>
      </CartMessage.Description>
      <ButtonWrapper>
        <Button
          onClick={() => {
            modeRegulator.setMode(modeRegulator.modes.CHECK_ORDER);
          }}
          size="small"
          fullWidth
        >
          Try again
        </Button>
      </ButtonWrapper>
    </CartMessage>
  );
};

export default OrderWarning;
