import { call, put, select } from "redux-saga/effects";
import { errors } from "../../constants";
import { errorActions } from "../error";
import { checkoutActions } from "./index";
import { fetchAvailablePaymentMethods, fetchCosts } from "./api";

export function* requestAdditionalCosts(payloadParam) {
  const payload = payloadParam.payload;
  try {
    yield put(errorActions.clearErrors());

    const state = yield select();
    const data = state.checkout.data;
    const currency = state.currency.current;

    yield put(checkoutActions.setAdditionalCosts(null));
    yield put(checkoutActions.setAdditionalCostsLoading(true));
    const res = yield call(() => fetchCosts(data, currency));

    if (res[1]) {
      if (res[2] == 400 && res[1]?.includes("zip")) {
        yield payload?.onError({
          type: errors.SHIPPING_ZIP_NOT_VALID_ERROR,
          data: res[1],
        });
      } else {
        yield payload?.onError({
          type: errors.COMMON_ERROR,
          data: res[1],
        });
      }
    } else {
      yield put(errorActions.clearErrors());
      yield put(checkoutActions.setAdditionalCostsError(""));
      yield put(checkoutActions.setAdditionalCosts(res[0]));
      yield payload?.onSuccess();
    }
  } catch (e) {
    yield put(
      checkoutActions.setAdditionalCostsError(
        "Error while loading additional costs data"
      )
    );
  } finally {
    yield put(checkoutActions.setAdditionalCostsLoading(false));
  }
}

export function* requestPaymentMethods() {
  try {
    const state = yield select();
    const currency = state.currency.current;

    yield put(checkoutActions.setPaymentMethodsLoading(true));
    const res = yield call(() => fetchAvailablePaymentMethods(currency));
    yield put(checkoutActions.setPaymentMethods(res[0]));
  } catch (e) {
    yield put(
      checkoutActions.setPaymentMethodsError(
        "Error while loading available payment methods"
      )
    );
  } finally {
    yield put(checkoutActions.setPaymentMethodsLoading(false));
  }
}
