import { productTypes } from "./index";

const defaultAmountsAndMeasures = {
  [productTypes.BB]: {
    amount: 1,
    measure: "g",
  },
  [productTypes.SCR]: {
    amount: 10,
    measure: "mg",
  },
  [productTypes.MADE]: {
    amount: 1,
    measure: "g",
  },
  [productTypes.REALDB]: {
    amount: 0.01,
    measure: "g",
  },
  [productTypes.EBC]: {
    amount: 0.01,
    measure: "g",
  },
};

export default defaultAmountsAndMeasures;
