import React from "react";
import { Textarea } from "../../../ui";

const Comment = ({ formik, ...props }) => {
  return (
    <Textarea
      label="Comment"
      name="comment"
      formik={formik}
      data-test="comment_input"
      placeholder="Enter comment"
      {...props}
    />
  );
};

export default Comment;
