import * as React from "react";
import Backdrop from "ui/Backdrop/Backdroup";
import Loader from "ui/loader/loader";
import { Wrapper } from "./fullscreen-loader.styles";

const FullscreenLoader = ({ ...props }) => {
  return (
    <Wrapper className="fullscreen-loader" {...props}>
      <Backdrop>
        <Loader />
      </Backdrop>
    </Wrapper>
  );
};

export default FullscreenLoader;
