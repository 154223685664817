import colors from "../../../../ui/colors";
import { Wrapper } from "./Colors.styles";

const Colors = () => {
  return (
    <Wrapper className="colors">
      <div className="colors__list">
        {Object.keys(colors).map((key, index) => {
          const value = colors[key];

          return (
            <div className="colors__card" key={index}>
              <h3 className="colors__card-title">{key}</h3>
              <div className="colors__card-body" key={index}>
                <div
                  className="colors__card-color-box"
                  style={{ background: value }}
                ></div>
                <span className="colors__card-color-text">{value}</span>
              </div>
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Colors;
