import React from "react";
import { LabledTabs } from "components";
import { CarrierId } from "parts/form-inputs";
import { Button, Message, RadioCard } from "ui/index";
import {
  Wrapper,
  InputWrapper,
  ButtonWrapper,
  Title,
  RadioCardText,
  OptionsGrid,
  MessageWrapper,
  TextAsLink,
} from "./Carrier.styles";
import RushDeliveryModal from "../rush-delivery-modal/RushDeliveryModal";

const Carrier = ({
  items,
  selectedId,
  setSelectedId,
  handleClick,
  formik,
  isLoading,
  shipmentOptions,
  handleSelectShipmentOption,
  shipmentOption,
  deliveryOptions,
  deliveryOption,
  handleSelectDeliveryOption,
  deliveryModalOpen,
  setDeliveryModalOpen,
}) => {
  return (
    <Wrapper disable={isLoading}>
      <Title>Carrier</Title>
      <LabledTabs
        items={items}
        activeId={selectedId}
        setActiveId={setSelectedId}
      />
      {selectedId !== 0 ? (
        <InputWrapper>
          <CarrierId formik={formik} placeholder="Please enter carrier ID" />
        </InputWrapper>
      ) : (
        <>
          <Title>Delivery Options</Title>
          <OptionsGrid>
            <RadioCard
              handleClick={() => {
                handleSelectDeliveryOption(deliveryOptions.STANDARD);
              }}
              active={deliveryOption === deliveryOptions.STANDARD}
            >
              <RadioCardText>Standard Delivery (2-14 days)</RadioCardText>
            </RadioCard>
            <RadioCard
              handleClick={() => {
                handleSelectDeliveryOption(deliveryOptions.RUSH);
              }}
              active={deliveryOption === deliveryOptions.RUSH}
            >
              <RadioCardText>Rush Delivery (1-3 days)</RadioCardText>
            </RadioCard>
          </OptionsGrid>
          {deliveryOption === deliveryOptions.RUSH ? (
            <>
              <MessageWrapper>
                <Message>
                  <TextAsLink onClick={() => setDeliveryModalOpen(true)}>
                    Items available for Rush Delivery.
                  </TextAsLink>{" "}
                  (May involve additional charges)
                </Message>
              </MessageWrapper>
              <RushDeliveryModal
                open={deliveryModalOpen}
                setOpen={setDeliveryModalOpen}
              />
            </>
          ) : null}

          <Title>Shipment Options</Title>
          <OptionsGrid>
            <RadioCard
              handleClick={() => {
                handleSelectShipmentOption(shipmentOptions.STANDARD);
              }}
              active={shipmentOption === shipmentOptions.STANDARD}
            >
              <RadioCardText>Standard Shipment</RadioCardText>
            </RadioCard>
            <RadioCard
              handleClick={() => {
                handleSelectShipmentOption(shipmentOptions.PARTIAL);
              }}
              active={shipmentOption === shipmentOptions.PARTIAL}
            >
              <RadioCardText>Partial Shipment</RadioCardText>
            </RadioCard>
          </OptionsGrid>
          {shipmentOption === shipmentOptions.PARTIAL ? (
            <MessageWrapper>
              <Message>
                Your order may be fillfiled in more than one delivery to
                facilitate shipping.
              </Message>
            </MessageWrapper>
          ) : null}
        </>
      )}
      <ButtonWrapper>
        <Button onClick={handleClick} size="small" fullWidth>
          Next
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Carrier;
