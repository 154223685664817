import { SET_LOADERS_FULL_PAGE } from "./types";
import { PayloadAction } from "@reduxjs/toolkit";

export type LoadersState = {
  fullPage: boolean;
};

const initialState: LoadersState = {
  fullPage: false,
};

const loadersReducer = (
  state = initialState,
  action: PayloadAction<boolean>
) => {
  switch (action.type) {
    case SET_LOADERS_FULL_PAGE:
      return {
        ...state,
        fullPage: action.payload,
      };
    default:
      return state;
  }
};

export default loadersReducer;
