const handleError = (err, formik) => {
  if (err) {
    if (err?.status === 401) {
      formik?.setErrors({ common: err?.statusText });
    } else if (typeof err === "string") {
      formik?.setErrors({ common: err });
    } else {
      formik?.setErrors({ common: "Some unhandled error" });
    }

    formik?.setSubmitting(false);

    return true;
  }

  return false;
};

export default handleError;
