import * as yup from "yup";
import { endpoints, rootPages, validation } from "constants/index";
import { customFetch } from "utils";

export const generateInvoicesItemsForTable = (invoices) => {
  const items = [];

  invoices?.failed?.forEach((el) => {
    items.push({
      title: el.name,
      text: el.failure,
      failed: true,
    });
  });

  invoices?.success?.forEach((el) => {
    items.push({
      title: el,
      text: "Found",
      failed: false,
    });
  });

  return items;
};

export const getValidationSchema = () => {
  const validationSchema = yup.object({
    names: validation.INVOICE_NUMBERS,
    sum: validation.INVOICE_TOTAL_SUM,
  });

  return validationSchema;
};

const postPayment = async (data) => {
  return await customFetch({
    method: "post",
    url: `${endpoints.CUSTOMERS_INVOICES_PAYMENTS}`,
    data,
  });
};

export const redirectToPaymentForm = ({ paymentToken, history }) => {
  localStorage.setItem("payment-token", paymentToken);
  history.push(`/${rootPages.SUBMIT_PAYMENT_FORM}`);
};

export const handleSubmit = async (
  data,
  {
    history,
    formik,
    setError,
    setInvoicesTotalSum,
    setInvoicesForPopup,
    setFailedInvoicesPopupOpen,
    currency,
    setInvoicesToPayCount,
    setPaymentToken,
  }
) => {
  const res = await postPayment(data);

  if (res[2] === 200) {
    const paymentToken = res[0]?.paymentToken;

    if (res[0]?.invoices?.failed?.length) {
      const totalSum = `${data.sum} ${currency}`;
      const items = generateInvoicesItemsForTable(res[0]?.invoices);
      const successInvoicesCount = res[0]?.invoices?.success?.length;
      setInvoicesTotalSum(totalSum);
      setInvoicesForPopup(items);
      setPaymentToken(paymentToken);
      setInvoicesToPayCount(successInvoicesCount);
      setFailedInvoicesPopupOpen(true);
    } else {
      redirectToPaymentForm({ paymentToken, history });
    }
  } else if (
    res[2] === 400 &&
    res[1].indexOf("no successfully processed invoices!")
  ) {
    formik.setErrors({ names: "Invoice(s) not found" });
  } else {
    setError(
      "Something went wrong. There was an issue when paying your invoice(s). Please try again"
    );
  }

  formik.setSubmitting(false);
};
