const makeParams = (params) => {
  const parts = [];

  Object.keys(params).forEach((key) => {
    if (params[key] || params[key] === 0) {
      parts.push(`${key}=${params[key]}`);
    }
  });

  return parts.join("&");
};

export default makeParams;
