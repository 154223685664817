import styled from "styled-components";

import { Button as ButtonComponent } from "../../../../ui";
import { accentColor, darkBlue, lineColor } from "ui/colors";

export const Content = styled.div`
  padding-right: 20px;
  padding-left: 25px;
`;

export const Wrapper = styled.div`
  background: #ffffff;
  border-radius: 16px;
  min-width: 360px;
`;

export const Scrollable = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${lineColor};

  ${(props) => {
    let styles = "";

    if (props.mt) {
      styles += `
        margin-top: ${props.mt}px;
      `;
    }

    if (props.mb) {
      styles += `
        margin-bottom: ${props.mb}px;
      `;
    }

    return styles;
  }}
`;

export const Button = styled(ButtonComponent)`
  width: 100%;
  margin-top: 20px;
`;

export const Footer = styled.div`
  padding: 24px;
`;

export const List = styled.div``;

export const ListItem = styled.div`
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${lineColor};
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: ${darkBlue};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  padding-top: 24px;
  padding-left: 22px;
  padding-right: 22px;
`;

export const FooterInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 10px;
  font-size: 20px;
  line-height: 23px;
  font-weight: normal;

  ${({ bold }) => {
    if (bold) {
      return `
        font-weight: 600;
      `;
    }
  }}
`;

export const InfoTitle = styled.span``;

export const InfoValue = styled.span`
  padding-left: 20px;
`;

export const InfoCell = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    path {
      fill: ${darkBlue};
    }

    &:hover {
      path {
        fill: ${accentColor};
      }
    }
  }
`;
