import React from "react";
import { SearchFormContainer } from "../search-form/SearchForm.container";
import { LogoWrapper, Wrapper } from "./HeroSearch.styled";
import { LogoIcon } from "ui/icons";

export const HeroSearch = () => (
  <Wrapper>
    <LogoWrapper>
      <LogoIcon />
    </LogoWrapper>
    <SearchFormContainer />
  </Wrapper>
);
