export const productTypes = {
  BB: "BB",
  SCR: "SCR",
  EBC: "EBC",
  REALDB: "REALDB",
  MADE: "MADE",
};

export enum ProductTypesV2 {
  BB = "building-blocks",
  SCR = "screening-compounds",
  MADE = "made-building-blocks",
  REALDB = "real",
  EBC = "bioactive-compounds",
}

export enum NormalizedCatalogName {
  "building-blocks" = "Building Block",
  "screening-compounds" = "Screening Compound",
  "made-building-blocks" = "MADE Building Block",
  "real" = "REAL Database",
  "bioactive-compounds" = "Bioactive Compound",
}

export default productTypes;
