import { ProductTypesV2 } from "constants/productTypes";

// @ts-check
const getButtonLabel = ({ catalog, currentPrice, count = 1 }) => {
  if (catalog === ProductTypesV2.MADE || catalog === ProductTypesV2.REALDB) {
    return "Add to cart";
  }

  const isInStock = currentPrice?.weight?.available === "IN_STOCK";

  return isInStock ? "Add to cart" : "Synthesis";
};

export default getButtonLabel;
