import classNames from "classnames";
import IconButton from "../icon-button/icon-button.js";
import { CartIcon } from "../icons.js";
import { Wrapper, Count } from "./cart-button.styles";

const CartButton = ({ to, count = 0, ...rest }) => {
  const classnames = classNames("cart-button");

  return (
    <Wrapper className={classnames} {...rest}>
      <IconButton icon={<CartIcon />} variant="lightBlue" to={to} />
      <Count data-test="cart-counter">{count}</Count>
    </Wrapper>
  );
};

export default CartButton;
