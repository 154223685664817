import styled from "styled-components";

export const ButtonWrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 40px;
`;

export const ErrorWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 10px;
`;

export const CheckboxWrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const CheckboxGroup = styled.div`
  padding-top: 16px;
  padding-bottom: 8px;
`;
