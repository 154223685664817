// @ts-check

import styled from "styled-components";
import { accentColor } from "ui/colors";

export const LearnMoreLinkStyled = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  color: ${accentColor};
  text-decoration: none;
`;
