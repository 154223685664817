import styled from "styled-components";
import { Link } from "../../ui";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkWrapper = styled.div`
  padding-left: 40px;
`;

export const LinkStyled = styled(Link)`
  white-space: nowrap;
  color: white;
  font-size: 12px;
`;
