import React from "react";
import { useController } from "react-hook-form";
import { SearchButton, SearchTextField } from "./SearchForm.styled";
import { SearchIcon } from "../../../../ui/icons";
import { TextFieldProps } from "@mui/material/TextField/TextField";

type SearchInputProps = {
  control: any;
  autoFocus: boolean;
  onEnter: () => void;
  isLoading: boolean;
} & TextFieldProps;

export const SearchInput = ({
  control,
  autoFocus = false,
  onEnter,
  isLoading,
  ...rest
}: SearchInputProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name: "search",
    rules: { required: true, message: "Search string is required" },
  });

  return (
    <SearchTextField
      {...rest}
      variant="outlined"
      fullWidth
      autoFocus={autoFocus}
      label="Enter Enamine ID, CAS, MFCD, SMILES or Name of the Product"
      value={field.value.replace(/(\r\n|\n|\r)/gm, " ")}
      onBlur={(e) => {
        field.onBlur(e);
        rest?.onBlur(e);
      }}
      onFocus={rest?.onFocus}
      onChange={field.onChange}
      inputRef={field.ref}
      error={!!error}
      helperText={error ? error.message : null}
      data-test="search_input"
      multiline
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          e.preventDefault();
          onEnter(e);
        }
      }}
      inputProps={{
        style: {
          padding: "10px 10px 10px 0px",
        },
      }}
      InputProps={{
        endAdornment: (
          <SearchButton
            disabled={isLoading}
            iconLeft={<SearchIcon />}
            type="submit"
            aria-label="search"
            data-test="search_submit_button"
          />
        ),
        style: {
          minHeight: "56px",
          padding: "0 4px 0 16px",
        },
      }}
    />
  );
};
