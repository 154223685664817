import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 100px;
`;

export const Spacer = styled.div`
  height: 28px;
`;
