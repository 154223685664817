import React, { useEffect, useState } from "react";

import ProfileForm from "./parts/profile-form/ProfileForm";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "../../../../ui";
import { FullWidthLoader } from "../../../../parts";
import { userActions, userSelectors } from "../../../../redux/user";

export default function Main() {
  const dispatch = useDispatch();
  const profile = useSelector(userSelectors.selectProfile);
  const profileLoading = useSelector(userSelectors.selectProfileLoading);
  const profileError = useSelector(userSelectors.selectProfileError);
  const dispatchSetProfile = (value) => dispatch(userActions.setProfile(value));
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (profile) {
      setInit(true);
    }
  }, [profile]);

  useEffect(() => {
    dispatch(userActions.requestProfile());
  }, []);

  return (
    <>
      {profileError ? (
        <>Some error</>
      ) : (
        <Paper>
          {profileLoading || !init || !profile ? (
            <FullWidthLoader />
          ) : (
            <ProfileForm
              profile={profile}
              dispatchSetProfile={dispatchSetProfile}
            />
          )}
        </Paper>
      )}
    </>
  );
}
