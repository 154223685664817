import { ToastNoCartModify } from "parts";
import { toast } from "react-toastify";

const toastNoCartModify = () => {
  toast(<ToastNoCartModify />, {
    closeOnClick: true,
  });
};

export default toastNoCartModify;
