import React from "react";
import { Button, Modal } from "../../../../ui";
import { Inner, Buttons, ButtonWrapper } from "./PostalCodeIssueModal.styles";

const PostalCodeIssueModal = ({ modal, handleClickChangeAddress }) => {
  return (
    <Modal
      open={modal.open}
      setOpen={modal.setOpen}
      title={modal.title}
      width="800px"
    >
      <Inner>
        {modal.message}
        <Buttons>
          <ButtonWrapper>
            <Button size="small" onClick={handleClickChangeAddress}>
              Change address
            </Button>
          </ButtonWrapper>
        </Buttons>
      </Inner>
    </Modal>
  );
};

export default PostalCodeIssueModal;
