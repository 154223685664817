import React from "react";
import { TextField } from "@mui/material";
import { TextFieldProps } from "@mui/material/TextField/TextField";

type InputUpdatedProps = NonNullable<unknown> & TextFieldProps;

const InputUpdated = ({ ...rest }: InputUpdatedProps) => {
  return <TextField variant="outlined" {...rest} />;
};

export default InputUpdated;
