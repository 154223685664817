import { SET_CURRENT, SET_DISABLE_SELECT } from "./types";

const initialState = {
  current: null,
  disableSelect: false,
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload,
      };
    case SET_DISABLE_SELECT:
      return {
        ...state,
        disableSelect: action.payload,
      };
    default:
      return state;
  }
};

export default currencyReducer;
