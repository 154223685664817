// @ts-check
import styled from "styled-components";
import {
  darkBlue,
  borderColor,
  accentColor,
  dangerColor,
  lightBlue,
} from "ui/colors";

export const Separator = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  color: ${darkBlue};
  font-size: 20px;
  font-weight: 400;
  align-items: center;
  margin-top: 38px;
  margin-bottom: 48px;

  &::before,
  &:after {
    content: "";
    border-bottom: solid 1px ${borderColor};
    flex: 1 1 auto;
  }
`;

export const Text = styled.span`
  text-transform: capitalize;
  flex: 0 0 auto;
  padding: 0 20px;
`;

export const InputWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  padding-top: 50px;
  display: flex;
  gap: 12px;
    //height: 56px;

  .MuiFormLabel-root {
    color: ${borderColor};

    &.Mui-error {
      color: ${dangerColor};
    }

    &.MuiInputLabel-shrink {
      color: ${darkBlue};
    }
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${accentColor};
      }
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: ${borderColor};
    }

    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${accentColor};
        border-width: 1px;
      }
    }
    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${dangerColor};
        border-width: 1px;
      }
    }
  }
`;

export const SearchHint = styled.div`
  padding: 16px;
  background-color: ${lightBlue};
  border-radius: 8px;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${accentColor};
`;

export const HintWrapper = styled.div`
  padding-top: 12px;

  .search-hint-enter {
    opacity: 0;
  }

  .search-hint-enter-active {
    opacity: 1;
    transition: opacity 600ms;
  }

  .search-hint-exit {
    opacity: 0;
  }
  .search-hint-exit-active {
    opacity: 0;
    transition: opacity 600ms;
  }
`;
