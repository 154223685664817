import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCurrentPrice } from "../../hooks";
import ProductCard from "./ProductCard";
import { userSelectors } from "../../redux/user";
import { getButtonLabel, makeParams, toastNoCartModify } from "../../utils";
import { events, useAmplitudeState } from "../../context/amplitude-context";
import { currencySelectors } from "redux/currency";
import superCartActions from "features/super-cart/redux/actions";
import { prepareItemToAdd } from "features/super-cart/redux/utils";
import { GetImageParams, ProductDTO } from "../../typings/DTO/data-contracts";
import convertCatalogToCat from "../../utils/convetCatalogToCat";

type UseProductCardPropsProps = {
  item: ProductDTO;
  image: GetImageParams;
  images: GetImageParams[];
  isRelated: boolean;
  proposal: any;
};
export const useProductCardProps = ({
  item,
  image,
  images,
  isRelated,
  proposal,
}: UseProductCardPropsProps) => {
  const { product, prices } = item;

  const { amplitude } = useAmplitudeState();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const dispatch = useDispatch();

  const [isAddingToCart, setAddingToCart] = useState(false);

  const productImage =
    image || images?.find((el) => el && el.code === product.code);

  const currency = useSelector(currencySelectors.selectCurrent);

  const [currentPrice, setCurrentPrice] = useCurrentPrice({
    prices: prices,
  });

  const readyToCart = useMemo(() => !!currentPrice, [currentPrice]);

  const buttonLabel = useMemo(
    () =>
      getButtonLabel({
        catalog: product?.catalog,
        currentPrice,
      }),
    [currentPrice, product?.catalog]
  );

  const queryParams = makeParams({
    cat: convertCatalogToCat(product?.catalog),
  });
  const href = `/catalog/${product?.code}?${queryParams}`;

  const handleAdd = async () => {
    const itemToAdd = prepareItemToAdd({
      item,
      currentPrice,
      quantity: 1,
    });

    amplitude.logEvent(events.catalog_item_add, {
      itemtype: convertCatalogToCat(itemToAdd?.catalog),
      amount: 1,
      related: isRelated,
      Proposal: proposal,
    });

    if (isAuth) {
      saveOnServer(itemToAdd);
    } else {
      saveOnLocalStorage(itemToAdd);
    }
  };

  const saveOnServer = async (item) => {
    setAddingToCart(true);

    dispatch(
      superCartActions.postCartContent({
        items: [item],
        currency,
        cb: (meta) => {
          if (meta) {
            if (meta?.[0]) {
              toast("Product was added to cart!");
              setAddingToCart(false);
            } else if (meta?.[2] === 400) {
              toastNoCartModify();
            } else {
              toast.error("Issue with adding product to cart");
              setAddingToCart(false);
            }
          }
        },
      })
    );
  };

  const saveOnLocalStorage = (item) => {
    dispatch(superCartActions.addItem(item));
    toast("Product was added to cart!");
  };

  const handleClick = () => {
    if (isRelated) {
      amplitude.logEvent(events.related_click);
    }

    if (proposal) {
      amplitude.logEvent(events.subscribe_click, {
        itemtype: convertCatalogToCat(product?.catalog),
        Proposal: proposal,
      });
    }
  };

  return {
    buttonLabel,
    productImage,
    currentPrice,
    setCurrentPrice,
    currency,
    readyToCart,
    href,
    handleClick,
    handleAdd,
    isAddingToCart,
  };
};

type ProductCardContainerProps = {
  item: ProductDTO;
  image: GetImageParams;
  images?: GetImageParams[];
  isRelated?: boolean;
  proposal: any;
  listMode?: any;
};

const ProductCardContainer = ({
  item = {},
  images,
  image,
  listMode,
  isRelated,
  proposal,
}: ProductCardContainerProps) => {
  const {
    buttonLabel,
    productImage,
    currency,
    currentPrice,
    setCurrentPrice,
    readyToCart,
    href,
    handleClick,
    handleAdd,
    isAddingToCart,
  } = useProductCardProps({
    item,
    image,
    images,
    isRelated,
    proposal,
  });

  return (
    <ProductCard
      item={item}
      image={productImage}
      handleClick={handleClick}
      code={item?.product?.code || ""}
      buttonLabel={buttonLabel}
      currentPrice={currentPrice}
      currency={currency}
      setCurrentPrice={setCurrentPrice}
      handleAdd={handleAdd}
      readyToCart={readyToCart}
      isAddingToCart={isAddingToCart}
      listMode={listMode}
      href={href}
    />
  );
};

export default ProductCardContainer;
