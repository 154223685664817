import {
  ADD_CATALOG_ITEMS,
  GET_PRODUCT_LIST,
  REQUEST_PRODUCTS_WITH_PRICES,
  SET_CATALOG_FILTERS,
  SET_LOADING_ITEMS,
  SET_PAGE_SIZE,
  SET_PRODUCT_LIST,
  SET_VIEW_MODE,
} from "./types";

const addItems = (payload) => {
  return {
    type: ADD_CATALOG_ITEMS,
    payload,
  };
};

const setLoadingItems = (payload) => {
  return {
    type: SET_LOADING_ITEMS,
    payload,
  };
};

const setPageSize = (payload) => {
  return {
    type: SET_PAGE_SIZE,
    payload,
  };
};

const setViewMode = (payload) => {
  return {
    type: SET_VIEW_MODE,
    payload,
  };
};

const requestProductsWithPrices = (payload) => {
  return {
    type: REQUEST_PRODUCTS_WITH_PRICES,
    payload,
  };
};

const getProductList = (payload) => {
  return {
    type: GET_PRODUCT_LIST,
    payload,
  };
};

const setProductList = (payload) => {
  return {
    type: SET_PRODUCT_LIST,
    payload,
  };
};

const setCatalogFilters = (payload) => {
  return {
    type: SET_CATALOG_FILTERS,
    payload,
  };
};

export default {
  addItems,
  requestProductsWithPrices,
  setLoadingItems,
  setPageSize,
  setViewMode,
  getProductList,
  setProductList,
  setCatalogFilters,
};
