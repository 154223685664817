import { SET_LOADERS_FULL_PAGE } from "./types";

const setLoaderFullPage = (isShown: boolean) => {
  return {
    type: SET_LOADERS_FULL_PAGE,
    payload: isShown,
  };
};

export default {
  setLoaderFullPage,
};
