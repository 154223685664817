import styled from "styled-components";
import { accentColor, blue, lineColor } from "../colors";
import constants from "../constants";

export const Wrapper = styled.div`
  color: ${blue};

  .footer__social {
    display: flex;
    gap: 34px;
  }

  .footer__social-button {
    display: inline-flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &:hover {
      svg path {
        fill: ${accentColor};
      }
    }
  }

  .footer__link {
    text-decoration: none;
    color: ${blue};
    font-size: 18px;
    font-weight: 500;

    &--pseudo {
      cursor: pointer;
      display: inline;
    }

    &--small {
      font-size: 16px;
    }

    &:not(:last-child) {
      margin-right: 50px;
    }

    &:hover {
      color: ${accentColor};
    }
  }

  .footer__logo {
    display: inline-flex;
    margin-right: 20px;
  }

  .footer__row--top {
    border-bottom: 1px solid ${lineColor};
    padding-bottom: 27px;
    padding-top: 60px;
  }

  .footer__row--bottom {
    padding-top: 35px;
    padding-bottom: 50px;
  }

  .footer__row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${constants.maxWidth}px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: border-box;
  }

  .footer__copyright {
    font-weight: 500;
  }
`;
