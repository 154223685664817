const convertToDdMmYy = (dateString, delimiter = "/") => {
  const date = new Date(dateString);

  if (date == "Invalid Date") {
    return "--";
  }

  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;

  let day = date.getDate();
  day = day < 10 ? "0" + day : day;

  const year = date.getFullYear();

  return `${day}${delimiter}${month}${delimiter}${year}`;
};

export default convertToDdMmYy;
