import React from "react";
import { useHistory } from "react-router";
import { rootPages } from "../../../../constants";
import { LoginSignupPanel, Button } from "../../../../ui";
import { Wrapper, ButtonWrapper, Message } from "./SignUpComplete.styles";

export default function SignUpComplete() {
  const history = useHistory();
  return (
    <LoginSignupPanel title="Registration is complete!">
      <Wrapper>
        <Message>You can now proceed to the login</Message>
        <ButtonWrapper>
          <Button
            onClick={() => history.push(`/${rootPages.SIGNIN}`)}
            fullWidth
          >
            Login
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </LoginSignupPanel>
  );
}
