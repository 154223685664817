import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import useLoaderFullPage from "features/Loaders/hooks/useLoaderFullPage";
import { regexPatterns } from "constants/index";
import Billing from "./Billing";
import {
  getValidationSchema,
  handleSubmit,
  redirectToPaymentForm,
} from "./utils";

const initialValues = {
  names: "",
  sum: null,
};

const BillingContainer = () => {
  const history = useHistory();
  const currency = "USD";
  const [error, setError] = useState("");
  const { showLoaderFillPage, hideLoaderFillPage } = useLoaderFullPage();
  const [failedInvoicesPopupOpen, setFailedInvoicesPopupOpen] = useState(false);
  const [invoicesTotalSum, setInvoicesTotalSum] = useState("");
  const [invoicesForPopup, setInvoicesForPopup] = useState([]);
  const [invoicesToPayCount, setInvoicesToPayCount] = useState(0);
  const [paymentToken, setPaymentToken] = useState("");

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: async (values) => {
      setError("");

      showLoaderFillPage();

      await handleSubmit(values, {
        history,
        formik,
        setError,
        setInvoicesTotalSum,
        setInvoicesForPopup,
        setFailedInvoicesPopupOpen,
        setInvoicesToPayCount,
        setPaymentToken,
        currency,
      });

      hideLoaderFillPage();
    },
  });

  const handleSumChange = (e) => {
    const pattern = regexPatterns.TOTAL_SUM_INVOICES;

    if (pattern.test(e.target.value) || !e.target.value) {
      formik.setFieldValue("sum", e.target.value);
    }
  };

  const handleFailedInvoicesPopupConfirm = () => {
    redirectToPaymentForm({ paymentToken, history });
  };

  const handleFailedInvoicesPopupClose = () => {
    setFailedInvoicesPopupOpen(false);
  };

  const failedInvoicesPopup = {
    open: failedInvoicesPopupOpen,
    setOpen: setFailedInvoicesPopupOpen,
    title: "Warning",
    list: invoicesForPopup,
    sum: invoicesTotalSum,
    invoicesToPayCount,
    currency: "USD",
    handleConfirm: handleFailedInvoicesPopupConfirm,
    handleClose: handleFailedInvoicesPopupClose,
  };

  useEffect(() => {
    setError("");
  }, [formik.values?.sum, formik.values?.names]);

  return (
    <Billing
      formik={formik}
      error={error}
      handleSumChange={handleSumChange}
      failedInvoicesPopup={failedInvoicesPopup}
    />
  );
};

export default BillingContainer;
