//@ts-check

import React from "react";
import { useFormContext } from "react-hook-form";
import { Wrapper, Input, SubscribeButton } from "./NewsletterInput.styles";
import { EnvelopeIcon } from "ui/icons";

export const NewsletterInput = () => {
  const { register } = useFormContext();

  return (
    <Wrapper>
      <Input
        placeholder="Enter email"
        {...register("email", { required: true })}
      />
      <SubscribeButton iconLeft={<EnvelopeIcon />}>Subscribe</SubscribeButton>
    </Wrapper>
  );
};
