// @ts-check

import styled from "styled-components";
import { accentColor, blue, darkBlue, lightBlue } from "ui/colors";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 60px 0;
  max-width: 940px;
`;

export const QuestionsList = styled.div`
  width: 100%;
  padding: 36px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Question = styled.div`
  padding: 40px;
  background-color: ${lightBlue};
  border-radius: 16px;
  color: ${darkBlue};
  overflow: hidden;
`;

export const QuestionTitle = styled.h3`
  display: inline;
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;

  &:hover {
    color: ${accentColor};
  }
`;

export const Answer = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-top: ${(props) => (props.expanded ? "16px" : 0)};
  opacity: ${(props) => (props.expanded ? 1 : 0)};
  max-height: ${(props) => (props.expanded ? "500px" : 0)};
  transition: all 100ms;
`;

export const ContactMessage = styled.p`
  margin: 0;
  color: ${blue};
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    color: ${accentColor};
  }
`;

export const ContactLink = styled.a`
  color: ${accentColor};
`;
