import styled from "styled-components";
import { accentColor, borderColor, darkBlue, lightBlue } from "../colors";

export const Wrapper = styled.div`
  .tabs__button {
    color: ${darkBlue};
    line-height: 28px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weighg: 400;
    padding: 0 26px;
    text-decoration: none;
    min-height: 52px;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid ${borderColor};
    cursor: pointer;

    &:hover {
      color: ${accentColor};
    }

    &.is-active {
      color: ${accentColor};
      font-weight: 600;
      border-bottom-color: ${accentColor};
    }
  }
`;
