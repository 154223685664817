import React from "react";
import classNames from "classnames";
import { LinearLoader, Link } from "../index";
import {
  ButtonStyled,
  IconWrapper,
  Text,
  LinearLoaderWrapper,
} from "./Button.styled";

type CommonButtonProps = {
  children: React.ReactNode;
  dataTestAttr?: string;
  className?: string;
  [key: string]: any;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const CommonButton = ({
  children,
  dataTestAttr,
  className,
  ...rest
}: CommonButtonProps) => {
  return (
    <ButtonStyled
      className={classNames("button", className)}
      data-test={dataTestAttr}
      {...rest}
    >
      {rest.iconLeft && (
        <IconWrapper type={rest.type}>{rest.iconLeft}</IconWrapper>
      )}
      {rest.iconTop && (
        <IconWrapper iconTop={rest.iconTop} type={rest.type}>
          {rest.iconTop}
        </IconWrapper>
      )}
      {children && <Text iconTop={rest.iconTop}>{children}</Text>}
      {rest.iconRight && (
        <IconWrapper type={rest.type}>{rest.iconRight}</IconWrapper>
      )}
      <LinearLoaderWrapper isLoading={rest.isLoading}>
        <LinearLoader />
      </LinearLoaderWrapper>
    </ButtonStyled>
  );
};

type ButtonProps = CommonButtonProps & {
  href?: string;
};

const Button = ({
  children,
  dataTestAttr,
  href,
  className,
  ...rest
}: ButtonProps) => {
  if (href) {
    if (typeof href === "string" && href?.startsWith("https:")) {
      return (
        <a href={href}>
          <CommonButton
            dataTestAttr={dataTestAttr}
            className={className}
            {...rest}
          >
            {children}
          </CommonButton>
        </a>
      );
    }

    return (
      <Link to={href} {...(rest?.target && { target: rest?.target })}>
        <CommonButton
          dataTestAttr={dataTestAttr}
          className={className}
          {...rest}
        >
          {children}
        </CommonButton>
      </Link>
    );
  }

  return (
    <CommonButton dataTestAttr={dataTestAttr} className={className} {...rest}>
      {children}
    </CommonButton>
  );
};

export default Button;
