import styled from "styled-components";
import { media } from "../../constants";

export const Wrapper = styled.div`
  width: 104px;
  display: flex;

  svg {
    display: inline-block;
  }

  @media ${media.TABLET} {
    width: 90px;
  }
`;
