import styled from "styled-components";
import { Button, Link } from "ui/index";
import { darkBlue } from "ui/colors";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 90px;
  color: ${darkBlue};
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled(Link)`
  width: 190px;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 24px 20px 0;
  flex-grow: 1;
  gap: 12px;
`;

export const Code = styled(Link)`
  box-sizing: border-box;
  min-width: 160px;
  padding: 0 18px;
  flex-grow: 1;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none !important;
`;
export const PriceList = styled.div`
  max-width: 354px;
  width: 100%;
  flex-grow: 1;
`;
export const AddToCartButton = styled(Button)`
  width: 60px;
`;
