import React, { useEffect, useState } from "react";
import {
  Wrapper,
  SelectStyled,
  Label,
  ErrorMessage,
  ArrowWrapper,
  SelectGroup,
} from "./select.styles";
import { DownArrowIcon } from "../icons";
import classNames from "classnames";

const Select = ({
  isCartItem,
  label,
  formik,
  name,
  options,
  value,
  handleChange,
  id,
  dataTestAttr,
  placeholder,
  variant,
  ...rest
}) => {
  const hasError =
    formik && formik.touched[name] && Boolean(formik.errors[name]);
  const errorMessage = formik && formik.touched[name] && formik.errors[name];
  const selectValue = formik ? formik.values[name] : value;
  const onHandleChange = formik ? formik.handleChange : handleChange;

  const [localOptions, setLocalOptions] = useState(options);

  useEffect(() => {
    if (isCartItem) {
      setLocalOptions(options?.map((option) => ({...option, title: option?.selectedTitle})));
    } else {
      setLocalOptions(options);
    }
  }, [options, isCartItem]);

  const handleFocusSelect = () => {
    if (isCartItem) {
      setLocalOptions(options);
    }
  }

  const handleFocusBlur = () => {
    if (isCartItem) {
      setLocalOptions(options.map((option) => ({...option, title: option?.selectedTitle})));
    }
  }

  const classnames = classNames("select", {
    [`select--variant--${variant}`]: variant,
  });

  return (
    <Wrapper className={classnames} {...rest}>
      {label && (
        <Label htmlFor={name} hasError={hasError}>
          {label}
        </Label>
      )}
      <SelectGroup>
        <SelectStyled
          className="select__select"
          hasError={hasError}
          name={name}
          id={id}
          value={selectValue}
          onChange={(event) => onHandleChange(event)}
          data-test={dataTestAttr}
          required
          onFocus={handleFocusSelect}
          onBlur={handleFocusBlur}
          {...rest}
        >
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {localOptions &&
            localOptions.map((el, index) => {
              return (
                <option key={index} value={el.value}>
                  {el.title}
                </option>
              );
            })}
        </SelectStyled>
        <ArrowWrapper>
          <DownArrowIcon />
        </ArrowWrapper>
      </SelectGroup>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Wrapper>
  );
};

export default Select;
