import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import { Wrapper } from "./ErrorBoundary.styles";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <Wrapper>
      <p>Something went wrong</p>
    </Wrapper>
  );
}

const ErrorBoundaryPart = ({ children }) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        console.log("test");
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryPart;
