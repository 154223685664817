import React from "react";
import { Button, Modal } from "../../../../../../ui";
import { ButtonWrapper } from "./DeleteDialog.styles";

const DeleteDialog = ({ open, setOpen, title, handleDelete }) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      boxProps={{ width: "800px", maxWidth: "100%" }}
      title={title}
      dataTestAttr="delete-modal"
    >
      <ButtonWrapper>
        <Button
          onClick={() => setOpen(false)}
          size="small"
          type="outlined"
          dataTestAttr="cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
            handleDelete();
          }}
          type="outlined-danger"
          size="small"
          dataTestAttr="delete-button"
        >
          Delete
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default DeleteDialog;
