import styled from "styled-components";
import { accentColor, borderColor } from "../colors.js";

const radioWidth = 20;

export const Wrapper = styled.div`
  width: ${radioWidth}px;
  height: ${radioWidth}px;
  border: 2px solid ${borderColor};
  border-radius: ${radioWidth}px;
  position: relative;
  flex-shrink: 0;

  &::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &.is-active {
    border-color: ${accentColor};
    background-color: white;

    &::after {
      background-color: ${accentColor};
    }
  }
`;
