import styled from "styled-components";
import { accentColor } from "ui/colors";

export const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

export const ContactLink = styled.a`
  color: ${accentColor};
`;
