import styled from "styled-components";
import { accentColor, lightBlue } from "ui/colors";

export const Wrapper = styled.div`
  .empty-message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }
`;

export const AnalogsMessage = styled.button`
  outline: none;
  width: 100%;
  margin-top: 32px;
  padding: 20px 26px;
  border: 1px solid ${lightBlue};
  border-radius: 8px;
  background-color: ${lightBlue};
  color: ${accentColor};
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  &:hover {
    border: 1px solid ${accentColor};
  }
`;
AnalogsMessage.displayName = "AnalogsMessage";
