// @ts-check
import React from "react";
import { ArrowRight2Icon } from "ui/icons";
import { LearnMoreLinkStyled } from "./LearnMoreLink.styles";

export const LearnMoreLink = (props) => {
  return (
    <>
      <LearnMoreLinkStyled {...props}>
        Learn more
        <ArrowRight2Icon />
      </LearnMoreLinkStyled>
    </>
  );
};
