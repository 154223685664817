import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { utilSelectors } from "../../redux/util";
import AddressCard from "./address-card";

const AddressCardContainer = (props) => {
  const {
    handleClickEdit,
    handleClickDelete,
    type,
    dataTestAttr,
    item: { phone, country, city, company, zip, vat, state, addressLine1 },
  } = props;
  const [list, setList] = useState([]);
  const countries = useSelector(utilSelectors.selectCountries);
  const usStates = useSelector(utilSelectors.selectUsStates);

  useEffect(() => {
    const countryItem = { title: "Country", value: countries?.[country] };

    if (state) {
      countryItem.title += "/State";
      countryItem.value = `USA/${usStates?.[state]}`;
    }
    const newList = [countryItem];

    newList.push({ title: "City", value: city });
    newList.push({ title: "Address", value: addressLine1 });
    newList.push({ title: "Postal code", value: zip });

    newList.push({ title: "Contact phone", value: phone });
    newList.push({ title: "Company", value: company });

    if (vat) {
      newList.push({
        title: "VAT number",
        value: vat,
      });
    }

    setList(newList);
  }, [vat, phone, country, city, zip, company, state, addressLine1]);

  return (
    <AddressCard
      list={list}
      handleEdit={handleClickEdit}
      handleDelete={handleClickDelete}
      dataTestAttr={dataTestAttr}
      type={type}
    />
  );
};

export default AddressCardContainer;
