// @ts-check
import React from "react";
import { Button } from "ui";
import { Wrapper } from "./action-bar.styles";
import { CartIcon, FileDownloadIcon } from "ui/icons";

export const ActionBar = ({
  onHandleAllToCart,
  isLoadingAllToCart,
  onHandleDownloadSdf,
  isLoadingSdf,
}) => (
  <Wrapper>
    <Button
      iconLeft={<CartIcon />}
      type="outlined-white"
      size="small"
      onClick={onHandleAllToCart}
      isLoading={isLoadingAllToCart}
    >
      All to cart
    </Button>
    <Button
      iconLeft={<FileDownloadIcon />}
      type="outlined-white"
      size="small"
      onClick={onHandleDownloadSdf}
      isLoading={isLoadingSdf}
    >
      Save as SD file
    </Button>
  </Wrapper>
);
