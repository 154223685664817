import { convertToDdMmYy } from "utils";
import {
  MappedCertificate,
  TaxFreeCertificate,
} from "./TaxFreeCertificates.types";

const prepareCertificatesData = (
  certificates: TaxFreeCertificate[]
): MappedCertificate[] => {
  return certificates
    .sort((a, b) => {
      if (a.uploaded < b.uploaded) {
        return 1;
      }
      if (a.uploaded > b.uploaded) {
        return -1;
      }

      return 0;
    })
    .map((el, idx) => {
      let validTo = "--";
      let status = "--";

      if (el?.approve) {
        // calculate validTo
        if (el.approve?.expiration?.termless) {
          validTo = "Termless";
        } else if (
          !el.approve?.expiration?.termless &&
          el.approve?.expiration?.time
        ) {
          validTo = convertToDdMmYy(el.approve?.expiration?.time, ".");
        }

        // calculate status
        if (el.approve?.expiration?.time) {
          const dateFrom = new Date(el.approve?.time);
          const dateTo = new Date(el.approve?.expiration?.time);

          if (dateFrom !== "Invalid Date" && dateTo !== "Invalid Date") {
            const timeDiff = dateTo.getTime() - dateFrom.getTime();

            status = timeDiff <= 0 ? "Expired" : "Valid";
          }
        } else {
          if (el.approve?.expiration?.termless) {
            status = "Valid";
          }
        }
      } else {
        status = "Pending";
      }

      return {
        uuid: el.uuid,
        name: `Certificate ${certificates.length - idx}`,
        status,
        uploaded: convertToDdMmYy(el.uploaded, "."),
        validTo,
      };
    });
};

export default prepareCertificatesData;
