export const REQUEST_IMAGES = "UTIL/REQUEST_IMAGES";
export const REQUEST_CATALOGS = "UTIL/REQUEST_CATALOGS";
export const REQUEST_CATALOG_WEIGHTS = "UTIL/REQUEST_CATALOG_WEIGHTS";
export const REQUEST_COUNTRIES = "UTIL/REQUEST_COUNTRIES";
export const REQUEST_US_STATES = "UTIL/REQUEST_US_STATES";
export const REQUEST_VAT_PATTERNS = "UTIL/REQUEST_VAT_PATTERNS";
export const REQUEST_CARRIERS = "UTIL/REQUEST_CARRIERS";
export const SET_CATALOGS = "UTIL/SET_CATALOGS";
export const SET_CATALOG_WEIGHTS = "UTIL/SET_CATALOG_WEIGHTS";
export const SET_COUNTRIES = "UTIL/SET_COUNTRIES";
export const SET_US_STATES = "UTIL/SET_US_STATES";
export const SET_VAT_PATTERNS = "UTIL/SET_VAT_PATTERNS";
export const SET_IMAGES = "UTIL/SET_IMAGES";
export const SET_CARRIERS = "UTIL/SET_CARRIERS";
export const RESET = "UTIL/RESET";
