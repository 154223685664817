// @ts-check
import React from "react";
import { Flex, Title } from "ui";
import { FormLayoutWrapper, Logo, Main, Footer } from "./form-layout.styles";
import classNames from "classnames";

export const FormLayout = ({
  title = "",
  children,
  footer = null,
  className,
}) => {
  return (
    <FormLayoutWrapper hasFooter={!!footer} className={classNames(className)}>
      <Main>
        <Flex type="center-between">
          <Title variant="h2">{title}</Title>
          <Logo />
        </Flex>
        {children}
      </Main>
      {footer && <Footer>{footer}</Footer>}
    </FormLayoutWrapper>
  );
};
