import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { errorActions } from "../../redux/error";
import Error from "./error";

const ErrorContainer = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.error.errors);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      dispatch(errorActions.clearErrors());
    };
  }, []);

  useEffect(() => {
    if (items?.length) {
      setOpen(true);
    }
  }, [items]);

  return (
    <Error
      items={items}
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
    />
  );
};

export default ErrorContainer;
