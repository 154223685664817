import React from "react";
import Form from "../../../profile/pages/address-book/parts/address-dialog/Form";
import { Button } from "../../../../ui";
import { ButtonWrapper, FormWrapper } from "./Billing.styles";

const Billing = ({ formik, vatPattern, showStateSelect, handleClick }) => {
  return (
    <>
      <FormWrapper>
        <Form
          formik={formik}
          vatPattern={vatPattern}
          showStateSelect={showStateSelect}
        />
      </FormWrapper>
      <ButtonWrapper>
        <Button onClick={handleClick} size="small" fullWidth>
          Next
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default Billing;
