// @ts-check
import React, { useState } from "react";
import { NavHashLink } from "react-router-hash-link";

export const NavItem = ({ data, opened = false }) => {
  const { id, items, title } = data;
  return (
    <li>
      {items && items?.length ? (
        <details open={opened}>
          <summary>
            <NavHashLink to={`#${id}`} activeClassName="activeLink">
              {title}
            </NavHashLink>
          </summary>
          <NavList items={items} opened={opened} />
        </details>
      ) : (
        <NavHashLink to={`#${id}`} activeClassName="activeLink">
          {title}
        </NavHashLink>
      )}
    </li>
  );
};

export const NavList = ({ items = [], opened = false }) => {
  return (
    <ul>
      {items.map((item) => {
        return <NavItem key={item.id} data={item} opened={opened} />;
      })}
    </ul>
  );
};

export const Navigation = ({ items = [], opened = false }) => {
  const [isMenuOpened, openMenu] = useState(opened);

  return (
    <>
      {/* <button onClick={() => openMenu((state) => !state)}>
        {isMenuOpened ? "-" : "+"}
      </button> */}
      <nav>
        <NavList items={items} opened={isMenuOpened} />
      </nav>
    </>
  );
};
