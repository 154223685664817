import styled from "styled-components";

export const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: 4px;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    content: "";
    position: absolute;
    height: 100%;
    background-color: black;
    opacity: 0.4;
    animation: indeterminate_first 1s infinite ease-out;
  }

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    animation: indeterminate_second 1s infinite ease-in;
  }

  @keyframes indeterminate_first {
    0% {
      left: -100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }

  @keyframes indeterminate_second {
    0% {
      left: -150%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 10%;
    }
  }
`;
