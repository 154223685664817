import React from "react";
import { rootPages } from "../../constants";
import ProfileSidebar from "./profile-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "../../redux/user";
import { useHistory } from "react-router";
import { events, useAmplitudeState } from "../../context/amplitude-context";
import { fetchLogout } from "../../redux/user/api";

const links = [
  {
    title: "Orders",
    alias: "orders",
  },
  {
    title: "Profile",
    alias: rootPages.PROFILE,
  },
  {
    title: "Address book",
    alias: "address-book",
  },
  {
    title: "Tax Exempt Certificates",
    alias: "tax-free-certificates",
  },
  {
    title: "Billing",
    alias: "billing",
  },
];

const getLinkHref = (alias) =>
  alias === rootPages.PROFILE
    ? `/${rootPages.PROFILE}`
    : `/${rootPages.PROFILE}/${alias}`;

const getUsernameAndAvatarLetters = (profile) => {
  const username =
    profile?.firstName && profile?.lastName
      ? `${profile?.firstName} ${profile?.lastName}`
      : null;
  const firstNameLetter = profile?.firstName?.charAt(0);
  const lastNameLetter = profile?.lastName?.charAt(0);
  const avatarLetters =
    firstNameLetter && lastNameLetter ? firstNameLetter + lastNameLetter : null;

  return {
    username,
    avatarLetters,
  };
};

const ProfileSidebarContainer = ({ alias }) => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector(userSelectors.selectProfile);
  const { username, avatarLetters } = getUsernameAndAvatarLetters(profile);
  const title =
    profile && profile.title !== "Undefined" ? `${profile.title}.` : null;

  const handleLogout = () => {
    dispatch(
      userActions.logout({
        redirectTo: "/",
        cb: async () => {
          amplitude.logEvent(events.logout);
          await fetchLogout();
        },
      })
    );
  };

  const handleClick = (item) => {
    const alias = item?.alias;
    if (alias === "profile") {
      amplitude.logEvent(events.profile_click);
    } else if (alias === "orders") {
      amplitude.logEvent(events.profile_orders_click);
    } else if (alias === "address-book") {
      amplitude.logEvent(events.profile_addresses_click);
    } else if (alias === "change-password") {
      amplitude.logEvent(events.profile_changepass_click);
    }
  };

  return (
    <ProfileSidebar
      handleLogout={handleLogout}
      alias={alias}
      links={links}
      getLinkHref={getLinkHref}
      username={username}
      avatarLetters={avatarLetters}
      title={title}
      handleClick={handleClick}
    />
  );
};

export default ProfileSidebarContainer;
