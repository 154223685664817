import { colors } from "@mui/material";
import { createTheme } from "@mui/material";
import { defaultTheme } from "./constants/theme";
import { accentColor, borderColor, lightBlue } from "./ui/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    borderColor: defaultTheme.borderColor,
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: colors.red.A700,
      text: "#d92d26",
    },
    background: {
      default: "white",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    subtitle1: {
      fontSize: '22px',
      fontWeight: 700,
    },
  },
  components: {

    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '8px',
            height: '48px',
          },
          '& .MuiOutlinedInput-root': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: `${borderColor}`,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px !important",
              borderColor: `${accentColor} !important`,
            },
          },
        }
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          color: defaultTheme.linkColorDarken,
          padding: "5px",
          "&.Mui-checked": {
            color: defaultTheme.linkColorDarken,
          },
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment": {
            right: '16px'
          },
          "&.Mui-focused": {
            border: 0,
            outline: 0,
          },
          "& .MuiInputBase-root": {
            borderRadius: '8px',
            paddingLeft: '12px',
            paddingRight: '45px',
            '& input::placeholder': {
              color: borderColor,
              opacity: 1,
            }
          },
          "& .MuiChip-root": {
            height: '24px',
            margin: '3px 4px'
          },
          "& .MuiChip-label": { fontSize: '14px', paddingRight: '10px' },
          ".MuiAutocomplete-tag": {
            background: lightBlue,
          },
          "& .MuiOutlinedInput-notchedOutline": { borderColor: borderColor },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px !important",
            borderColor: `${accentColor} !important`,
          },
          "& .MuiChip-root .MuiChip-deleteIcon": {
            fontSize: '12px',
            color: accentColor,
            marginRight: '10px'
          }
        },
      },
    }
  },
});

export default theme;
