import React from "react";
import { Wrapper } from "./logo.styles";
import { ReactComponent as LogoIcon } from "../../icons/logo.svg";
import { Link } from "../index";

const Container = ({ dataTestAttr, ...props }) => {
  return (
    <Link className="logo" to="/" data-test={dataTestAttr} {...props}>
      <Wrapper>
        <LogoIcon />
      </Wrapper>
    </Link>
  );
};

export default Container;
