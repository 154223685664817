import {
  LOGOUT,
  SET_LOGOUT,
  REQUEST_ADDRESSES,
  REQUEST_PROFILE,
  REQUEST_USER,
  SET_ADDRESSES,
  SET_ADDRESSES_LOADING,
  SET_PROFILE,
  SET_PROFILE_LOADING,
  SET_USER,
  SET_USER_LOADING,
  SET_USER_READY,
  SET_REFRESH_TOKEN,
  SET_PROFILE_ERROR,
  SET_IS_AUTH,
  SET_REDIRECT_TO,
  SET_CURRENT_USER,
} from "./types";

const requestUser = () => {
  return {
    type: REQUEST_USER,
  };
};

const requestAddresses = () => {
  return {
    type: REQUEST_ADDRESSES,
  };
};

const requestProfile = () => {
  return {
    type: REQUEST_PROFILE,
  };
};

const setUser = (info) => {
  return {
    type: SET_USER,
    payload: info,
  };
};

const setUserReady = (isReady) => {
  return {
    type: SET_USER_READY,
    payload: isReady,
  };
};

const setIsAuth = (isAuth) => {
  return {
    type: SET_IS_AUTH,
    payload: isAuth,
  };
};

const setRefreshToken = (refreshToken) => {
  return {
    type: SET_REFRESH_TOKEN,
    payload: refreshToken,
  };
};

const setProfile = (payload) => {
  return {
    type: SET_PROFILE,
    payload,
  };
};

const setAddresses = (payload) => {
  return {
    type: SET_ADDRESSES,
    payload,
  };
};

const logout = (payload) => {
  return {
    type: LOGOUT,
    payload,
  };
};

const setLogout = (payload) => {
  return {
    type: SET_LOGOUT,
    payload,
  };
};

const setRedirectTo = (payload) => {
  return {
    type: SET_REDIRECT_TO,
    payload,
  };
};

const setUserLoading = (isLoading) => {
  return {
    type: SET_USER_LOADING,
    payload: isLoading,
  };
};

const setAddressesLoading = (isLoading) => {
  return {
    type: SET_ADDRESSES_LOADING,
    payload: isLoading,
  };
};

const setProfileLoading = (isLoading) => {
  return {
    type: SET_PROFILE_LOADING,
    payload: isLoading,
  };
};

const setProfileError = (message) => {
  return {
    type: SET_PROFILE_ERROR,
    payload: message,
  };
};

const setCurrentUser = (currentUser) => {
  return {
    type: SET_CURRENT_USER,
    payload: currentUser,
  };
};

export default {
  requestUser,
  setUser,
  setUserLoading,
  setProfileLoading,
  setAddressesLoading,
  setLogout,
  logout,
  setProfile,
  setUserReady,
  setAddresses,
  requestProfile,
  requestAddresses,
  setRefreshToken,
  setProfileError,
  setIsAuth,
  setRedirectTo,
  setCurrentUser,
};
