import styled from "styled-components";
import { blue, lightGray, lineColor } from "ui/colors";

export const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 18px;
  justify-content: space-between;

  button {
    width: 50%;
  }
`;

export const TableTitle = styled.p`
  margin: 0;
  margin-bottom: 18px;
`;
export const TableStyled = styled.div`
  display: grid;
  grid-template-columns: minmax(144px, 20%) 1fr;
  border: 1px solid ${lineColor};
  border-radius: 8px;
  overflow: hidden;
  color: ${blue};
`;

export const TableCell = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${lineColor};
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }

  &:nth-of-type(2n - 1) {
    padding-left: 45px;
    justify-content: flex-end;
    background-color: ${lightGray};
    border-right: 1px solid ${lineColor};
    font-weight: 600;
  }

  &:nth-of-type(2n - 1):nth-last-child(2),
  &:last-child {
    border-bottom: 0;
  }
  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const TotalSum = styled.p`
  margin: 0;
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 28px;

  span:last-child {
    font-weight: 500;
  }
`;
