import { useDispatch } from "react-redux";
import { checkoutActions } from "../../redux/checkout";
import superCartActions from "features/super-cart/redux/actions";

const useRequestAdditionalCosts = () => {
  const dispatch = useDispatch();

  const requestAdditionalCosts = async ({
    onSuccess = () => {},
    onError = () => {},
  }) => {
    const onSuccessAdditionalCosts = () => {
      const onSuccessCartInfo = onSuccess;

      dispatch(
        superCartActions.requestCartInfo({ onSuccess: onSuccessCartInfo })
      );
    };

    dispatch(
      checkoutActions.requestAdditionalCosts({
        onSuccess: onSuccessAdditionalCosts,
        onError,
      })
    );
  };

  return { requestAdditionalCosts };
};

export default useRequestAdditionalCosts;
