import { ProductTypesV2 } from "../constants/productTypes";

const convertCatalogToCat = (catalog: ProductTypesV2) => {
  if (catalog) {
    return Object.keys(ProductTypesV2)[
      Object.values(ProductTypesV2).indexOf(catalog)
    ];
  }
};
export default convertCatalogToCat;
