export const SET_DATA = "CHECKOUT/SET_DATA";
export const CLOSE_ACCORDION = "CHECKOUT/CLOSE_ACCORDION";
export const OPEN_ACCORDION = "CHECKOUT/OPEN_ACCORDION";
export const SET_INITIAL = "CHECKOUT/SET_INITIAL";
export const CHANGE_INPUT = "CHANGE_INPUT";

export const SET_ADDITIONAL_COSTS = "CHECKOUT/SET_ADDITIONAL_COSTS";
export const SET_ADDITIONAL_COSTS_LOADING =
  "CHECKOUT/SET_ADDITIONAL_COSTS_LOADING";
export const SET_ADDITIONAL_COSTS_ERROR = "CHECKOUT/SET_ADDITIONAL_COSTS_ERROR";
export const REQUEST_ADDITIONAL_COSTS = "CHECKOUT/REQUEST_ADDITIONAL_COSTS";


export const SET_PAYMENT_METHODS = "CHECKOUT/SET_PAYMENT_METHODS";
export const SET_PAYMENT_METHODS_LOADING = "CHECKOUT/SET_PAYMENT_METHODS_LOADING";
export const SET_PAYMENT_METHODS_ERROR = "CHECKOUT/SET_PAYMENT_METHODS_ERROR";

export const REQUEST_PAYMENT_METHODS = "CHECKOUT/REQUEST_ADDITIONAL_COSTS";
