import styled from "styled-components";
import { ToastContainer as Container, toast } from "react-toastify";
import {
  accentColor,
  dangerColor,
  successColor,
  warningColor,
} from "ui/colors";

export const Wrapper = styled.div`
  font-family: Roboto, Helvetica, Arial, sans-serif;

  .toast__title {
    white-space: nowrap;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 6px;
  }
  .toast__text {
    white-space: nowrap;

    a {
      color: white;
    }
  }
`;

export const ToastContainer = styled(Container).attrs({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 3000,
  icon: false,
  hideProgressBar: true,
  closeOnClick: false,
  style: {
    top: "75px",
    right: "36px",
    maxWidth: "560px",
  },
})`
  width: unset;

  .Toastify__toast {
    color: white;
    padding: 20px;
    border-radius: 8px;
    min-height: unset;
    cursor: default;
  }
  .Toastify__toast--info,
  .Toastify__toast--default {
    background-color: ${accentColor};
  }
  .Toastify__toast--success {
    background-color: ${successColor};
  }
  .Toastify__toast--error {
    background-color: ${dangerColor};
  }
  .Toastify__toast--warning {
    background-color: ${warningColor};
  }

  .Toastify__toast-container {
  }

  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    padding-right: 20px;
  }

  .Toastify__toast--default .Toastify__progress-bar {
    background: ${accentColor};
  }

  .Toastify__toast--error .Toastify__progress-bar {
    background: ${dangerColor};
  }

  .Toastify__toast-icon {
    margin-right: 20px;
  }
`;

export const CloseButton = styled.span`
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
  svg path {
    fill: white;
  }
`;
