import styled from "styled-components";
import { ReactComponent as CheckedGreenIcon } from "icons/checked-green.svg";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckIcon = styled(CheckedGreenIcon)`
  width: 70px;
  path {
    fill: black;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 20px;
  margin-bottom: 30px;
`;
