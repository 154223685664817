import { events } from "../../context/amplitude-context";
import { AmplitudeClient } from "amplitude-js";

export const fireInitialEvent = (
  amplitude: AmplitudeClient,
  timeNow: number
) => {
  const date = new Date(timeNow);
  let month: string | number = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let day: string | number = date.getDate();
  day = day < 10 ? "0" + day : day;
  const year = date.getFullYear();
  const resultDate = `${year}${month}${day}`;

  amplitude.setUserProperties({
    cohortday: resultDate,
    visitedNew: "yes",
  });

  amplitude.logEvent(events.session_start);
};
