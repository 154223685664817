import React from "react";
import { Wrapper, BoxButton } from "./pagination.styles";

const Pagination = ({ pagination, onHandleClickPagination }) => {
  return (
    <Wrapper>
      {pagination?.map((item) => (
        <BoxButton
          key={item.id}
          active={item.active}
          onClick={() => onHandleClickPagination(item.id)}
        >
          {item.title}
        </BoxButton>
      ))}
    </Wrapper>
  );
};

export default Pagination;
