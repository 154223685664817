import generateFullNameWithTitle from "../utils/generateFullNameWithTitle";

const ContactPerson = ({ item }) => {
  return (
    <>
      <p>{item?.name}</p>
      <p>{item?.email}</p>
      <p>{item?.phone}</p>
    </>
  );
};

export default ContactPerson;
