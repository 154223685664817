import styled from "styled-components";
import Table from "ui/table/table";

export const Wrapper = styled(Table.Row)`
  &.address-card {
  }

  .address-card__controls {
    display: flex;
    align-items: center;
    padding-left: 20px;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
