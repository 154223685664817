import styled from "styled-components";
import { accentColor, blue, borderColor, darkBlue } from "ui/colors";

export const Wrapper = styled.div`
  font-size: 16px;

  .info-list__divider {
    border-bottom: 1px solid ${borderColor};
    margin-top: 12px;
    margin-bottom: 10px;
  }
`;

Wrapper.displayName = "InfoListWrapper";

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
`;

ListItem.displayName = "ListItem";

export const Title = styled.div`
  color: ${blue};
  padding-right: 20px;
`;

Title.displayName = "Title";

export const Value = styled.div`
  color: ${darkBlue};
  word-break: break-all;
  text-align: right;
`;

Value.displayName = "Value";

export const Purity = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${accentColor};
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;

  svg {
    margin-right: 5px;
  }
`;

Purity.displayName = "Purity";
