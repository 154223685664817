// @ts-check
import React from "react";
import { HeroSearch } from "./parts/HeroSearch/HeroSearch";
import { useQuery } from "hooks";
import { FeatureList } from "./parts/FeatureList/FeatureList";
import { FaqList } from "./parts/faq/Faq";
import { NewsletterSubscribeContainer } from "features/newsletter-subscribe/NewsletterSubscribe.container";
import MiddlePageContainer from "pages/search/parts/middle-page/MiddlePage.container";

/**
 * @type {React.FC}
 */
export default function HomePage() {
  const query = useQuery();
  const q = query.get("q");

  return !q ? (
    <>
      <HeroSearch />
      <FeatureList />
      <FaqList />
      <NewsletterSubscribeContainer />
    </>
  ) : (
    <MiddlePageContainer />
  );
}
