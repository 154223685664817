import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchProducts } from "redux/catalog/api";
import { userSelectors } from "redux/user";
import { decode } from "utils";
import { prepareData, prepareList } from "./useAllToCart.utils";
import { currencySelectors } from "redux/currency";
import { events, useAmplitudeState } from "context/amplitude-context";
import superCartActions from "features/super-cart/redux/actions";
import cartAPI from "features/super-cart/redux/api";

const useAllToCart = ({ total, code, cat, type, sstype, list = [] }) => {
  const { amplitude } = useAmplitudeState();
  const isAuth = useSelector(userSelectors.selectIsAuth);
  const currency = useSelector(currencySelectors.selectCurrent);
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleAllToCart = async () => {
    amplitude.logEvent(events.all_to_cart);
    setLoading(true);
    setError(false);

    if (isAuth) {
      const preparedData = prepareData({
        query: decode(code),
        currency: currency?.abbr,
        sstype,
        cat,
        type,
      });

      const allToCartResponse = await cartAPI.allToCart(preparedData);

      if (allToCartResponse?.[0]?.data == "OK") {
        const cb = () => {
          runSuccessToast();
          setLoading(false);
        };

        const fetchedItems = await cartAPI.fetchItems();

        dispatch(superCartActions.setItems(fetchedItems));

        cb();
      } else {
        setError(true);
        setLoading(false);
      }
    } else {
      let finalList = [];

      if (total > list?.length) {
        const productsResponse = await fetchProducts({
          code,
          cat,
          type,
          sstype,
          curPage: 1,
          pageSize: total,
        });
        finalList = productsResponse?.searchResults;
      } else {
        finalList = list;
      }

      const preparedList = prepareList(finalList);

      dispatch(superCartActions.allToCart(preparedList));

      runSuccessToast();
      setLoading(false);
    }
  };

  return {
    handleAllToCart,
    isLoading,
    error,
  };
};

export const runSuccessToast = () => {
  toast("Products were added to cart!");
};

export default useAllToCart;
