import { FullWidthLoader } from "parts";
import React from "react";
import { CartItem } from "shared/types/cartItem";
import { Currency } from "shared/types/cyrrency";
import { Container, EmptyCart, Pagination, Paper, Section, Title } from "ui";
import CartItemContainer from "../CartItem/CartItem.container";
import ModalGroupUpdateContainer from "../ModalGroupUpdate/ModalGroupUpdateContainer";
import Summary from "../Summary/Summary";
import Header from "../header";
import QuoteModalContainer from "../quote/quoteModal/container";
import { Column, PaginationWrapper, Wrapper } from "./CartPage.styled";

type PaginationItem = {
  id: number;
  title: string | number;
  active: boolean;
};

type CartPageProps = {
  currency: Currency;
  downloadSdf: {
    isLoading: boolean;
    handleDownload: () => void;
    error: boolean;
  };
  totalItemsCount: number;
  pageItems: CartItem[];
  allItems: CartItem[];
  pagination: PaginationItem[] | null;
  loading: boolean;
  loadingRest: boolean;
  subtotal: string;
  handleChangeCount: (args: {
    key: string;
    data: { quantity: number };
  }) => Promise<void>;
  handleClickPagination: (page: number) => void;
  handleChangePriceSelect: (args: { key: string; data: any }) => Promise<void>;
  handleClearCart: () => Promise<void>;
  handleDelete: (args: { key: string }) => Promise<void>;
  handleGroupUpdate: () => void;
  quote: {
    open: boolean;
    setOpen: (open: boolean) => void;
    handleRequestQuote: () => void;
  };
  cartInfoLoading: boolean;
  isModalGroupUpdateOpen: boolean;
  discount: number;
};

const CartPage = ({
  currency,
  downloadSdf,
  totalItemsCount,
  pageItems,
  allItems,
  pagination,
  loading,
  loadingRest,
  subtotal,
  handleChangeCount,
  handleClickPagination,
  handleChangePriceSelect,
  handleClearCart,
  handleDelete,
  handleGroupUpdate,
  quote,
  cartInfoLoading,
  discount,
  isModalGroupUpdateOpen,
}: CartPageProps) => {
  return (
    <Section>
      <Container>
        <QuoteModalContainer quote={quote} products={allItems} />
        <ModalGroupUpdateContainer
          cartItems={allItems}
          isOpen={isModalGroupUpdateOpen}
          onClose={handleGroupUpdate}
        />
        <Header
          handleClearCart={handleClearCart}
          downloadSdf={downloadSdf}
          showSelect={pageItems.length}
          totalItems={totalItemsCount}
          handleGroupUpdate={handleGroupUpdate}
        />
        {loading ? (
          <FullWidthLoader />
        ) : (
          <>
            {!totalItemsCount ? (
              <EmptyCart />
            ) : (
              <Wrapper>
                <Column main>
                  <Paper smallPaddings>
                    <Title>Items</Title>

                    {pageItems.map((item) => {
                      return (
                        <CartItemContainer
                          key={item.key}
                          item={item}
                          handleChangePriceSelect={handleChangePriceSelect}
                          handleDelete={handleDelete}
                          handleChangeCount={handleChangeCount}
                          currency={currency}
                          cartInfoLoading={cartInfoLoading}
                        />
                      );
                    })}

                    {pagination?.length ? (
                      <PaginationWrapper mt>
                        <Pagination
                          pagination={pagination}
                          onHandleClickPagination={handleClickPagination}
                        />
                      </PaginationWrapper>
                    ) : null}
                  </Paper>
                </Column>

                <Column>
                  <Summary
                    subtotal={subtotal}
                    loading={loadingRest || cartInfoLoading}
                    handleRequestQuote={quote.handleRequestQuote}
                    currency={currency}
                    discount={discount}
                  />
                </Column>
              </Wrapper>
            )}
          </>
        )}
      </Container>
    </Section>
  );
};

export default CartPage;
