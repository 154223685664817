import styled from "styled-components";
import { ReactComponent as CheckedGreenIcon } from "../../../../icons/thank-you.svg";

export const Wrapper = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  max-width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckIcon = styled(CheckedGreenIcon)`
  width: 240px;
  height: auto;
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: 400;
  line-height: 72px;
  font-size: 62px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 24px;
  margin-bottom: 30px;
  color: #6c6c6c;
  text-align: center;
`;
