import { put, select } from "@redux-saga/core/effects";
import { versionActions } from ".";
import { utilActions } from "../util";
import { getVersionString } from "./utils";

export function* requestVersion(payload) {
  try {
    const state = yield select();

    const version = getVersionString();

    if (state.version.version !== version) {
      yield put(utilActions.reset());
      yield put(utilActions.requestUsStates());
      yield put(utilActions.requestCountries());
      yield put(utilActions.requestCatalogs());
      yield put(utilActions.requestCarriers());
      yield put(utilActions.requestCatalogWeights());
      yield put(utilActions.requestVatPatterns());

      yield put(versionActions.setVersion(version));
    }
    if (payload.payload) {
      payload.payload();
    }
  } catch (e) {
    console.log(e);
    console.log(e, "Something went wrong!");
  }
}
