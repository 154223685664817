const common = {
  ENV: process.env.REACT_APP_ENVIRONMENT,
  API_URL: "/api/v1/",
  API_V2_URL: "/api/v2/",
  PUBLIC_URL: "",
  REMOTE_PUBLIC_URL: process.env.REACT_APP_REMOTE_BASE_URL,
  REMOTE_API_URL: `${process.env.REACT_APP_REMOTE_BASE_URL}/api/v1/`,
  AMPLITUDE_KEY: process.env.REACT_APP_AMPLITUDE_KEY,
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY,
  OLD_SHOP_HREF: process.env.REACT_APP_OLD_SHOP_HREF,
  OLD_SHOP_COOKIE_DOMAIN: process.env.REACT_APP_OLD_SHOP_COOKIE_DOMAIN,
  HOTJAR_ID: 2299498,
  DEFAULT_SIMILARITY_THRESHOLD: 0.8,
  REALDB_SIMILARITY_THRESHOLD: 0.1,
  HOTJAR_SV: 6,
  DRAW_PAGE_QUERY_VALUE: "draw-search",
  DRAW_PAGE_QUERY_KEY: "drawSearch",
  DEV_PRODUCT_IMAGE:
    "https://shop-static-test.enamine.net/new/png/325893133/1702/17027415_3.png",
  CONTACTS_HREF: "https://enamine.atlassian.net/servicedesk/customer/portal/23",
  COMPUTATIONAL_SUPPORT_HREF:
    "https://enamine.net/services/computer-aided-drug-design",
  SCREENING_LIBRARIES_HREF: "https://enamine.net/compound-libraries",
  MAILCHIMP_ID: process.env.REACT_APP_MAILCHIMP_ID,
  MAILCHIMP_LIST_ID: process.env.REACT_APP_MAILCHIMP_LIST_ID,
  MAILCHIMP_DOMAIN: process.env.REACT_APP_MAILCHIMP_DOMAIN,
  DECRYPTION_KEY: process.env.REACT_APP_DECRYPTION_KEY,
};

export default common;
