import { Button, CustomizedTooltip, Modal } from "ui";
import { InfoIcon } from "ui/icons";
import { Buttons, DiscardButton, Wrapper } from "./DiscardOrder.styles";

const DiscardOrder = ({
  handleDiscard,
  open,
  setOpen,
  showTooltip,
  setShowTooltip,
}) => {
  return (
    <Wrapper>
      <Modal
        title="Are you sure you want to discard order?"
        open={open}
        setOpen={setOpen}
        width={850}
      >
        <Buttons>
          <Button size="small" type="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            size="small"
            type="outlined-danger"
            onClick={() => {
              handleDiscard();
              setOpen(false);
            }}
          >
            Discard
          </Button>
        </Buttons>
      </Modal>
      <CustomizedTooltip
        title="Discarding an order will cancel your credit card 
        session and you'll be able to start a new one."
        open={showTooltip}
        disableHoverListener
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <DiscardButton
          onClick={() => {
            setOpen(true);
            setShowTooltip(false);
          }}
        >
          <InfoIcon />
          Discard order
        </DiscardButton>
      </CustomizedTooltip>
    </Wrapper>
  );
};

export default DiscardOrder;
