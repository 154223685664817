import styled from "styled-components";
import { accentColor, borderColor, lightBlue } from "ui/colors";

export const Wrapper = styled.div`
  &.upload-modal--hover .upload-modal__file-uploader-wrapper,
  .upload-modal__file-uploader-wrapper:hover {
    background-color: ${lightBlue};
    border-color: ${accentColor};
    cursor: pointer;
  }

  .upload-modal__button-wrapper {
    padding-top: 28px;
    display: flex;
    justify-content: flex-end;

    & > *:not(last-child) {
      margin-left: 16px;
    }
  }

  .upload-modal__icon-wrapper {
  }

  .upload-modal__uploader-title {
    font-size: 18px;
    margin: 0;
    margin-top: 28px;
  }

  .upload-modal__uploader-subtitle {
    margin: 0;
    margin-top: 12px;
  }

  .upload-modal__uploader-button-wrapper {
    margin: 0;
    padding-top: 24px;
  }

  .upload-modal__file-uploader {
    padding: 0;
    display: inline-block;
    width: 100%;
    border-radius: 12px;

    input + div:not(.upload-modal__file-uploader-wrapper) {
      display: none !important;
    }

    &:focus-within {
      outline: none;
    }
  }

  .upload-modal__file-uploader-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px dashed ${borderColor};
    border-radius: 10px;
    padding-top: 55px;
    padding-bottom: 55px;
  }
`;
