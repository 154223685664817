import styled from 'styled-components';
import { ReactComponent as ErrorIcon } from '../../../../icons/error.svg';
import { dangerColor } from 'ui/colors';

export const Wrapper = styled.div`
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled(ErrorIcon)`
  width: 70px;
  path {
    fill: ${dangerColor};
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 40px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 20px;
  margin-bottom: 30px;
`;
