// @ts-check
import React from "react";
import { Wrapper, Title, NotFoundImage } from "./NotFound.styles";

const NotFound = ({ message = "Products not found" }) => {
  return (
    <Wrapper>
      <NotFoundImage />
      <Title>{message}</Title>
    </Wrapper>
  );
};

export default NotFound;
