import React from "react";
import { useDispatch } from "react-redux";
import { checkoutActions } from "../../../../redux/checkout";
import PostalCodeIssueModal from "./PostalCodeIssueModal";

const PostalCodeIssueModalContainer = ({ modal }) => {
  const dispatch = useDispatch();

  const handleClickChangeAddress = () => {
    modal.setOpen(false);
    dispatch(checkoutActions.openAccordion("step3"));
  };

  return (
    <PostalCodeIssueModal
      modal={modal}
      handleClickChangeAddress={handleClickChangeAddress}
    />
  );
};

export default PostalCodeIssueModalContainer;
