// @ts-check
import React from "react";
import { Switch, Route } from "react-router-dom";
import { Section } from "ui";
import { Documentation } from "./components/Documentation";
import apiDocsList from "./docs/searchApiConfig";
import { ApiContainer } from "./components/documentation.styles";

export const DocumentationRoutes = () => {
  return (
    <ApiContainer>
      <Section>
        <Switch>
          <Route path={["/documentation", "/documentation/search-api"]}>
            <Documentation docList={apiDocsList} />
          </Route>
        </Switch>
      </Section>
    </ApiContainer>
  );
};
