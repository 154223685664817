/*
Axios interceptor refresh token for multiple requests:
https://stackoverflow.com/questions/57890667/axios-interceptor-refresh-token-for-multiple-requests
*/

import axios from "axios";

import common from "../constants/common";
import { userActions } from "../redux/user";
import { store } from "../redux/configureStore";
import { endpoints } from "../constants";

const instance = axios.create({
  baseURL: common.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const originalRequest = err.config;

    if (
      err.response.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== "auth/refresh"
    ) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function (resolve, reject) {
        instance
          .post(endpoints.AUTH_REFRESH, {
            refreshToken: store.getState().user.refreshToken,
          })
          .then(({ data }) => {
            store.dispatch(userActions.setRefreshToken(data.refreshToken));

            processQueue(null);
            resolve(instance(originalRequest));
          })
          .catch((err) => {
            processQueue(err);

            store.dispatch(userActions.logout());

            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(err);
  }
);

export default instance;
