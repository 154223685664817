const addZeroes = (num) => {
  const res = num?.toLocaleString("en", {
    useGrouping: false,
    minimumFractionDigits: 2,
  });

  return res;
};

export default addZeroes;
