import { PUSH_ERROR, CLEAR_ERRORS } from "./types";

const pushError = (payload) => {
  return {
    type: PUSH_ERROR,
    payload,
  };
};

const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export default {
  pushError,
  clearErrors,
};
