// @TODO Check logic to do pagination on FE side
export const getCurPage = (items, pageSize) => {
  if (!items) return 1;

  const curPage = Math.floor(items?.length / pageSize + 1);

  return curPage;
};

export const filterCatalogResult = (res) => {
  const newRes = {
    ...res,
  };

  if (res.searchResultQuantity <= res.searchResults.length) {
    const searchResults = res.searchResults.map((el) => el);
    const searchResultQuantity = searchResults.length;

    newRes.searchResults = searchResults;
    newRes.searchResultQuantity = searchResultQuantity;
  }

  return newRes;
};
