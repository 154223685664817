import React from "react";
import { useEffect, useState } from "react";
import getUsStatesOptions from "../../../utils/getUsStatesOptions";
import { useSelector } from "react-redux";
import { Select } from "../../../ui";
import { utilSelectors } from "../../../redux/util";

const StateSelect = ({ formik }) => {
  const [stateOptions, setStateOptions] = useState([]);
  const usStates = useSelector(utilSelectors.selectUsStates);

  useEffect(() => {
    setStateOptions(getUsStatesOptions(usStates));
  }, [usStates]);

  return (
    <Select
      formik={formik}
      label="State"
      name="state"
      options={stateOptions}
      placeholder="Select state"
    />
  );
};

export default StateSelect;
