import { useState } from "react";

const useModal = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState(false);

  return {
    open,
    setOpen,
    message,
    setMessage,
    title,
    setTitle,
  };
};

export default useModal;
