import React from "react";
import { Button } from "ui";
import { ResetPasswordLayout } from "pages/reset-password/components/layout/layout";
import { common } from "constants/index";
import { ResetPasswordIconType } from "pages/reset-password/components/icon/icon";
import { UserMessage } from "ui/user-message/user-message";

const CheckEmailInfo = () => {
  return (
    <ResetPasswordLayout variant={ResetPasswordIconType.MESSAGE_SUCCESS}>
      <UserMessage.Title>Please, check your email</UserMessage.Title>
      <UserMessage.Description>
        We've sent an email to the address associated with your account.
        <br />
        Check your inbox and follow the instructions in the email to reset your
        password.
        <br />
        <br />
        If you don't receive an email within a few minutes or need further
        assistance, please contact our support team.
      </UserMessage.Description>
      <Button size="small" href={common.CONTACTS_HREF}>
        Contact us
      </Button>
    </ResetPasswordLayout>
  );
};

export default CheckEmailInfo;
