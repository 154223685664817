import styled from "styled-components";

export const Wrapper = styled.div`
  &.colors {
  }

  .colors__card-title {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 300;
  }

  .colors__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 50px;
    row-gap: 20px;
  }

  .colors__card-body {
    display: flex;
    align-items: center;
    box-shadow: 0px 5px 10px 5px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }

  .colors__card-color-box {
    width: 50px;
    height: 60px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
  }

  .colors__card-color-text {
    padding-left: 20px;
    color: #51607a;
    font-size: 16px;
  }
`;
