import { common } from "constants/index";
import React from "react";
import { Button, Modal } from "ui/index";
import { ButtonWrapper } from "./ScreeningLibrariesDialog.styles";

const ScreeningLibrariesDialog = ({ open, setOpen, title }) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      boxProps={{ width: "800px", maxWidth: "100%" }}
      title={title}
      dataTestAttr="screening-libraries-modal"
    >
      <Modal.Text>
        Thank you for your interest in the screening compounds libraries. We are
        working on adding the products to our store, meanwhile you can check out
        the latest libraries updates below.
      </Modal.Text>
      <ButtonWrapper>
        <Button
          onClick={() => setOpen(false)}
          size="small"
          type="outlined"
          dataTestAttr="cancel-button"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          size="small"
          href={{ pathname: common.SCREENING_LIBRARIES_HREF }}
          target="_blank"
          dataTestAttr="external-site-link-button"
        >
          Libraries Catalog
        </Button>
      </ButtonWrapper>
    </Modal>
  );
};

export default ScreeningLibrariesDialog;
