import { useEffect } from "react";
import { events, useAmplitudeState } from "../../context/amplitude-context";

const AmplitudeHelper = () => {
  const { amplitude } = useAmplitudeState();

  useEffect(() => {
    amplitude.logEvent(events.cookie_notice_popup);
  }, []);

  return <></>;
};

export default AmplitudeHelper;
