import ModalUnstyled from "@mui/base/ModalUnstyled";
import IconButton from "@mui/material/IconButton";
import styled from "styled-components";
import { accentColor, darkBlue } from "ui/colors";

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 21;

  svg path {
    fill: ${darkBlue};
  }

  &:hover {
    background-color: transparent;
    svg path {
      fill: ${accentColor};
    }
  }
`;

export const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
`;

export const BackdropCustom = styled.div`
  z-index: 1000;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(23, 43, 78, 0.3);
  -webkit-tap-highlight-color: transparent;
`;

export const Title = styled.h2`
  margin: 0;
  padding-left: 28px;
  padding-right: 70px;
  padding-top: 25px;
  font-size: 24px;
  line-height: 27px;
  font-weight: 500;
  position: relative;
  z-index: 20;
`;

export const ContentWrapper = styled.div`
  padding: 28px;
`;

export const TextStyled = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 28px;
`;

export const ButtonsStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 28px;
  gap: 16px;
`;
