export type SendPasswordFormValues = {
  password: string;
  repeatPassword: string;
};

export type ResetPasswordPayload = {
  token: string;
} & SendPasswordFormValues;

export enum ResetPasswordStatus {
  USED = "link-used",
  ERROR = "error",
  OK = "ok",
}
