import * as yup from "yup";
import endpoints from "../../../../../../constants/endpoints";
import validation from "../../../../../../constants/validation";
import customFetch from "../../../../../../utils/customFetch";
import handleError from "../../../../../../utils/handleError";
import sleep from "../../../../../../utils/sleep";

import { fetchUserDetails } from "../../utils";

const initParams = {
  retry: 5,
  retryDelay: 300,
};

export const handleSubmit = async (
  data,
  { formik, dispatchSetProfile, logProfileSave }
) => {
  const [updateProfileData, err] = await fetchUpdateProfile(data);

  if (handleError(err, formik)) return;
  if (updateProfileData.data !== "OK") {
    handleError("Some error when updating!", formik);
    return;
  }

  const [fetchedUserDetails, detailsError] = await fetchUserDetails();

  if (handleError(detailsError, formik)) return;

  await sleep(300);
  logProfileSave();

  dispatchSetProfile(fetchedUserDetails);
  formik.setSubmitting(false);
};

export const fetchUpdateProfile = async (data) => {
  const { email: _, ...preparedData } = data;

  return await customFetch(
    {
      method: "put",
      url: endpoints.USER_DETAILS,
      data: {
        defaultCurrency: "0",
        subscribe: true,
        ...preparedData,
        email: "",
      },
    },
    initParams
  );
};

export const getValidationSchema = () => {
  const validationSchema = yup.object({
    email: validation.EMAIL,
    firstName: validation.FIRST_NAME,
    lastName: validation.LAST_NAME,
    company: validation.COMPANY,
    phone: validation.PHONE,
    terms: yup.bool().oneOf([true], "Field must be checked"),
  });

  return validationSchema;
};
