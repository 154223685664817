import { SyntheticReagentsConfigType } from "./SyntheticReagentsTypes";

export const syntheticReagentsConfig: SyntheticReagentsConfigType[] = [
  {
    title: "Acylation/Formylation",
    catalogUrl: "acylation-formylation",
    codes: [
      "EN300-185455",
      "EN300-3450927",
      "EN300-3553997",
      "EN300-7592173",
      "EN300-7385578",
      "EN300-7206150",
      "EN300-7411013",
    ],
  },
  {
    title: "Alkylation/Arylation",
    catalogUrl: "alkylation-arylation",
    codes: ["EN300-19477491", "EN300-19609"],
  },
  {
    title: "Amination/Deamination",
    catalogUrl: "amination-deamination",
    codes: [
      "EN300-39868153",
      "EN300-101508",
      "EN300-33050767",
      "EN300-46591695",
      "EN300-80797",
      "EN300-19628425",
    ],
  },
  {
    title: "Click-chemistry",
    catalogUrl: "click-chemistry",
    codes: [
      "EN300-20626",
      "EN300-78543",
      "EN300-33267752",
      "EN300-19477491",
      "EN300-133907",
      "EN300-55951",
      "EN300-227474",
    ],
  },
  {
    title: "Condensation Agents",
    catalogUrl: "condensation-agents",
    codes: [
      "EN300-37435118",
      "EN300-239576",
      "EN300-20280",
      "EN300-77565",
      "EN300-21624",
      "EN300-21655",
      "EN300-49821",
      "EN300-17439",
      "EN300-23976",
      "EN300-7361941",
      "EN300-100400",
      "EN300-300704",
      "EN300-316966",
      "EN300-21556",
      "EN300-21023",
    ],
  },
  {
    title: "Cross-coupling",
    catalogUrl: "cross-coupling",
    codes: ["EN300-346030", "EN300-127576", "EN300-173458"],
  },
  {
    title: "DNA/peptide modifications",
    catalogUrl: "dna-peptide-modifications",
    codes: [
      "EN300-37435118",
      "EN300-239576",
      "EN300-20280",
      "EN300-77565",
      "EN300-21624",
      "EN300-21655",
      "EN300-49821",
      "EN300-212574",
      "EN300-17439",
      "EN300-7492597",
      "EN300-23976",
      "EN300-318150",
      "EN300-7363509",
      "EN300-7375554",
      "EN300-43394895",
      "EN300-17466",
    ],
  },
  {
    title: "Fluorination/Fluoroalkylation",
    catalogUrl: "fluorination-fluoroalkylation",
    codes: [
      "EN300-101267",
      "EN300-108124",
      "EN300-244367",
      "EN300-97056",
      "EN300-118467",
      "EN300-136055",
      "EN300-204918",
      "EN300-98145",
      "EN300-138725",
      "EN300-195971",
      "EN300-7401892",
      "EN300-18527578",
      "EN300-7209485",
      "EN300-8393932",
      "EN300-258021",
      "EN300-22891572",
      "EN300-30885",
      "EN300-43352843",
      "EN300-7401892",
      "EN300-258021",
      "EN300-154148",
      "EN300-204918",
      "EN300-39847676",
      "EN300-26950446",
      "EN300-51162",
      "EN300-56237",
    ],
  },
  {
    title: "Halogenation/Dehalogenation",
    catalogUrl: "halogenation-dehalogenation",
    codes: [
      "EN300-19692",
      "EN300-7394435",
      "EN300-3543882",
      "EN300-120673",
      "EN300-1664590",
      "EN300-1717054",
      "EN300-19454",
      "EN300-19870",
      "EN300-20081",
      "EN300-262963",
      "EN300-262964",
      "EN300-27158741",
      "EN300-52551",
      "EN300-7166637",
      "EN300-7417367",
      "EN300-1698807",
      "EN300-7582372",
      "EN300-8167356",
    ],
  },
  {
    title: "Organometalics",
    catalogUrl: "organometalics",
    codes: ["EN300-7427817", "EN300-77910", "EN300-57239"],
  },
  {
    title: "Organophosphorus Reagents",
    catalogUrl: "organophosphorus-reagents",
    codes: [
      "EN300-20626",
      "EN300-239576",
      "EN300-19477491",
      "EN300-105912",
      "EN300-133907",
      "EN300-55951",
      "EN300-101508",
      "EN300-19609",
      "EN300-298220",
      "EN300-7492597",
      "EN300-52552",
      "EN300-1724932",
      "EN300-316330",
      "EN300-7352927",
      "EN300-17081",
      "EN300-19626223",
      "EN300-1709364",
      "EN300-7418579",
      "EN300-22865587",
      "EN300-193826",
      "EN300-23607735",
      "EN300-223238",
      "EN300-78927",
      "EN300-1086660",
      "EN300-21235",
      "EN300-7403821",
      "EN300-7870276",
      "EN300-343729",
      "EN300-371777",
      "EN300-247255",
      "EN300-1084778",
      "EN300-24204356",
      "EN300-266546",
      "EN300-23920",
      "EN300-7356975",
      "EN300-316539",
      "EN300-211946",
      "EN300-18559180",
    ],
  },
  {
    title: "Oxidation/Reduction",
    catalogUrl: "oxidation-reduction",
    codes: [
      "EN300-346030",
      "EN300-66581",
      "EN300-19692",
      "EN300-113070",
      "EN300-43675",
      "EN300-125971",
      "EN300-120673",
      "EN300-1724932",
      "EN300-26986",
      "EN300-7427817",
      "EN300-51835347",
      "EN300-7421023",
      "EN300-7419267",
      "EN300-26277098",
      "EN300-33317489",
      "EN300-21637606",
      "EN300-18599622",
      "EN300-7423026",
    ],
  },
  {
    title: "Protection/Deprotection",
    catalogUrl: "protection-deprotection",
    codes: [
      "EN300-20626",
      "EN300-45159795",
      "EN300-45671553",
      "EN300-30885",
      "EN300-7394471",
    ],
  },
  {
    title: "Synthetic Reagents Other",
    catalogUrl: "synthetic-reagents-other",
    codes: [
      "EN300-253475",
      "EN300-321062",
      "EN300-211397",
      "EN300-1987789",
      "EN300-118173",
      "EN300-54483",
      "EN300-8668096",
      "EN300-6487157",
      "EN300-43394895",
      "EN300-45333",
      "EN300-37378975",
      "EN300-17081",
      "EN300-27164666",
      "EN300-25072102",
      "EN300-22249032",
      "EN300-8665447",
      "EN300-27734787",
      "EN300-19626639",
      "EN300-7404769",
      "EN300-26784",
      "EN300-18665849",
      "EN300-26189083",
      "EN300-19627579",
      "EN300-7406699",
      "EN300-205456",
      "EN300-209560",
      "EN300-216361",
      "EN300-26485507",
      "EN300-6495224",
      "EN300-7423026",
      "EN300-55379",
    ],
  },
  {
    title: "Cyanation/Cyanoalkylation",
    catalogUrl: "cyanation-cyanoalkylation",
    codes: [
      "EN300-19189",
      "EN300-127576",
      "EN300-6731863",
      "EN300-7867959",
      "EN300-29571",
      "EN300-57100",
      "EN300-105912",
      "EN300-749626",
      "EN300-212574",
      "EN300-154751",
      "EN300-6731863",
    ],
  },
  {
    title: "Sulfofluorination",
    catalogUrl: "sulfofluorination",
    codes: ["EN300-6745900", "EN300-43675", "EN300-6734306"],
  },
  {
    title: "Protection",
    catalogUrl: "protection",
    codes: [
      "EN300-20626",
      "EN300-45159795",
      "EN300-45671553",
      "EN300-100400",
      "EN300-30885",
      "EN300-7394471",
      "EN300-7541092",
      "EN300-18528009",
      "EN300-18574111",
      "EN300-7817131",
      "EN300-9411742",
      "EN300-7606163",
      "EN300-214831",
      "EN300-25973",
      "EN300-18691374",
      "EN300-211133",
      "EN300-7368622",
      "EN300-7423933",
      "EN300-68359",
      "EN300-6766546",
      "EN300-5211169",
      "EN300-175786",
      "EN300-7363446",
      "EN300-7392725",
      "EN300-7358435",
      "EN300-113943",
      "EN300-317051",
      "EN300-7416793",
      "EN300-7381616",
      "EN300-706234",
      "EN300-7410228",
      "EN300-18552155",
      "EN300-7383611",
      "EN300-22865587",
      "EN300-193826",
      "EN300-239576",
      "EN300-7356975",
      "EN300-23703515",
      "EN300-6496297",
      "EN300-7398026",
      "EN300-19940",
      "EN300-8764604",
      "EN300-7366614",
      "EN300-39074",
      "EN300-11788308",
      "EN300-7400001",
      "EN300-23924885",
      "EN300-7204359",
      "EN300-7388712",
      "EN300-37509",
      "EN300-128285",
      "EN300-6762257",
      "EN300-7400482",
      "EN300-77226",
    ],
  },
  {
    title: "Alkylation/Alkenylation/Alkynylation",
    catalogUrl: "alkylation-alkenylation-alkynylation",
    codes: [
      "EN300-133907",
      "EN300-1709364",
      "EN300-1724932",
      "EN300-19477491",
      "EN300-19609",
      "EN300-239576",
      "EN300-316330",
      "EN300-52552",
      "EN300-7352927",
      "EN300-7407239",
      "EN300-7418579",
      "EN300-19626223",
    ],
  },
  {
    title: "Amide Coupling Agents",
    catalogUrl: "amide-coupling-agents",
    codes: [
      "EN300-37435118",
      "EN300-20280",
      "EN300-77565",
      "EN300-21624",
      "EN300-21655",
      "EN300-49821",
      "EN300-17439",
      "EN300-23976",
      "EN300-7361941",
      "EN300-300704",
      "EN300-316330",
      "EN300-316966",
      "EN300-21556",
      "EN300-21023",
      "EN300-37435118",
      "EN300-310977",
      "EN300-21097",
      "EN300-1091520",
      "EN300-7386471",
      "EN300-17466",
      "EN300-7413209",
      "EN300-7367861",
      "EN300-317051",
      "EN300-7372770",
      "EN300-317025",
      "EN300-7390649",
      "EN300-18543889",
      "EN300-12441198",
    ],
  },
  {
    title: "Crown-ether",
    catalogUrl: "crown-ether",
    codes: [
      "EN300-37135",
      "EN300-6743589",
      "EN300-7403338",
      "EN300-17608",
      "EN300-111213",
      "EN300-6743669",
      "EN300-6743610",
      "EN300-7396258",
      "EN300-6743585",
      "EN300-6743602",
      "EN300-6743614",
      "EN300-202126",
      "EN300-136271",
      "EN300-19748908",
      "EN300-199770",
      "EN300-6743625",
    ],
  },
  {
    title: "Vinylation",
    catalogUrl: "vinylation",
    codes: [
      "EN300-7405738",
      "EN300-6490756",
      "EN300-107820",
      "EN300-7405193",
      "EN300-344893",
      "EN300-91288",
      "EN300-18528090",
      "EN300-111842",
      "EN300-7406338",
      "EN300-7613852",
      "EN300-7405198",
      "EN300-142072",
      "EN300-92920",
    ],
  },
  {
    title: "Diazotransfer",
    catalogUrl: "diazotransfer",
    codes: ["EN300-74206", "EN300-133907", "EN300-1070022", "EN300-86232"],
  },
  {
    title: "Nitrosation/Nitration",
    catalogUrl: "nitrosation-nitration",
    codes: [
      "EN300-3476413",
      "EN300-7411622",
      "EN300-27164666",
      "EN300-35960",
      "EN300-7411622",
      "EN300-46631282",
    ],
  },
];
