import React from "react";
import amplitude from "amplitude-js";
import common from "../constants/common";

const AmplitudeContext = React.createContext();

const amplitudeInstance = amplitude.getInstance();

amplitudeInstance.init(common.AMPLITUDE_KEY, null, {
  includeReferrer: true,
  includeUtm: true,
  includeGclid: true,
});

const events = {
  session_start: "session_start",
  forgot_password: "forgot_password",
  sign_up: "sign_up",
  search: "search",
  search_click: "search_click",
  search_start: "search_start",
  search_category: "search_category",
  search_type: "search_type",
  catalog_item_add: "catalog_item_add",
  search_stock_location: "search_stock_location",
  search_bb_classses: "search_bb_classses",
  search_sc_collections: "search_sc_collections",
  error_page: "error_page",
  cookie_notice_popup: "cookie_notice_popup",
  cookie_notice_accept: "cookie_notice_accept",
  contact_us: "contact_us",
  privacy_policy: "privacy_policy",
  currency_select: "currency_select",
  Home_click: "Home_click",
  login_click: "login_click",
  remember_me: "remember_me",
  login_success: "Login_success",
  login_failure: "login_failure",
  logout: "logout",
  personal_info_click: "personal_info_click",
  profile_click: "profile_click",
  profile_save: "profile_save",
  profile_orders_click: "profile_orders_click",
  profile_addresses_click: "profile_addresses_click",
  profile_address_add: "profile_address_add",
  profile_address_edit: "profile_address_edit",
  profile_changepass_click: "profile_changepass_click",
  search_nothing_found: "search_nothing_found",
  donwload_sdf_click: "donwload_sdf_click",
  change_view_click: "change_view_click",
  search_type: "search_type",
  search_category: "search_category",
  pagination_click: "pagination_click",
  show_by: "show_by",
  search_stock_location: "search_stock_location",
  download_sds: "download_sds",
  find_analogs: "find_analogs",
  related_click: "related_click",
  cart_change_quantity: "cart_change_quantity",
  cart_clear_cart: "cart_clear_cart",
  cart_request_quote_click: "cart_request_quote_click",
  cart_checkout_click: "cart_checkout_click",
  quote_sent: "quote_sent",
  order_start: "order_start",
  checkout_carrier: "checkout_carrier",
  checkout_contact: "checkout_contact",
  checkout_billing: "checkout_billing",
  checkout_shipping: "checkout_shipping",
  checkout_payment: "checkout_payment",
  checkout_order: "checkout_order",
  checkout_to_search: "checkout_to_search",
  checkout_to_my_orders: "checkout_to_my_orders",
  product_details_opened: "product_details_opened",
  new_design_rollback: "new_design_rollback",
  new_design_enroll: "new_design_enroll",
  screening_libraries: "screening_libraries",
  product_page_details_click: "product_page_details_click",
  product_page_equivalents_click: "product_page_equivalents_click",
  product_page_analogs_click: "product_page_analogs_click",
  subscribe_click: "subscribe_click",
  all_to_cart: "all_to_cart",
  cart_item_removed: "cart_item_removed",
  subscription_email: "subscription_email",
  category_click: "category_click"
};

const initialState = {
  amplitude: amplitudeInstance,
};

function AmplitudeProvider({ children }) {
  return (
    <AmplitudeContext.Provider value={initialState}>
      {children}
    </AmplitudeContext.Provider>
  );
}

function useAmplitudeState() {
  const context = React.useContext(AmplitudeContext);
  if (context === undefined) {
    throw new Error(
      "useAmplitudeState must be used within a AmplitudeProvider"
    );
  }
  return context;
}

export { AmplitudeProvider, useAmplitudeState, events };
