// @ts-check

import styled from "styled-components";
import { blue, darkBlue, lightBlue } from "ui/colors";

export const Wrapper = styled.div`
  background-color: ${lightBlue};
  background-image: url("/newsletter-background.svg");
  background-position: bottom;
  padding: 60px 0;
  text-align: center;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  color: ${darkBlue};
`;

export const Description = styled.p`
  margin: 18px 0 0;
  font-size: 18px;
  line-height: 26px;
  color: ${blue};
`;
