import React, { useEffect, useState } from "react";
import { searchAsTypes } from "../../../../constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";
import { CatalogFilterFormGroup } from "../../components/CatalogFilters/CatalogFilters.styles";

const SstypeTitles = {
  [searchAsTypes.EXACT]: "Exact",
  [searchAsTypes.SUB]: "Substructure",
  [searchAsTypes.SIM]: "Similarity",
};

const sstypeOptions = [
  {
    id: searchAsTypes.EXACT,
    title: SstypeTitles[searchAsTypes.EXACT],
    active: false,
  },
  {
    id: searchAsTypes.SUB,
    title: SstypeTitles[searchAsTypes.SUB],
    active: false,
  },
  {
    id: searchAsTypes.SIM,
    title: SstypeTitles[searchAsTypes.SIM],
    active: false,
  },
];

type FilterSsTypesProps = {
  fieldName: string;
  sstype: string;
  formik: FormikProps<FormikValues>;
};

const FilterSsTypes = ({ fieldName, sstype, formik }: FilterSsTypesProps) => {
  const [list, setList] = useState([...sstypeOptions]);

  useEffect(() => {
    setList((list) =>
      list.map((item) => ({
        ...item,
        active: sstype === item.id,
      }))
    );
  }, [sstype]);

  return (
    <CatalogFilterFormGroup>
      {list?.map(({ id, title }, index) => (
        <FormControlLabel
          key={index}
          control={
            <Radio
              name={fieldName}
              onChange={formik?.handleChange}
              checked={formik?.values[fieldName] === id}
              value={id}
            />
          }
          label={title}
        />
      ))}
    </CatalogFilterFormGroup>
  );
};

export default FilterSsTypes;
