export const errorMessages = {
  TOO_SHORT: "Password must be at least 8 characters long.",
  TOO_WEAK:
    "Your password is too predictable, common, or using well-known and easy to guess password words or patterns like 'pass', 'user', 'QWerty' etc. Please choose more versatile and less guessable combination.",
  NO_NUMBERS: "Include at least one number in your password.",
  NO_UPPER_LETTERS: "Include at least one uppercase letter in your password.",
  TOO_LOW_DIVERSITY:
    "Your password contains too many duplicates of the same character.",
  NO_SPECIAL_CHARS: "Include at least one special character in your password.",
  NO_LOWER_LETTERS: "Include at least one lowercase letter in your password.",
};
