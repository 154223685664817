// @ts-check
import React from "react";
import { common } from "../../../../constants";
import { UserMessage } from "ui/user-message/user-message";
import { ResetPasswordLayout } from "pages/reset-password/components/layout/layout";
import { ResetPasswordIconType } from "pages/reset-password/components/icon/icon";

const LinkNotValid = () => {
  return (
    <ResetPasswordLayout variant={ResetPasswordIconType.MESSAGE_DANGER}>
      <UserMessage.Title>
        Sorry, password reset link is not valid anymore
      </UserMessage.Title>
      <UserMessage.Description>
        You've done new attempts already and current link is not valid. Please
        use the latest link sent on your email or request new reset password
        link.
      </UserMessage.Description>
      <UserMessage.Description>
        In case you encounter any difficulties, our{" "}
        <UserMessage.ExternalLink href={common.CONTACTS_HREF} target="_blank">
          support team
        </UserMessage.ExternalLink>{" "}
        is available to assist you.
      </UserMessage.Description>
    </ResetPasswordLayout>
  );
};

export default LinkNotValid;
