import React from "react";
import { Input } from "../../../ui";

const Address = ({
  formik,
  dataTestAttr = "input-address-line-1",
  ...props
}) => {
  return (
    <Input
      label="Address"
      name="addressLine1"
      formik={formik}
      data-test={dataTestAttr}
      placeholder="Enter address"
      {...props}
    />
  );
};

export default Address;
