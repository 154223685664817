import React, { useEffect, useState } from "react";
import { fetchOrders } from "./utils";
import Orders from "./Orders";
import { usePagination, useQuery } from "../../../../hooks";
import { makeParams } from "../../../../utils";
import { useHistory } from "react-router-dom";

const OrdersContainer = () => {
  const query = useQuery();
  const history = useHistory();
  const [ordersList, setOrdersList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const curPage = parseInt(query.get("curPage")) || 1;
  const pageSize = 10;

  const [pagination] = usePagination({
    total,
    pageSize,
    curPage,
  });

  const onHandleClickPagination = (page) => {
    const queryParams = makeParams({
      curPage: page,
    });

    history.push(`/profile/orders?${queryParams}`);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const res = await fetchOrders({ curPage, pageSize });

      if (res[0]) {
        setTotal(res[0].ordersResultQuantity);

        const enrichedList = res[0].orders.map((el) => {
          const detailsLink = `/profile/orders/${el.number}?fromPage=${curPage}`;

          return {
            ...el,
            detailsLink,
          };
        });
        setOrdersList(enrichedList);
      }

      setIsLoading(false);
    })();
  }, [curPage]);

  return (
    <Orders
      list={ordersList}
      isLoading={isLoading}
      pagination={pagination}
      onHandleClickPagination={onHandleClickPagination}
    />
  );
};

export default OrdersContainer;
