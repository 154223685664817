import * as yup from "yup";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import validation from "constants/validation";
import { customFetch } from "utils";
import { endpoints } from "constants";
import { events, useAmplitudeState } from "context/amplitude-context";
import { toast } from "react-toastify";
import { CircleCheckIcon } from "ui/icons";
import superCartActions from "features/super-cart/redux/actions";
import QuoteModal from ".";

const initialValues = {
  fullName: "",
  email: "",
  comment: "",
};

const modes = {
  DEFAULT: "DEFAULT",
  SENDING: "SENDING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

const initParams = {
  retry: 5,
  retryDelay: 300,
};

const QuoteModalContainer = ({ quote, products = [] }) => {
  const { amplitude } = useAmplitudeState();
  const dispatch = useDispatch();
  const [mode, setMode] = useState(modes.DEFAULT);

  const handleClose = () => {
    quote.setOpen(false);

    // HACK
    setTimeout(() => {
      setMode(modes.DEFAULT);
    }, 300);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(),
    onSubmit: async (values) => {
      amplitude.logEvent(events.quote_sent);

      setMode(modes.SENDING);

      const data = {
        fullName: values.fullName,
        email: values.email,
        comment: values.comment,
      };

      const res = await customFetch(
        {
          url: endpoints.ORDERS_QUOTE,
          method: "post",
          data,
        },
        initParams
      );

      if (res[0]) {
        products.forEach((productItem) => {
          const revenue = new amplitude.Revenue()
            .setProductId(productItem.code)
            .setPrice(Math.floor(productItem.price * productItem.usCurK))
            .setQuantity(productItem.quantity)
            .setRevenueType("quote");

          amplitude.logRevenueV2(revenue);
        });

        handleClose();
        formik.setValues(initialValues, false);
        dispatch(superCartActions.clear());
        toast.success("Thank you! Your quote has been sent", {
          icon: <CircleCheckIcon />,
          autoClose: 6000,
        });
        return;
      }

      setMode(modes.ERROR);
      return;
    },
  });

  return (
    <QuoteModal
      open={quote.open}
      setOpen={quote.setOpen}
      handleClose={handleClose}
      formik={formik}
      mode={mode}
      modes={modes}
    />
  );
};

export const getValidationSchema = () => {
  const validationSchema = yup.object({
    email: validation.EMAIL,
    fullName: validation.FULL_NAME.required("Full Name is required"),
    comment: validation.COMMENT.required("Comment is required"),
  });

  return validationSchema;
};

export default QuoteModalContainer;
