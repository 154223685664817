import React from "react";

import Thank from "./parts/thank/Thank";
import Error from "./parts/error/Error";

import { Wrapper } from "./PaymentStatus.styles";

const PaymentStatus = ({ loading, error, success }) => {
  return (
    <Wrapper>
      {loading ? (
        <div>Checking payment status...</div>
      ) : (
        <div>
          {error && <Error error={error} />}
          {success && <Thank />}
        </div>
      )}
    </Wrapper>
  );
};

export default PaymentStatus;
