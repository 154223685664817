import React from "react";
import { Button } from "ui";
import { common } from "../../../../constants";
import { ResetPasswordLayout } from "pages/reset-password/components/layout/layout";
import { ResetPasswordIconType } from "pages/reset-password/components/icon/icon";
import { UserMessage } from "ui/user-message/user-message";

const EmailNotFound = () => (
  <ResetPasswordLayout variant={ResetPasswordIconType.MESSAGE_WARNING}>
    <UserMessage.Title>
      Sorry, we couldn't find an account linked to the provided email address
    </UserMessage.Title>
    <UserMessage.Description>
      If you haven't registered with us yet, click below to create your account:
    </UserMessage.Description>
    <UserMessage.ActionWrapper>
      <Button size="small" href="/signup">
        Register New Account
      </Button>
    </UserMessage.ActionWrapper>
    <UserMessage.Description>
      In case you encounter any difficulties,{" "}
      <UserMessage.ExternalLink href={common.CONTACTS_HREF} target="_blank">
        support team
      </UserMessage.ExternalLink>{" "}
      is available to assist you.
    </UserMessage.Description>
  </ResetPasswordLayout>
);

export default EmailNotFound;
