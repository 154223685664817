// @ts-check
import React from "react";
import { useSelector } from "react-redux";
import { productSelectors } from "redux/product";
import { ProductSaltForms } from "./product-salt-forms";

/**
 * @typedef SaltForm
 * @property {number} cdId
 * @property {string} productType
 * @property {string} smiles
 * @property {string} code
 */

/**
 * @type { React.FC<{ currency: string}>}
 */
export const ProductSaltFormsContainer = ({ currency }) => {
  const saltForms = useSelector(productSelectors.selectProductSaltForms);

  return saltForms?.items.length ?  <ProductSaltForms productForms={saltForms?.items} loading={false} /> : null;
};
