import styled from "styled-components";
import { dangerColor } from "ui/colors";

export const Wrapper = styled.div`
  padding-top: 19px;

  ${({ disabled }) => {
    if (disabled) {
      return `
        opacity: 0.5;
        pointer-events: none;
      `;
    }
  }}
`;

export const InputWrapper = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const CardInner = styled.div`
  flex-grow: 1;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
`;

export const ButtonWrapper = styled.div`
  padding-top: 15px;
`;

export const ErrorMessage = styled.p`
  color: ${dangerColor};
`;

export const TextRow = styled.p`
  margin: 0;
`;

export const CardWrapper = styled.div`
  padding-bottom: 18px;
`;
