import { restrictionKeys } from "../../../../constants";
import { getRestrictionMessage } from "../../../../utils";

export const generateMessagesBasedOnKeysInString = (stringWithKeys) => {
  const keys = stringWithKeys.split("|");
  const component = keys
    .filter((el) => el)
    .map((el, idx) => {
      const message = getRestrictionMessage(el);

      return (
        <div
          key={idx}
          style={{
            marginBottom: "10px",
          }}
        >
          {message}
        </div>
      );
    });

  return component;
};

export const isDisableOrderPlacement = (stringWithKeys) => {
  const keys = stringWithKeys.split("|");

  if (keys?.[1]) return true;

  if (keys?.[0] === restrictionKeys.IATA_NOT_ALLOWED) return false;

  return true;
};
