import React from "react";
import { Button } from "ui";
import { ResetPasswordLayout } from "pages/reset-password/components/layout/layout";
import { common } from "constants";
import { rootPages } from "constants";
import { UserMessage } from "ui/user-message/user-message";
import { ResetPasswordIconType } from "pages/reset-password/components/icon/icon";

const LinkAlreadyUsed = () => {
  return (
    <ResetPasswordLayout variant={ResetPasswordIconType.MESSAGE_DANGER}>
      <UserMessage.Title>
        Sorry, password reset link has been already used and cannot be used
        anymore
      </UserMessage.Title>
      <UserMessage.Description>
        Please request new reset password link.
      </UserMessage.Description>
      <UserMessage.ActionWrapper>
        <Button size="small" href={`/${rootPages.RESET_PASSWORD}`}>
          Reset Password
        </Button>
      </UserMessage.ActionWrapper>
      <UserMessage.Description>
        In case you encounter any difficulties, our{" "}
        <UserMessage.ExternalLink href={common.CONTACTS_HREF} target="_blank">
          support team
        </UserMessage.ExternalLink>{" "}
        is available to assist you.
      </UserMessage.Description>
    </ResetPasswordLayout>
  );
};

export default LinkAlreadyUsed;
