import {
  SET_IMAGE,
  SET_INSTOCK_EQUIVALENTS,
  SET_PRODUCT_ANALOGS,
  SET_PRODUCT_SALT_FORMS,
  SET_PRODUCT_WITH_PRICES,
} from "./types";

const initialState = {
  item: null,
  image: null,
  saltForms: {},
  analogs: {},
  inStockEquivalents: {},
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCT_WITH_PRICES:
      return {
        ...state,
        item: action.payload,
      };
    case SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case SET_INSTOCK_EQUIVALENTS:
      return {
        ...state,
        inStockEquivalents: {
          ...state.inStockEquivalents,
          [action.payload.code]: {
            items: action.payload.items,
          },
        },
      };
    case SET_PRODUCT_ANALOGS:
      return {
        ...state,
        analogs: {
          ...state.analogs,
          [action.payload.code]: {
            items: action.payload.items,
          },
        },
      };
    case SET_PRODUCT_SALT_FORMS:
      return {
        ...state,
        saltForms: {
          ...state.saltForms,
          [action.payload.code]: {
            items: action.payload.items,
          },
        },
      };
    default:
      return state;
  }
};

export default productReducer;
