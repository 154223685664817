import React from "react";
import { Input } from "../../../ui";

const Zip = ({ formik, dataTestAttr = "input-zip", ...props }) => {
  return (
    <Input
      label="Postal Code"
      name="zip"
      formik={formik}
      data-test={dataTestAttr}
      placeholder="Enter postal code"
      {...props}
    />
  );
};

export default Zip;
