import { useSelector } from "react-redux";
import { Backdrop } from "ui";
import { loadersSelectors } from "../redux";
import React from "react";
import Lottie from "lottie-react";
import loaderFullPage from "./LoaderFullPage.json";

const LoaderFullPage = () => {
  const isLoaderFullPageShown = useSelector(
    loadersSelectors.selectLoaderFullPage
  );

  if (!isLoaderFullPageShown) return null;

  return (
    <Backdrop>
      <Lottie
        style={{ height: "230px" }}
        animationData={loaderFullPage}
        loop={true}
      />
    </Backdrop>
  );
};

export default LoaderFullPage;
