import React from "react";
import LabledTabs from "./LabledTabs";

const LabledTabsContainer = ({ items, activeId, setActiveId }) => {
  const handleTabClick = (clickedId) => {
    setActiveId(clickedId);
  };

  return (
    <LabledTabs
      items={items}
      activeId={activeId}
      handleTabClick={handleTabClick}
    />
  );
};

export default LabledTabsContainer;
