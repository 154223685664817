import React from "react";
import { Button, RadioCard } from "ui/index";
import {
  Wrapper,
  CardInner,
  CardWrapper,
  TextRow,
  ButtonWrapper,
  ErrorMessage,
} from "./Shipping.styles";
import NewAddressForm from "./parts/new-address-form/NewAddressForm.container";

const Shipping = ({
  items,
  handleSelectAddress,
  selectedId,
  newAddressFormProps,
  handleClick,
  buttonClickedOnce,
  disabled,
  countries,
  usStates,
}) => {
  return (
    <Wrapper disabled={disabled}>
      {items && items?.length ? (
        <>
          {items.map((item, idx) => {
            const isActive = item.id === selectedId;

            return (
              <CardWrapper key={idx}>
                <RadioCard
                  handleClick={() => handleSelectAddress(item?.id)}
                  active={isActive}
                >
                  <CardInner data-element="card-wrapper">
                    <TextRow>{countries?.[item?.country]}</TextRow>
                    {item?.state ? (
                      <TextRow>{usStates?.[item?.state]}</TextRow>
                    ) : (
                      ""
                    )}
                    <TextRow>{item?.city}</TextRow>
                    <TextRow>{item?.addressLine1}</TextRow>
                    <TextRow>{item?.zip}</TextRow>
                    <TextRow>{item?.phone}</TextRow>
                    <TextRow>{item?.company}</TextRow>
                    {item?.vat ? <TextRow>{item?.vat}</TextRow> : ""}
                  </CardInner>
                </RadioCard>
              </CardWrapper>
            );
          })}
        </>
      ) : null}
      {!selectedId && buttonClickedOnce && items?.length ? (
        <ErrorMessage>Please select address</ErrorMessage>
      ) : (
        ""
      )}
      {!selectedId && buttonClickedOnce && !items?.length ? (
        <ErrorMessage>Please add new address</ErrorMessage>
      ) : (
        ""
      )}
      {items && items?.length < 5 ? (
        <NewAddressForm newAddressFormProps={newAddressFormProps} />
      ) : (
        ""
      )}

      <ButtonWrapper>
        <Button onClick={handleClick} size="small" fullWidth>
          Next
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Shipping;
