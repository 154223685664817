export enum FilterTypes {
  sstype = "sstype",
  category = "category",
  LOCATION = "location",
  classes = "classes",
  subclasses = "subclasses",
  MW = "MW",
  CLogP = "CLogP",
  HBA = "HBA",
  HBD = "HBD",
  RotB = "RotB",
  TPSA = "TPSA",
  HAC = "HAC",
  Fsp3 = "Fsp3",
  collections = "collections",
  similarity = "similarity",
}
