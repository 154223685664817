import styled from "styled-components";
import {
  accentColor,
  blue,
  darkBlue,
  lightBlue,
} from "../../../../../ui/colors";
import ArrowRightIconImage from "icons/arrow-right-2.svg";

export const SearchReagentsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  margin: 16px auto 0;
  max-width: 950px;
  min-height: 88px;
  box-sizing: border-box;

  padding: 14px 16px;
  background-color: ${lightBlue};
  background-image: url("/union.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
  border-radius: 8px;
  color: ${darkBlue};
  overflow: hidden;
  cursor: pointer;

  &:hover {
    outline: 1px solid ${accentColor};
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: "";
    position: absolute;
    background-image: url(${ArrowRightIconImage});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 36px;
    height: 36px;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.3s ease;
  }
`;

export const SearchReagentsTitle = styled.div`
  display: inline;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;

  ${SearchReagentsWrapper}:hover & {
    color: ${accentColor};
  }
`;

export const SearchReagentsDescription = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: ${blue};
`;
