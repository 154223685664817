import React from "react";
import getUserTitlesOptions from "../../../utils/getUserTitlesOptions";
import { Select } from "../../../ui";

const UserTitleSelect = ({
  formik,
  dataTestAttr = "user-title-select",
  ...rest
}) => {
  const titleOptions = getUserTitlesOptions();

  return (
    <>
      <Select
        formik={formik}
        label="Title"
        name="title"
        options={titleOptions}
        dataTestAttr={dataTestAttr}
        {...rest}
      />
    </>
  );
};

export default UserTitleSelect;
