import React from "react";
import Breadcrumbs from "./breadcrumbs";

const initialItems = [
  {
    title: "Home",
    href: "/",
  },
];

const BreadcrumbsContainer = ({ items }) => {
  const finalItems = [...initialItems, ...items];

  return <Breadcrumbs items={finalItems} />;
};

export default BreadcrumbsContainer;
