import styled from 'styled-components';

export const Wrapper = styled.div`
    display: inline-block;
    position: relative;
`;

export const MarkIconWrapper = styled.div`
    position: absolute;
    top: 10px;
    right: -12px;
`;