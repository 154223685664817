import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
`;

export const Message = styled.h2`
  margin: 0;
  margin-top: 20px;
  font-weight: 400;
`;
