import React from "react";
import classNames from "classnames";
import { Wrapper } from "../input/input.styles";
import { FormikProps } from "formik";
import { FormikValues } from "formik/dist/types";

type FromToInputProps = {
  [key: string]: any;
  label?: string;
  formik: FormikProps<FormikValues>;
  name: string;
  subName: string;
  type?: string;
  disabled?: boolean;
  value?: string;
  handleChange?: () => void;
  className?: string;
  inputHeight?: string;
};

const FromToInput = ({
  label,
  formik,
  name,
  subName,
  type = "text",
  disabled,
  value = "",
  handleChange,
  className,
  inputHeight,
  ...rest
}: FromToInputProps) => {
  const hasError = formik?.touched[name] && Boolean(formik?.errors[name]);
  const errorMessage = formik?.touched[name] && formik?.errors[name];

  const classes = classNames(
    "input",
    {
      "is-error": hasError,
      "is-disabled": disabled,
    },
    className
  );

  return (
    <Wrapper className={classes}>
      <label className="input__label" htmlFor={name} disabled={disabled}>
        {label}
      </label>
      <input
        className="input__input"
        name={`${name}.${subName}`}
        id={`${name}.${subName}`}
        value={(formik?.values[name] && formik?.values[name][subName]) || ""}
        onChange={formik?.handleChange || handleChange}
        type={type}
        disabled={disabled}
        style={{ height: inputHeight ? inputHeight : undefined }}
        {...rest}
      />
      {errorMessage && Array.isArray(errorMessage) && (
        <>
          {errorMessage.map((el, idx) => {
            return (
              <div className="input__error-message" key={idx}>
                {el}
              </div>
            );
          })}
        </>
      )}

      {errorMessage && (
        <div className="input__error-message">
          {errorMessage[subName]?.split(".").join(" ")}
        </div>
      )}
    </Wrapper>
  );
};

export default FromToInput;
