const userTitles = [
  "Undefined",
  "Mr",
  "Ms",
  "Miss",
  "Mrs",
  "PhD",
  "Dr",
  "Prof",
];

export default userTitles;
