import { useEffect, useState } from "react";
import storage from "./data/storage";
// import getImageSrcFromEnamineTools from "./data/getImageSrcFromEnamineTools";
import MarvinImage from "./marvin-image";

const MarvinImageContainer = ({ smile, variant = "default" }) => {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    const image = storage.getItem(smile);

    if (image) {
      setSrc(image);
    } else {
      // SHOP-3778: enamine tools service is not visible outside, as a hotfix -
      // remove any requests to the service.
      /**
       const srcEnamineTools = getImageSrcFromEnamineTools(smile);
       setSrc(srcEnamineTools);
      */
      setSrc(null);
    }
  }, [smile]);

  return <MarvinImage src={src} variant={variant} />;
};

export default MarvinImageContainer;
