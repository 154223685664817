import styled from "styled-components";
import { media } from "../../constants";
import constants from "../constants";

export const Wrapper = styled.header`
  display: flex;
  background-color: #fff;
  box-sizing: border-box;
  position: fixed;
  right: 0;
  width: 100%;
  top: 0;
  z-index: 21;
  height: 88px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
`;

export const Cells = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: ${constants.maxWidth}px;
  max-width: 100%;
  margin: 0 auto;
  padding-right: 40px;
  padding-left: 40px;
  box-sizing: border-box;
`;

export const ButtonWrapper = styled.div`
  margin-left: 12px;
`;

export const NavLinks = styled.div`
  display: flex;
  padding-left: 50px;
  width: 100%;

  @media ${media.TABLET} {
    padding-left: 20px;
  }

  @media ${media.MOBILE} {
    padding-left: 0;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    width: auto;
  }
`;

export const Navigation = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media ${media.MOBILE} {
    position: fixed;
    background-color: white;
    top: 68px;
    left: 0;
    right: 0;
    bottom: 0;
    transition: transform 0.3s;
    transform: translateX(100%);

    ${(props) => {
      if (props.menuOpen) {
        return `
          transition: transform 0.3s;
          transform: translateX(0);
        `;
      }
    }}
  }
`;

export const NavigationInner = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;

  @media ${media.MOBILE} {
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    padding: 20px 0;
  }
`;

export const Burger = styled.div`
  width: 38px;
  height: 38px;
  cursor: pointer;
  user-select: none;
  display: none;
  position: relative;
  margin-right: 10px;

  @media ${media.MOBILE} {
    display: flex;
  }

  span {
    width: 30px;
    height: 1px;
    background-color: black;
    position: absolute;
    left: 5px;
    transform-origin: center;

    &:nth-child(1) {
      top: 9px;
      transition: 0.3s;
    }

    &:nth-child(2) {
      top: 19px;
      transition: 0.1s;
    }

    &:nth-child(3) {
      top: 29px;
      transition: 0.3s;
    }

    ${(props) => {
      if (props.menuOpen) {
        return `
          &:nth-child(1) {
            top: 9px;
            transform: rotate(45deg) translateY(7px) translateX(7px);
          }
      
          &:nth-child(2) {
            opacity: 0;
          }
      
          &:nth-child(3) {
            top: 29px;
            transform: rotate(-45deg) translateY(-7px) translateX(7px);
          }
        `;
      }
    }}
  }
`;

export const CartButtonText = styled.div`
  white-space: nowrap;
`;
