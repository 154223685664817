// @ts-check

import { Title } from "ui";
import { Wrapper } from "./styles";
import { UserMessage } from "ui/user-message/user-message";
import { ResetPasswordIcon } from "../icon/icon";

export const ResetPasswordLayout = ({ variant, children }) => {
  return (
    <Wrapper>
      <Title variant="h1">Reset Password</Title>
      <UserMessage>
        <UserMessage.ImageWrapper>
          <ResetPasswordIcon variant={variant} />
        </UserMessage.ImageWrapper>
        {children}
      </UserMessage>
    </Wrapper>
  );
};
