export const REQUEST_USER = "USER/REQUEST_USER";
export const REQUEST_PROFILE = "USER/REQUEST_PROFILE";
export const REQUEST_ADDRESSES = "USER/REQUEST_ADDRESSES";
export const SET_USER = "USER/SET_USER";
export const SET_USER_READY = "USER/SET_USER_READY";
export const LOGOUT = "USER/LOGOUT";
export const SET_LOGOUT = "USER/SET_LOGOUT";
export const SET_USER_LOADING = "USER/SET_USER_LOADING";
export const SET_PROFILE_LOADING = "USER/SET_PROFILE_LOADING";
export const SET_PROFILE_ERROR = "USER/SET_PROFILE_ERROR";
export const SET_PROFILE = "USER/SET_PROFILE";
export const SET_ADDRESSES = "USER/SET_ADDRESSES";
export const SET_ADDRESSES_LOADING = "USER/SET_ADDRESSES_LOADING";
export const SET_REFRESH_TOKEN = "USER/SET_REFRESH_TOKEN";
export const SET_IS_AUTH = "USER/SET_IS_AUTH";
export const SET_REDIRECT_TO = "USER/SET_REDIRECT_TO";
export const SET_CURRENT_USER = "USER/SET_CURRENT_USER";
