import React from "react";
import {
  Wrapper,
  Cells,
  NavLinks,
  Navigation,
  NavigationInner,
  Burger,
  ButtonWrapper,
} from "./header.styles";
import {
  Logo,
  NavLink,
  CurrencySelect,
  IconButton,
  CartButton,
  Dropdown,
} from "../index";
import { LogoutIcon, UserIcon } from "../icons.js";
import ScreeningLibrariesDialog from "./parts/screening-libraries-dialog/ScreeningLibrariesDialog";
import { isDev } from "utils";

const Header = ({
  navLinks,
  pathname,
  profileLink,
  isAuth,
  menuOpen,
  setMenuOpen,
  loginLink,
  count,
  handleNavClick,
  handleLogout,
  handleProfileClick,
  modal,
}) => {
  return (
    <>
      <ScreeningLibrariesDialog
        title="Screening Libraries"
        open={modal.open}
        setOpen={modal.setOpen}
      />
      <Wrapper className="header">
        <Cells>
          <Logo
            onClick={() => {
              setMenuOpen(false);
              handleNavClick({ to: "/" });
            }}
            dataTestAttr="header-logo"
          />
          <Navigation menuOpen={menuOpen}>
            <NavigationInner>
              <NavLinks>
                {navLinks.map((el, index) => (
                  <NavLink
                    key={index}
                    to={el?.to}
                    href={el?.href}
                    active={el?.to === pathname}
                    onClick={(event) => {
                      setMenuOpen(false);
                      handleNavClick({
                        event,
                        ...el,
                      });
                    }}
                    data-test={el.dataTestAttr}
                  >
                    {el.title}
                  </NavLink>
                ))}
                {isDev() ? <NavLink to="/ui">UI</NavLink> : null}
              </NavLinks>

              <CurrencySelect />

              <ButtonWrapper>
                {isAuth ? (
                  <Dropdown
                    control={
                      <IconButton
                        icon={<UserIcon />}
                        variant={isAuth ? "lightBlue" : "outlined"}
                      />
                    }
                    variant="header"
                    dataTestAttr="profile-dropdown"
                  >
                    <Dropdown.MenuItem
                      icon={<UserIcon />}
                      to={profileLink}
                      style={{ minWidth: "180px" }}
                      dataTestAttr="dropdown-menu-item-profile"
                      onClick={handleProfileClick}
                    >
                      Personal Info
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                      icon={<LogoutIcon />}
                      onClick={handleLogout}
                      dataTestAttr="dropdown-menu-item-logout"
                    >
                      Logout
                    </Dropdown.MenuItem>
                  </Dropdown>
                ) : (
                  <IconButton
                    icon={<UserIcon />}
                    variant="outlined"
                    to={loginLink}
                    dataTestAttr="login-button"
                  />
                )}
              </ButtonWrapper>

              <ButtonWrapper>
                <CartButton
                  to="/cart"
                  count={count}
                  onClick={() => setMenuOpen(false)}
                  data-test="cart-button"
                />
              </ButtonWrapper>
            </NavigationInner>
          </Navigation>
          <Burger
            menuOpen={menuOpen}
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            <span></span>
            <span></span>
            <span></span>
          </Burger>
        </Cells>
      </Wrapper>
    </>
  );
};

export default Header;
