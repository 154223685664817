import { useEffect, useState } from "react";
import { customFetch } from "utils";
import prepareCertificatesData from "../TaxFreeCertificates.utils";
import { TaxFreeCertificate } from "../TaxFreeCertificates.types";

type UseFetchListProps = {
  customerId: string;
};

const useFetchList = ({ customerId }: UseFetchListProps) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const handleFetch = async () => {
    setLoading(true);
    const res = await fetchCertificates(customerId);

    if (res[0]) {
      const preparedData = prepareCertificatesData(res[0]);
      setData(preparedData);
    }

    setLoading(false);
  };

  useEffect(
    () => {
      (async () => {
        if (customerId) {
          handleFetch();
        }
      })();
    },
    // eslint-disable-next-line
  [customerId]);

  return {
    loading,
    data,
    refetch: handleFetch,
  };
};

export default useFetchList;

export const fetchCertificates = async (customerId: string) => {
  return customFetch<TaxFreeCertificate[]>({
    url: `customers/${customerId}/tax-free-certificates`,
  });
};
