import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "redux/user";
import { useAddressForm, useCheckNextOrJustClose } from "hooks";
import Billing from "./Billing";
import { checkoutActions, checkoutSelectors } from "redux/checkout";
import { events, useAmplitudeState } from "context/amplitude-context";
import { addBillingAddress } from "features/super-cart/redux/api";
import superCartActions from "features/super-cart/redux/actions";

const BillingContainer = () => {
  const dispatch = useDispatch();
  const { amplitude } = useAmplitudeState();
  const checkoutData = useSelector(checkoutSelectors.selectData);
  const [isLoading, setIsLoading] = useState(false);
  const address = useSelector(userSelectors.selectBillingAddress);
  const [goNextOrJustClose, buttonLabel] = useCheckNextOrJustClose(2);

  const handleSubmit = (values) => {
    setIsLoading(true);

    addBillingAddress(values)
      .then((_) => {
        const onSuccess = () => {
          dispatch(superCartActions.requestItemsAndImages());
        };
        dispatch(superCartActions.requestCartInfo({ onSuccess }));

        const newData = { ...checkoutData };
        newData.billing = values;
        dispatch(checkoutActions.setData(newData));

        dispatch(checkoutActions.closeAccordion("step2"));
        if (goNextOrJustClose) {
          dispatch(checkoutActions.openAccordion("step3"));
        }

        amplitude.logEvent(events.checkout_billing);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(setIsLoading(false));
  };

  const { formik, vatPattern, showStateSelect } = useAddressForm({
    handleSubmit,
  });

  const handleClick = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    dispatch(userActions.requestAddresses());
  }, []);

  useEffect(() => {
    if (checkoutData.billing) {
      if (checkoutData) {
        formik.setValues((prevValues) => ({
          ...prevValues,
          ...checkoutData.billing,
        }));
      }
    } else {
      formik.setValues((prevValues) => ({
        ...prevValues,
        ...address,
      }));
    }
  }, [checkoutData, address]);

  return (
    <Billing
      formik={formik}
      vatPattern={vatPattern}
      showStateSelect={showStateSelect}
      handleClick={handleClick}
      buttonLabel={buttonLabel}
    />
  );
};

export default BillingContainer;
