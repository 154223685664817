import React from "react";
import { InStockEquivalents } from "./instock-equivalents";

export const InStockEquivalentsContainer = ({
  onSelectAnalogs,
  equivalents,
}) => {

  return (
    <InStockEquivalents
      items={equivalents.items}
      onSelectAnalogs={onSelectAnalogs}
    />
  );
};
