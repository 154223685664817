import styled from "styled-components";
import { blue, successColor } from "ui/colors";

export const Wrapper = styled.div`
  margin-top: 10px;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const DiscardButton = styled.span`
  display: inline-flex;
  align-items: center;
  color: ${blue};
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  cursor: pointer;

  svg {
    margin-right: 6px;
  }

  svg path {
    fill: ${blue};
  }

  &:hover {
    color: ${successColor};

    svg path {
      fill: ${successColor};
    }
  }
`;
