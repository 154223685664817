import React from "react";

import UserTitleSelect from "../../../../parts/form-inputs/user-title-select/UserTitleSelect";
import FirstName from "../../../../parts/form-inputs/first-name/FirstName";
import LastName from "../../../../parts/form-inputs/last-name/LastName";
import Phone from "../../../../parts/form-inputs/phone/Phone";
import Email from "../../../../parts/form-inputs/email/Email";
import Company from "../../../../parts/form-inputs/company/Company";
import { ButtonWrapper, InputWrapper } from "./ContactPerson.styles";

import { Button } from "../../../../ui";

const ContactPerson = ({ formik, handleClick, buttonLabel }) => {
  return (
    <>
      <InputWrapper>
        <UserTitleSelect formik={formik} />
      </InputWrapper>
      <InputWrapper>
        <FirstName formik={formik} />
      </InputWrapper>
      <InputWrapper>
        <LastName formik={formik} />
      </InputWrapper>
      <InputWrapper>
        <Phone formik={formik} label="Contact Phone" />
      </InputWrapper>
      <InputWrapper>
        <Company formik={formik} />
      </InputWrapper>
      <InputWrapper>
        <Email formik={formik} label="Your email" />
      </InputWrapper>
      <ButtonWrapper>
        <Button onClick={handleClick} size="small" fullWidth>
          Next
        </Button>
      </ButtonWrapper>
    </>
  );
};

export default ContactPerson;
