import React from "react";
import { Input } from "../../../ui";

const Company = ({ formik, dataTestAttr = "input-company", ...props }) => {
  return (
    <Input
      label="Company"
      name="company"
      formik={formik}
      data-test={dataTestAttr}
      placeholder="Enter company"
      {...props}
    />
  );
};

export default Company;
