import { SET_ANALOGS } from "./types";

const initialState = {
  page_size: 12,
  page_preview_size: 4,
  product_page_timelimit: 1000 * 60 * 5, //5 minutes"
  price_timelimit: 1000 * 60 * 5, //5 minutes"
  product_pages: null,
  images: null,
  prices: null,
};

const analogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALOGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default analogsReducer;
