import React from "react";
import {
  SearchReagentsDescription,
  SearchReagentsTitle,
  SearchReagentsWrapper,
} from "./SearchReagents.styled";
import { useHistory } from "react-router-dom";
import {
  events,
  useAmplitudeState,
} from "../../../../../context/amplitude-context";

const SearchReagents = () => {
  const { amplitude } = useAmplitudeState();
  const history = useHistory();

  const handleNavigate = () => {
    history.push("/synthetic-reagents");
    amplitude.logEvent(events.category_click, {
      type: "Synthetic Reagents",
    });
  };

  return (
    <SearchReagentsWrapper onClick={handleNavigate}>
      <SearchReagentsTitle>Synthetic Reagents</SearchReagentsTitle>
      <SearchReagentsDescription>
        Browse through Enamine's collection, which includes nearly 317 reagents
      </SearchReagentsDescription>
    </SearchReagentsWrapper>
  );
};

export default SearchReagents;
