// @ts-check
import React from "react";
import { ReactComponent as ListViewIcon } from "../../icons/list-view.svg";
import { ReactComponent as CardsViewIcon } from "../../icons/cards-view.svg";
import { Wrapper, SmallTitle, BoxButton } from "./catalog-header.styles";
import { catalogViewModes } from "../../constants";

const CatalogHeader = ({
  pageSize,
  onHandleSetPageSize,
  onHandleSetViewMode,
  viewMode,
}) => {
  return (
    <Wrapper>
      <>
        <SmallTitle>Show by</SmallTitle>
        {[9, 18, 27].map((count) => {
          return (
            <BoxButton
              key={count}
              onClick={() => {
                onHandleSetPageSize(count);
              }}
              active={pageSize === count}
            >
              {count}
            </BoxButton>
          );
        })}
      </>
      <BoxButton
        active={viewMode === catalogViewModes.LIST}
        onClick={() => onHandleSetViewMode(catalogViewModes.LIST)}
      >
        <ListViewIcon />
      </BoxButton>
      <BoxButton
        active={viewMode === catalogViewModes.CARDS}
        onClick={() => onHandleSetViewMode(catalogViewModes.CARDS)}
      >
        <CardsViewIcon />
      </BoxButton>
    </Wrapper>
  );
};

export default CatalogHeader;
