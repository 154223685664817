import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 19px;
  ${({ disable }) => {
    if (disable) {
      return `
        opacity: 0.8;
        pointer-events: none;
      `;
    }
  }}
`;

export const ButtonWrapper = styled.div`
  padding-top: 10px;
`;

export const CardInner = styled.div`
  padding-left: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-right: 28px;

  svg {
    max-width: 100%;
  }
`;

export const CardWrapper = styled.div`
  padding-bottom: 18px;
`;

export const CardTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  b {
    margin-right: 12px;
  }
`;
