import React from "react";
import { Wrapper } from "./loader.styles";
import Spinner from "ui/spinner/Spinner";

type LoaderProps = {
  fullWidth?: boolean;
  paddings?: boolean;
};

const Loader = ({ fullWidth, paddings }: LoaderProps) => {
  return (
    <Wrapper fullWidth={fullWidth} paddings={paddings}>
      <Spinner size={32} isLoading />
    </Wrapper>
  );
};

export default Loader;
