import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { common } from "./constants";
import { userSelectors } from "./redux/user";
import { versionActions } from "./redux/version";
import AmplitudeInitialTrigger from "./parts/amplitude-initial-trigger/AmplitudeInitialTrigger";
import Cookie from "./parts/cookie-consent/CookieConsent";
import { AmplitudeProvider } from "./context/amplitude-context";
import { defaultTheme } from "./constants/theme";
import ScrollToTop from "./parts/scroll-to-top/ScrollToTop";
import Routes from "./Routes";
import { currencyActions } from "redux/currency";
import { ToastContainer } from "ui/toast/toast";
import WelcomePopupContainer from "features/onboarding/welcome-popup";
import SorryToSeeYouGoPopupContainer from "features/onboarding/sorry-to-see-you-go-popup";
import { SSEProvider } from "./context/sseContext";
import LoaderFullPage from "./features/Loaders/LoaderFullPage/LoaderFullPage";

const App = () => {
  const [theme] = useState(defaultTheme);
  // @TODO Cause HUGE ISSUE WHEN YOU DELETE SELECTOR, RECHECK LOGIC and find issue
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useSelector(userSelectors.selectRefreshToken);
  const dispatch = useDispatch();

  useEffect(() => {
    Cookies.set("redirect", "true", { domain: common.OLD_SHOP_COOKIE_DOMAIN });
    dispatch(currencyActions.setDisableSelect(false));

    dispatch(versionActions.requestVersion());
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SSEProvider>
        <Router>
          <LoaderFullPage />
          <ScrollToTop />
          <ToastContainer />
          <AmplitudeProvider>
            <WelcomePopupContainer />
            <SorryToSeeYouGoPopupContainer />
            <Cookie />
            <AmplitudeInitialTrigger />
            <Routes />
          </AmplitudeProvider>
        </Router>
      </SSEProvider>
    </ThemeProvider>
  );
};

export default App;
