import { put, select } from "redux-saga/effects";
import { fetchProductList, fetchProducts } from "./api";
import { catalogActions } from "./index";
import { utilActions } from "../util";
import { listErrorTypes } from "pages/catalog/Catalog.container";

export function* updateCurrentItemsWithPageSize({ payload }) {
  const state = yield select();
  const { allItems, pageSize } = state.catalog;

  const { curPage, storeKey } = payload;

  if (allItems) {
    const filteredItems = allItems.filter((el) => {
      return el?.product?.code;
    });

    const currentItems = filteredItems.slice(
      (curPage - 1) * pageSize,
      (curPage - 1) * pageSize + pageSize
    );

    yield put(
      catalogActions.addItems({
        items: currentItems,
        key: storeKey,
      })
    );

    yield put(
      utilActions.requestImages(
        currentItems.map((productWithPrice) => productWithPrice?.product)
      )
    );
  }
}

export function* requestProductsWithPrices({ payload }) {
  try {
    const state = yield select();
    const { pageSize, catalogFilters } = state.catalog;
    const { current } = state.currency;

    const updatedFiltersPayload = { ...catalogFilters };

    if (updatedFiltersPayload.location === "All") {
      delete updatedFiltersPayload.location;
    }

    const similarity = updatedFiltersPayload.similarity;
    delete updatedFiltersPayload.sstype;
    delete updatedFiltersPayload.similarity;

    const {
      code,
      type,
      sstype,
      cat,
      storeKey,
      setNothingFound,
      curPage,
      setTotal,
      searchBy,
    } = payload;

    yield put(catalogActions.setLoadingItems(true));
    setNothingFound(false);

    const result = yield fetchProducts({
      code,
      type,
      cat,
      sstype,
      curPage,
      pageSize,
      searchBy,
      currency: current,
      catalogFilters: updatedFiltersPayload,
      similarity,
    });

    const fetchedItems = result.searchResults ?? [];
    const total = result.searchResultQuantity ?? 0;

    const filteredItems = fetchedItems.filter((el) => {
      return el?.product?.code;
    });

    setTotal(total);

    const currentItems = filteredItems.slice(
      (curPage - 1) * pageSize,
      (curPage - 1) * pageSize + pageSize
    );

    if (filteredItems?.length === 0 || !filteredItems) {
      setNothingFound(true);
    } else {
      setNothingFound(false);
    }

    yield put(
      catalogActions.addItems({
        items: currentItems,
        allItems: filteredItems,
        key: storeKey,
      })
    );
    yield put(catalogActions.setLoadingItems(false));
    yield put(
      utilActions.requestImages(
        currentItems.map((productWithPrice) => productWithPrice?.product)
      )
    );
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}

export function* getProductList({ payload }) {
  try {
    const state = yield select();

    const {
      id,
      storeKey,
      setQ,
      setListName,
      setListErrorType,
      curPage,
      pageSize,
      setTotal,
    } = payload;

    const fullList = state.catalog.productLists?.[id];
    let result = [null, null];

    if (fullList) {
      result[0] = fullList;
    } else {
      result = yield fetchProductList({ id });
    }

    if (result[0]) {
      setQ(result[0].codes.toString());
      setListName(result[0].name);

      const filteredItems = result[0]?.products.filter((el) => {
        return el?.product?.code;
      });

      setTotal(filteredItems?.length);

      yield put(
        catalogActions.setProductList({
          id,
          name: result[0].name,
          codes: result[0].codes,
          products: filteredItems,
        })
      );

      const currentItems = filteredItems.slice(
        (curPage - 1) * pageSize,
        (curPage - 1) * pageSize + pageSize
      );

      yield put(
        catalogActions.addItems({ items: currentItems, key: storeKey })
      );

      yield put(catalogActions.setLoadingItems(false));

      yield put(
        utilActions.requestImages(
          currentItems.map((productWithPrice) => productWithPrice?.product)
        )
      );
    } else {
      if (result[2] === 400) {
        setListErrorType(listErrorTypes.DISABLED);
      } else if (result[2] === 404) {
        setListErrorType(listErrorTypes.NOT_FOUND);
      }

      yield put(catalogActions.setLoadingItems(false));
    }
  } catch (e) {
    console.log("Something went wrong!", e);
  }
}
