import React, { useCallback, useRef } from "react";
import { DnaIcon, FlaskIcon, LibraryIcon } from "ui/icons";
import { SlidingArrow } from "pages/home/components/SlidingArrow";
import { LearnMoreLink } from "pages/home/components/LearnMoreLink";
import {
  FeatureWrapper,
  Feature,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  Description,
  Link,
} from "./FeatureList.styled";
import animateScrollTo from "animated-scroll-to";

export const FeatureList = () => {
  const ref = useRef(null);

  const handleArrowClick = useCallback(() => {
    if (ref.current) {
      animateScrollTo(ref.current, { verticalOffset: -88, minDuration: 500 });
    }
  }, []);
  return (
    <>
      <SlidingArrow onClick={handleArrowClick} />
      <FeatureWrapper ref={ref}>
        <Feature>
          <FeatureIcon>
            <LibraryIcon />
          </FeatureIcon>
          <FeatureDescription>
            <FeatureTitle>Screening Libraries</FeatureTitle>
            <Description>
              Diverse{" "}
              <Link
                target="_blank"
                href="https://enamine.net/compound-libraries"
              >
                Compound Libraries
              </Link>{" "}
              already plated as DMSO solutions for fast delivery. Screening
              them, you can obtain a decent number of quality hits. If you’re
              looking for a specific screening library design, you can do
              cherry-picking from our Liquid Stock Collection.
            </Description>
            <LearnMoreLink
              target="_blank"
              href="https://enamine.net/compound-libraries"
            />
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <FlaskIcon />
          </FeatureIcon>
          <FeatureDescription>
            <FeatureTitle>REAL Database</FeatureTitle>
            <Description>
              Cutting-edge screening compound collection uncover billions of
              previously undiscovered structures. Enamine continuously develops
              new and innovative building blocks. These building blocks provide
              access to enumerated libraries of novel REAL Compounds.
            </Description>
            <LearnMoreLink
              target="_blank"
              href="https://enamine.net/compound-collections/real-compounds"
            />
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <DnaIcon />
          </FeatureIcon>
          <FeatureDescription>
            <FeatureTitle>Enamine Bioactive Compounds</FeatureTitle>
            <Description>
              25 000 compounds with reported biological activity from Enamine’s
              collections. They are fully annotated with the range of known
              targets, activity types and their values. Custom-tailored compound
              libraries prepared using our proprietary SMART Search Engine.
            </Description>
            <LearnMoreLink target="_blank" href="https://ebc.enamine.net" />
          </FeatureDescription>
        </Feature>
        <Feature>
          <FeatureIcon>
            <DnaIcon />
          </FeatureIcon>
          <FeatureDescription>
            <FeatureTitle>Computer-Aided Drug Design</FeatureTitle>
            <Description>
              Enamine CADD Team is your partner in navigating extensive compound
              libraries like REAL Space and beyond. Led by seasoned
              professionals, our custom virtual modeling, molecule search, and
              design solutions address drug discovery challenges.
            </Description>
            <LearnMoreLink
              target="_blank"
              href="https://enamine.net/services/computer-aided-drug-design"
            />
          </FeatureDescription>
        </Feature>
      </FeatureWrapper>
    </>
  );
};
