import React from "react";
import { SpinnerStyled, Wrapper } from "./Spinner.styles";
import classNames from "classnames";

type SpinnerProps = {
  isLoading?: boolean;
  size?: number;
  height?: string;
  className?: string;
};

const Spinner = ({ isLoading, size, height, className }: SpinnerProps) => {
  const wrapperClassName = classNames({ loading: isLoading }, className);

  return (
    <Wrapper size={size} height={height} className={wrapperClassName}>
      <SpinnerStyled className={classNames({ loading: isLoading })} />
    </Wrapper>
  );
};

export default Spinner;
