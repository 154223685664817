import styled from "styled-components";

export const Wrapper = styled.div`
  .product-groups-tabs {
    margin: 40px 0 0;
  }
`;

export const TabContentWrapper = styled.div`
  padding: 22px 0 0 0;
`;
