import styled from "styled-components";

export const Wrapper = styled.div`
  &.dots-spinner {
    position: relative;
    width: 80px;
    height: 80px;
  }

  i {
    position: absolute;
    width: 100%;
    height: 100%;

    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background: #01a37c;
      border-radius: 50%;
      position: absolute;
      left: ${(80 - 16) / 2}px;
      animation: spinning 1.6s ease-out infinite;
      animation-delay: -1s;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }
    &:nth-child(2):before {
      animation-delay: -0.875s;
    }
    &:nth-child(3) {
      transform: rotate(90deg);
    }
    &:nth-child(3):before {
      animation-delay: -0.75s;
    }
    &:nth-child(4) {
      transform: rotate(135deg);
    }
    &:nth-child(4):before {
      animation-delay: -0.625s;
    }
    &:nth-child(5) {
      transform: rotate(180deg);
    }
    &:nth-child(5):before {
      animation-delay: -0.5s;
    }
    &:nth-child(6) {
      transform: rotate(225deg);
    }
    &:nth-child(6):before {
      animation-delay: -0.375s;
    }
    &:nth-child(7) {
      transform: rotate(270deg);
    }
    &:nth-child(7):before {
      animation-delay: -0.25s;
    }
    &:nth-child(8) {
      transform: rotate(315deg);
    }
    &:nth-child(8):before {
      animation-delay: -0.125s;
    }
  }

  @keyframes spinning {
    0% {
      background: #d1d5dc;
      transform: scale(1);
    }
    100% {
      background: #fefefe;
      transform: scale(1);
    }
  }
`;
