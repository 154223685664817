import React from "react";
import { Wrapper } from "./profile-sidebar.styles";
import { Tabs } from "../index";

const ProfileSidebar = ({ alias, handleClick, links, getLinkHref }) => (
  <Wrapper>
    <Tabs
      navigation={
        <>
          {links.map((item, index) => {
            const active = alias === item.alias;

            return (
              <Tabs.Button
                key={index}
                active={active}
                onClick={() => handleClick(item)}
                to={getLinkHref(item.alias)}
              >
                {item.title}
              </Tabs.Button>
            );
          })}
        </>
      }
    />
  </Wrapper>
);

export default ProfileSidebar;
