import React from "react";
import { Alert } from "@mui/material";

const ErrorMessage = ({ children, ...props }) => {
  return (
    <Alert variant="outlined" severity="error">
      {children}
    </Alert>
  );
};

export default ErrorMessage;
