import styled from "styled-components";
import { borderColor } from "ui/colors";

export const Wrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;
`;

export const List = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  min-height: 48px;
`;

export const ListItem = styled.li`
  display: flex;
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  opacity: ${(props) => (props.hasHref ? "1" : "0.6")};

  &:not(:last-child):after {
    content: "/";
    color: ${borderColor};
    margin-left: 8px;
    margin-right: 6px;
  }

  a:hover {
    text-decoration: none !important;
  }
`;
