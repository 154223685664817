import {
  ADD_ITEM,
  ALL_TO_CART,
  CLEAR_CART,
  DELETE_ITEM,
  POST_CART_CONTENT,
  REQUEST_CART_INFO,
  REQUEST_ITEMS_AND_IMAGES,
  RESET_CART_INFO,
  SET_CART_INFO,
  SET_IMAGES,
  SET_ITEMS,
  SET_PRICES,
  SET_SUPER_CART,
  UPDATE_ITEM,
} from "./types";

const setSuperCart = (payload) => {
  return {
    type: SET_SUPER_CART,
    payload,
  };
};

const setPrices = (payload) => {
  return {
    type: SET_PRICES,
    payload,
  };
};

const setImages = (payload) => {
  return {
    type: SET_IMAGES,
    payload,
  };
};

const addItem = (payload) => {
  return {
    type: ADD_ITEM,
    payload,
  };
};

const setItems = (payload) => {
  return {
    type: SET_ITEMS,
    payload,
  };
};

const updateItem = (payload) => {
  return {
    type: UPDATE_ITEM,
    payload,
  };
};

const allToCart = (payload) => {
  return {
    type: ALL_TO_CART,
    payload,
  };
};

const deleteItem = (payload) => {
  return {
    type: DELETE_ITEM,
    payload,
  };
};

const clear = (payload) => {
  return {
    type: CLEAR_CART,
    payload,
  };
};

const resetCartInfo = () => {
  return {
    type: RESET_CART_INFO,
  };
};

const requestCartInfo = (payload) => {
  return {
    type: REQUEST_CART_INFO,
    payload,
  };
};

const setCartInfo = (payload) => {
  return {
    type: SET_CART_INFO,
    payload,
  };
};

const postCartContent = (payload) => {
  return {
    type: POST_CART_CONTENT,
    payload,
  };
};

const requestItemsAndImages = (payload) => {
  return {
    type: REQUEST_ITEMS_AND_IMAGES,
    payload,
  };
};

const superCartActions = {
  setSuperCart,
  addItem,
  updateItem,
  allToCart,
  deleteItem,
  setPrices,
  setImages,
  setItems,
  clear,
  resetCartInfo,
  requestCartInfo,
  setCartInfo,
  postCartContent,
  requestItemsAndImages,
};

export default superCartActions;
