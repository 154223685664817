import styled from "styled-components";
import { lineColor } from "ui/colors";

export const Wrapper = styled.div`
  &.order-page {
    padding-bottom: 140px;
    padding-top: 30px;
  }

  .order-page__grid {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(2, 1fr);
  }

  .order-page__grid-item {
    &--full-width {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  .order-page__paper-title {
    margin: 0;
    line-height: 32px;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;

    &--small-margin {
      margin-bottom: 10px;
    }
  }

  .order-page__product-image {
    width: 104px;
    height: auto;
  }

  .order-page__back-link {
    &:hover {
      text-decoration: underline !important;
    }
  }

  .order-page__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
  }

  .order-page__order-info {
    gap: 8px;
  }

  .order-page__order-number {
    margin: 0;
    font-size: 32px;
    font-weight: 800;
    line-height: 32px;
    margin-right: 10px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .order-page__price-row {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    line-height: 24px;

    &:not(:last-of-type) {
      margin-bottom: 18px;
    }

    span {
      display: flex;
      align-items: center;
    }
  }

  .order-page__divider {
    border-bottom: 1px solid ${lineColor};
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .order-page__text {
    font-size: 20px;
    line-height: 32px;

    p {
      margin: 0;
    }
  }
`;
