// @ts-check
import React from "react";
import { Modal, Button } from "ui/index";
import {
  FormWrapper,
  InputStyled,
  TextareaStyled,
} from "./CustomerRequestDialog.styles";

export const Anonymous = ({ formik }) => (
  <FormWrapper>
    <InputStyled label="Full name" name="name" formik={formik} />
    <InputStyled label="Email" name="email" formik={formik} />
    <TextareaStyled
      label="Comment"
      name="text"
      formik={formik}
      dataTestAttr="request-comment"
    />
  </FormWrapper>
);

export const Registered = ({ formik }) => (
  <FormWrapper>
    <TextareaStyled
      label="Comment"
      name="text"
      formik={formik}
      dataTestAttr="request-comment"
    />
  </FormWrapper>
);

export const CustomerRequestDialog = ({ isOpened, open, formik, isAuth }) => {
  return (
    <Modal
      open={isOpened}
      setOpen={open}
      boxProps={{ width: "520px", maxWidth: "100%" }}
      title="Request Analogs"
      dataTestAttr="customer-request-dialog"
    >
      {isAuth ? <Registered formik={formik} /> : <Anonymous formik={formik} />}
      <Modal.Buttons>
        <Button fullWidth type="submit" onClick={formik.handleSubmit}>
          Send request
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};
