import styled from "styled-components";
import { ReactComponent as LogoIcon } from "icons/logo.svg";
import { lineColor } from "ui/colors";

export const FormLayoutWrapper = styled.div`
  box-sizing: border-box;
  padding: 36px 0;
  display: flex;
  flex-direction: column;
  width: 640px;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  gap: 18px;
  margin: 0 auto;
  margin-top: 25px;
  padding-bottom: ${(props) => (props.hasFooter ? "18px" : "36px")};

  .input__label {
    line-height: 10px;
  }
`;

export const Main = styled.div`
  margin: 0 64px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

export const Footer = styled.div`
  margin-top: 10px;
  padding-top: 18px;
  border-top: 1px solid ${lineColor};
`;

export const Logo = styled(LogoIcon)`
  width: 80px;
  height: auto;
`;
