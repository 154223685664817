import styled from "styled-components";
import { accentColor, borderColor, darkBlue, lightBlue } from "ui/colors";

export const Wrapper = styled.div`
  &.counter {
    font-size: 20px;
    user-select: none;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
  }

  &.counter--variant--white {
    .counter__control {
      border-color: white;
      background-color: white;
    }

    .counter__input {
      border-color: white;
      margin-right: 2px;
      margin-left: 2px;
    }

    .counter__cells {
      background-color: transparent;
    }
  }

  &.counter--fullWidth {
    width: 100%;

    .counter__input {
      width: auto;
      flex-grow: 1;
    }

    .counter__cells {
      width: 100%;
    }
  }

  &.is-loading {
    pointer-events: none;

    .counter__loader {
      display: block;
    }
  }

  .counter__cells {
    display: inline-flex;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    height: 48px;
    box-sizing: border-box;
  }

  .counter__input {
    width: 50px;
    padding: 0;
    height: 100%;
    border: 1px solid ${borderColor};
    color: ${darkBlue};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    margin: 0;
    margin-left: -1px;
    margin-right: -1px;

    &:hover {
      border-color: ${accentColor};
      z-index: 10;
    }

    &:focus {
      outline: none;
      border-color: ${accentColor};
      z-index: 10;
    }
  }

  .counter__control {
    position: relative;
    padding: 10px;
    display: flex;
    border: 1px solid ${borderColor};
    align-items: center;
    justify-content: center;
    min-width: 45px;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: inherit;
    background-color: transparent;

    svg path {
      fill: ${darkBlue};
    }

    &:hover {
      border-color: ${accentColor};
      z-index: 10;

      svg path {
        fill: ${accentColor};
      }
    }

    &:active {
      background-color: ${lightBlue};
    }

    &--left {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &--right {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &--disabled {
      pointer-events: none;
      svg path {
        fill: ${borderColor};
      }
      &:hover {
        border: none;
      }
    }
  }

  .counter__loader {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    opacity: 0.6;
  }
`;
