import React from "react";
import { Container, Paper } from "ui";
import { Page, PaperInner, Text } from "./styles";
import Spinner from "ui/spinner/Spinner";

const RedirectToOldStorePage = () => (
  <Page>
    <Container>
      <Paper>
        <PaperInner>
          <Spinner size={40} isLoading />
          <Text>
            Please wait, we are now redirecting you to the old store...
          </Text>
        </PaperInner>
      </Paper>
    </Container>
  </Page>
);

export default RedirectToOldStorePage;
