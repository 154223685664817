import styled from "styled-components";
import {
  accentColor,
  blue,
  borderColor,
  dangerColor,
  darkBlue,
  lightGray,
} from "ui/colors";

export const Wrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};

  &.select--variant--white {
    .select__select {
      border-color: white;
    }
  }
`;

export const SelectStyled = styled.select`
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  height: 48px;
  border: 1px solid ${borderColor};
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 16px;
  padding-right: 40px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  outline: none;
  cursor: pointer;
  background-color: white;
  color: ${darkBlue};

  option {
    color: ${darkBlue};
  }

  &:not(:disabled):hover {
    border-color: ${accentColor};
  }

  &:disabled {
    background: ${lightGray};
    color: ${blue};
    opacity: 1;
    cursor: default;
  }

  &:invalid {
    color: ${borderColor};
  }

  &:focus {
    border-color: ${accentColor};
  }

  // check size
  ${({ size }) => {
    if (size === "extra-small") {
      return `
        height: 40px;
      `;
    }
  }}
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 6px;
  display: inline-block;

  ${({ disabled }) => {
    if (disabled) {
      return `
        opacity: 0.7;
      `;
    }
  }}
`;

export const ErrorMessage = styled.p`
  color: ${dangerColor};
  margin: 0;
  margin-top: 4px;
  font-size: 14px;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  pointer-events: none;
  transform: translateY(-50%);
  margin-top: 3px;

  & svg {
    width: 24px;
    height: 24px;
  }
`;

export const SelectGroup = styled.div`
  position: relative;
`;
