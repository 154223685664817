import { SearchByTypes } from "./SearchForm.types";
import { encode } from "../../../../utils";

export const getTypeSearchByWithEncodedPhrase = (
  analysedCompounds: Record<SearchByTypes, string[]>
) => {
  const typesSearchBy: string[] = Object.keys(analysedCompounds);

  const selectedTypeSearchBy: any = typesSearchBy.includes("codes")
    ? "codes"
    : typesSearchBy.length === 1
    ? typesSearchBy[0]
    : !typesSearchBy?.includes("codes") &&
      typesSearchBy.length > 1 &&
      typesSearchBy.reduce((acc: string, next: string) => {
        if (!acc) return next;
        if (analysedCompounds[next].length > analysedCompounds[acc].length)
          return next;
        return acc;
      }, "");

  const encodedTypeSearchByPhrase = encode(
    analysedCompounds[selectedTypeSearchBy].join(", ")
  );

  return {
    selectedTypeSearchBy,
    encodedTypeSearchByPhrase,
    foundEntitiesByTypeSearchBy: analysedCompounds[selectedTypeSearchBy],
  };
};
