import styled from "styled-components";

export const Wrapper = styled.div`
  height: ${(props) => (props.fullHeight ? "100%" : null)};

  ${({ ySpacing }) => {
    if (ySpacing) {
      return `
        padding-top: 50px;
        padding-bottom: 50px;
      `;
    }
  }}
`;
