import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addressTypes } from "../../../../../../constants";
import { useAddressForm } from "../../../../../../hooks";
import { userActions } from "../../../../../../redux/user";
import { fetchPostAddress } from "../../../../../../redux/user/api";
import NewAddressForm from "./NewAddressForm";

const NewAddressFormContainer = () => {
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);
  const [isAddingAddress, setAddingAddress] = useState(false);

  const handleSubmit = async (values, formik) => {
    setAddingAddress(true);
    const newValues = {
      ...values,
      addressType: addressTypes.SHIPPING,
    };
    await fetchPostAddress(newValues);
    formik.resetForm();
    dispatch(userActions.requestAddresses());
    setAddingAddress(false);
    setShowForm(false);
    toast("Address was added!");
  };

  const { formik, vatPattern, showStateSelect } = useAddressForm({
    handleSubmit,
  });

  return (
    <NewAddressForm
      showForm={showForm}
      setShowForm={setShowForm}
      isAddingAddress={isAddingAddress}
      newAddressFormProps={{
        vatPattern,
        showStateSelect,
        formik,
      }}
    />
  );
};

export default NewAddressFormContainer;
