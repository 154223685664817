import React from "react";
import { useParams } from "react-router-dom";
import useBackToOrdersLink from "./hooks/useBackToOrdersLink";
import useFetchData from "./hooks/useFetchData";
import Order from "./Order";

const OrderContainer = () => {
  const { id: orderId } = useParams();
  const backToOrdersLink = useBackToOrdersLink();
  const order = useFetchData({ orderId });

  return (
    <Order
      backToOrdersLink={backToOrdersLink}
      loading={order?.loading}
      details={order?.data?.details}
      items={order?.data?.items}
      orderId={orderId}
    />
  );
};

export default OrderContainer;
