import { common } from "constants";
import { Button, Modal } from "ui";
import { DownloadDocIcon } from "ui/icons";

const DownloadSds = ({ handleDownloadSds, isLoadingSds, notFoundModal }) => {
  return (
    <>
      <Button
        type="outlined"
        iconLeft={<DownloadDocIcon />}
        size="small"
        onClick={handleDownloadSds}
        disabled={isLoadingSds}
      >
        Download SDS
      </Button>
      <Modal
        title={"Download SDS"}
        smallPaddings
        width={841}
        {...notFoundModal}
      >
        <Modal.Text>
          This document is currently unavailable. Contact our team to request
          the SDS file.
        </Modal.Text>
        <Modal.Buttons>
          <Button
            onClick={() => notFoundModal.setOpen(false)}
            size="small"
            type="outlined"
            dataTestAttr="cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              notFoundModal.setOpen(false);
            }}
            size="small"
            href={common.CONTACTS_HREF}
            target="_blank"
            dataTestAttr="contact-us-button"
          >
            Contact us
          </Button>
        </Modal.Buttons>
      </Modal>
    </>
  );
};

export default DownloadSds;
