const convertEmptyStringsToNull = (obj) => {
  const res = {};

  Object.keys(obj).map((el) => {
    if (obj[el] === "") {
      res[el] = null;
    } else {
      res[el] = obj[el];
    }
  });

  return res;
};

export default convertEmptyStringsToNull;
