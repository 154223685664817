import styled from "styled-components";

export const LinkWrapper = styled.div`
  text-align: center;
  padding-top: 20px;
  padding-bottom: 25px;
`;

export const CheckboxWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 30px;
`;

export const ErrorWrapper = styled.div`
  padding-bottom: 15px;
  white-space: break-all;
`;
