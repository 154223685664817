import classNames from "classnames";
import { Wrapper } from "./tag.styled";

const Tag = ({ children, type = "primary", ...rest }) => {
  const classes = classNames("tag", {
    [`tag--type--${type}`]: type,
  });

  return (
    <Wrapper className={classes} {...rest}>
      {children}
    </Wrapper>
  );
};

export default Tag;
