import * as Yup from "yup";
import { FilterTypes } from "../../types";
import { productTypes } from "../../../../constants";

export const catalogFiltersAvailableConfig = {
  [productTypes.BB]: {
    [FilterTypes.LOCATION]: "All",
    [FilterTypes.classes]: [],
    [FilterTypes.subclasses]: [],
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
  },
  [productTypes.SCR]: {
    [FilterTypes.collections]: [],
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
    [FilterTypes.RotB]: {
      from: "",
      to: "",
    },
    [FilterTypes.HAC]: {
      from: "",
      to: "",
    },
  },
  [productTypes.EBC]: {
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
  },
  [productTypes.MADE]: {
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
  },
  [productTypes.REALDB]: {
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
    [FilterTypes.HBA]: {
      from: "",
      to: "",
    },
    [FilterTypes.HBD]: {
      from: "",
      to: "",
    },
    [FilterTypes.RotB]: {
      from: "",
      to: "",
    },
    [FilterTypes.TPSA]: {
      from: "",
      to: "",
    },
    [FilterTypes.HAC]: {
      from: "",
      to: "",
    },
    [FilterTypes.Fsp3]: {
      from: "",
      to: "",
    },
  },
  DEFAULT: {
    [FilterTypes.LOCATION]: "All",
    [FilterTypes.classes]: [],
    [FilterTypes.subclasses]: [],
    [FilterTypes.collections]: [],
    [FilterTypes.MW]: {
      from: "",
      to: "",
    },
    [FilterTypes.CLogP]: {
      from: "",
      to: "",
    },
    [FilterTypes.HBA]: {
      from: "",
      to: "",
    },
    [FilterTypes.HBD]: {
      from: "",
      to: "",
    },
    [FilterTypes.RotB]: {
      from: "",
      to: "",
    },
    [FilterTypes.TPSA]: {
      from: "",
      to: "",
    },
    [FilterTypes.HAC]: {
      from: "",
      to: "",
    },
    [FilterTypes.Fsp3]: {
      from: "",
      to: "",
    },
  },
};

const patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;

export const catalogFiltersRegisterSchema = Yup.object().shape({
  [FilterTypes.MW]: Yup.object().shape({
    from: Yup.number()
      .min(1)
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigitsAfterComma.test(val);
          }
          return true;
        }
      ),
    to: Yup.number()
      .min(1)
      .test(
        "is-decimal",
        "The amount should be a decimal with maximum two digits after comma",
        (val: any) => {
          if (val !== undefined) {
            return patternTwoDigitsAfterComma.test(val);
          }
          return true;
        }
      ),
  }),
  [FilterTypes.CLogP]: Yup.object().shape({
    from: Yup.number().min(-100).max(100),
    to: Yup.number().min(-100).max(100),
  }),
  [FilterTypes.RotB]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.HAC]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.HBA]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.HBD]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.TPSA]: Yup.object().shape({
    from: Yup.number().positive(),
    to: Yup.number().positive(),
  }),
  [FilterTypes.similarity]: Yup.number().min(0).max(1),
});
