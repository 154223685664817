import CookieConsent from "react-cookie-consent";
import { events, useAmplitudeState } from "../../context/amplitude-context";
import AmplitudeHelper from "./AmplitudeHelper";
import { hoverActiveLinkColor } from "ui/colors";
import { LinkWrapper, Wrapper, LinkStyled } from "./CookieConsent.styles";

const Cookie = () => {
  const { amplitude } = useAmplitudeState();

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="cookie-consent-accept"
      style={{ background: "#2B373B", display: "flex", alignItems: "center" }}
      buttonStyle={{
        color: "white",
        backgroundColor: hoverActiveLinkColor,
        fontSize: "13px",
      }}
      expires={150}
      onAccept={() => {
        amplitude.logEvent(events.cookie_notice_accept);
      }}
    >
      <AmplitudeHelper />
      <Wrapper>
        <div>
          <div>Your choice regarding cookies on Enamine Store.</div>
          <div style={{ fontSize: "12px" }}>
            Some of these cookies are essential, while others help us to improve
            the performance of the website and your experience by having insight
            in how it is being used.
          </div>
        </div>
        <LinkWrapper>
          <LinkStyled to="/privacy-policy">Privacy Policy</LinkStyled>
        </LinkWrapper>
        <LinkWrapper>
          <LinkStyled to="/terms-of-use">Terms Of Use</LinkStyled>
        </LinkWrapper>
      </Wrapper>
    </CookieConsent>
  );
};

export default Cookie;
