import {
  REQUEST_CARRIERS,
  REQUEST_CATALOGS,
  REQUEST_CATALOG_WEIGHTS,
  REQUEST_COUNTRIES,
  REQUEST_IMAGES,
  REQUEST_US_STATES,
  REQUEST_VAT_PATTERNS,
  RESET,
  SET_CARRIERS,
  SET_CATALOGS,
  SET_CATALOG_WEIGHTS,
  SET_COUNTRIES,
  SET_IMAGES,
  SET_US_STATES,
  SET_VAT_PATTERNS,
} from "./types";

const requestCarriers = () => {
  return {
    type: REQUEST_CARRIERS,
  };
};

const requestCatalogs = () => {
  return {
    type: REQUEST_CATALOGS,
  };
};

const requestImages = (items) => {
  return {
    type: REQUEST_IMAGES,
    payload: items,
  };
};

const requestCountries = () => {
  return {
    type: REQUEST_COUNTRIES,
  };
};

const requestVatPatterns = () => {
  return {
    type: REQUEST_VAT_PATTERNS,
  };
};

const requestCatalogWeights = () => {
  return {
    type: REQUEST_CATALOG_WEIGHTS,
  };
};

const requestUsStates = () => {
  return {
    type: REQUEST_US_STATES,
  };
};

const setCatalogs = (catalogs) => {
  return {
    type: SET_CATALOGS,
    payload: catalogs,
  };
};

const setCountries = (countries) => {
  return {
    type: SET_COUNTRIES,
    payload: countries,
  };
};

const setImages = (images) => {
  return {
    type: SET_IMAGES,
    payload: images,
  };
};

const setVatPatterns = (vatPatterns) => {
  return {
    type: SET_VAT_PATTERNS,
    payload: vatPatterns,
  };
};

const setCatalogWeights = (weights) => {
  return {
    type: SET_CATALOG_WEIGHTS,
    payload: weights,
  };
};

const setUsStates = (usStates) => {
  return {
    type: SET_US_STATES,
    payload: usStates,
  };
};

const setCarriers = (carriers) => {
  return {
    type: SET_CARRIERS,
    payload: carriers,
  };
};

const reset = () => {
  return {
    type: RESET,
  };
};

export default {
  requestCountries,
  requestCatalogs,
  requestVatPatterns,
  requestUsStates,
  requestCatalogWeights,
  requestImages,
  requestCarriers,
  setCatalogs,
  setCountries,
  setCarriers,
  setVatPatterns,
  setUsStates,
  setCatalogWeights,
  setImages,
  reset,
};
