// @ts-check
import React from "react";
import { Button, EmptyMessage, ProductCard, ProductsLoader } from "ui";
import common from "constants/common";
import { inStockEquivalents as descriptionText } from "constants/productGroups";
import { ProductGrid, ProductItem } from "ui/product-grid/product-grid.styles";
import { ProductDescription } from "ui/ProductDetails/ProductDetails.styled";
import { Wrapper, AnalogsMessage } from "./instock-equivalents.styles";
import { proposalKeys } from "constants/index";

const NotFoundMessage = () => (
  <EmptyMessage variant="alternativePaddings">
    Unfortunately we weren’t able to find any equivalents for this particular
    product. Try contact our team so we can pick equivalents for you
    <Button type="outlined" size="small" href={common.CONTACTS_HREF}>
      Contact us
    </Button>
  </EmptyMessage>
);

/**
 * @type {React.FC<{
 *  items: import("features/product-groups/data/productInfo").ProductInfoFull[];
 *  loading: boolean;
 *  loaded: boolean;
 *  onSelectAnalogs: () => void;
 * }>}
 */
export const InStockEquivalents = ({
  items,
  loading,
  loaded = true,
  onSelectAnalogs,
}) => {
  return (
    <Wrapper>
      <ProductDescription>{descriptionText}</ProductDescription>
      {loading || !loaded ? (
        <ProductsLoader />
      ) : (
        <>
          {items.length ? (
            <ProductGrid>
              {items.map((item) => {
                return (
                  <ProductItem key={item?.product.code}>
                    <ProductCard
                      item={item}
                      image={item?.image}
                      isRelated={false}
                      listMode={false}
                      images={null}
                      proposal={proposalKeys.equivalent}
                    />
                  </ProductItem>
                );
              })}
            </ProductGrid>
          ) : (
            <NotFoundMessage />
          )}
          <AnalogsMessage onClick={onSelectAnalogs}>
            Couldn't find what you are looking for? Expand you selection with
            our analogs
          </AnalogsMessage>
        </>
      )}
    </Wrapper>
  );
};
