import React from "react";
import { Button, Modal } from "../../../../ui";
import { Inner, Buttons, ButtonWrapper } from "./RestrictionModal.styles";

const RestrictionModal = ({
  modal,
  handleClickBackToCart,
  handleClickChangeAddress,
}) => {
  return (
    <Modal
      open={modal.open}
      setOpen={modal.setOpen}
      title={modal.title}
      width="800px"
    >
      <Inner>
        {modal.message}
        <Buttons>
          <ButtonWrapper>
            <Button
              size="small"
              type="secondary"
              onClick={handleClickBackToCart}
            >
              Back to cart
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button size="small" onClick={handleClickChangeAddress}>
              Change address
            </Button>
          </ButtonWrapper>
        </Buttons>
      </Inner>
    </Modal>
  );
};

export default RestrictionModal;
