import { endpoints } from "../../constants";
import { decode } from "../../utils";
import productTypes, { ProductTypesV2 } from "../../constants/productTypes";
import { customFetchDelayedMiddleware } from "../../context/sseContext";
import { RequestMethods } from "../../utils/customFetch";

export const fetchProduct = async ({ code, cat, currencyAbbr }) => {
  const url = `${endpoints.CATALOG}/search/in/${ProductTypesV2[cat]}/by/codes`;

  const res = await customFetchDelayedMiddleware({
    method: RequestMethods.POST,
    url: url,
    data: {
      compounds: decode(code),
      ...(currencyAbbr && { currency: currencyAbbr }),
      ...(cat !== productTypes.REALDB && { include: ["ANALOGS", "EQUIVALENTS", "FORMS"]}),
    },
  });

  const updatedResponse = [...res];

  if (updatedResponse[0]) {
    const parsedDelayedResult = updatedResponse[0]?.delayedResult
      ? JSON.parse(updatedResponse[0]?.delayedResult)?.results
      : updatedResponse[0]?.results;

    updatedResponse[0] = {
      product: parsedDelayedResult?.products[0]?.product,
      prices: parsedDelayedResult?.products[0]?.prices,
      equivalents: parsedDelayedResult?.included?.equivalents,
      analogs: parsedDelayedResult?.included?.analogs,
      forms: parsedDelayedResult?.included?.forms,
    };

    return updatedResponse;
  }
};
