import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { checkoutSelectors } from "../redux/checkout";

const check = (id, accordionData) => {
  const key = `step${id}`;
  const nextKey = `step${id + 1}`;

  if (accordionData[key] && accordionData[nextKey]) {
    if (accordionData[nextKey].disabled) {
      return true;
    }
  }

  return false;
};

const useCheckNextOrJustClose = (id) => {
  const accordionData = useSelector(checkoutSelectors.selectAccordion);
  const [goNextOrJustClose, setGoNextOrJustClose] = useState(true);

  useEffect(() => {
    const checkResult = check(id, accordionData);
    setGoNextOrJustClose(checkResult);
  }, [accordionData]);

  return [goNextOrJustClose];
};

export default useCheckNextOrJustClose;
