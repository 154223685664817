import { useState } from "react";
import { useHistory } from "react-router-dom";

const usePrevLocation = () => {
  const history = useHistory();
  const [prevLocation, setPrevLocation] = useState("");

  history.listen((location) => {
    if (location) {
      setPrevLocation(location?.pathname);
    }
  });

  return prevLocation;
};

export default usePrevLocation;
