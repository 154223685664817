import classNames from "classnames";
import { Wrapper } from "./radio-circle.styles";

const RadioCircle = ({ active, ...rest }) => {
  const classnames = classNames("radio-circle", {
    "is-active": active,
  });

  return <Wrapper className={classnames} {...rest} />;
};

export default RadioCircle;
