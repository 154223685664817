import icons from "../../../../ui/icons";
import { Wrapper } from "./Icons.styles";

const Icons = () => {
  return (
    <Wrapper className="icons">
      <div className="icons__list">
        {Object.keys(icons).map((key, index) => {
          const Icon = icons[key];

          return (
            <div key={index}>
              <p>{key}</p>
              <Icon />
            </div>
          );
        })}
      </div>
    </Wrapper>
  );
};

export default Icons;
