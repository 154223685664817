import { customFetch, isObject } from "utils";
import { rootPages } from "../../../../constants";
import handleError from "utils/handleError";
import { encodeToBase64 } from "utils/base64Encoding";
import { RequestMethods } from "../../../../utils/customFetch";
import {
  ResetPasswordPayload,
  ResetPasswordStatus,
  SendPasswordFormValues,
} from "../../ResetPassword.types";
import { FormikProps } from "formik/dist/types";

export const handleSubmit = async (
  data: ResetPasswordPayload,
  {
    history,
    formik,
  }: {
    history: any;
    formik: FormikProps<SendPasswordFormValues>;
  }
) => {
  const [, err] = await fetchSendPassword(data);

  if (err) {
    // @TODO Probably this code works wrong,
    // @TODO cause we store error as string inside the array, recheck logic and test it
    if (isObject(err)) {
      if (err?.status === 400) {
        history.push(`/${rootPages.RESET_PASSWORD}/link-not-valid`);
      } else {
        formik.setSubmitting(false);
        handleError(err, formik);
        return;
      }
    } else if (err?.includes("has been already used")) {
      history.push(`/${rootPages.RESET_PASSWORD}/link-already-used`);
    }
  } else {
    history.push(`/${rootPages.RESET_PASSWORD}/reset-complete`);
  }
};

export const fetchSendPassword = async ({
  password,
  token,
}: ResetPasswordPayload) => {
  const preparedData = {
    password: encodeToBase64(password),
  };

  try {
    return await customFetch({
      method: RequestMethods.POST,
      url: `auth/passwords/resets/${token}/finish`,
      data: preparedData,
    });
  } catch (e) {
    console.error("fetchSendPassword error, try again later", e);
  }
};

export async function checkResetPasswordStatus(token: string) {
  const [, error, code] = await customFetch({
    method: RequestMethods.GET,
    url: `auth/passwords/resets/${token}/is-valid`,
  });

  if (error) {
    if (
      code === 400 &&
      typeof error === "string" &&
      error.includes("has been already used")
    ) {
      return ResetPasswordStatus.USED;
    }
    return ResetPasswordStatus.ERROR;
  }
  return ResetPasswordStatus.OK;
}
