import { defaultAmountsAndMeasures } from "../../../../constants";
import convertCatalogToCat from "../../../../utils/convetCatalogToCat";

export const prepareList = (list) => {
  return list.map(({ product, prices }) => {
    const defaultAmountAndMeasure =
      defaultAmountsAndMeasures[convertCatalogToCat(product?.catalog)];

    return {
      productType: convertCatalogToCat(product?.catalog),
      code: product?.code,
      catalog: product?.catalog,
      quantity: 1,
      amount: defaultAmountAndMeasure.amount,
      measure: defaultAmountAndMeasure.measure,
      mode: "Add",
      cdId: product?.cdId,
      stock: product?.stock,
      smile: product?.smile,
      name: product?.name,

      product,
      prices: prices,
    };
  });
};

export const prepareData = (props) => {
  const { query, currency, sstype, cat, type } = props;

  if (!sstype) {
    return {
      query,
      currency,
    };
  } else {
    return {
      query,
      structureSearchType: sstype,
      searchType: type,
      productType: cat,
      currency,
    };
  }
};
