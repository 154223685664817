import React from "react";
import PoNumber from "../../../../parts/form-inputs/po-number/PoNumber";
import Comment from "../../../../parts/form-inputs/comment/Comment";
import { InputWrapper, Title, Wrapper } from "./Additional.styles";

const Additional = ({ formik }) => {
  return (
    <Wrapper>
      <Title>Additional info</Title>
      <InputWrapper>
        <PoNumber formik={formik} />
      </InputWrapper>

      <InputWrapper>
        <Comment formik={formik} />
      </InputWrapper>
    </Wrapper>
  );
};

export default Additional;
