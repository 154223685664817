import styled from "styled-components";
import { darkBlue, blue, lightBlue, accentColor } from "ui/colors";

export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 0 84px 0;
  gap: 20px;
  background-color: ${lightBlue};
  align-items: center;
`;

export const Feature = styled.div`
  max-width: 1140px;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 40px;
`;

export const FeatureDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const FeatureTitle = styled.h3`
  margin: 0;
  color: ${darkBlue};
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
`;

export const Description = styled.p`
  margin: 0;
  color: ${blue};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 20px;
`;

export const FeatureIcon = styled.div`
  display: flex;
  float: left;
  padding: 0 20px;
  height: 76px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: ${lightBlue};
  svg {
    width: 36px;
    height: 36px;
  }
`;

export const Link = styled.a`
  color: ${accentColor};
`;
